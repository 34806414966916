/* eslint-disable @typescript-eslint/no-explicit-any */
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

const enterpriseRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: (): Promise<any> =>
      import('./enterprise-dashboard/enterprise-dashboard.module').then((m) => m.EnterpriseDashboardModule),
  },
  {
    path: 'companies',
    loadChildren: (): Promise<any> => import('./companies/companies.module').then((m) => m.CompaniesModule),
  },
  {
    path: 'invite',
    loadChildren: (): Promise<any> =>
      import('./../invite-users-enterprise/invite-users-enterprise.module').then((m) => m.InviteUsersEnterpriseModule),
  },
  {
    path: 'profile',
    loadChildren: (): Promise<any> => import('./profile/profile.module').then((m) => m.ProfileModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(enterpriseRoutes)],
  exports: [RouterModule],
})
export class EnterpriseRouting {}
