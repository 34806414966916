import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import ApexCharts from 'apexcharts';
import { MaterialityTheme } from '../../models/scoring.model';
import { EeveryTranslateService } from '../../../../shared/eevery.translate.service';
import { CsrdDataPointPopupComponent } from '../popup/datapoint/csrd-datapoint-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { CsrdDataPointPopupModel } from '../popup/csrd-popup.model';

export interface Series {
  name: string;
  data: number[];
}

@Component({
  selector: 'app-csrd-materiality-chart',
  templateUrl: './csrd-materiality-chart.component.html',
  styleUrls: ['./csrd-materiality-chart.component.scss'],
})
export class CsrdMaterialityChartComponent implements OnChanges, OnInit {
  @Input()
  matrixRankedThemes!: MaterialityTheme[];

  @Input()
  scaleScoresRelative = false;

  @Input()
  widgetStyling = false;

  @Input()
  minLabel = '';

  @Input()
  maxLabel = '';

  @Input()
  xaxisLabel = '';

  @Input()
  yaxisLabel = '';

  @Input()
  disablePopup = false;

  series: Series[] = [];

  chart!: ApexCharts;

  language = '';

  constructor(private translationService: EeveryTranslateService, private dialog: MatDialog) {}

  async ngOnInit(): Promise<void> {
    this.language = this.translationService.getLanguage().replace('_', '-').replace('UK', 'GB');
    await this.renderChart();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.matrixRankedThemes.currentValue) {
      this.series = [];

      if (this.chart) {
        await this.chart.updateOptions(this.getChartOptions());
      }
    }
  }

  public tryToRerenderChart(): void {
    this.chart?.resetSeries();
  }

  private async renderChart(): Promise<void> {
    this.chart = new ApexCharts(document.querySelector('#chart-csrd-materiality'), this.getChartOptions());
    await this.chart.render();
  }

  getChartOptions(): Record<string, unknown> {
    const series = this.getChartSeries();
    let [lowestXValue, highestXValue] = this.scaleScoresRelative
      ? this.matrixRankedThemes.reduce(
          ([min, max], score) => [Math.min(min, score.financialIndex), Math.max(max, score.financialIndex)],
          [this.matrixRankedThemes[0]?.financialIndex ?? 0, this.matrixRankedThemes[0]?.financialIndex ?? 0],
        )
      : [0, this.matrixRankedThemes.length];
    let [lowestYValue, highestYValue] = this.scaleScoresRelative
      ? this.matrixRankedThemes.reduce(
          ([min, max], score) => [Math.min(min, score.impactIndex), Math.max(max, score.impactIndex)],
          [this.matrixRankedThemes[0]?.financialIndex ?? 0, this.matrixRankedThemes[0]?.financialIndex ?? 0],
        )
      : [0, this.matrixRankedThemes.length];

    if (this.scaleScoresRelative && this.widgetStyling) {
      lowestXValue += 0.3;
      highestXValue += 0.5;
      lowestYValue += 0.3;
      highestYValue += 0.5;
    }

    const generalLabels = this.getThemeLabelsForCategory('general');
    const environmentLabels = this.getThemeLabelsForCategory('environment');
    const socialLabels = this.getThemeLabelsForCategory('social');
    const governanceLabels = this.getThemeLabelsForCategory('governance');
    const { minLabel, maxLabel, xaxisLabel, yaxisLabel, disablePopup } = this;
    const openDatapointPopup = this.getOpenDatapointPopup();

    // @ts-ignore
    return {
      series,
      chart: {
        width: '100%',
        height: this.widgetStyling ? '100%' : 325,
        redrawOnWindowResize: true,
        type: 'scatter',
        offsetY: this.widgetStyling ? -4 : 0,
        offsetX: this.widgetStyling ? -2 : 0,
        zoom: { enabled: false },
        toolbar: { show: false },
        events: {
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          async dataPointSelection(event, chartContext, config) {
            if (!disablePopup && config.seriesIndex !== undefined && config.dataPointIndex !== undefined) {
              (await openDatapointPopup)(config.w.config.series[config.seriesIndex].data[config.dataPointIndex]);
            }
          },
        },
      },
      grid: { show: false },
      tooltip: {
        enabled: true,
        style: {
          fontSize: '14px',
          fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
        },
        marker: { show: false },
        // @ts-ignore
        // eslint-disable-next-line no-shadow,@typescript-eslint/no-unused-vars
        custom({ series, seriesIndex, dataPointIndex, w }): string {
          let arrayToUse;

          if (w.config.series[seriesIndex].name === 'General') {
            arrayToUse = generalLabels;
          } else if (w.config.series[seriesIndex].name === 'Environment') {
            arrayToUse = environmentLabels;
          } else if (w.config.series[seriesIndex].name === 'Social') {
            arrayToUse = socialLabels;
          } else if (w.config.series[seriesIndex].name === 'Governance') {
            arrayToUse = governanceLabels;
          }

          if (arrayToUse && arrayToUse[dataPointIndex]) {
            return `<div style='padding: 4px;'><span>${arrayToUse[dataPointIndex]}</span></div>`;
          }

          return '';
        },
      },
      dataLabels: {
        enabled: false,
        offsetY: -6,
        distributed: false,
        style: {
          fontSize: '12px',
          fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
          fontWeight: 400,
          lineHeight: '17px',
          colors: [],
        },
        background: { enabled: false },
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        formatter(val, { seriesIndex, dataPointIndex, w }): string {
          let arrayToUse;

          if (w.config.series[seriesIndex].name === 'General') {
            arrayToUse = generalLabels;
          } else if (w.config.series[seriesIndex].name === 'Environment') {
            arrayToUse = environmentLabels;
          } else if (w.config.series[seriesIndex].name === 'Social') {
            arrayToUse = socialLabels;
          } else if (w.config.series[seriesIndex].name === 'Governance') {
            arrayToUse = governanceLabels;
          }

          if (arrayToUse && arrayToUse[dataPointIndex]) {
            return arrayToUse[dataPointIndex];
          }

          return val;
        },
      },
      legend: { show: false },
      xaxis: {
        title: {
          text: xaxisLabel,
          offsetY: this.widgetStyling ? -9 : 0,
          offsetX: this.widgetStyling ? -21 : 0,
          style: {
            fontSize: '14px',
            fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
            fontWeight: 700,
            lineHeight: '19px',
            color: '#1C2B35',
          },
        },
        crosshairs: { show: false },
        tooltip: { enabled: false },
        axisBorder: { show: true },
        tickAmount: 1,
        tickPlacement: 'on',
        stepSize: highestXValue - lowestXValue + 1,
        labels: {
          formatter(value: number): string {
            if (value === lowestXValue - 1) {
              return minLabel;
            }
            if (value === highestXValue + 1 || value === highestXValue) {
              return maxLabel;
            }

            return '';
          },
          style: {
            colors: '#1C2B35',
            fontSize: '13px',
            fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
            fontWeight: 400,
          },
        },
        min: lowestXValue - 1,
        max: highestXValue,
      },
      yaxis: {
        title: {
          text: yaxisLabel,
          offsetX: this.widgetStyling ? 10 : 0,
          style: {
            fontSize: '14px',
            fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
            fontWeight: 700,
            lineHeight: '19px',
            color: '#1C2B35',
          },
        },
        axisBorder: { show: true },
        tickAmount: 2,
        labels: {
          formatter(value: number): string {
            if (value === lowestYValue - 1) {
              return minLabel;
            }
            if (value === highestYValue + 1 || value === highestYValue) {
              return maxLabel;
            }

            return '';
          },
          style: {
            colors: ['#1C2B35'],
            fontSize: '13px',
            fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
            fontWeight: 400,
          },
        },
        min: lowestYValue - 1,
        max: highestYValue,
      },
    };
  }

  private async getOpenDatapointPopup() {
    return (datapointValue: number[]): void => {
      const data: CsrdDataPointPopupModel = {
        datapointValue,
        language: this.language,
        matrixRankedThemes: this.matrixRankedThemes,
      };

      this.dialog.open(CsrdDataPointPopupComponent, {
        width: '60vw',
        autoFocus: false,
        data,
      });
    };
  }

  private getChartSeries(): Record<string, unknown>[] {
    return [
      {
        name: 'General',
        data: this.getSeriesForCategory('general'),
        color: '#4440E2',
      },
      {
        name: 'Environment',
        data: this.getSeriesForCategory('environment'),
        color: '#00BAFF',
      },
      {
        name: 'Social',
        data: this.getSeriesForCategory('social'),
        color: '#FF45E6',
      },
      {
        name: 'Governance',
        data: this.getSeriesForCategory('governance'),
        color: '#FFCA38',
      },
    ];
  }

  private getSeriesForCategory(category: string): number[][] {
    const series: number[][] = [];

    this.matrixRankedThemes.forEach((theme) => {
      if (theme.category === category) {
        series.push([theme.financialIndex, theme.impactIndex]);
      }
    });

    return series;
  }

  private getThemeLabelsForCategory(category: string): string[] {
    const labels: string[] = [];

    this.matrixRankedThemes.forEach((theme) => {
      if (theme.category === category) {
        if (this.language === 'nl-NL') {
          labels.push(theme.standardNameNL);
        } else {
          labels.push(theme.standardNameUK);
        }
      }
    });

    return labels;
  }
}
