import { Action } from '../../../shared/xs/actions';
import { Role } from '../../../dashboard/profile/models/role';

export interface SetProfilePayload {
  email: string;
  firstName: string;
  lastName: string;
  language: string;
  roles: Role[];
}

export interface SetCompanyPayload {
  name: string;
  draft: boolean;
  type: string;
  country: string;
}

export class LoginAsCompanySuccessAction implements Action {
  static readonly type = '[Enterprise] Login as sme successful';

  constructor(public profilePayload: SetProfilePayload, public companyPayload: SetCompanyPayload) {}
}
