import { Injectable } from '@angular/core';
import { AbstractService } from 'src/app/shared/services/abstract.service';
import { SliderGroupState } from './xs/slider-group.state';
import { SetSliderPositionsAction } from './xs/set-slider-positions-action';

@Injectable({ providedIn: 'root' })
export class SliderGroupService extends AbstractService {
  formatName(alias: string): string {
    if (alias && alias !== '') {
      // it will format dates from "ESG - 2 Jan 2024" to "2 Jan '24"
      const splitAlias = alias.split(' - ');
      const date = splitAlias[1];
      const splitDate = date.split(' ');
      const day = splitDate[0];
      const month = splitDate[1];
      const year = splitDate[2];
      const formattedYear = year.slice(2);

      return `${day} ${month} '${formattedYear}`;
    }

    return alias;
  }

  formatNameFullYear(alias: string): string {
    if (alias && alias !== '') {
      // it will format dates from "ESG - 2 Jan 2024" to "2 Jan '24"
      const splitAlias = alias.split(' - ');
      const date = splitAlias[1];
      const splitDate = date.split(' ');
      const day = splitDate[0];
      const month = splitDate[1];
      const year = splitDate[2];

      return `${day} ${month} ${year}`;
    }

    return alias;
  }

  getPositionIndex(): (number | undefined)[] {
    return this.store.selectSnapshot(SliderGroupState.getPosition);
  }

  setPositionIndex(positionIndex: number | undefined, optionsLength: number | undefined, origin: number): void {
    this.store.dispatch(new SetSliderPositionsAction({ positionIndex, optionsLength, origin }));
  }
}
