import { Action } from '../../../shared/xs/actions';
import { ScoringStatus } from '../../scoreboard';
import { QuestionnaireType } from '../../scoreboard/models/questionnaire.type';
import { AssessmentVersion } from '../../scoreboard/models/scoring.model';

export class GoToQuestionnaireAction implements Action {
  static readonly type = '[Dashboard] Update current scoring';

  constructor(public payload: GoToQuestionnaireActionPayload) {}
}

export interface GoToQuestionnaireActionPayload {
  id: string;
  status: ScoringStatus;
  type: QuestionnaireType;
  version: AssessmentVersion;
  improvementsId?: string;
  disabled: boolean;
  unfinished: boolean;
  viewOnly: boolean;
}
