import { AbstractService } from '../../shared/services/abstract.service';
import { CompanyState, CompanyStateModel } from './xs/company.state';
import { GetCompanyResponse } from './xs/actions/company/get-company.action';
import {
  LookupCompanyActionPayload,
  LookupCompanyActionPayloadResponse,
} from './xs/actions/company/lookup-company.action';
import {
  UpdateCompanyActionPayload,
  UpdateCompanyActionPayloadV2,
  UpdateCompanyActionResponse,
} from './xs/actions/company/update-company.action';
import { RefreshLogoAction } from './xs/actions/company/refresh-logo.action';
import { SearchCompany, SearchCompanyResponse } from './models/search-company.model';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { SubscriptionStatus } from './xs/subscription-status.enum';
import {
  SubmitCompanyProfileActionPayload,
  SubmitCompanyProfileActionResponse,
} from '../../company-profile/xs/submit-company-profile.action';
import { GetEnterpriseActionPayload } from './xs/actions/company/get-enterprise.action';
import { CompanyProfileState } from '../../company-profile/xs/company-profile.state';
import { UserToInviteModel } from '../../company-profile/invite/user-to-invite.model';

@Injectable({ providedIn: 'root' })
export class CompanyService extends AbstractService {
  public async refreshCompany(): Promise<CompanyStateModel> {
    const result = await this.http.get(`${this.apiBaseUrl}/companies/current`).toPromise();

    return result as GetCompanyResponse;
  }

  public searchCompany(request: SearchCompany): Observable<SearchCompanyResponse[]> {
    if (request.country && request.query) {
      return this.http.get<SearchCompanyResponse[]>(
        `${this.apiBaseUrl}/companies/search/${request.country}/${request.query}`,
      );
    }

    return of([]);
  }

  public async lookupCompany(payload: LookupCompanyActionPayload): Promise<LookupCompanyActionPayloadResponse> {
    const result: LookupCompanyActionPayloadResponse = (await this.http
      .get(`${this.apiBaseUrl}/companies/lookup/${payload.country}/${payload.registeredId}`)
      .toPromise()) as LookupCompanyActionPayloadResponse;

    result.dataImmutable = true;

    return result;
  }

  public getCompany(): CompanyStateModel {
    return this.store.selectSnapshot(CompanyState.getCompany);
  }

  public getSubscriptionStatus(): SubscriptionStatus {
    return this.store.selectSnapshot(CompanyState.getSubscriptionStatus);
  }

  public async patchCompany(
    payload: UpdateCompanyActionPayload | UpdateCompanyActionPayloadV2,
  ): Promise<UpdateCompanyActionResponse> {
    const result = await this.http.patch(`${this.apiBaseUrl}/companies`, payload).toPromise();

    return result as UpdateCompanyActionResponse;
  }

  public async saveCompany(
    payload: UpdateCompanyActionPayload | UpdateCompanyActionPayloadV2,
  ): Promise<UpdateCompanyActionResponse> {
    const result = await this.http.put(`${this.apiBaseUrl}/companies`, payload).toPromise();

    return result as UpdateCompanyActionResponse;
  }

  public async submitCompanyProfile(
    payload: SubmitCompanyProfileActionPayload,
  ): Promise<SubmitCompanyProfileActionResponse> {
    const result = await this.http.put(`${this.apiBaseUrl}/companies`, payload).toPromise();

    return result as SubmitCompanyProfileActionResponse;
  }

  public async uploadLogo(file: File): Promise<void> {
    const form = new FormData();
    const modifiedFile = await this.removeMetadata(file);

    form.append('file', modifiedFile);
    form.append('filename', modifiedFile.name);

    try {
      await this.http.post(`${this.apiBaseUrl}/upload/company/logo`, form).toPromise();
    } catch (e) {
      throw new Error();
    }
  }

  public async removeMetadata(file: File): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      const img = new Image();

      img.onload = (): void => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (ctx) {
          canvas.width = img.width;
          canvas.height = img.height;

          ctx.drawImage(img, 0, 0);

          canvas.toBlob((blob) => {
            if (blob) {
              const transformedFile = new File([blob], file.name, {
                type: 'image/png',
                lastModified: Date.now(),
              });

              resolve(transformedFile);
            } else {
              reject(new Error('Failed to convert canvas to blob.'));
            }
          }, 'image/png');
        } else {
          reject(new Error('Failed to create 2D context for canvas.'));
        }
      };

      img.onerror = (): void => {
        reject(new Error('Failed to load the image.'));
      };

      img.src = URL.createObjectURL(file);
    });
  }

  public async getLogo(): Promise<{ src: string | null }> {
    return (await this.http.get(`${this.apiBaseUrl}/upload/company/logo`).toPromise()) as {
      src: string;
    };
  }

  public async onLogoError(): Promise<string> {
    await this.dispatchActionAndWait(new RefreshLogoAction());

    // @ts-ignore
    return this.getCompany()?.logoSrc;
  }

  public async refreshEnterprise(): Promise<GetEnterpriseActionPayload> {
    return (await this.http.get(`${this.apiBaseUrl}/enterprises/current`).toPromise()) as GetEnterpriseActionPayload;
  }

  getUsersToInvite(): UserToInviteModel[] {
    return this.store.selectSnapshot(CompanyProfileState.usersToInvite);
  }

  isFreeTrialUser(): boolean {
    if (this.getCompany().trialEndDate !== null) {
      const trialDate = Date.parse(this.getCompany().trialEndDate ?? '');

      const currentDate = new Date().getTime();

      return trialDate > currentDate;
    }

    return false;
  }
}
