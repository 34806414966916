import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SetSliderPositionsAction } from './set-slider-positions-action';
import { LogoutSuccessAction } from '../../../../../auth/xs/actions/logout-success.action';
import { LoginAsCompanySuccessAction } from '../../../../../enterprise/xs/actions/login-as-company-success.action';
import { LogoutFromSmeSuccessAction } from '../../../../../enterprise/xs/actions/logout-from-sme-success.actions';
import { ScoreboardStateModel } from '../../../xs';

interface SliderGroupStateModel {
  positionIndex?: number | undefined;
  optionsLength?: number | undefined;
  origin?: number;
}

@State<SliderGroupStateModel>({ name: 'sliderGroup' })
@Injectable()
export class SliderGroupState {
  @Selector()
  static getPosition(state: SliderGroupStateModel): (number | undefined)[] {
    return [state.positionIndex, state.optionsLength, state.origin];
  }

  @Action(SetSliderPositionsAction)
  async setSliderPositions(ctx: StateContext<SliderGroupStateModel>, action: SetSliderPositionsAction): Promise<void> {
    ctx.patchState({
      positionIndex: action.payload.positionIndex,
      optionsLength: action.payload.optionsLength,
      origin: action.payload.origin,
    });
  }

  @Action([LogoutSuccessAction, LoginAsCompanySuccessAction, LogoutFromSmeSuccessAction])
  clearPositions(ctx: StateContext<ScoreboardStateModel>): void {
    ctx.setState({});
  }
}
