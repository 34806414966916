import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { first } from 'rxjs/operators';
import { concat, interval } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UpdateApplicationService {
  constructor(appRef: ApplicationRef, updates: SwUpdate) {
    if (updates.isEnabled) {
      const appIsStable$ = appRef.isStable.pipe(first((isStable) => isStable));
      const everyThirtySeconds$ = interval(30 * 1000);
      const everyThirtySecondsOnceAppIsStable$ = concat(appIsStable$, everyThirtySeconds$);

      everyThirtySecondsOnceAppIsStable$.subscribe(() => {
        updates.checkForUpdate().then((updated) => {
          if (updated) {
            updates.activateUpdate().then(() => document.location.reload());
          }
        });
      });
    }
  }
}
