import { Component, OnInit } from '@angular/core';
import { SignupAction, SignupActionPayload } from '../../xs';
import { AuthService } from '../../auth.service';
import '../../../../assets/js/prevent-paste';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ResendConfirmEmailAction } from '../../xs/actions/resend-confirm.action';
import { EeveryTranslateService } from '../../../shared/eevery.translate.service';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({ height: '71px' })),
      state('close', style({ height: '0px' })),
      transition('* => *', [animate('0.5s')]),
    ]),
  ],
})
export class CreateAccountComponent implements OnInit {
  currentPage: Pages = Pages.SIGNUP;

  signupSuccess?: string;

  emailSent = false;

  serverError = false;

  user: SignupActionPayload & NonActiveSignupValues = {
    dataUsageApproved: false,
    confirmEmailValue: '',
    confirmPasswordValue: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    billingAddress: {
      city: '',
      name: '',
      houseNumber: '',
      street: '',
      postalCode: '',
      country: '',
    },
  };

  supportEmail = '';

  constructor(private authService: AuthService, private translateService: EeveryTranslateService) {}

  ngOnInit(): void {
    this.initSupportEmail();
  }

  async initSupportEmail(): Promise<void> {
    const emailSubject = await this.translateService.get('signup.email.subject');
    const emailBody = await this.translateService.get('signup.email.body');

    this.supportEmail = `mailto:support@eevery.co?Subject=${emailSubject}&Body=${emailBody}`;
  }

  back(): void {
    this.authService.navigateToPage('login');
  }

  nextPage(user: SignupActionPayload & NonActiveSignupValues): void {
    this.user = user;
    this.currentPage = Pages.BILLING;
  }

  async createAccount(user: SignupActionPayload & NonActiveSignupValues): Promise<void> {
    this.user = user;

    try {
      // eslint-disable-next-line prefer-destructuring
      this.user.language = this.translateService.getLanguage().split('_')[1];
      await this.authService.dispatchActionAndWait(new SignupAction(this.user));
      this.signupSuccess = this.user.email;
    } catch (e) {
      this.serverError = true;
    }
  }

  async resendEmail(): Promise<void> {
    if (this.signupSuccess && !this.emailSent) {
      await this.authService.dispatchActionAndWait(new ResendConfirmEmailAction({ email: this.signupSuccess }));
      this.emailSent = true;
    }
  }

  tpPage1(user: SignupActionPayload & NonActiveSignupValues): void {
    this.user = user;
    this.currentPage = Pages.SIGNUP;
  }
}

// eslint-disable-next-line no-shadow
export enum Pages {
  SIGNUP,
  BILLING,
}

// eslint-disable-next-line no-shadow
export interface NonActiveSignupValues {
  confirmEmailValue: string;
  confirmPasswordValue: string;
  dataUsageApproved: boolean;
}
