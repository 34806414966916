import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CancelCheckPopupComponent } from './cancel-check/cancel-check-popup.component';
import { PaymentService } from '../payment.service';
import { CompanyService } from '../company.service';
import { SubscriptionStatus } from '../xs/subscription-status.enum';
import { GetCompanyAction } from '../xs/actions/company/get-company.action';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
  status?: SubscriptionStatus;

  constructor(
    private dialog: MatDialog,
    private paymentService: PaymentService,
    private companyService: CompanyService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.companyService.dispatchActionAndWait(new GetCompanyAction());

    this.status = this.companyService.getSubscriptionStatus();
  }

  async cancel(): Promise<void> {
    const dialogref = this.dialog.open(CancelCheckPopupComponent, { autoFocus: false });

    dialogref.afterClosed().subscribe((cancel: boolean) => {
      if (cancel) {
        this.cancelSubscription();
      }
    });
  }

  private async cancelSubscription(): Promise<void> {
    await this.paymentService.cancelSubscription();

    await this.companyService.dispatchActionAndWait(new GetCompanyAction());

    this.status = this.companyService.getSubscriptionStatus();
  }

  async reactivateSubscription(): Promise<void> {
    await this.paymentService.reactivateSubscription();

    await this.companyService.dispatchActionAndWait(new GetCompanyAction());

    this.status = this.companyService.getSubscriptionStatus();
  }
}
