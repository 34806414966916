import { Action } from '../../../shared/xs/actions';

export interface CheckDashboardActionPayload {
  hasBeenChecked: boolean;
}

export class CheckDashboardAction implements Action {
  static readonly type = '[Dashboard] Dashboard checked';

  constructor(public payload: CheckDashboardActionPayload) {}
}
