import { Action, ActionResponse } from '../../../shared/xs/actions';
import { Role } from '../../../dashboard/profile/models/role';
import { ColourSchemeModel } from '../../../shared/sidenav';
import { PaymentRedirectionType } from './payment-redirection-type';

export interface LoginActionPayload {
  email: string;
  password: string;
}

export class LoginAction implements Action {
  static readonly type = '[Auth] Login';

  constructor(public payload: LoginActionPayload) {}
}

export interface LoginActionResponse extends ActionResponse {
  token: {
    accessToken: string;
    refreshToken: string;
  };
  profile: {
    email: string;
    firstName: string;
    lastName: string;
    language: string;
    roles: Role[];
  };
  company: {
    name: string;
    draft: boolean;
    type: string;
    country: string;
  };
  paymentCountry: string;
  cobranding: CobrandingModel;
  paymentComplete: boolean;
  paymentRedirectionType: PaymentRedirectionType;
  firstLogin: boolean;
  firstUser: boolean;
}

export interface CobrandingModel extends ColourSchemeModel {
  logo: string;
}
