import { Component } from '@angular/core';
import { AbstractQuestionDirective } from '../abstract-question.directive';
import { QuestionOptionsModel } from '../../models';

@Component({
  selector: 'app-select-number-question',
  templateUrl: './select-number-question.component.html',
  styleUrls: ['./select-number-question.component.scss'],
})
export class SelectNumberQuestionComponent extends AbstractQuestionDirective {
  optionSelected(id: number): void {
    this.answerModel.answer.optionId = id;
    this.answerModel.answer.value = undefined;
    this.answerChanged();
  }

  onBlur(): void {
    for (const option of this.question.options) {
      if (String(this.answerModel.answer.value) === option.option) {
        this.optionSelected(option.id);

        return;
      }
    }

    const manualInput = this.question.options.filter((o) => o.option === 'MANUAL_INPUT')[0];

    if (manualInput) {
      this.answerModel.answer.optionId = manualInput.id;
      this.answerChanged();

      return;
    }

    this.answerChanged();
  }

  filterOption(option: QuestionOptionsModel): boolean {
    return option.option !== 'MANUAL_INPUT';
  }
}
