import { Action } from '../../../../shared/xs/actions';
import { ImpactScanRevampModel } from '../impact-scan-revamp.model';

export interface UpdateImpactScanAnswerPayload {
  id: number;
  answers: ImpactScanRevampModel;
}

export class UpdateImpactScanAnswerAction implements Action {
  static readonly type = '[Impact Scan Revamp] Update impact scan answers';

  constructor(public payload: UpdateImpactScanAnswerPayload) {}
}
