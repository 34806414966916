import { Component, OnInit } from '@angular/core';

import { LogoutAction } from './auth';
import { AuthService } from './auth/auth.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { EeveryTranslateService } from './shared/eevery.translate.service';
import { Role } from './dashboard/profile/models/role';
import { UpdateApplicationService } from './shared/update-application.service';
import localeNl from '@angular/common/locales/nl';
import localeEnGb from '@angular/common/locales/en-GB';
import { registerLocaleData } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'oxygen-frontend';

  constructor(
    private readonly translate: EeveryTranslateService,
    private readonly authService: AuthService,
    private readonly updateService: UpdateApplicationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.translate.init();
    registerLocaleData(localeNl, 'nl-NL');
    registerLocaleData(localeEnGb, 'en-GB');
    this.matIconRegistry.addSvgIcon(
      'comment',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/comment.svg'),
    );
  }

  ngOnInit(): void {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          registration.unregister();
        }
      });
    }
  }

  logout(): void {
    this.authService.dispatchAction(new LogoutAction());
  }

  isViewer(): boolean {
    return (
      !!this.authService.getRoles()?.includes(Role.SMEViewer) &&
      !!this.authService.getRoles()?.includes(Role.Enterprise)
    );
  }
}
