export class UnansweredQuestionModel {
  id: string;

  category: string;

  categoryOrder: number;

  constructor(id: string, category: string, categoryOrder: number) {
    this.id = id;
    this.category = category;
    this.categoryOrder = categoryOrder;
  }
}
