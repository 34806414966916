import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  UpdateCompanyAction,
  UpdateCompanyActionPayload,
} from '../dashboard/profile/xs/actions/company/update-company.action';
import { GetCompanyAction } from '../dashboard/profile/xs/actions/company/get-company.action';
import { CompanyService } from '../dashboard';
import { CategoryListItemModel } from '../shared/questionnaire-header/category-list-item.model';
import { OnboardingPageDirective } from './onboarding.page.directive';

// eslint-disable-next-line no-shadow
enum OnboardingCategories {
  general,
  subsidiaries,
  products,
  governance,
  contact,
}

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit {
  navMenu = [
    {
      img: 'onboarding',
      title: 'onboarding',
      route: '/onboarding/sme',
    },
  ];

  categories: string[] = ['general', 'subsidiaries', 'products', 'governance', 'contact'];

  /* eslint-disable no-invalid-this */
  onboardingCategories: CategoryListItemModel[] = [
    {
      categoryName: 'onboarding.general.heading',
      isValid: (): boolean => this.isValidCategory(0),
    },
    {
      categoryName: 'onboarding.subsidiaries.heading',
      isValid: (): boolean => this.isValidCategory(1),
    },
    {
      categoryName: 'onboarding.products.heading',
      isValid: (): boolean => this.isValidCategory(2),
    },
    {
      categoryName: 'onboarding.governance.heading',
      isValid: (): boolean => this.isValidCategory(3),
    },
    {
      categoryName: 'onboarding.contact.heading',
      isValid: (): boolean => this.isValidCategory(4),
    },
  ];
  /* eslint-enable no-invalid-this */

  selected = OnboardingCategories.general;

  @ViewChild('page')
  currentPage!: OnboardingPageDirective;

  company: UpdateCompanyActionPayload & { loadedFromBackend: boolean | undefined } = {
    annualTurnover: 0,
    numberOfEmployees: 0,
    totalAssets: 0,
    contactEmail: '',
    controlType: '',
    executiveDirectors: 0,
    hasMultipleShareholders: null,
    hasNonExecutiveDirectors: null,
    draft: true,
    country: '',
    registeredId: '',
    name: '',
    legalEntityType: '',
    localSectorCode: '',
    loadedFromBackend: false,
    subsidiaries: [],
    products: [],
    services: [],
  };

  constructor(
    private readonly router: Router,
    private readonly companyService: CompanyService,
    public dialog: MatDialog,
  ) {}

  async ngOnInit(): Promise<void> {
    let finishedLoading;

    try {
      await this.companyService.dispatchActionAndWait(new GetCompanyAction());
      finishedLoading = true;
    } catch (e) {
      finishedLoading = false;
    }
    const companyFromState = { ...this.companyService.getCompany() };

    if (companyFromState) {
      this.company = JSON.parse(JSON.stringify(companyFromState));
      this.company.loadedFromBackend = finishedLoading;
    }
  }

  async finishOnboarding(company: UpdateCompanyActionPayload): Promise<void> {
    this.company.localSectorCode = company.localSectorCode;
    this.company.redirect = true;
    this.company.draft = false;

    this.companyService.dispatchAction(new UpdateCompanyAction(this.company));
  }

  patchCompany(company: UpdateCompanyActionPayload): void {
    const draftValue = this.company.draft;

    this.company = { ...this.company, ...company };
    this.company.draft = draftValue;
    this.companyService.dispatchAction(new UpdateCompanyAction(this.company));
  }

  previousPage(company: UpdateCompanyActionPayload): void {
    this.patchCompany(company);

    if (this.selected !== OnboardingCategories.general) {
      this.selected -= 1;
    }
    this.scrollToTop();
  }

  nextPage(company: UpdateCompanyActionPayload): void {
    this.patchCompany(company);

    if (this.selected !== OnboardingCategories.contact) {
      this.selected += 1;
    }
    this.scrollToTop();
  }

  scrollToTop(): void {
    const component = document.getElementById('page_container');

    if (component) {
      component.scrollTop = 0;
    }
  }

  getTitle(): string {
    return this.onboardingCategories[this.selected].categoryName;
  }

  isValidCategory(index: number): boolean {
    return this.selected > index;
  }

  getWhiteButtonText(): string | undefined {
    if (this.selected !== 0) {
      return 'onboarding.prev_page';
    }

    return undefined;
  }

  handleWhiteButtonClicked(): void {
    if (this.selected !== 0) {
      this.previousPage(this.company);
    }
  }

  getYellowButtonText(): string | undefined {
    if (this.selected !== 4) {
      return 'onboarding.next_page';
    }

    return undefined;
  }

  handleYellowButtonClicked(): void {
    this.currentPage.triggerNgSubmit();
  }

  getRedButtonText(): string | undefined {
    if (this.selected === 4) {
      return 'onboarding.finish';
    }

    return undefined;
  }

  handleRedButtonClicked(): void {
    this.currentPage.triggerNgSubmit();
  }
}
