import { Action, ActionResponse } from '../../../shared/xs/actions';

export class RefreshTokenAction implements Action {
  static readonly type = '[Auth] Refresh Token';
}

export interface RefreshTokenActionResponse extends ActionResponse {
  accessToken: string;
}

export interface RefreshTokenRequest {
  email?: string;
  refreshToken?: string;
}
