import { AbstractService } from '../shared/services/abstract.service';
import { DashboardState, StartScoringResponse, SubmitScoringActionPayload } from './xs';
import { AssessmentVersion, LastAssessmentMetadata, ScoringModel } from './scoreboard/models/scoring.model';
import { ImpactListModel } from './assessment-list/impact-list.model';
import { PrefilledAssessmentModel, StartAssessmentModel } from './assessment-list/start-assessment.model';
import { Injectable } from '@angular/core';
import { ScoringStatus } from './scoreboard';
import { EsgAssessmentModel } from './questionnaire/questionaire/esg-prefill-popup/esg-prefill.model';

@Injectable({ providedIn: 'root' })
export class DashboardService extends AbstractService {
  public async startImpact(): Promise<StartScoringResponse> {
    return (await this.http.post(`${this.apiBaseUrl}/impact-scan`, {}).toPromise()) as StartScoringResponse;
  }

  public async startScoring(startAssessmentModel: StartAssessmentModel): Promise<StartScoringResponse> {
    const uri = startAssessmentModel.version === AssessmentVersion.MANAGED ? 'assessments/esg' : 'assessment';

    return (await this.http
      .post(`${this.apiBaseUrl}/${uri}`, startAssessmentModel)
      .toPromise()) as StartScoringResponse;
  }

  public async getScoringFromBackend(id: string): Promise<ScoringModel> {
    return (await this.http.get(`${this.apiBaseUrl}/scorings/${id}`).toPromise()) as ScoringModel;
  }

  public async submitScoring(scoring: SubmitScoringActionPayload): Promise<void> {
    await this.http.post(`${this.apiBaseUrl}/assessment/${scoring.id}/submit`, {}).toPromise();
  }

  public isFirstDashboardCheck(): boolean {
    return this.store.selectSnapshot(DashboardState.isFirstDashboardCheck);
  }

  public isFirstAssessmentsCheck(): boolean {
    return this.store.selectSnapshot(DashboardState.isFirstAssessmentsCheck);
  }

  public hasImpact(): boolean {
    return this.store.selectSnapshot(DashboardState.hasImpact);
  }

  public hasAssessment(): boolean {
    return this.store.selectSnapshot(DashboardState.hasAssessment);
  }

  public getOldAssessmentList(): ImpactListModel[] {
    return this.store.selectSnapshot(DashboardState.assessmentListOld);
  }

  public getRevampedAssessmentList(): ImpactListModel[] {
    return this.store.selectSnapshot(DashboardState.assessmentListRevamp);
  }

  async getAssessmentListFromBackend(): Promise<ImpactListModel[]> {
    const result = await this.http.get(`${this.apiBaseUrl}/scorings/assessment_list`).toPromise();

    return result as ImpactListModel[];
  }

  async getAssessmentListRevampFromBackend(): Promise<ImpactListModel[]> {
    const result = await this.http.get(`${this.apiBaseUrl}/scorings/assessment_list_v2`).toPromise();

    return result as ImpactListModel[];
  }

  async getAllCompletedEsgAssessments(): Promise<EsgAssessmentModel[]> {
    return (await this.http.get(`${this.apiBaseUrl}/assessments/esg/completed`).toPromise()) as EsgAssessmentModel[];
  }

  async startPrefilledEsgAssessment(assessmentModel: PrefilledAssessmentModel): Promise<StartScoringResponse> {
    return (await this.http
      .post(`${this.apiBaseUrl}/assessments/esg/clone`, assessmentModel)
      .toPromise()) as StartScoringResponse;
  }

  async getManagedAssessmentListFromBackend(): Promise<ImpactListModel[]> {
    const result = await this.http.get(`${this.apiBaseUrl}/assessments/esg`).toPromise();

    return result as ImpactListModel[];
  }

  // eslint-disable-next-line consistent-return
  public async scoreboardInit(lastAssessmentMetadata: LastAssessmentMetadata): Promise<ScoringModel | undefined> {
    if (lastAssessmentMetadata.firstId) {
      return this.getScoringFromBackend(lastAssessmentMetadata.firstId);
    }
  }

  public getLastAssessmentMetadata(list: ImpactListModel[]): LastAssessmentMetadata {
    let firstId: string | undefined;
    let assessmentVersion = AssessmentVersion.REVAMP;

    for (const impact of list) {
      if (impact.unfinished) {
        // eslint-disable-next-line no-continue
        continue;
      }
      for (const assessment of impact.assessmentList) {
        if (assessment.viewOnly && !assessment.unfinished) {
          assessmentVersion = assessment.assessmentVersion;
          firstId = assessment.id;
          break;
        }
      }

      if (firstId) {
        break;
      }
    }

    return {
      assessmentVersion,
      firstId,
    };
  }

  impactNotDraft(): boolean {
    const assessments = this.getRevampedAssessmentList();
    let notDraft = false;

    assessments.forEach((impact) => {
      if (impact.status !== ScoringStatus.DRAFT && impact.status !== ScoringStatus.ANSWERS_LOCKED) {
        notDraft = true;
      }
    });

    return notDraft;
  }

  getNavigationTarget(): string {
    return this.store.selectSnapshot(DashboardState.navigationTarget);
  }
}
