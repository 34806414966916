import { AbstractService } from '../shared/services/abstract.service';
import { ImprovementModel, SaveImprovement } from './models/improvement.model';
import { ImprovementCentreState } from './xs/improvement-centre.state';
import { ImprovementDtoModel } from './models/improvement-dto.model';
import { UserStatus } from '../dashboard/assessment-list/user-status.enum';
import { Injectable } from '@angular/core';
import { Topic } from '../dashboard/scoreboard/models/topic.model';
import { Upload } from '../shared/questions';

@Injectable({ providedIn: 'root' })
export class ImprovementCentreService extends AbstractService {
  public isFirstImprovementCheck(): boolean {
    return this.store.selectSnapshot(ImprovementCentreState.isFirstImprovementCheck);
  }

  getImprovements(): ImprovementModel[] | undefined {
    return this.store.selectSnapshot(ImprovementCentreState.getImprovements);
  }

  getFullName(): string {
    return this.store.selectSnapshot(ImprovementCentreState.getFullName);
  }

  getStatus(): UserStatus | undefined {
    return this.store.selectSnapshot(ImprovementCentreState.getStatus);
  }

  async getAllImprovements(): Promise<Topic[]> {
    const result = await this.http.get(`${this.apiBaseUrl}/assessments/esg/improvements`).toPromise();

    return result as Topic[];
  }

  async getLatestImprovements(): Promise<ImprovementDtoModel> {
    const result = await this.http.get(`${this.apiBaseUrl}/improvement/latest`).toPromise();

    return result as ImprovementDtoModel;
  }

  async getSpecificImprovements(id: string): Promise<ImprovementDtoModel> {
    const result = await this.http.get(`${this.apiBaseUrl}/improvement/${id}`).toPromise();

    return result as ImprovementDtoModel;
  }

  getFileOld(name: string): Promise<Blob | undefined> {
    return this.http.get(`${this.apiBaseUrl}/improvement/file/${name}`, { responseType: 'blob' }).toPromise();
  }

  getUploadFile(id: string): Promise<Blob | undefined> {
    return this.http.get(`${this.apiBaseUrl}/improvement/file/upload/${id}`, { responseType: 'blob' }).toPromise();
  }

  getFile(id: string): Promise<Blob | undefined> {
    return this.http.get(`${this.apiBaseUrl}/files/${id}`, { responseType: 'blob' }).toPromise();
  }

  async completeImprovement(improvementId: string, assessmentId: string): Promise<void> {
    await this.http
      // eslint-disable-next-line camelcase
      .post(`${this.apiBaseUrl}/improvements/complete`, { improvement_id: improvementId, assessment_id: assessmentId })
      .toPromise();
  }

  async saveImprovement(improvement: SaveImprovement): Promise<void> {
    await this.http.post(`${this.apiBaseUrl}/improvements`, improvement).toPromise();
  }

  async saveComments(improvement: SaveImprovement): Promise<void> {
    await this.http.post(`${this.apiBaseUrl}/improvements/comments`, improvement).toPromise();
  }

  async uploadFiles(files: FileList, assessmentId: string): Promise<Upload[]> {
    const result: Upload[] = [];

    for (const file of Array.from(files)) {
      const form = new FormData();

      form.append('file', file);
      form.append('filename', file.name);

      try {
        // eslint-disable-next-line no-await-in-loop
        const res = (await this.http
          .post(`${this.apiBaseUrl}/upload/company/scoring/${assessmentId}`, form)
          .toPromise()) as Upload;

        result.push(res);
      } catch (e) {
        return [];
      }
    }

    return result;
  }
}
