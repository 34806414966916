/* eslint-disable @typescript-eslint/no-explicit-any */

import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SmeGuard } from './auth';
import { OnboardingGuard } from './auth/onboarding.guard';
import { AdminGuard } from './admin/admin.guard';
import { EnterpriseGuard } from './enterprise/enterprise.guard';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: (): Promise<any> => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'company-profile',
    canActivate: [OnboardingGuard],
    loadChildren: (): Promise<any> =>
      import('./company-profile/company-profile.module').then((m) => m.CompanyProfileModule),
  },
  {
    path: 'dashboard/sme',
    canActivate: [SmeGuard],
    loadChildren: (): Promise<any> => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'dashboard/enterprise',
    canActivate: [EnterpriseGuard],
    loadChildren: (): Promise<any> => import('./enterprise/enterprise.module').then((m) => m.EnterpriseModule),
  },
  {
    path: 'dashboard/sme/improvements',
    canActivate: [SmeGuard],
    loadChildren: (): Promise<any> =>
      import('./improvement-centre/improvement-centre.module').then((m) => m.ImprovementCentreModule),
  },
  {
    path: 'dashboard/sme/co2calculator',
    canActivate: [SmeGuard],
    loadChildren: (): Promise<any> =>
      import('./co2-calculator/co2-calculator.module').then((m) => m.Co2CalculatorModule),
  },
  {
    path: 'dashboard/sme/co2offsetting',
    canActivate: [SmeGuard],
    loadChildren: (): Promise<any> =>
      import('./co2-offsetting/co2-offsetting.module').then((m) => m.Co2OffsettingModule),
  },
  {
    path: 'admin',
    canActivate: [AdminGuard],
    loadChildren: (): Promise<any> => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'mha',
    loadChildren: (): Promise<any> => import('./auth/sso/sso.module').then((m) => m.SsoModule),
  },
  {
    path: '**',
    redirectTo: 'auth',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
