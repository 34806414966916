import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../../dashboard';
import { Role } from '../../dashboard/profile/models/role';
import { LogoutFromSmeAction } from '../../enterprise/xs/actions/logout-from-sme.action';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-view-border',
  templateUrl: './view-border.component.html',
  styleUrls: ['./view-border.component.scss'],
})
export class ViewBorderComponent implements OnInit {
  companyName?: string;

  showBorder = false;

  constructor(private authService: AuthService, private companyService: CompanyService) {}

  ngOnInit(): void {
    this.companyName = this.companyService.getCompany().clientName;
    this.showBorder = this.activate();
  }

  activate(): boolean {
    return (
      !!this.authService.getRoles()?.includes(Role.SMEViewer) &&
      !!this.authService.getRoles()?.includes(Role.Enterprise)
    );
  }

  logoutFromSme(): void {
    let refreshToken = this.authService.getRefreshToken();

    if (!refreshToken) {
      refreshToken = '';
    }

    this.authService.dispatchAction(new LogoutFromSmeAction({ refreshToken }));
  }
}
