import { Component, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DashboardService } from '../../dashboard.service';

@Component({
  selector: 'app-results-popup',
  templateUrl: './results-popup.component.html',
  styleUrls: ['./results-popup.component.scss'],
})
export class ResultsPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<ResultsPopupComponent>,
    private readonly ngZone: NgZone,
    private readonly dashboardService: DashboardService,
  ) {}

  close(): void {
    this.ngZone.run(() => this.dialogRef.close());
  }

  gotoImprovements(): void {
    this.ngZone.run(() => this.dashboardService.navigateToPage('/dashboard/sme/improvements'));
    this.close();
  }
}
