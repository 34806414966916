import { Component, OnInit } from '@angular/core';
import { SubsidiaryModel } from '../../dashboard/profile/models/subsidiary.model';
import { InfoModel } from '../../shared/info/info.model';
import { CountryModel } from '../../shared/questions';
import { PopUpComponent } from '../../shared/pop-up/pop-up.component';
import countryJsonGB from 'src/assets/json/country-code-gb.json';
import countryJsonNL from '../../../assets/json/country-code-nl.json';
import { OnboardingPageDirective } from '../onboarding.page.directive';

@Component({
  selector: 'app-onboarding-subsidiaries',
  templateUrl: './subsidiaries.component.html',
  styleUrls: ['./subsidiaries.component.scss'],
})
export class SubsidiariesComponent extends OnboardingPageDirective implements OnInit {
  edit = true;

  countryList: CountryModel[] = countryJsonGB as CountryModel[];

  subsidiariesInfo: InfoModel = { oldGriStandards: ['2-1', '2-6', '207-4'] };

  addSubsidiary(): void {
    if (this.company?.subsidiaries.length >= 5) {
      return;
    }

    const sub: SubsidiaryModel = {
      country: '',
      includedInFinancial: false,
      includedInSustainability: false,
      name: '',
      sectorCode: '',
      localSectorCode: '',
    };

    this.company?.subsidiaries?.push(sub);
    this.onAnswerAdded();
  }

  async ngOnInit(): Promise<void> {
    if (this.translateService.getLanguage() === 'nl_NL') {
      this.countryList = countryJsonNL as CountryModel[];
    }
    /* eslint-disable */
    this.subsidiariesInfo.description = { explanation: await this.translateService.get('onboarding.subsidiaries.desc') };
    /* eslint-enable */
  }

  remove(index: number): void {
    this.company?.subsidiaries.splice(index, 1);
    this.onAnswerAdded();
  }

  employeesChange($event: number): void {
    if ($event > this.company.numberOfEmployees) {
      this.dialog.open(PopUpComponent, {
        width: '400px',
        autoFocus: false,
        data: {
          title: 'pop_up.employee_number_total.title',
          message: 'pop_up.employee_number_total.description',
        },
      });
    }
  }

  setLocalSectorCode(index: number, value: string): void {
    this.company.subsidiaries[index].localSectorCode = value;
    this.onAnswerAdded();
  }

  subTrack(index: number): string | number {
    return index;
  }
}
