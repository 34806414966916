import { Component, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Co2CalculatorPopupComponent } from '../co2-calculator-popup/co2-calculator-popup.component';

@Component({
  selector: 'app-co2-calculation-end-popup',
  templateUrl: './co2-calculation-end-popup.component.html',
  styleUrls: ['./co2-calculation-end-popup.component.scss'],
})
export class Co2CalculationEndPopupComponent {
  constructor(public dialogRef: MatDialogRef<Co2CalculatorPopupComponent>, private ngZone: NgZone) {}

  close(): void {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }
}
