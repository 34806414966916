import { Action } from '../../../shared/xs/actions';

export interface GetScoringActionPayload {
  id: string;
}

export class GetScoringAction implements Action {
  static readonly type = '[Scoring] get scoring';

  constructor(public payload: GetScoringActionPayload) {}
}
