import { Component, OnInit } from '@angular/core';
import html2canvas from 'html2canvas';
import JSPDF from 'jspdf';
import { CompanyService } from '../../profile';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-download-pdf-pop-up',
  templateUrl: './download-pdf-pop-up.component.html',
  styleUrls: ['./download-pdf-pop-up.component.scss'],
})
export class DownloadPdfPopUpComponent implements OnInit {
  companyName!: string;

  constructor(private companyService: CompanyService, public dialogRef: MatDialogRef<DownloadPdfPopUpComponent>) {}

  ngOnInit(): void {
    this.companyName = this.companyService.getCompany().name || '';
  }

  close(): void {
    this.dialogRef.close();
  }

  saveAggregated(): void {
    const data = document.getElementsByClassName('pdfContent')[0];

    html2canvas(data as HTMLElement, { scale: 2 }).then((canvas) => {
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new JSPDF('p', 'mm', 'a4');
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = pdf.internal.pageSize.getHeight();

      pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);

      pdf.save(`Eevery report ${this.companyName}.pdf`);
    });
  }
}
