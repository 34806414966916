import { Component, OnInit } from '@angular/core';
import { AbstractQuestionDirective } from '../abstract-question.directive';
import { CountryModel } from '../../models';
import { Concentration } from '../../models/answer.model';
import { EeveryTranslateService } from '../../../eevery.translate.service';
import { QuestionService } from '../../question.service';
import countryJsonGB from '../../../../../assets/json/country-code-gb.json';
import countryJsonNL from '../../../../../assets/json/country-code-nl.json';

@Component({
  selector: 'app-dropdown-country-with-input',
  templateUrl: './dropdown-country-with-input.component.html',
  styleUrls: ['./dropdown-country-with-input.component.scss'],
})
export class DropdownCountryWithInputComponent extends AbstractQuestionDirective implements OnInit {
  countryList: CountryModel[] = countryJsonGB as CountryModel[];

  filteredCountries: CountryModel[][] = [];

  countryConcentrations: Concentration[] = [];

  constructor(private translateService: EeveryTranslateService, protected questionService: QuestionService) {
    super(questionService);
  }

  ngOnInit(): void {
    if (this.translateService.getLanguage() === 'nl_NL') {
      this.countryList = countryJsonNL as CountryModel[];
    }
    super.ngOnInit();
    while (this.answerModel.answer.countryList.length < 5) {
      this.answerModel.answer.countryList.push({});
    }
    this.countryConcentrations = [...this.answerModel.answer.countryList];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const country of this.countryConcentrations) {
      this.filteredCountries.push([...this.countryList]);
    }
  }

  trackByIndex(index: number): number {
    return index;
  }

  onChange(): void {
    this.answerModel.answer.countryList = [...this.countryConcentrations];
    for (let i = 0; i < this.answerModel.answer.countryList.length; i++) {
      if (!this.answerModel.answer.countryList[i].code) {
        this.answerModel.answer.countryList.splice(i, 1);
        i -= 1;
      }
    }
    this.answerChanged();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter(event: any, i: number): void {
    this.filteredCountries[i] = this.countryList.filter((country) =>
      country.countryName.toLowerCase().includes(event.target.value.toLowerCase()),
    );
  }
}
