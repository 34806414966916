import { AbstractService } from '../../../shared/services/abstract.service';
import { Injectable } from '@angular/core';
import {
  ImpactScanModel,
  ImpactScanRevampDetailsModel,
  ImpactScanRevampModel,
  SubmitImpactScanResponseModel,
} from './impact-scan-revamp.model';
import { ImpactScanRevampState } from './impact-scan-revamp.state';
import { Observable } from 'rxjs';
import { GriEntityModel } from '../../../shared/relevant-theme/relevant-theme.model';

@Injectable({ providedIn: 'root' })
export class ImpactScanRevampService extends AbstractService {
  impactScanRevampUrl = 'impact-scan-v2';

  public getImpactScanIdFromState(): number | undefined {
    return this.store.selectSnapshot(ImpactScanRevampState.impactScanId);
  }

  public getImpactScanFromState(): ImpactScanRevampDetailsModel | undefined {
    return this.store.selectSnapshot(ImpactScanRevampState.impactScanRevampDetails);
  }

  public async startNewImpactScan(): Promise<ImpactScanRevampDetailsModel> {
    return (await this.http
      .post(`${this.apiBaseUrl}/${this.impactScanRevampUrl}`, {})
      .toPromise()) as ImpactScanRevampDetailsModel;
  }

  public async startPrefilledImpactScan(id: string): Promise<ImpactScanRevampDetailsModel> {
    return (await this.http
      .post(`${this.apiBaseUrl}/${this.impactScanRevampUrl}/${id}/clone`, {})
      .toPromise()) as ImpactScanRevampDetailsModel;
  }

  public async getImpactScan(id: number): Promise<ImpactScanRevampDetailsModel> {
    return (await this.http
      .get(`${this.apiBaseUrl}/${this.impactScanRevampUrl}/${id}`)
      .toPromise()) as ImpactScanRevampDetailsModel;
  }

  public async getAllCompletedImpactScans(): Promise<ImpactScanModel[]> {
    return (await this.http.get(`${this.apiBaseUrl}/${this.impactScanRevampUrl}`).toPromise()) as ImpactScanModel[];
  }

  public async saveAnswer(id: number, answers: ImpactScanRevampModel): Promise<ImpactScanRevampDetailsModel> {
    return (await this.http
      .put(`${this.apiBaseUrl}/${this.impactScanRevampUrl}/${id}/answer`, answers)
      .toPromise()) as ImpactScanRevampDetailsModel;
  }

  public async lockAnswers(id: number): Promise<ImpactScanRevampDetailsModel> {
    return (await this.http
      .put(`${this.apiBaseUrl}/${this.impactScanRevampUrl}/${id}/lock`, {})
      .toPromise()) as ImpactScanRevampDetailsModel;
  }

  public async submit(id: number): Promise<SubmitImpactScanResponseModel> {
    return (await this.http
      .put(`${this.apiBaseUrl}/${this.impactScanRevampUrl}/${id}/submit`, {})
      .toPromise()) as SubmitImpactScanResponseModel;
  }

  public getAddableThemes(id: number): Observable<GriEntityModel[]> {
    return this.http.get(`${this.apiBaseUrl}/${this.impactScanRevampUrl}/${id}/addable-themes`) as Observable<
      GriEntityModel[]
    >;
  }

  public async addTheme(id: number, theme: GriEntityModel[]): Promise<ImpactScanRevampDetailsModel> {
    return (await this.http
      .post(`${this.apiBaseUrl}/${this.impactScanRevampUrl}/${id}/add-themes`, theme)
      .toPromise()) as ImpactScanRevampDetailsModel;
  }

  public async removeTheme(id: number, theme: GriEntityModel): Promise<ImpactScanRevampDetailsModel> {
    return (await this.http
      .delete(`${this.apiBaseUrl}/${this.impactScanRevampUrl}/${id}/remove-theme`, { body: theme })
      .toPromise()) as ImpactScanRevampDetailsModel;
  }
}
