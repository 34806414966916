import { Component, OnInit } from '@angular/core';
import { AnswerSavedResponse, CountryModel, QuestionModel } from '../../models';
import { AbstractQuestionDirective } from '../abstract-question.directive';
import { EeveryTranslateService } from '../../../eevery.translate.service';
import { QuestionService } from '../../question.service';
import countryJsonGB from '../../../../../assets/json/country-code-gb.json';
import countryJsonNL from '../../../../../assets/json/country-code-nl.json';

@Component({
  selector: 'app-dropdown-country',
  templateUrl: './dropdown-country.component.html',
  styleUrls: ['./dropdown-country.component.scss'],
})
export class DropdownCountryComponent extends AbstractQuestionDirective implements OnInit {
  countryList: CountryModel[] = countryJsonGB as CountryModel[];

  constructor(private translateService: EeveryTranslateService, protected questionService: QuestionService) {
    super(questionService);
  }

  filteredCountries: CountryModel[][] = [];

  countriesInUI: string[] = [];

  payrollQuestions: QuestionModel[] = [];

  async ngOnInit(): Promise<void> {
    if (this.translateService.getLanguage() === 'nl_NL') {
      this.countryList = countryJsonNL as CountryModel[];
    }
    super.ngOnInit();
    this.countriesInUI = [...this.answerModel.answer.countryCodes];
    if (this.countriesInUI.length < 1) {
      this.countriesInUI.push('');
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const country of this.countriesInUI) {
      this.filteredCountries.push([...this.countryList]);
    }
    this.payrollQuestions = await this.questionService.getPayrollSpecificQuestions();
  }

  trackByIndex(index: number, item: string): string {
    return index + item;
  }

  addClicked(): void {
    this.countriesInUI.push('');
    this.filteredCountries.push([...this.countryList]);
  }

  clearClicked(index: number): void {
    if (this.countriesInUI.length === 1) {
      this.answerModel.answer.countryCodes[index] = '';
      this.countriesInUI[index] = '';
      this.countrySelected();
    } else {
      this.answerModel.answer.countryCodes.splice(index, 1);
      this.countriesInUI.splice(index, 1);
      this.answerChanged();
    }
  }

  countrySelected(): void {
    const tidyList: string[] = [];

    for (const countryCode of this.countriesInUI) {
      if (countryCode.length > 0 && !tidyList.includes(countryCode)) {
        tidyList.push(countryCode);
      }
    }
    this.answerModel.answer.countryCodes = [...tidyList];
    this.answerChanged();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter(event: any, i: number): void {
    this.filteredCountries[i] = this.countryList.filter((country) =>
      country.countryName.toLowerCase().includes(event.target.value.toLowerCase()),
    );
  }

  async answerChanged(): Promise<void> {
    this.answerModel.answer.type = this.question.type;
    const response: AnswerSavedResponse = await this.questionService.answer(this.answerModel);

    this.payrollQuestions = response.triggeredQuestions;
  }
}
