import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../auth/auth.service';
import { HelpPopupComponent } from './help-popup/help-popup.component';
import { ProfileService } from '../../dashboard';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent {
  constructor(private dialog: MatDialog, public authService: AuthService, public profileService: ProfileService) {}

  HELP(): void {
    this.dialog.open(HelpPopupComponent, { width: '60vw', autoFocus: false, panelClass: 'mobile-dialog' });
  }
}
