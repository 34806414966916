/* eslint-disable @typescript-eslint/no-explicit-any */
import { RouterModule, Routes } from '@angular/router';
import { OnboardingComponent } from './onboarding.component';
import { NgModule } from '@angular/core';

const onboardingRoutes: Routes = [
  {
    path: '',
    component: OnboardingComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(onboardingRoutes)],
  exports: [RouterModule],
})
export class OnboardingRouting {}
