import { Component, OnInit } from '@angular/core';
import { CompanyCardDirective } from '../company-card.directive';
import { SubsidiaryModel } from '../../../dashboard/profile/models/subsidiary.model';
import { CountryModel } from '../../questions';
import countryJsonGB from '../../../../assets/json/country-code-gb.json';
import countryJsonNL from '../../../../assets/json/country-code-nl.json';
import { EeveryTranslateService } from '../../eevery.translate.service';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-subsidiaries-card',
  templateUrl: './subsidiaries-card.component.html',
  styleUrls: ['./subsidiaries-card.component.scss'],
})
export class SubsidiariesCardComponent extends CompanyCardDirective<SubsidiaryModel[]> implements OnInit {
  editIndex = -1;

  countryList: CountryModel[] = countryJsonGB as CountryModel[];

  constructor(private translateService: EeveryTranslateService, public authService: AuthService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.translateService.getLanguage() === 'nl_NL') {
      this.countryList = countryJsonNL as CountryModel[];
    }
  }

  submitSubsidiary(subsidiary: SubsidiaryModel): void {
    if (!subsidiary.localSectorCode) {
      return;
    }
    this.submit();
  }

  disableEdit(): void {
    this.editIndex = -1;
  }

  removeSubsidiary(i: number): void {
    this.data.splice(i, 1);
    this.submit();
  }

  addSubsidiary(): void {
    this.data.push({
      country: '',
      includedInFinancial: false,
      includedInSustainability: false,
      name: '',
      sectorCode: '',
      localSectorCode: '',
    });

    this.editIndex = this.data.length - 1;
  }

  isBritish(subsidiary: SubsidiaryModel): boolean {
    return subsidiary.country.toUpperCase() === 'GB';
  }
}
