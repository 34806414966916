import { Component, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-onboarding-complete',
  templateUrl: './complete-popup.component.html',
  styleUrls: ['./complete-popup.component.scss'],
})
export class CompletePopupComponent {
  constructor(
    public dialogRef: MatDialogRef<CompletePopupComponent>,
    private authService: AuthService,
    private readonly ngZone: NgZone,
  ) {}

  close(): void {
    this.ngZone.run(() => this.dialogRef.close({ startImpact: false }));
  }

  startImpactScan(): void {
    this.ngZone.run(() => {
      this.dialogRef.close({ startImpact: true });
    });
  }
}
