import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RelevantStandard } from '../../../models/scoring.model';
import { CsrdPopupModel } from '../csrd-popup.model';

@Component({
  selector: 'app-csrd-frame-popup',
  templateUrl: './csrd-frame-popup.component.html',
  styleUrls: ['./csrd-frame-popup.component.scss'],
})
export class CsrdFramePopupComponent implements OnInit {
  category!: string;

  relevantStandards: RelevantStandard[] = [];

  sliderPositions: number[] = [];

  maxHeight = '300px';

  constructor(
    public dialogRef: MatDialogRef<CsrdFramePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CsrdPopupModel,
  ) {}

  ngOnInit(): void {
    this.category = this.data.category;
    this.relevantStandards = this.filterRelevantStandardsByCategory(this.data.relevantStandards, this.category);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private filterRelevantStandardsByCategory(
    relevantStandards: RelevantStandard[],
    category: string,
  ): RelevantStandard[] {
    return relevantStandards.filter((standard) => standard.category.toLowerCase() === category.toLowerCase());
  }
}
