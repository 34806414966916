import { NgModule } from '@angular/core';
import { CommentComponent } from './comment.component';
import { CommonModule } from '@angular/common';
import { MaterialDesignModule } from '../shared';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  exports: [CommentComponent],
  declarations: [CommentComponent],
  imports: [CommonModule, MaterialDesignModule, TranslateModule],
})
export class CommentModule {}
