import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnswerModel, AnswerSavedResponse, AnswerStatus, NotApplicableReason, QuestionModel } from '../models';
import { QuestionService } from '../question.service';

@Directive()
export abstract class AbstractQuestionDirective implements OnInit {
  @Input()
  question!: QuestionModel;

  @Input()
  countryCode: string | undefined;

  @Input()
  disabled = false;

  answerModel!: AnswerModel;

  @Input()
  validateCallBack!: (value: number | string) => boolean;

  @Output()
  addQuestions = new EventEmitter<QuestionModel[]>();

  @Output()
  removeQuestions = new EventEmitter<string[]>();

  @Output()
  currentSectionValidity = new EventEmitter<boolean>();

  constructor(protected questionService: QuestionService) {}

  // TODO - refactor to be stateful

  ngOnInit(): void {
    this.answerModel = {
      questionId: this.question.qid,
      answer: {
        optionIds: [],
        countryCodes: [],
        countryList: [],
        concentrationList: [],
        relationships: [],
        files: [],
        applicable: true,
      },
      status: AnswerStatus.DRAFT,
      comment: {
        question: this.question.qid,
        text: '',
        createdAt: Date.now(),
      },
    };
    if (this.countryCode) {
      this.answerModel.countryCode = this.countryCode;
    }
    if (this.question.answer) {
      this.answerModel.answer = this.question.answer.answer;
      this.answerModel.status = this.question.answer.status;
    }
  }

  async naChanged(naModel: { value: boolean; reason?: NotApplicableReason; explanation?: string }): Promise<void> {
    if (naModel.value) {
      this.answerModel.answer = {
        optionIds: [],
        countryCodes: [],
        countryList: [],
        concentrationList: [],
        relationships: [
          {
            name: '',
            countryCode: '',
          },
        ],
        files: [],
        applicable: !naModel.value,
        naReason: naModel.reason,
        naExplanation: naModel.explanation,
      };
    } else {
      this.answerModel.answer.applicable = !naModel.value;
    }
    await this.answerChanged();

    this.disabled = naModel.value;
  }

  async answerChanged(): Promise<void> {
    this.answerModel.answer.type = this.question.type;
    const response: AnswerSavedResponse = await this.questionService.answer(this.answerModel);

    if (response.triggeredQuestions.length > 0) {
      this.addQuestions.emit(response.triggeredQuestions);
    }
    if (response.untriggeredQuestionIds.length > 0) {
      this.removeQuestions.emit(response.untriggeredQuestionIds);
    }

    this.currentSectionValidity.emit(response.sectionValid);
  }
}
