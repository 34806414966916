import { RouterModule, Routes } from '@angular/router';
import { QuestionnaireComponent } from './questionnaire.component';
import { NgModule } from '@angular/core';
import { MaterialityComponent } from './materiality/materiality.component';

const questionnaireRoutes: Routes = [
  {
    path: '',
    redirectTo: 'economic',
    pathMatch: 'full',
  },
  {
    path: 'materiality',
    component: MaterialityComponent,
    pathMatch: 'full',
  },
  {
    path: ':category',
    component: QuestionnaireComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(questionnaireRoutes)],
  exports: [RouterModule],
})
export class QuestionnaireRouting {}
