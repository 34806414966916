import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubmitQuestionnairePopupModel } from './submit-questionnaire-popup.model';

@Component({
  selector: 'app-submit-questionnaire-popup',
  templateUrl: './submit-questionnaire-popup.component.html',
  styleUrls: ['./submit-questionnaire-popup.component.scss'],
})
export class SubmitQuestionnairePopupComponent {
  title = '';

  text = '';

  submitButton = '';

  cancelButton = '';

  constructor(
    public dialogRef: MatDialogRef<SubmitQuestionnairePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SubmitQuestionnairePopupModel,
    private readonly ngZone: NgZone,
  ) {
    this.title = data.title;
    this.text = data.text;
    this.submitButton = data.submitButton;
    this.cancelButton = data.cancelButton;
  }

  continue(): void {
    this.ngZone.run(() => this.dialogRef.close(true));
  }

  back(): void {
    this.ngZone.run(() => this.dialogRef.close(false));
  }
}
