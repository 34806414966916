import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { config } from '../../../environments/config';
import { Action, ActionResponse } from '../xs/actions';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export abstract class AbstractService {
  protected apiBaseUrl = config.apiUrl;

  protected appBaseUrl = config.appUrl;

  public constructor(public store: Store, public router: Router, public http: HttpClient) {}

  public dispatchAction(action: Action): void {
    this.store.dispatch(action);
  }

  public async dispatchActionAndWait(action: Action): Promise<ActionResponse> {
    return this.store.dispatch(action).toPromise();
  }

  public dispatchActionAndObserve(action: Action): Observable<ActionResponse> {
    return this.store.dispatch(action);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public navigateToPage(page: string, params?: any): void {
    this.router.navigate([page], { queryParams: params });
  }

  public startLoading(): void {
    document.body.classList.add('loading');
  }

  public stopLoading(): void {
    document.body.classList.remove('loading');
  }

  public isTooltipDisabled(element: HTMLElement): boolean {
    if (element.scrollWidth > element.offsetWidth) {
      // eslint-disable-next-line no-param-reassign
      element.style.textOverflow = 'ellipsis';

      return false;
    }

    // eslint-disable-next-line no-param-reassign
    element.style.textOverflow = 'unset';

    return true;
  }
}
