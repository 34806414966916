import { Action } from '../../../../shared/xs/actions';

export interface SubmitImpactScanActionPayload {
  id: number;
}

export class SubmitImpactScanAction implements Action {
  static readonly type = '[Impact Scan Revamp] Submit impact scan';

  constructor(public payload: SubmitImpactScanActionPayload) {}
}
