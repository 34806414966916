import { Component, OnInit } from '@angular/core';
import { AbstractQuestionDirective } from '../abstract-question.directive';

@Component({
  selector: 'app-multiple-choice-question',
  templateUrl: './multiple-choice-question.component.html',
  styleUrls: ['./multiple-choice-question.component.scss'],
})
export class MultipleChoiceQuestionComponent extends AbstractQuestionDirective implements OnInit {
  idOfNone: number | undefined = undefined;

  ngOnInit(): void {
    super.ngOnInit();
    for (const option of this.question.options) {
      if (option.option === 'None') {
        this.idOfNone = option.id;
      }
    }
  }

  singleSelected(id: number): void {
    this.answerModel.answer.optionId = id;
    if (id !== 1) {
      this.answerModel.answer.value = undefined;
    }
    this.answerChanged();
  }

  multiSelected(id: number): void {
    const index = this.answerModel.answer.optionIds.indexOf(id);

    if (index === -1) {
      if (id === this.idOfNone) {
        this.answerModel.answer.optionIds = [];
      } else if (this.idOfNone) {
        const indexToRemove = this.answerModel.answer.optionIds.indexOf(this.idOfNone);

        if (indexToRemove > -1) {
          this.answerModel.answer.optionIds.splice(indexToRemove, 1);
        }
      }
      this.answerModel.answer.optionIds.push(id);
    } else {
      this.answerModel.answer.optionIds.splice(index, 1);
    }
    this.answerChanged();
  }

  onBlur(): void {
    if (this.answerModel.answer.text) {
      this.answerModel.answer.optionId = undefined;
    }
    this.answerChanged();
  }
}
