import { Component, OnInit } from '@angular/core';
import { AbstractQuestionDirective } from '../abstract-question.directive';
import { CountryModel, NotApplicableReason, Relationship } from '../../models';
import { EeveryTranslateService } from '../../../eevery.translate.service';
import { QuestionService } from '../../question.service';
import countryJsonGB from '../../../../../assets/json/country-code-gb.json';
import countryJsonNL from '../../../../../assets/json/country-code-nl.json';
import { PopUpModel } from '../../../pop-up/pop-up.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopUpComponent } from '../../../pop-up/pop-up.component';

@Component({
  selector: 'app-relationship-question',
  templateUrl: './relationship-list.component.html',
  styleUrls: ['./relationship-list.component.scss'],
})
export class RelationshipListComponent extends AbstractQuestionDirective implements OnInit {
  relationships: Relationship[] = [];

  countryList: CountryModel[] = countryJsonGB as CountryModel[];

  constructor(
    private dialog: MatDialog,
    private translateService: EeveryTranslateService,
    protected questionService: QuestionService,
  ) {
    super(questionService);
  }

  ngOnInit(): void {
    if (this.translateService.getLanguage() === 'nl_NL') {
      this.countryList = countryJsonNL as CountryModel[];
    }
    super.ngOnInit();
    this.relationships = this.answerModel.answer.relationships;
    if (this.relationships.length === 0) {
      this.addRelationship();
    }
  }

  addRelationship(): void {
    if (this.relationships.length >= 5) {
      return;
    }

    this.relationships.push({
      name: '',
      countryCode: '',
    });
  }

  remove(index: number): void {
    if (this.disabled) {
      return;
    }
    this.relationships.splice(index, 1);
    this.answerChanged();
  }

  onBlur(): void {
    let totalPercent = 0;

    this.relationships.forEach((relationship) => {
      if (relationship.percentage && relationship.percentage >= 0) {
        totalPercent += relationship.percentage;
        if (totalPercent > 100) {
          // eslint-disable-next-line no-param-reassign
          relationship.percentage = undefined;
        }
      } else if (relationship.percentage && relationship.percentage < 0) {
        // eslint-disable-next-line no-param-reassign
        relationship.percentage = 0;
      }
    });
    if (totalPercent > 100) {
      this.showInvalidPopup();

      return;
    }
    this.answerModel.answer.relationships = this.relationships;
    this.answerChanged();
  }

  async naChanged(naModel: { value: boolean; reason?: NotApplicableReason; explanation?: string }): Promise<void> {
    await super.naChanged(naModel);
    this.relationships = this.answerModel.answer.relationships;
  }

  showPopUp(data: PopUpModel): MatDialogRef<PopUpComponent, unknown> {
    return this.dialog.open(PopUpComponent, { width: '400px', autoFocus: false, data });
  }

  showInvalidPopup(): void {
    const data: PopUpModel = {
      title: 'pop_up.max_percentage.title',
      message: 'pop_up.max_percentage.description',
    };

    this.showPopUp(data);
  }
}
