import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialDesignModule, SharedModule } from '../shared';
import { TranslateModule } from '@ngx-translate/core';
import { EnterpriseRouting } from './enterprise.routing';
import { RemoveCompanyPopupComponent } from './company-list/remove-company-popup/remove-company-popup.component';
import { CompanyRemovedPopupComponent } from './company-list/company-removed-popup/company-removed-popup.component';

@NgModule({
  declarations: [RemoveCompanyPopupComponent, CompanyRemovedPopupComponent],
  imports: [EnterpriseRouting, MaterialDesignModule, TranslateModule, CommonModule, SharedModule],
})
export class EnterpriseModule {}
