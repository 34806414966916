import { Injectable } from '@angular/core';
import { SdgCardModel } from './models';
import cardsJSON from '../../../assets/json/sdgcards.json';
import { AbstractService } from '../../shared/services/abstract.service';
import { ScoringModel, ScoringWrapperModel } from './models/scoring.model';
import { ScoreboardState } from './xs';
import { SubmitForValidationPayload } from './xs/submit-for-validation.action';
import { Assessment, Topic } from './models/topic.model';
import { BehaviorSubject } from 'rxjs';
import { EsgPopupAssessment, EsgPopupProgressItem } from './esg/popup/esg-popup.model';

@Injectable({ providedIn: 'root' })
export class ScoreboardService extends AbstractService {
  private topicSource = new BehaviorSubject<Topic[] | undefined>(undefined);
  // eslint-disable-next-line
  currentTopic = this.topicSource.asObservable();

  updateScoreSelection(scores: Topic[]): void {
    this.topicSource.next(scores);
  }

  getCurrentTopic(): Topic[] | undefined {
    return this.topicSource.getValue();
  }

  public isFirstResultsCheck(): boolean {
    return this.store.selectSnapshot(ScoreboardState.isFirstResultsCheck);
  }

  async getAllScores(): Promise<Topic[]> {
    const result = await this.http.get(`${this.apiBaseUrl}/assessments/esg/scores`).toPromise();

    return result as Topic[];
  }

  public async getScoringFromBackend(id: string): Promise<ScoringModel> {
    const result = (await this.http.get(`${this.apiBaseUrl}/scorings/${id}`).toPromise()) as ScoringModel;

    return result;
  }

  public async getLatestScoringFromBackend(): Promise<ScoringWrapperModel> {
    return (await this.http.get(`${this.apiBaseUrl}/assessments/esg/scores/latest`).toPromise()) as ScoringWrapperModel;
  }

  public getScoring(): ScoringWrapperModel | undefined {
    return this.store.selectSnapshot(ScoreboardState.getScoringModel);
  }

  public getSelectedAssessment(): string | null | undefined {
    return this.store.selectSnapshot(ScoreboardState.getSelectedAssessment);
  }

  public async getManagedAssessmentScoresFromBackend(assessmentId: string): Promise<ScoringWrapperModel> {
    return (await this.http
      .get(`${this.apiBaseUrl}/assessments/esg/${assessmentId}/scores`)
      .toPromise()) as ScoringWrapperModel;
  }

  getCards(): SdgCardModel[] {
    return JSON.parse(JSON.stringify(cardsJSON.cards)) as SdgCardModel[];
  }

  async assignValidator(payload: SubmitForValidationPayload): Promise<void> {
    await this.http
      .post(`${this.apiBaseUrl}/scorings/${this.getScoring()?.deprecatedScoring?.id}/assign`, payload)
      .toPromise();
  }

  downloadReport(scoringId: string): Promise<Blob | undefined> {
    return this.http.get(`${this.apiBaseUrl}/scorings/${scoringId}/report`, { responseType: 'blob' }).toPromise();
  }

  downloadEsgImage(scoringId: string): Promise<Blob | undefined> {
    return this.http.get(`${this.apiBaseUrl}/scorings/${scoringId}/esg-img`, { responseType: 'blob' }).toPromise();
  }

  downloadSdgImage(scoringId: string): Promise<Blob | undefined> {
    return this.http.get(`${this.apiBaseUrl}/scorings/${scoringId}/sdg-img`, { responseType: 'blob' }).toPromise();
  }

  downloadStatement(scoringId: string): Promise<Blob | undefined> {
    return this.http
      .get(`${this.apiBaseUrl}/scorings/${scoringId}/commitment-img`, { responseType: 'blob' })
      .toPromise();
  }

  buildEsgPopupAssessments(category: string, language: string): EsgPopupAssessment[] {
    const scores = this.getCurrentTopic();

    if (!scores) {
      return [];
    }
    const formattedLanguage = language.replace('_', '-').replace('GB', 'UK');
    const esgAssessments: EsgPopupAssessment[] = [];
    // to do: remove color from progress item

    scores.forEach((score) => {
      score.assessments.forEach((assessment) => {
        const progressItems: EsgPopupProgressItem[] = [];
        const old = assessment.scoring.deprecatedLatest;

        if (!assessment.selected) {
          return;
        }

        if (!old) {
          this.addProgressItems(assessment, category, formattedLanguage, progressItems);
        }

        const popupAssessment: EsgPopupAssessment = {
          id: assessment.id,
          name: assessment.name,
          items: progressItems,
          old,
        };

        esgAssessments.push(popupAssessment);
      });
    });

    return esgAssessments;
  }

  private addProgressItems(
    assessment: Assessment,
    category: string,
    formattedLanguage: string,
    progressItems: EsgPopupProgressItem[],
  ): void {
    assessment.scoring.scoring.esg.forEach((esg) => {
      if (esg.category.toLowerCase() !== category) {
        return;
      }

      esg.subcategoryTranslations.forEach((subcategoryTranslation) => {
        if (subcategoryTranslation.language !== formattedLanguage) {
          return;
        }

        subcategoryTranslation.subcategories.forEach((subcategory) => {
          const progressItem: EsgPopupProgressItem = {
            title: subcategory.subcategory,
            value: subcategory.score,
            maxValue: subcategory.maxScore,
            color: esg.category,
          };

          progressItems.push(progressItem);
        });
      });
    });
  }
}
