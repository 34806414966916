import { AbstractService } from '../../shared/services/abstract.service';
import { UpdateProfileActionPayload } from './xs/actions/profile/update-profile.action';
import { ProfileModel, ProfileState } from './xs/profile.state';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ProfileService extends AbstractService {
  public async patchProfile(payload: UpdateProfileActionPayload): Promise<ProfileModel> {
    const result = await this.http.put(`${this.apiBaseUrl}/users/update`, payload).toPromise();

    return result as ProfileModel;
  }

  public getProfile(): ProfileModel {
    return this.store.selectSnapshot(ProfileState.getProfile);
  }

  public getLanguage(): string | undefined {
    return this.store.selectSnapshot(ProfileState.getLanguage);
  }
}
