import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { AuthService } from './auth.service';
import { CompanyService } from '../dashboard';
import { Role } from '../dashboard/profile/models/role';
import { SsoService } from './sso/sso.service';

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly companyService: CompanyService,
    private readonly ssoService: SsoService,
  ) {}

  canActivate(): boolean {
    if (this.ssoService.checkSsoFromPath()) {
      return this.canActivateForSso();
    }
    this.suspendSsoSessionIfNonSsoRoute();

    const isAuthenticated = this.authService.isUserAuthenticated();

    const roles = this.authService.getRoles();

    let hasPaid = false;

    if (roles) {
      hasPaid =
        roles.includes(Role.Admin) ||
        roles.includes(Role.SME) ||
        roles.includes(Role.SMEViewer) ||
        roles.includes(Role.Enterprise);
    }

    const isLoggedInPaidUser = isAuthenticated && hasPaid;

    if (isLoggedInPaidUser) {
      if (this.companyService.getCompany().type) {
        this.authService.navigateToPage(`/dashboard/${this.companyService.getCompany().type?.toLowerCase()}`);
      } else {
        // only admins don't have company type
        this.authService.navigateToPage('/admin');
      }
    }

    return !isLoggedInPaidUser;
  }

  suspendSsoSessionIfNonSsoRoute(): void {
    if (this.authService.isEnterpriseSSO() && !this.ssoService.isAuthenticated()) {
      this.ssoService.logoutLocalSession();
    }
  }

  canActivateForSso(): boolean {
    if (this.ssoService.isAuthenticated()) {
      return this.ssoService.validateSsoBackend().subscribe(
        (loginResponse): boolean => {
          // SSO User requested from Path, and authenticated
          if (loginResponse) {
            this.ssoService.activate(loginResponse);
            this.authService.navigateToPage(`/dashboard/${this.companyService.getCompany().type?.toLowerCase()}`);
          }

          return false;
        },
        (): boolean => {
          // SSO User requested from Path, with session active but not authenticated
          this.ssoService.logoutSession();

          return true;
        },
      ).closed;
    }
    // SSO User requested from Path, but not authenticated and session suspended
    this.ssoService.logoutSession();

    return true;
  }
}
