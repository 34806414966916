import { RelevantThemeModel } from '../../../../shared/relevant-theme/relevant-theme.model';

export interface SaveMaterialityDtoModel {
  materiality: RelevantThemeModel[][];
  materialityType: MaterialityType;
}

// eslint-disable-next-line no-shadow
export enum MaterialityType {
  IMPACT = 'IMPACT',
  FINANCIAL = 'FINANCIAL',
}
