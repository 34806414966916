import { Action } from '../../../shared/xs/actions';

export interface CommentPayload {
  questionId?: string;
  text: string;
  parentCommentId?: string;
}

export class CommentAction implements Action {
  static readonly type = '[Validation] Comment';

  constructor(public payload: CommentPayload) {}
}
