import { Action, ActionResponse } from '../../shared/xs/actions';
import { UserToInviteModel } from '../invite/user-to-invite.model';

export interface SubmitCompanyProfileActionPayload {
  name: string;
  country: string;
  registeredId: string;
  contactEmail: string;
  redirect?: boolean;
  usersToInvite?: UserToInviteModel[];
  localSectorCode?: string;
}

export class SubmitCompanyProfileAction implements Action {
  static readonly type = '[Company Profile] Submit Company Profile';

  constructor(public payload: SubmitCompanyProfileActionPayload) {}
}

export interface SubmitCompanyProfileActionResponse extends ActionResponse {
  country: string;
  registeredId: string;
  name: string;
}
