/* eslint-disable max-classes-per-file */

import { Action, ActionResponse } from '../../../shared/xs/actions';

export interface SignupActionPayload {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  language?: string;
  wasReferred?: boolean;
  partnerCode?: string;
  billingAddress: BillingAddress;
}

export interface BillingAddress {
  name: string;
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  country: string;
}

export class SignupAction implements Action {
  static readonly type = '[Auth] Signup';

  constructor(public payload: SignupActionPayload) {}
}

export type SignupActionResponse = ActionResponse;
