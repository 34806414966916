import { Component, OnInit } from '@angular/core';
import { ScoringModel, ScoringWrapperModel } from '../models/scoring.model';
import { ScoreboardService } from '../scoreboard.service';
import { CompanyService } from '../../profile';
import { EeveryTranslateService } from '../../../shared/eevery.translate.service';

@Component({
  selector: 'app-aggregated-report',
  templateUrl: './aggregated-report.component.html',
  styleUrls: ['./aggregated-report.component.scss'],
})
export class AggregatedReportComponent implements OnInit {
  scoring!: ScoringModel;

  scoringModel: ScoringWrapperModel | undefined;

  companyName!: string;

  fromDate!: string;

  toDate!: string;

  constructor(
    private scoreboardService: ScoreboardService,
    private companyService: CompanyService,
    private translateService: EeveryTranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.scoring = this.scoreboardService.getScoring()?.deprecatedScoring ?? ({} as ScoringModel);
    this.fromDate = `${await this.getMonth(new Date(this.scoring.reportingPeriodStart))} ${new Date(
      this.scoring.reportingPeriodStart,
    ).getFullYear()}`;
    this.toDate = `${await this.getMonth(new Date(this.scoring.reportingPeriodEnd))} ${new Date(
      this.scoring.reportingPeriodEnd,
    ).getFullYear()}`;
    this.companyName = this.companyService.getCompany().name || '';
  }

  getMonth(date: Date): Promise<string> {
    return this.translateService.get(`common.months.${date.getMonth() + 1}`);
  }
}
