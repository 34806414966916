import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CalculationInfoModel } from './calculation-info.model';
import { CalculationInfoPopUpComponent } from './calculation-info-pop-up/calculation-info-pop-up.component';

@Component({
  selector: 'app-calculation-info',
  templateUrl: './calculation-info.component.html',
  styleUrls: ['./calculation-info.component.scss'],
})
export class CalculationInfoComponent implements OnInit {
  @Input()
  info?: CalculationInfoModel;

  show = false;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.show = this.hasContent();
  }

  hasContent(): boolean {
    return !(this.info === undefined || this.info === null);
  }

  showInfo(): void {
    const data = {
      width: '60vw',
      autoFocus: false,
      data: this.info,
    };

    this.dialog.open(CalculationInfoPopUpComponent, data);
  }
}
