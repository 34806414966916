import { Component, OnInit } from '@angular/core';
import { UpdateCompanyActionResponse } from '../../dashboard/profile/xs/actions/company/update-company.action';
import { GetCompanyAction } from '../../dashboard/profile/xs/actions/company/get-company.action';
import { CompanyService, ProfileService } from '../../dashboard';
import { GeneralSection } from './company.models';
import { BillingAddress } from '../../auth';
import { TranslateService } from '@ngx-translate/core';
import {
  SubmitCompanyProfileAction,
  SubmitCompanyProfileActionPayload,
} from '../../company-profile/xs/submit-company-profile.action';

@Component({
  selector: 'app-verify-details',
  templateUrl: './verify-details.component.html',
  styleUrls: ['./verify-details.component.scss'],
})
export class VerifyDetailsComponent implements OnInit {
  companyToSave: SubmitCompanyProfileActionPayload = {
    contactEmail: '',
    name: '',
    registeredId: '',
    country: '',
    localSectorCode: '',
  };

  billingAddress: BillingAddress = {
    city: '',
    name: '',
    country: '',
    houseNumber: '',
    postalCode: '',
    street: '',
  };

  general: GeneralSection | undefined;

  contactEmail: string | undefined;

  countries: string[] = ['NL', 'GB'];

  constructor(
    private readonly companyService: CompanyService,
    private profileService: ProfileService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.companyService.startLoading();

    this.companyService.dispatchActionAndWait(new GetCompanyAction()).then(() => {
      const companyFromState = this.companyService.getCompany();

      if (companyFromState) {
        this.prefillData(companyFromState as UpdateCompanyActionResponse);
      }
      this.companyService.stopLoading();

      if (!this.billingAddress.name || this.billingAddress.name === '') {
        this.setUnavailableBillingData();
      }
    });
  }

  prefillData(companyBeforeEdit: UpdateCompanyActionResponse): void {
    this.companyToSave = { ...this.companyToSave, ...JSON.parse(JSON.stringify(companyBeforeEdit)) };
    this.general = { ...JSON.parse(JSON.stringify(this.companyToSave)) };
    this.contactEmail = this.companyToSave.contactEmail;
    this.billingAddress = { ...JSON.parse(JSON.stringify(companyBeforeEdit.billingAddress)) };
  }

  private setUnavailableBillingData(): void {
    this.translateService.get('profile.company.billing.empty').subscribe((text: string) => {
      this.billingAddress.name = text;
      this.billingAddress.street = text;
      this.billingAddress.houseNumber = text;
      this.billingAddress.postalCode = text;
      this.billingAddress.city = text;
      this.billingAddress.country = 'unavailable';
    });
  }

  generalSubmitted(general: GeneralSection): void {
    this.companyToSave.name = general.name;
    this.companyToSave.country = general.country;
    this.companyToSave.registeredId = general.registeredId;
    this.companyToSave.localSectorCode = general.localSectorCode;
    this.saveData();
  }

  contactSubmitted(contactEmail: string): void {
    this.companyToSave.contactEmail = contactEmail;
    this.saveData();
  }

  async saveData(): Promise<void> {
    await this.companyService.dispatchActionAndWait(new SubmitCompanyProfileAction(this.companyToSave));
    await this.companyService.dispatchActionAndWait(new GetCompanyAction());
  }

  isMobile(): boolean {
    return window.innerWidth < 600;
  }
}
