import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'callback',
  pure: false,
})
export class CallbackPipe implements PipeTransform {
  transform(items: unknown[], callback: (item: unknown) => boolean): unknown {
    if (!items || !callback) {
      return items;
    }

    return items.filter((item) => callback(item));
  }
}
