import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EesgPopupModel } from './eesg-popup.model';

@Component({
  selector: 'app-eesg-popup',
  templateUrl: './eesg-popup.component.html',
  styleUrls: ['./eesg-popup.component.scss'],
})
export class EesgPopupComponent implements OnInit {
  category!: string;

  message!: string;

  percentage?: number;

  title!: string;

  constructor(
    public dialogRef: MatDialogRef<EesgPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EesgPopupModel,
  ) {}

  ngOnInit(): void {
    this.category = this.data.category;
    this.message = `scoreboard.esg.charts_popup.${this.data.category}.description`;
    this.percentage = this.data.percentage;
    this.title = `scoreboard.esg.charts_popup.${this.data.category}.title`;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
