import { Action } from '../../../shared/xs/actions';

export interface ConfirmForgotPasswordActionPayload {
  email: string;
  password: string;
  confirmationCode: string;
}

export class ConfirmForgotPasswordAction implements Action {
  static readonly type = '[Auth] Confirm forgot password';

  constructor(public payload: ConfirmForgotPasswordActionPayload) {}
}
