import { Component, ViewChild } from '@angular/core';
import { SignupAction, SignupActionPayload } from '../../xs';
import { NgModel } from '@angular/forms';
import { AuthService } from '../../auth.service';
import { MatDialog } from '@angular/material/dialog';
import { PopUpModel } from '../../../shared/pop-up/pop-up.model';
import { PopUpComponent } from '../../../shared/pop-up/pop-up.component';

@Component({
  selector: 'app-accountant-signup',
  templateUrl: './accountant-signup.component.html',
  styleUrls: ['./accountant-signup.component.scss'],
})
export class AccountantSignupComponent {
  role = 'ACCOUNTANCY';

  showPasswordRequirements = false;

  user: SignupActionPayload & { confirmPassword: string; confirmEmail: string } = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    confirmEmail: '',
    billingAddress: {
      city: '',
      name: '',
      houseNumber: '',
      street: '',
      postalCode: '',
      country: '',
    },
  };

  // eslint-disable-next-line max-len
  // source: https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
  passwordRegex = '^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$';

  serverError = false;

  policyError = false;

  policyApproved = false;

  @ViewChild('email') email!: NgModel;

  @ViewChild('confirmEmail') confirmEmail!: NgModel;

  @ViewChild('password') password!: NgModel;

  @ViewChild('confirmPassword') confirmPassword!: NgModel;

  constructor(private readonly authService: AuthService, public dialog: MatDialog) {}

  async createAccount(): Promise<void> {
    if (!this.policyApproved) {
      this.policyError = true;

      return;
    }

    try {
      await this.authService.dispatchActionAndWait(new SignupAction(this.user));

      this.showSignUpSucces();
    } catch (e) {
      this.serverError = true;
    }
  }

  showSignUpSucces(): void {
    const data: PopUpModel = {
      title: 'Account created',
      message: 'Thank you for signing up. Please check your mailbox and verify your email address.',
    };

    this.dialog.open(PopUpComponent, {
      width: '400px',
      autoFocus: false,
      data,
    });
  }

  policyChecked(): void {
    this.policyError = !this.policyApproved;
  }

  validateEmail(): void {
    if (!this.confirmEmail.errors?.required && this.email.value !== this.confirmEmail.value) {
      this.confirmEmail.control.setErrors({ confirmError: true });
    } else {
      this.confirmEmail.control.updateValueAndValidity();
    }
  }

  validatePassword(): void {
    if (!this.confirmPassword.errors?.required && this.password.value !== this.confirmPassword.value) {
      this.confirmPassword.control.setErrors({ confirmError: true });
    } else {
      this.confirmPassword.control.updateValueAndValidity();
    }
  }

  togglePasswordRequirements(): void {
    this.showPasswordRequirements = !this.showPasswordRequirements;
  }

  back(): void {
    this.authService.navigateToPage('login');
  }
}
