import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GetScoringAction } from './get-scoring.action';
import { ScoreboardService } from '../scoreboard.service';
import { ScoringStatus } from '../models/';
import { ScoringModel, ScoringWrapperModel } from '../models/scoring.model';
import { SubmitForValidationAction } from './submit-for-validation.action';
import { CheckResultsAction } from '../../xs/actions/results-checked.action';
import { LogoutSuccessAction } from '../../../auth/xs/actions/logout-success.action';
import { LogoutFromSmeSuccessAction } from '../../../enterprise/xs/actions/logout-from-sme-success.actions';
import { LoginAsCompanySuccessAction } from '../../../enterprise/xs/actions/login-as-company-success.action';
import { GetLatestScoreAction } from '../../xs/actions/get-latest-score.action';
import { GetScoresFromBackendAction } from './get-scores-from-backend.action';
import { SetSelectedStateAssessmentAction } from './set-selected-state-assessment.action';
import { ClearSelectedStateAssessmentAction } from './clear-selected-state-assessment.action';

export interface ScoreboardStateModel {
  firstResultsCheck?: boolean;
  scoring?: ScoringModel;
  scoringModel?: ScoringWrapperModel;
  selectedAssessment?: string | null;
}

@State<ScoreboardStateModel>({ name: 'scoreboard' })
@Injectable()
export class ScoreboardState {
  @Selector()
  static isFirstResultsCheck(state: ScoreboardStateModel): boolean {
    return state.firstResultsCheck === undefined ? true : state.firstResultsCheck;
  }

  @Selector()
  static getScoring(state: ScoreboardStateModel): ScoringModel | undefined {
    return state.scoring;
  }

  @Selector()
  static getScoringModel(state: ScoreboardStateModel): ScoringWrapperModel | undefined {
    return state.scoringModel;
  }

  @Selector()
  static getSelectedAssessment(state: ScoreboardStateModel): string | null | undefined {
    return state.selectedAssessment;
  }

  constructor(private readonly scoreboardService: ScoreboardService) {}

  @Action(GetScoringAction)
  async getScoringFromBackend(ctx: StateContext<ScoreboardStateModel>, action: GetScoringAction): Promise<void> {
    const scoringResult = await this.scoreboardService.getScoringFromBackend(action.payload.id);

    ctx.patchState({ scoring: scoringResult });
  }

  @Action(SetSelectedStateAssessmentAction)
  async setSelectedStateAssessment(
    ctx: StateContext<ScoreboardStateModel>,
    action: SetSelectedStateAssessmentAction,
  ): Promise<void> {
    ctx.patchState({ selectedAssessment: action.payload.assessmentId });
  }

  @Action(ClearSelectedStateAssessmentAction)
  async clearSelectedStateAssessment(ctx: StateContext<ScoreboardStateModel>): Promise<void> {
    ctx.patchState({ selectedAssessment: null });
  }

  @Action(GetScoresFromBackendAction)
  async getScoresFromBackendByAssessmentId(
    ctx: StateContext<ScoreboardStateModel>,
    action: GetScoresFromBackendAction,
  ): Promise<void> {
    const scoringResult = await this.scoreboardService.getManagedAssessmentScoresFromBackend(action.payload.id);

    ctx.patchState({ scoringModel: scoringResult });
  }

  @Action(GetLatestScoreAction)
  async getLatestScoringFromBackend(ctx: StateContext<ScoreboardStateModel>): Promise<void> {
    const result = await this.scoreboardService.getLatestScoringFromBackend();

    ctx.patchState({ scoringModel: result });
  }

  @Action(SubmitForValidationAction)
  async submitForValidation(ctx: StateContext<ScoreboardStateModel>, action: SubmitForValidationAction): Promise<void> {
    const state = ctx.getState();

    if (!state.scoring) {
      return;
    }
    await this.scoreboardService.assignValidator(action.payload);

    ctx.patchState({
      scoring: {
        ...state.scoring,
        status: ScoringStatus.SUBMITTED_FOR_VALIDATION,
      },
    });
  }

  @Action(CheckResultsAction)
  async checkImprovement(ctx: StateContext<ScoreboardStateModel>, action: CheckResultsAction): Promise<void> {
    ctx.patchState({ firstResultsCheck: !action.payload.hasBeenChecked });
  }

  @Action([LogoutSuccessAction, LoginAsCompanySuccessAction, LogoutFromSmeSuccessAction])
  clearResults(ctx: StateContext<ScoreboardStateModel>): void {
    ctx.setState({});
  }
}
