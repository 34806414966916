import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import {
  AssessmentSource,
  CheckDashboardAction,
  GetScoringAction,
  GoToQuestionnaireAction,
  GoToQuestionnaireActionPayload,
  StartScoringAction,
  StartScoringResponse,
  SubmitScoringAction,
} from './actions';
import { ScoringStatus } from '../scoreboard';
import { UpdateQuestionnaireAction } from '../../shared/questions/models/update-questionnaire.action';
import { QuestionnaireType } from '../scoreboard/models/questionnaire.type';
import { MatDialog } from '@angular/material/dialog';
import { RestatementPopUpComponent } from '../assessment-list/restatement-pop-up/restatement-pop-up.component';
import { RestatementModel } from '../assessment-list/restatement-pop-up/restatement.model';
import { ImpactListModel } from '../assessment-list/impact-list.model';
import { GetAssessmentListAction } from './actions/get-assessment-list.action';
import { EsgStartPopupComponent } from '../questionnaire/questionaire/esg-start-popup/esg-start-popup.component';
import { CheckAssessmentsAction } from './actions/assessments-checked.action';
import { GetImprovementsAction } from '../../improvement-centre/xs/get_improvements.action';
import { EsgFinishPopupComponent } from '../questionnaire/questionaire/esg-finish-popup/esg-finish-popup.component';
import { ImpactFinishPopupComponent } from '../questionnaire/impact-scan/impact-finish-popup/impact-finish-popup.component';
import { IntroductionPopUpComponent } from '../../shared/introduction-pop-up/introduction-pop-up.component';
import { ViewQuestionnaireAction } from './actions/view-questionnaire.action';
import { LogoutSuccessAction } from '../../auth/xs/actions/logout-success.action';
import { LogoutFromSmeSuccessAction } from '../../enterprise/xs/actions/logout-from-sme-success.actions';
import { RefreshValidationAction } from './actions/refresh-validation.action';
import { AssessmentVersion } from '../scoreboard/models/scoring.model';
import { NavigationTargetEnum } from './navigation-target.enum';
import { SetNavigationTargetAction } from './actions/set-navigation-target.action';
import { OpenEsgFinishPopupAction } from './actions/open-esg-finish-popup.action';
import { SetSelectedStateAssessmentAction } from '../scoreboard/xs/set-selected-state-assessment.action';
import {
  DialogPrefillResultModel,
  EsgPrefillPopupComponent,
} from '../questionnaire/questionaire/esg-prefill-popup/esg-prefill-popup.component';
import { EsgAssessmentModel } from '../questionnaire/questionaire/esg-prefill-popup/esg-prefill.model';
import { PrefilledAssessmentModel } from '../assessment-list/start-assessment.model';

export interface DashboardStateModel {
  firstDashboardCheck?: boolean;
  firstAssessmentsCheck?: boolean;
  assessmentListOld?: ImpactListModel[];
  assessmentListRevamp?: ImpactListModel[];
  navigationTarget?: NavigationTargetEnum;
}

@State<DashboardStateModel>({ name: 'dashboard' })
@Injectable()
export class DashboardState {
  @Selector()
  static isFirstDashboardCheck(state: DashboardStateModel): boolean {
    return !!state.firstDashboardCheck;
  }

  @Selector()
  static isFirstAssessmentsCheck(state: DashboardStateModel): boolean {
    return !!state.firstAssessmentsCheck;
  }

  @Selector()
  static assessmentListOld(state: DashboardStateModel): ImpactListModel[] {
    return state.assessmentListOld ? [...state.assessmentListOld] : [];
  }

  @Selector()
  static assessmentListRevamp(state: DashboardStateModel): ImpactListModel[] {
    return state.assessmentListRevamp ? [...state.assessmentListRevamp] : [];
  }

  @Selector()
  static hasImpact(state: DashboardStateModel): boolean {
    let result = false;

    if (state.assessmentListOld && state.assessmentListOld.length > 0) {
      result = true;
    }
    if (state.assessmentListRevamp && state.assessmentListRevamp.length > 0) {
      result = true;
    }

    return result;
  }

  @Selector()
  static hasAssessment(state: DashboardStateModel): boolean {
    let hasAssessment = false;

    if (state.assessmentListOld) {
      state.assessmentListOld.forEach((impact) => {
        if (impact.assessmentList && impact.assessmentList.length > 0) {
          hasAssessment = true;
        }
      });
    }

    if (state.assessmentListRevamp) {
      state.assessmentListRevamp.forEach((impact) => {
        if (impact.assessmentList && impact.assessmentList.length > 0) {
          hasAssessment = true;
        }
      });
    }

    return hasAssessment;
  }

  @Selector()
  static navigationTarget(state: DashboardStateModel): string {
    return state.navigationTarget ? `${state.navigationTarget}` : 'HOME';
  }

  constructor(
    private readonly dashboardService: DashboardService,
    private readonly ngZone: NgZone,
    private readonly dialog: MatDialog,
  ) {}

  @Action(CheckDashboardAction)
  async checkDashboard(ctx: StateContext<DashboardStateModel>, action: CheckDashboardAction): Promise<void> {
    ctx.patchState({ firstDashboardCheck: !action.payload.hasBeenChecked });
  }

  @Action(CheckAssessmentsAction)
  async checkAssessments(ctx: StateContext<DashboardStateModel>, action: CheckAssessmentsAction): Promise<void> {
    ctx.patchState({ firstAssessmentsCheck: !action.payload.hasBeenChecked });
  }

  @Action(GetAssessmentListAction)
  async getList(ctx: StateContext<DashboardStateModel>): Promise<void> {
    const oldList = await this.dashboardService.getAssessmentListFromBackend();
    const revampList = await this.dashboardService.getAssessmentListRevampFromBackend();

    ctx.patchState({
      assessmentListOld: oldList,
      assessmentListRevamp: revampList,
    });
  }

  // --------------------------------------------------------------
  // Scoring Action Handlers
  // --------------------------------------------------------------
  @Action(StartScoringAction)
  async startScoring(ctx: StateContext<DashboardStateModel>, action: StartScoringAction): Promise<void> {
    const startPopupDialog = this.dialog.open(EsgStartPopupComponent, {
      width: '75vw',
      autoFocus: false,
      data: { first: true },
    });

    startPopupDialog.afterClosed().subscribe((proceed: boolean) => {
      if (proceed) {
        this.openReportingPeriodDialog(action);
      }
    });
  }

  private openReportingPeriodDialog(action: StartScoringAction): void {
    const reportingPeriodDialog = this.dialog.open(RestatementPopUpComponent, {
      width: '60vw',
      autoFocus: false,
      disableClose: true,
      data: { showRestatement: false },
    });

    reportingPeriodDialog.afterClosed().subscribe(async (data: { restatement: RestatementModel; return: boolean }) => {
      if (data.restatement) {
        this.dashboardService.getAllCompletedEsgAssessments().then(async (assessments: EsgAssessmentModel[]) => {
          if (assessments && assessments.length) {
            this.openEsgAssessmentPrefillPopup(action, data.restatement, assessments);
          } else {
            await this.startNewEsgAssessment(action, data.restatement);
          }
        });
      } else if (data.return) {
        this.dashboardService.dispatchAction(StartScoringAction);
      }
    });
  }

  private openEsgAssessmentPrefillPopup(
    action: StartScoringAction,
    reportingPeriodData: RestatementModel,
    esgAssessments: EsgAssessmentModel[],
  ): void {
    const prefillDialog = this.dialog.open(EsgPrefillPopupComponent, {
      width: '60vw',
      autoFocus: false,
      data: { esgAssessments },
    });

    prefillDialog.afterClosed().subscribe(async (response: DialogPrefillResultModel) => {
      if (response.continue) {
        if (response.prefillEnabled && response.selectedAssessment) {
          this.startPrefilledEsgAssessment(
            response.selectedAssessment,
            action.payload.impactScanId,
            reportingPeriodData.reportingPeriodStart,
          );
        } else {
          await this.startNewEsgAssessment(action, reportingPeriodData);
        }
      }
      if (response.back) {
        this.openReportingPeriodDialog(action);
      }
    });
  }

  private startPrefilledEsgAssessment(
    previousAssessmentId: string,
    impactScanId: number | undefined,
    reportingPeriod: Date,
  ): void {
    const request: PrefilledAssessmentModel = {
      impactScanId,
      previousAssessmentId,
      reportingPeriod,
    };

    this.dashboardService.startPrefilledEsgAssessment(request).then((response) => {
      this.dashboardService.dispatchAction(
        new GoToQuestionnaireAction({
          id: response.id,
          status: ScoringStatus.DRAFT,
          type: QuestionnaireType.ASSESSMENT,
          version: AssessmentVersion.MANAGED,
          unfinished: true,
          disabled: false,
          viewOnly: false,
        }),
      );
    });
  }

  private async startNewEsgAssessment(
    action: StartScoringAction,
    reportingPeriodData: RestatementModel,
  ): Promise<void> {
    // to go switch between the assessment versions, change the value below to 'REVAMP'
    const assessmentVersion = AssessmentVersion.MANAGED;

    const scoring: StartScoringResponse = await this.dashboardService.startScoring({
      assessmentSource: action.payload.source,
      impactScanId: action.payload.impactScanId,
      restatementData: reportingPeriodData,
      version: assessmentVersion,
    });

    this.dashboardService.dispatchAction(
      new GoToQuestionnaireAction({
        id: scoring.id,
        status: ScoringStatus.DRAFT,
        type: QuestionnaireType.ASSESSMENT,
        version: assessmentVersion,
        unfinished: true,
        disabled: false,
        viewOnly: false,
      }),
    );
  }

  @Action(GoToQuestionnaireAction)
  async goToQuestionnaire(ctx: StateContext<DashboardStateModel>, action: GoToQuestionnaireAction): Promise<void> {
    await this.dashboardService.dispatchActionAndWait(new UpdateQuestionnaireAction(action.payload));

    // non-draft status -> we go to the scoreboard
    if (!action.payload.unfinished && action.payload.type === QuestionnaireType.IMPACT_SCAN) {
      await this.goToImpactScoreboard(action.payload);

      return;
    }

    if (!action.payload.unfinished && action.payload.type === QuestionnaireType.ASSESSMENT) {
      await this.goToAssessmentScoreboard(action.payload);

      return;
    }

    // draft status -> we go to the questions
    if (action.payload.type === QuestionnaireType.IMPACT_SCAN) {
      await this.goToImpactQuestionnaire(action.payload);

      return;
    }

    if (action.payload.type === QuestionnaireType.ASSESSMENT) {
      await this.goToAssessmentQuestionnaire(action.payload);
    }
  }

  @Action(ViewQuestionnaireAction)
  async viewQuestionnaire(ctx: StateContext<DashboardStateModel>, action: ViewQuestionnaireAction): Promise<void> {
    await this.dashboardService.dispatchActionAndWait(new UpdateQuestionnaireAction(action.payload));
    if (action.payload.type === QuestionnaireType.IMPACT_SCAN) {
      this.ngZone.run(() => this.dashboardService.navigateToPage('/dashboard/sme/impact-scan'));
    } else if (
      action.payload.version === AssessmentVersion.REVAMP ||
      action.payload.version === AssessmentVersion.OLD
    ) {
      this.ngZone.run(() => this.dashboardService.navigateToPage('/dashboard/sme/questionnaire'));
    } else if (action.payload.version === AssessmentVersion.MANAGED) {
      this.ngZone.run(() => this.dashboardService.navigateToPage('/dashboard/sme/esg-assessment'));
    }
  }

  @Action(SubmitScoringAction)
  async submitScoring(ctx: StateContext<DashboardStateModel>, action: SubmitScoringAction): Promise<void> {
    const isFirstImpactScan = !this.dashboardService.hasImpact();

    const isFirstAssessment = !this.dashboardService.hasAssessment();

    this.dashboardService.startLoading();
    await this.dashboardService.submitScoring(action.payload);
    await this.dashboardService.dispatchActionAndWait(new GetScoringAction({ id: action.payload.id }));

    if (action.payload.type === QuestionnaireType.ASSESSMENT) {
      await this.dashboardService.dispatchActionAndWait(new GetImprovementsAction({ id: action.payload.id }));
    }

    this.dashboardService.stopLoading();
    this.ngZone.run(() => {
      this.dashboardService.navigateToPage('/dashboard/sme/assessments', { noShowPopUp: true });
    });

    if (action.payload.type === QuestionnaireType.IMPACT_SCAN) {
      const dialogRef = this.dialog.open(ImpactFinishPopupComponent, {
        disableClose: true,
        autoFocus: false,
        data: { first: isFirstImpactScan },
        width: '40vw',
      });

      dialogRef.afterClosed().subscribe((data: { startEsg: boolean }) => {
        if (data.startEsg) {
          this.dashboardService.dispatchAction(new StartScoringAction({ source: AssessmentSource.IMPACT_SCAN }));
        } else if (this.dashboardService.isFirstAssessmentsCheck()) {
          this.openAssessmentListPopUp();
        }
      });
    } else {
      this.openEsgFinishPopup(isFirstAssessment);
    }
  }

  @Action(OpenEsgFinishPopupAction)
  private openEsgFinishPopup(isFirstAssessment: boolean): void {
    const dialogRef = this.dialog.open(EsgFinishPopupComponent, {
      disableClose: true,
      autoFocus: false,
      data: { first: isFirstAssessment },
      width: '60vw',
    });

    dialogRef.afterClosed().subscribe((data: { navigate: string }) => {
      if (data.navigate) {
        this.dashboardService.navigateToPage(`/dashboard/sme/${data.navigate}`);
      } else if (this.dashboardService.isFirstAssessmentsCheck()) {
        this.openAssessmentListPopUp();
      }
    });
  }

  private openAssessmentListPopUp(): void {
    this.dialog.open(IntroductionPopUpComponent, {
      width: '60vw',
      autoFocus: false,
      data: {
        title: 'dashboard.assessment_list.pop_up.title',
        message: 'dashboard.assessment_list.pop_up.description',
        list: [
          'dashboard.assessment_list.pop_up.item_one',
          'dashboard.assessment_list.pop_up.item_two',
          'dashboard.assessment_list.pop_up.item_three',
        ],
      },
    });
  }

  private async goToImpactQuestionnaire(payload: GoToQuestionnaireActionPayload): Promise<void> {
    await this.dashboardService.dispatchActionAndWait(
      new RefreshValidationAction({ id: payload.id, type: QuestionnaireType.IMPACT_SCAN }),
    );
    this.ngZone.run(() => this.dashboardService.navigateToPage('/dashboard/sme/impact-scan'));
  }

  private async goToAssessmentQuestionnaire(payload: GoToQuestionnaireActionPayload): Promise<void> {
    // eslint-disable-next-line no-console
    console.log(payload);

    if (payload.version !== 'MANAGED') {
      await this.dashboardService.dispatchActionAndWait(
        new RefreshValidationAction({ id: payload.id, type: QuestionnaireType.ASSESSMENT }),
      );
    }
    if (
      payload.status === ScoringStatus.ANSWERS_LOCKED &&
      (payload.version === 'OLD' || payload.version === 'REVAMP')
    ) {
      this.ngZone.run(() => this.dashboardService.navigateToPage('/dashboard/sme/questionnaire/materiality'));
    } else if (payload.version === 'REVAMP' || payload.version === 'OLD') {
      this.ngZone.run(() => this.dashboardService.navigateToPage('/dashboard/sme/questionnaire'));
    } else if (payload.version === 'MANAGED' && payload.status === ScoringStatus.ANSWERS_LOCKED) {
      this.ngZone.run(() => this.dashboardService.navigateToPage('/dashboard/sme/esg-assessment/materiality'));
    } else if (payload.version === 'MANAGED') {
      this.ngZone.run(() => this.dashboardService.navigateToPage('/dashboard/sme/esg-assessment'));
    }
  }

  private async goToImpactScoreboard(payload: GoToQuestionnaireActionPayload): Promise<void> {
    await this.dashboardService.dispatchActionAndWait(new GetScoringAction({ id: payload.id }));
    if (payload.improvementsId && payload.improvementsId.length > 0) {
      // we should refresh improvements based on the improvementsId of the payload
      await this.dashboardService.dispatchActionAndWait(new GetImprovementsAction({ id: payload.improvementsId }));
    }
    this.ngZone.run(() => this.dashboardService.navigateToPage('/dashboard/sme/scoreboard'));
  }

  private async goToAssessmentScoreboard(payload: GoToQuestionnaireActionPayload): Promise<void> {
    if (payload.version === AssessmentVersion.OLD || payload.version === AssessmentVersion.REVAMP) {
      await this.dashboardService.dispatchActionAndWait(new GetImprovementsAction({ id: payload.id }));
    }

    await this.dashboardService.dispatchActionAndWait(
      new SetSelectedStateAssessmentAction({ assessmentId: payload.id }),
    );
    this.ngZone.run(() => this.dashboardService.navigateToPage('/dashboard/sme/scoreboard'));
  }

  @Action([LogoutSuccessAction, LogoutFromSmeSuccessAction])
  clearDashboard(ctx: StateContext<DashboardStateModel>): void {
    ctx.setState({});
  }

  @Action(SetNavigationTargetAction)
  setNavigationTarget(ctx: StateContext<DashboardStateModel>, action: SetNavigationTargetAction): void {
    ctx.patchState({ navigationTarget: action.payload.navigationTarget });
  }
}
