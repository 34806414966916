import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SsoService } from '../sso.service';

@Component({ templateUrl: './sso-callback.component.html' })
export class SsoCallbackComponent implements OnInit {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private router: Router,
    private ssoService: SsoService,
  ) {}

  ngOnInit(): void {
    this.oidcSecurityService.checkAuth().subscribe({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      next: (auth) => {
        this.router.navigate([`/${this.ssoService.getProvider()}`]);
      },
      error: (err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        // this.router.navigate(['/error']);
      },
    });
  }
}
