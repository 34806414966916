import { Action } from '../../../shared/xs/actions';

export interface FinishCo2CalculationPayload {
  id: string;
}

export class FinishCo2CalculationAction implements Action {
  static readonly type = '[Co2Calculator] Finish CO2 calculation';

  constructor(public payload: FinishCo2CalculationPayload) {}
}
