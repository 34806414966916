import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ImpactScanRevampComponent } from './impact-scan-revamp.component';
import { ThemesComponent } from './themes/themes.component';

const impactRoutes: Routes = [
  {
    path: '',
    component: ImpactScanRevampComponent,
  },
  {
    path: 'themes',
    component: ThemesComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(impactRoutes)],
  exports: [RouterModule],
})
export class ImpactScanRevampRouting {}
