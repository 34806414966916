import { Action } from '../../../shared/xs/actions';

export interface Co2PopupShownActionPayload {
  hasBeenChecked: boolean;
}

export class CheckCo2Action implements Action {
  static readonly type = '[Co2Calculator] first popup shown action';

  constructor(public payload: Co2PopupShownActionPayload) {}
}
