import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-esg-start-popup',
  templateUrl: './esg-start-popup.component.html',
  styleUrls: ['./esg-start-popup.component.scss'],
})
export class EsgStartPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<EsgStartPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { first: boolean },
    private readonly ngZone: NgZone,
  ) {}

  close(): void {
    this.ngZone.run(() => this.dialogRef.close());
  }

  proceed(): void {
    this.ngZone.run(() => this.dialogRef.close(true));
  }
}
