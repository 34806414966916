import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CompanyProfileStateModel } from './company-profile.model';
import { UserToInviteModel } from '../invite/user-to-invite.model';
import { UpdateCompanyProfileUserAction } from './action/update-company-profile-user.action';

@State<CompanyProfileStateModel>({
  name: 'companyProfile',
  defaults: { usersToInvite: [{ email: '', firstName: '', lastName: '' }] },
})
export class CompanyProfileState {
  @Selector()
  static usersToInvite(state: CompanyProfileStateModel): UserToInviteModel[] {
    return state.usersToInvite.map((user: UserToInviteModel) => ({ ...user }));
  }

  @Action(UpdateCompanyProfileUserAction)
  updateUsersAction(ctx: StateContext<CompanyProfileStateModel>, action: UpdateCompanyProfileUserAction): void {
    const state = ctx.getState();
    const updatedUsersToInvite = action.payload.map((user: UserToInviteModel) => ({ ...user }));

    ctx.setState({ ...state, usersToInvite: updatedUsersToInvite });
  }
}
