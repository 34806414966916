import { Component, OnInit } from '@angular/core';
import { UploadLogoAction } from '../xs/actions/company/upload-logo.action';
import { CompanyService } from '../company.service';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit {
  editImg = false;

  img!: string | ArrayBuffer | undefined | null;

  imgBack!: string | ArrayBuffer | undefined | null;

  file!: File;

  msg = '';

  constructor(private companyService: CompanyService, public profileService: ProfileService) {}

  async ngOnInit(): Promise<void> {
    this.img = await this.companyService.getCompany()?.logoSrc;
    this.imgBack = this.img;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFileChange(fileEvent: any): void {
    const file = fileEvent.target.files[0];
    const reader = new FileReader();

    if (!file || file.length === 0) {
      this.msg = 'You must select an image';

      return;
    }

    reader.readAsDataURL(file);

    reader.onload = (): void => {
      this.msg = '';

      this.img = reader.result;
    };

    this.file = file;
  }

  async onImgError(): Promise<void> {
    this.img = await this.companyService.onLogoError();
  }

  saveImg(): void {
    if (this.editImg) {
      this.imgBack = this.img;

      this.companyService.dispatchAction(new UploadLogoAction({ file: this.file }));
    }

    this.editImg = !this.editImg;
  }

  cancelImg(): void {
    this.img = this.imgBack;
  }
}
