/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, HostListener, OnInit } from '@angular/core';
import { AbstractQuestionDirective } from '../abstract-question.directive';
import { Upload } from '../../models/answer.model';
import { QuestionEnum } from '../question.enum';

import { saveAs } from 'file-saver';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent extends AbstractQuestionDirective implements OnInit {
  files: Upload[] = [];

  dragAreaClass = 'dragarea';

  ngOnInit(): void {
    super.ngOnInit();
    if (this.question.type === QuestionEnum.FILE_UPLOAD_MULTIPLE) {
      this.files = this.answerModel.answer.files;
    } else if (this.answerModel.answer.file) {
      this.files = [this.answerModel.answer.file];
    }
  }

  onFileChange(event: any): void {
    const { files } = event.target;

    this.saveFiles(files);
  }

  removeFile(index: number): void {
    this.files.splice(index, 1);
    this.filesChanged();
  }

  download(fileId: number, fileName: string): void {
    this.questionService.getFile(fileId).then((blob) => {
      if (blob) {
        saveAs(blob, fileName);
      }
    });
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: any): void {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event'])
  onDragEnter(event: any): void {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  @HostListener('dragend', ['$event'])
  onDragEnd(event: any): void {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: any): void {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  onDrop(event: any): void {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && !this.disabled) {
      const { files } = event.dataTransfer;

      this.saveFiles(files);
    }
  }

  saveFiles(files?: FileList): void {
    if (files && files.length > 0) {
      this.filesUploaded(files);
    } else {
      // todo error
    }
  }

  async filesUploaded(files: FileList): Promise<void> {
    this.files = await this.questionService.uploadFiles(files);
    this.filesChanged();
  }

  filesChanged(): void {
    if (this.question.type === QuestionEnum.FILE_UPLOAD_MULTIPLE) {
      this.answerModel.answer.files = this.files;
    } else {
      this.answerModel.answer.file = this.files?.length > 0 ? this.files[0] : undefined;
    }
    this.answerChanged();
  }

  isMobile(): boolean {
    return window.innerWidth < 960;
  }
}
