import { Directive, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective, ValidationErrors } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EeveryTranslateService } from '../shared/eevery.translate.service';
import { CompanyService, ProfileService } from '../dashboard';
import { PopUpModel } from '../shared/pop-up/pop-up.model';
import { PopUpComponent } from '../shared/pop-up/pop-up.component';
import { UpdateCompanyActionPayload } from '../dashboard/profile/xs/actions/company/update-company.action';
import { QuestionService } from '../shared/questions';

@Directive()
export abstract class OnboardingPageDirective {
  company: UpdateCompanyActionPayload = {
    annualTurnover: 0,
    country: '',
    name: '',
    numberOfEmployees: 0,
    totalAssets: 0,
    contactEmail: '',
    controlType: '',
    draft: false,
    executiveDirectors: 0,
    hasMultipleShareholders: false,
    hasNonExecutiveDirectors: false,
    products: [],
    services: [],
    subsidiaries: [],
  };

  @Input()
  set companyData(value: UpdateCompanyActionPayload) {
    this.company = value;
  }

  @Output()
  companyDataChange = new EventEmitter<UpdateCompanyActionPayload>();

  @Output() answerAdded = new EventEmitter<UpdateCompanyActionPayload>();

  @Output() finished = new EventEmitter<UpdateCompanyActionPayload>();

  @ViewChild('form')
  form!: FormGroupDirective;

  constructor(
    public dialog: MatDialog,
    public readonly translateService: EeveryTranslateService,
    public readonly companyService: CompanyService,
    public readonly profileService: ProfileService,
    public readonly questionService: QuestionService,
  ) {}

  triggerNgSubmit(): void {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const i in this.form.form.controls) {
      this.form.form.controls[i].markAsTouched();
    }
    this.form.ngSubmit.emit();
  }

  validateForm(formValid: boolean): boolean {
    return formValid;
  }

  submit(): void {
    this.finished.emit(this.company);
  }

  async showInvalidPopup(form: FormGroup): Promise<void> {
    const errors = this.getAllErrors(form);

    const data: PopUpModel = await this.getInvalidPopUpData(errors);

    this.showPopUp(data);
  }

  getInvalidPopUpData(errors: Set<string>): PopUpModel {
    if (errors.has('required')) {
      return {
        title: 'pop_up.requiredfields.title',
        message: 'pop_up.requiredfields.description',
      };
    }

    return {
      title: 'pop_up.invalidvalues.title',
      message: 'pop_up.invalidvalues.description',
    };
  }

  showPopUp(data: PopUpModel): MatDialogRef<PopUpComponent, unknown> {
    return this.dialog.open(PopUpComponent, { width: '60vw', autoFocus: false, data });
  }

  showRequiredFieldsPopUp(): MatDialogRef<PopUpComponent, unknown> {
    const data = {
      title: 'pop_up.requiredfields.title',
      message: 'pop_up.requiredfields.description',
    };

    return this.dialog.open(PopUpComponent, { width: '60vw', autoFocus: false, data });
  }

  getAllErrors(form: FormGroup): Set<string> {
    const result: Set<string> = new Set();

    Object.keys(form.controls).forEach((key) => {
      // @ts-ignore
      const controlErrors: ValidationErrors = form.get(key).errors;

      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach((keyError) => {
          result.add(keyError);
        });
      }
    });

    return result;
  }

  onAnswerAdded(): void {
    this.answerAdded.emit(this.company);
  }
}
