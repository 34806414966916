import { ChangeDetectorRef, Component, Inject, NgZone } from '@angular/core';
import { RestatementModel } from './restatement.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-restatement-pop-up',
  templateUrl: './restatement-pop-up.component.html',
  styleUrls: ['./restatement-pop-up.component.scss'],
  animations: [
    trigger('OpenClose', [
      state('true', style({ height: '*' })),
      state('false', style({ height: '0' })),
      transition('false <=> true', [animate(500)]),
    ]),
  ],
})
export class RestatementPopUpComponent {
  restatementModel: RestatementModel = {
    sustainabilityPeriodSame: true,
    reportingPeriodStart: new Date(),
    reportingPeriodEnd: new Date(),
  };

  constructor(
    public dialogRef: MatDialogRef<RestatementPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { showRestatement: boolean },
    private ngZone: NgZone,
    private cd: ChangeDetectorRef,
  ) {
    const date = new Date();

    // date.setFullYear(date.getFullYear(), 0, 1);
    date.setUTCFullYear(date.getUTCFullYear(), 0, 1);
    date.setHours(18);
    // eslint-disable-next-line no-console
    console.log('date', date);

    this.restatementModel.reportingPeriodStart = date;
    this.periodChanged();

    this.restatementModel.sustainabilityPeriodStart = new Date(this.restatementModel.reportingPeriodStart);
    this.susPeriodChanged();
  }

  yesClicked(): void {
    this.restatementModel.restated = true;
    this.refreshView();
  }

  noClicked(): void {
    this.restatementModel.restated = false;
    this.refreshView();
  }

  periodChanged(): void {
    const periodEnd = new Date(this.restatementModel.reportingPeriodStart);

    periodEnd.setFullYear(periodEnd.getFullYear() + 1);
    this.restatementModel.reportingPeriodStart.setHours(18);
    this.restatementModel.reportingPeriodEnd = periodEnd;
  }

  susPeriodChanged(): void {
    if (this.restatementModel.sustainabilityPeriodStart) {
      const periodEnd = new Date(this.restatementModel.sustainabilityPeriodStart);

      periodEnd.setFullYear(periodEnd.getFullYear() + 1);
      this.restatementModel.sustainabilityPeriodEnd = periodEnd;
    }
  }

  checkboxChanged(): void {
    this.restatementModel.sustainabilityPeriodStart = new Date(this.restatementModel.reportingPeriodStart);
    this.susPeriodChanged();
    this.refreshView();
  }

  refreshView(): void {
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

  submit(): void {
    this.restatementModel.reportingPeriodStart.setHours(18);
    this.restatementModel.reportingPeriodEnd.setHours(18);
    // eslint-disable-next-line no-console
    console.log('dateWhenSubmitting', this.restatementModel.reportingPeriodStart);
    if (this.restatementModel.sustainabilityPeriodSame) {
      this.restatementModel.sustainabilityPeriodStart = new Date(this.restatementModel.reportingPeriodStart);
      this.restatementModel.sustainabilityPeriodEnd = new Date(this.restatementModel.reportingPeriodEnd);
    }
    this.ngZone.run(() => {
      this.dialogRef.close({ restatement: this.restatementModel, return: false });
    });
  }

  back(): void {
    this.ngZone.run(() => {
      this.dialogRef.close({ restatement: undefined, return: true });
    });
  }

  close(): void {
    this.ngZone.run(() => {
      this.dialogRef.close({ restatement: undefined, return: false });
    });
  }
}
