import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GriEntityModel, RelevantThemeModel } from '../../../../../shared/relevant-theme/relevant-theme.model';
import { TranslateService } from '@ngx-translate/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ImpactScanRevampService } from '../../impact-scan-revamp.service';
import { PopUpComponent } from '../../../../../shared/pop-up/pop-up.component';

@Component({
  selector: 'app-add-theme',
  templateUrl: './add-theme-popup.component.html',
  styleUrls: ['./add-theme-popup.component.scss'],
})
export class AddThemePopupComponent implements OnInit {
  themes: (GriEntityModel & { name: string })[] = [];

  sortedThemes: (GriEntityModel & { name: string })[] = [];

  selectedThemes: (GriEntityModel & { name: string })[] = [];

  changedThemes: RelevantThemeModel[] = [];

  maxSelected = 2;

  themesNameFilter = '';

  constructor(
    public translateService: TranslateService,
    public impactService: ImpactScanRevampService,
    public dialogRef: MatDialogRef<AddThemePopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { selectableThemes: GriEntityModel[]; maxThemes: number; changedThemes: RelevantThemeModel[] },
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.translateThemes(this.data.selectableThemes);
    this.sortedThemes = this.themes;
    this.maxSelected = this.data.maxThemes;
    this.changedThemes = this.data.changedThemes;
    this.themes.sort((firstValue, secondValue) =>
      firstValue.name.toLowerCase().localeCompare(secondValue.name.toLowerCase()),
    );
  }

  private translateThemes(themesFromBackend: GriEntityModel[]): void {
    if (this.translateService.currentLang === 'en_GB') {
      this.themes = themesFromBackend.map((themeFromBackend) => ({
        id: themeFromBackend.id,
        nameUK: themeFromBackend.nameUK,
        nameNL: themeFromBackend.nameNL,
        name: themeFromBackend.nameUK,
      }));
    } else {
      this.themes = themesFromBackend.map((themeFromBackend) => ({
        id: themeFromBackend.id,
        nameUK: themeFromBackend.nameUK,
        nameNL: themeFromBackend.nameNL,
        name: themeFromBackend.nameNL,
      }));
    }
  }

  closeAndCancel(): void {
    this.dialogRef.close();
  }

  closeAndContinue(): void {
    this.dialogRef.close(this.selectedThemes);
  }

  themeSelected(changeEvent: MatCheckboxChange, theme: GriEntityModel & { name: string }): void {
    if (changeEvent.checked) {
      this.selectedThemes.push(theme);
    } else {
      this.selectedThemes.splice(this.selectedThemes.indexOf(theme, 0), 1);
    }
  }

  selectedThemesToString(): string {
    if (this.selectedThemes.length === 0) {
      return '';
    }

    if (this.selectedThemes.length === 1) {
      return this.selectedThemes[0].name;
    }

    return `${this.selectedThemes[0].name}, ${this.selectedThemes[1].name}`;
  }

  filterThemes(): void {
    this.sortedThemes = this.themes.filter((theme) =>
      theme.name.toLowerCase().includes(this.themesNameFilter.toLowerCase()),
    );
  }

  isChangedTheme(theme: GriEntityModel & { name: string }): boolean {
    return this.changedThemes.some((changedTheme) => changedTheme.standard.id === theme.id);
  }

  showChangedTagPopup(): void {
    const data = {
      title: 'impact_scan.themes.popup.tag.changed.title',
      message: 'impact_scan.themes.popup.tag.changed.body',
      layoutAlign: 'end',
    };

    const popup = this.dialog.open(PopUpComponent, {
      width: '60vw',
      autoFocus: false,
      data,
    });

    popup.afterClosed().subscribe(() => {
      if (document.activeElement) {
        (document.activeElement as HTMLElement).blur();
      }
    });
  }
}
