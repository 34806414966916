import { Action } from '../../../shared/xs/actions';
import { QuestionnaireType } from '../../scoreboard/models/questionnaire.type';

export interface SubmitScoringActionPayload {
  id: string;
  type: QuestionnaireType;
}

export class SubmitScoringAction implements Action {
  static readonly type = '[Dashboard] Submit scoring';

  constructor(public payload: SubmitScoringActionPayload) {}
}
