import { Injectable, NgZone } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CompanyListItemModel } from '../company-list/company-list-item.model';
import { EnterpriseService } from '../enterprise.service';
import { GetEnterpriseCompanyListAction } from './actions/get-enterprise-company.list';
import { LogoutSuccessAction } from '../../auth/xs/actions/logout-success.action';
import { LoginAsCompanyAction, LoginAsCompanyActionResponse } from './actions/login-as-company.action';
import { NewTokenReceivedAction, NewTokenReceivedPayload } from '../../auth/xs/actions/new-token-received.action';
import { LogoutFromSmeAction, LogoutFromSmeActionResponse } from './actions/logout-from-sme.action';
import { LoginAsCompanySuccessAction } from './actions/login-as-company-success.action';
import { LogoutFromSmeSuccessAction } from './actions/logout-from-sme-success.actions';
import { UpdateEnterpriseProfileAction } from './actions/update-enterprise-profile.action';
import { ProfileService } from '../../dashboard';
import { EeveryTranslateService } from '../../shared/eevery.translate.service';
import { SetProfileAction } from '../../auth/xs/actions/set-profile.action';
import { SetCompanyAction } from '../../auth/xs/actions/set-company.action';
import { CheckEnterpriseDashboardAction } from './actions/dashboard-checked.action';
import { CheckPortfolioAction } from './actions/portfolio-checked.action';
import { CheckViewModeAction } from './actions/view-mode-checked.action';

export interface EnterpriseStateModel {
  companyListItems?: CompanyListItemModel[];
  firstDashboardCheck?: boolean;
  firstPortfolioCheck?: boolean;
  firstViewModeCheck?: boolean;
}

@State<EnterpriseStateModel>({ name: 'enterprise' })
@Injectable()
export class EnterpriseState {
  @Selector()
  static companyListItems(state: EnterpriseStateModel): CompanyListItemModel[] {
    return state.companyListItems ? [...state.companyListItems] : [];
  }

  @Selector()
  static isFirstDashboardCheck(state: EnterpriseStateModel): boolean {
    return !!state.firstDashboardCheck;
  }

  @Selector()
  static isFirstPortfolioCheck(state: EnterpriseStateModel): boolean {
    return !!state.firstPortfolioCheck;
  }

  @Selector()
  static isFirstViewModeCheck(state: EnterpriseStateModel): boolean {
    return !!state.firstViewModeCheck;
  }

  constructor(
    private readonly enterpriseService: EnterpriseService,
    private readonly profileService: ProfileService,
    private readonly translateService: EeveryTranslateService,
    private readonly ngZone: NgZone,
  ) {}

  @Action(GetEnterpriseCompanyListAction)
  async getCompanyListItems(ctx: StateContext<EnterpriseStateModel>): Promise<void> {
    const list = await this.enterpriseService.getCompanyListItemsFromBackend();

    ctx.patchState({ companyListItems: list });
  }

  @Action(LoginAsCompanyAction)
  async loginAsSme(ctx: StateContext<EnterpriseStateModel>, action: LoginAsCompanyAction): Promise<void> {
    const result: LoginAsCompanyActionResponse = await this.enterpriseService.loginAsSme(action.payload);

    await this.enterpriseService.dispatchActionAndWait(new NewTokenReceivedAction(result as NewTokenReceivedPayload));
    await this.enterpriseService.dispatchActionAndWait(new LoginAsCompanySuccessAction(result.profile, result.company));
    this.ngZone.run(() => this.enterpriseService.navigateToPage('dashboard/sme/home'));
  }

  @Action(LogoutFromSmeAction)
  async logoutFromSme(ctx: StateContext<EnterpriseStateModel>, action: LogoutFromSmeAction): Promise<void> {
    const result: LogoutFromSmeActionResponse = await this.enterpriseService.logoutFromSme(action.payload);

    await this.enterpriseService.dispatchActionAndWait(new NewTokenReceivedAction(result as NewTokenReceivedPayload));
    await this.enterpriseService.dispatchActionAndWait(new LogoutFromSmeSuccessAction(result.profile, result.company));
    this.ngZone.run(() => this.enterpriseService.navigateToPage('/dashboard/enterprise/companies'));
  }

  @Action(UpdateEnterpriseProfileAction)
  async updateProfile(ctx: StateContext<EnterpriseStateModel>, action: UpdateEnterpriseProfileAction): Promise<void> {
    const result = await this.enterpriseService.updateProfile(action.payload);

    await this.enterpriseService.dispatchActionAndWait(new SetProfileAction(result));
    await this.enterpriseService.dispatchActionAndWait(new SetCompanyAction({ name: action.payload.enterpriseName }));
  }

  @Action(CheckEnterpriseDashboardAction)
  async checkDashboard(ctx: StateContext<EnterpriseStateModel>, action: CheckEnterpriseDashboardAction): Promise<void> {
    ctx.patchState({ firstDashboardCheck: !action.payload.hasBeenChecked });
  }

  @Action(CheckPortfolioAction)
  async checkPortfolio(ctx: StateContext<EnterpriseStateModel>, action: CheckPortfolioAction): Promise<void> {
    ctx.patchState({ firstPortfolioCheck: !action.payload.hasBeenChecked });
  }

  @Action(CheckViewModeAction)
  async checkViewMode(ctx: StateContext<EnterpriseStateModel>, action: CheckViewModeAction): Promise<void> {
    ctx.patchState({ firstViewModeCheck: !action.payload.hasBeenChecked });
  }

  @Action(LogoutSuccessAction)
  clearDashboard(ctx: StateContext<EnterpriseStateModel>): void {
    ctx.setState({});
  }
}
