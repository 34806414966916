import { Component } from '@angular/core';
import { AbstractCo2QuestionnaireDirective } from '../abstract-co2-questionnaire.directive';
import { EeveryTranslateService } from '../../../shared/eevery.translate.service';

@Component({
  selector: 'app-co2-questionnaire-mobility',
  templateUrl: './co2-questionnaire-mobility.component.html',
  styleUrls: ['./co2-questionnaire-mobility.component.scss'],
})
export class Co2QuestionnaireMobilityComponent extends AbstractCo2QuestionnaireDirective {
  constructor(private translateService: EeveryTranslateService) {
    super();
  }

  getDescriptionWithMilesToKm(translationKey: string): string {
    return this.translateService.getLanguage() === 'en-GB' || this.answers.country === 'GB'
      ? `${translationKey}_with_miles`
      : translationKey;
  }
}
