import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/services/abstract.service';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { EsgAssessmentResponseModel } from './model/esg-assessment-response.model';
import { QuestionnaireState, QuestionnaireStateModel, QuestionService } from '../../shared/questions';
import { EsgMaterialityModel } from './model/esg-materiality-model';
import { EsgAssessmentLockAnswersActionResponse } from '../xs/actions/esg-assessment-lock-answers.action';
import { RelevantThemeModel } from '../../shared/relevant-theme/relevant-theme.model';
import {
  MaterialityType,
  SaveMaterialityDtoModel,
} from '../questionnaire/questionaire/materiality/save-materiality-dto.model';
import { EsgAssessmentUpdateMaterialitiesAction } from '../xs/actions/esg-assessment-update-materialities.action';
import { SdgTarget } from '../../shared/info/info.model';

@Injectable({ providedIn: 'root' })
export class EsgAssessmentService extends AbstractService {
  constructor(
    public store: Store,
    public router: Router,
    public http: HttpClient,
    private questionService: QuestionService,
  ) {
    super(store, router, http);
  }

  getEsgAssessment(inputId?: string): Observable<EsgAssessmentResponseModel> {
    const assessmentId = inputId ?? this.questionService.getScoringId();

    return this.http.get<EsgAssessmentResponseModel>(`${this.apiBaseUrl}/assessments/esg/${assessmentId}`);
  }

  getMateriality(): Observable<EsgMaterialityModel> {
    const assessmentId = this.questionService.getScoringId();

    return this.http.get<EsgMaterialityModel>(`${this.apiBaseUrl}/assessments/esg/${assessmentId}/materiality`);
  }

  public async getDoubleMateriality(): Promise<{ doubleMateriality: RelevantThemeModel[][] }> {
    const assessmentId = this.questionService.getScoringId();

    return (await this.http
      .get(`${this.apiBaseUrl}/assessments/esg/${assessmentId}/double-materiality`)
      .toPromise()) as { doubleMateriality: RelevantThemeModel[][] };
  }

  saveAssessment(esgAssessment: EsgAssessmentResponseModel): Observable<void> {
    const assessmentId = this.questionService.getScoringId();

    return this.http.put<void>(`${this.apiBaseUrl}/assessments/esg/${assessmentId}`, esgAssessment);
  }

  lockAnswers(): Observable<EsgAssessmentLockAnswersActionResponse> {
    const assessmentId = this.questionService.getScoringId() ?? '';

    return this.http.post<EsgAssessmentLockAnswersActionResponse>(
      `${this.apiBaseUrl}/assessments/esg/${assessmentId}/lock`,
      {},
    );
  }

  getEsgAssessmentState(): QuestionnaireStateModel {
    return this.store.selectSnapshot(QuestionnaireState);
  }

  saveMateriality(materialities: RelevantThemeModel[][], type: MaterialityType): Observable<void> {
    const assessmentId = this.questionService.getScoringId();

    const payload: SaveMaterialityDtoModel = {
      materiality: materialities,
      materialityType: type,
    };

    return this.http.put<void>(`${this.apiBaseUrl}/assessments/esg/${assessmentId}/materiality`, payload);
  }

  patchMaterialityState(materiality: RelevantThemeModel[][], type: MaterialityType): void {
    this.store.dispatch(new EsgAssessmentUpdateMaterialitiesAction({ materiality, type }));
  }

  createScores(assessmentId: string | undefined): Observable<void> {
    return this.http.post<void>(`${this.apiBaseUrl}/assessments/esg/${assessmentId}/scores`, {});
  }

  getSdgTargets(): Observable<SdgTarget[]> {
    return this.http.get<SdgTarget[]>(`${this.apiBaseUrl}/standards/sdg/targets`);
  }
}
