import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Topic } from '../models/topic.model';

@Component({
  selector: 'app-esg',
  templateUrl: './esg.component.html',
  styleUrls: ['./esg.component.scss'],
})
export class EsgComponent {
  @Input()
  scores: Topic[] = [];

  @Output()
  placeholderButtonClick = new EventEmitter<void>();

  toggleDropdown(): void {
    this.placeholderButtonClick.emit();
  }
}
