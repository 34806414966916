import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Co2CalculationResultModel } from '../../model/co2-calculation-result.model';
import { FormattedGraphData } from '../../../dashboard';
import { Color } from 'ng2-charts';
// @ts-ignore
import { textInMiddlePlugin } from '../../../../assets/js/chart-plugin';
import { StartCo2CalculationAction } from '../../xs/actions/start-co2-calculation.action';
import { Co2CalculatorService } from '../../co2-calculator.service';
import { formatNumber } from '@angular/common';
import { Store } from '@ngxs/store';
import { Co2ResultGraphClickEventModel } from '../../model/co2-result-graph-click-event.model';
import { EeveryTranslateService } from '../../../shared/eevery.translate.service';

@Component({
  selector: 'app-co2-chart',
  templateUrl: './co2-chart.component.html',
  styleUrls: ['./co2-chart.component.scss'],
})
export class Co2ChartComponent implements OnInit, OnChanges {
  @Input()
  co2calculation?: Co2CalculationResultModel;

  @Input()
  showStartButtonInPlaceHolder?: boolean;

  @Output()
  energyClicked = new EventEmitter<void>();

  @Output()
  mobilityClicked = new EventEmitter<void>();

  @Output()
  boardClicked = new EventEmitter<void>();

  @Output()
  materialClicked = new EventEmitter<void>();

  textInMiddlePlugin = textInMiddlePlugin;

  doughnutChart?: FormattedGraphData;

  blue = '#4440E2';

  turquoise = '#00BAFF';

  pink = '#FF45E6';

  yellow = '#FFCA38';

  beige = '#E6E9EB';

  /* eslint-disable no-invalid-this */
  doughnutChartColors: Color[] = [
    {
      backgroundColor: [this.blue, this.turquoise, this.pink, this.yellow],
      borderColor: [this.blue, this.turquoise, this.pink, this.yellow],
      borderWidth: 0,
      pointHoverRadius: 5,
      hoverBorderWidth: 3,
      hoverBackgroundColor: [this.blue, this.turquoise, this.pink, this.yellow],
    },
    { backgroundColor: ['transparent', 'transparent'], borderColor: 'transparent' },
  ];
  /* eslint-enable no-invalid-this */

  language = 'nl-NL';

  constructor(
    private co2CalculatorService: Co2CalculatorService,
    private store: Store,
    private translateService: EeveryTranslateService,
  ) {}

  ngOnInit(): void {
    this.scoresInit();
    this.language = this.translateService.getLanguage();
  }

  ngOnChanges(): void {
    this.scoresInit();
    this.language = this.translateService.getLanguage();
  }

  showEnergyPopup(): void {
    this.energyClicked.emit();
  }

  showMobilityPopup(): void {
    this.mobilityClicked.emit();
  }

  showBoardPopup(): void {
    this.boardClicked.emit();
  }

  showMaterialPopup(): void {
    this.materialClicked.emit();
  }

  startCalculation(): void {
    this.store.dispatch(new StartCo2CalculationAction());
  }

  scoresInit(): void {
    if (!this.co2calculation) {
      return;
    }
    this.doughnutChart = this.formatDataForChart(this.co2calculation);
  }

  formatDataForChart(co2Results: Co2CalculationResultModel): FormattedGraphData {
    const bluePart = co2Results.energyEmission;
    const turquoisePart = co2Results.mobilityEmission;
    const pinkPart = co2Results.foodEmission;
    const yellowPart = co2Results.materialAndWasteEmission;
    const chartData = [
      [bluePart, turquoisePart, pinkPart, yellowPart],
      [0, 0],
    ];
    const chartOptions = {
      aspectRatio: 1.1,
      tooltips: { enabled: false },
      rotation: -0.5 * Math.PI,
      elements: {
        center: {
          // the longest text that could appear in the center
          maxText: '35/35',
          text: `${formatNumber(co2Results.totalEmission, this.language, '1.1-1')} t`,
          fontColor: '#263A46',
          fontFamily: 'oxygen, serif',
          fontStyle: 'normal',
          fontSize: 18,
        },
      },
    };

    return {
      chartData,
      chartOptions,
      chartName: '',
    };
  }

  chartClicked(event: Co2ResultGraphClickEventModel): void {
    /* eslint-disable no-underscore-dangle */
    if (event && event.active && event.active.length > 0) {
      const datasetId = event.active[0]?._index;

      /* eslint-disable @typescript-eslint/indent */
      // eslint-disable-next-line default-case
      switch (datasetId) {
        case 0:
          this.showEnergyPopup();
          break;
        case 1:
          this.showMobilityPopup();
          break;
        case 2:
          this.showBoardPopup();
          break;
        case 3:
          this.showMaterialPopup();
          break;
      }
      /* eslint-enable @typescript-eslint/indent */
    }
    // eslint-enable no-underscore-dangle
  }
}
