import { Component } from '@angular/core';
import { ProfileService } from '../profile.service';
import { UpdateProfileAction, UpdateProfileActionPayload } from '../xs/actions/profile/update-profile.action';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss'],
})
export class PersonalComponent {
  img = '';

  editName = false;

  languages = ['GB', 'NL'];

  savedProfile: UpdateProfileActionPayload;

  profile: UpdateProfileActionPayload = {
    firstName: '',
    lastName: '',
    email: '',
    language: 'GB',
  };

  constructor(private profileService: ProfileService) {
    const profile = profileService.getProfile();

    this.profile = { ...profile } as UpdateProfileActionPayload;

    this.savedProfile = { ...this.profile };
  }

  async saveProfile(): Promise<void> {
    if (this.editName) {
      this.profileService.startLoading();
      await this.profileService.dispatchActionAndWait(new UpdateProfileAction(this.profile));
      this.savedProfile = { ...this.profile };
      this.profileService.stopLoading();
    }
    this.editName = false;
  }

  cancel(): void {
    this.editName = false;
    this.profile = { ...this.savedProfile };
  }
}
