import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-check',
  templateUrl: './cancel-check-popup.component.html',
  styleUrls: ['./cancel-check-popup.component.scss'],
})
export class CancelCheckPopupComponent {
  constructor(public dialogRef: MatDialogRef<CancelCheckPopupComponent>) {}

  close(): void {
    this.dialogRef.close(false);
  }

  cancelSubscription(): void {
    this.dialogRef.close(true);
  }
}
