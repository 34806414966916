import { Action } from '../../../../shared/xs/actions';

export interface GetImpactScanActionPayload {
  id: number;
}

export class GetImpactScanAction implements Action {
  static readonly type = '[Impact Scan Revamp] Get impact scan';

  constructor(public payload: GetImpactScanActionPayload) {}
}
