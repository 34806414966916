import { Injectable } from '@angular/core';
import { EsgAssessmentService } from '../../dashboard/esg-assessment/esg-assessment.service';
import { EsgAssessmentResponseModel } from '../../dashboard/esg-assessment/model/esg-assessment-response.model';
import { EsgAssessmentSubcategoryModel } from '../../dashboard/esg-assessment/model/subcategory/esg-assessment-subcategory.model';
import { AssessmentListModel } from '../../dashboard/assessment-list/assessment-list.model';
import { CompanyService } from '../../dashboard';
import { EeveryTranslateService } from '../eevery.translate.service';
import { ExportUtils } from './exportUtils';

@Injectable({ providedIn: 'root' })
export class EsgAssessmentExportService {
  constructor(
    private readonly esgAssessmentService: EsgAssessmentService,
    private readonly companyService: CompanyService,
    private readonly translateService: EeveryTranslateService,
  ) {}

  /*
   * Applicable only to revamped assessments
   */
  async exportAssessmentToCSV(assessmentListModel: AssessmentListModel): Promise<void> {
    const categories: string[] = ['Economic', 'Environmental', 'Social', 'Governance'];
    const esgAssessment: EsgAssessmentResponseModel = await this.esgAssessmentService
      .getEsgAssessment(assessmentListModel.id)
      .toPromise();
    const isEnglish: boolean = this.isEnglish();
    let data = this.translateService.instant('export.esg_assessment.csv.headers');

    categories.forEach((categoryName: string) => {
      const subcategories: EsgAssessmentSubcategoryModel[] =
        esgAssessment.categories.find((category) => category.name === categoryName)?.subcategories ?? [];
      const triggeredQuestions: string[] = [];

      subcategories.forEach((subcategory: EsgAssessmentSubcategoryModel) => {
        const translatedSubcategory =
          subcategory.translations.find((subcategoryTranslation) =>
            subcategoryTranslation.language.toUpperCase().includes(isEnglish ? 'EN' : 'NL'),
          ) ?? subcategory.translations[0];

        translatedSubcategory.questions.forEach((question) => {
          if (!question.root && !triggeredQuestions.includes(question.number)) {
            return;
          }

          let answer = '';

          switch (question.type?.type) {
            case 'input_area':
              answer = question.type?.answer?.areaAnswer;
              if (answer?.length > 1) {
                const prefix = question.type?.areaPrefix ?? '';
                const suffix = question.type?.areaSuffix ?? '';

                answer = `${prefix} ${answer} ${suffix}`;
              }
              break;
            case 'multiple_choice':
              answer =
                question.type?.options
                  ?.filter((_, index) => question.type?.answer?.choicesAnswer?.map(Number).includes(index))
                  ?.map((option) => {
                    triggeredQuestions.push(...option.triggeredQuestions);

                    return option.name;
                  })
                  ?.join(',') ?? '';
              break;
            case 'file_upload':
              answer = [
                question.type?.answer?.files?.length ?? 0,
                this.translateService.instant('export.esg_assessment.csv.file-upload-answer-text'),
                question.type?.answer?.files?.map((file) => file.filename)?.join(', ') ?? '',
              ]
                .map(String)
                .join('');

              break;
            case 'question_only':
              answer = this.translateService.instant('export.esg_assessment.csv.question-only-answer-text');
              break;
            default:
          }

          if (question.notApplicableAnswer) {
            answer = [
              this.translateService.instant('export.esg_assessment.csv.not-applicable-answer-text.0'),
              question.notApplicableReason || '-',
              this.translateService.instant('export.esg_assessment.csv.not-applicable-answer-text.1'),
              question.notApplicableExplanation || '-',
            ]
              .map(String)
              .join('');
          }

          data = data.concat(
            '\n',
            [categoryName, translatedSubcategory.name, question.number, question.question, question.details, answer]
              .map((x) => x ?? '')
              .map(String)
              .map((str) => str.replace(/\n/g, ' ').replace(/;/g, '.'))
              .join(';'),
          );
        });
      });
    });

    const companyName = this.companyService.getCompany().name;
    const reportingPeriodStart = new Date(assessmentListModel.reportingPeriodStart)
      .toLocaleDateString(isEnglish ? 'en-GB' : 'nl-NL', { year: 'numeric', month: 'long' })
      .replace(' ', '_');

    const filename = [
      companyName,
      this.translateService.instant('export.esg_assessment.csv.file-name.0'),
      reportingPeriodStart,
      this.translateService.instant('export.esg_assessment.csv.file-name.1'),
    ].join('');

    ExportUtils.downloadCSV(data, filename);
  }

  // todo
  async exportAssessmentToPDF(assessmentListModel: AssessmentListModel): Promise<void> {
    await this.esgAssessmentService.getEsgAssessment(assessmentListModel.id).toPromise();
  }

  isEnglish(): boolean {
    return this.translateService.getLanguage().toUpperCase().includes('EN');
  }
}
