import { Action } from '../../../shared/xs/actions';
import { RelevantThemeModel } from '../../../shared/relevant-theme/relevant-theme.model';

export interface LockAnswersActionPayload {
  id: string;
}

export class LockAnswersAction implements Action {
  static readonly type = '[Dashboard] Lock answers';

  constructor(public payload: LockAnswersActionPayload) {}
}

export interface LockAnswersActionResponse {
  impactMateriality: RelevantThemeModel[][];
  financialMateriality: RelevantThemeModel[][];
}
