import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './error.interceptor';
import { SharedModule } from '../shared';

@NgModule({
  declarations: [],
  imports: [SharedModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
})
export class InterceptorModule {}

// AuthModule.forRoot({ config: { secureRoutes: ['/api/sso/users/me'] } }),
