import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Co2CalculatorService } from '../co2-calculator.service';
import { Store } from '@ngxs/store';
import { GetCo2CalculationsListAction } from '../xs/actions/get-co2-calculations-list.action';
import { Co2CalculationListItemModel } from '../model/co2-calculation-list-item.model';

@Component({
  selector: 'app-co2-calculation-list',
  templateUrl: './co2-calculation-list.component.html',
  styleUrls: ['./co2-calculation-list.component.scss'],
})
export class Co2CalculationListComponent implements OnInit {
  calculations: Co2CalculationListItemModel[] = [];

  loading = true;

  @Output()
  startCalculationClicked = new EventEmitter<void>();

  @Output()
  listUpdated = new EventEmitter<void>();

  @Output()
  resultsButtonClicked = new EventEmitter<void>();

  constructor(private store: Store, private co2CalculatorService: Co2CalculatorService) {}

  ngOnInit(): void {
    this.refreshList();
  }

  refreshList(): void {
    this.loading = true;
    this.co2CalculatorService.startLoading();
    this.store.dispatch(new GetCo2CalculationsListAction()).subscribe(
      () => {
        this.calculations = this.co2CalculatorService.getCo2Calculations();
        this.listUpdated.emit();
        this.loading = false;
        this.co2CalculatorService.stopLoading();
      },
      () => {
        this.loading = false;
      },
    );
  }

  startCalculation(): void {
    this.startCalculationClicked.emit();
  }

  showResults(): void {
    this.resultsButtonClicked.emit();
  }
}
