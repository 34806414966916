// eslint-disable-next-line no-shadow
export enum QuestionEnum {
  DROPDOWN = 'DROPDOWN',
  DROPDOWN_COUNTRY = 'DROPDOWN_COUNTRY',
  DROPDOWN_COUNTRY_WITH_INPUT = 'DROPDOWN_COUNTRY_WITH_INPUT',
  DROPDOWN_MULTIPLE = 'DROPDOWN_MULTIPLE',
  DROPDOWN_WITH_INPUT = 'DROPDOWN_WITH_INPUT',
  FILE_UPLOAD_MULTIPLE = 'FILE_UPLOAD_MULTIPLE',
  FILE_UPLOAD_SINGLE = 'FILE_UPLOAD_SINGLE',
  INPUT_FILE_OR_TEXT = 'INPUT_FILE_OR_TEXT',
  INPUT_NUMBER = 'INPUT_NUMBER',
  INPUT_TEXT = 'INPUT_TEXT',
  INPUT_TEXTAREA = 'INPUT_TEXTAREA',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  MULTIPLE_CHOICE_MULTIPLE_ANSWER = 'MULTIPLE_CHOICE_MULTIPLE_ANSWER',
  MULTIPLE_CHOICE_NUMBER = 'MULTIPLE_CHOICE_NUMBER',
  MULTIPLE_CHOICE_WITH_INPUT = 'MULTIPLE_CHOICE_WITH_INPUT',
  QUESTION_ONLY = 'QUESTION_ONLY',
  RELATIONSHIP_LIST = 'RELATIONSHIP_LIST',
  SLIDER_NUMBER = 'SLIDER_NUMBER',
}
