import { Action } from '../../../shared/xs/actions';

export interface SetCo2QuestionnaireIdPayload {
  id: string;
}

export class SetCo2QuestionnaireIdAction implements Action {
  static readonly type = '[Co2Calculator] Set questionnaire id in state';

  constructor(public payload: SetCo2QuestionnaireIdPayload) {}
}
