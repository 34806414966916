import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../../auth/auth.service';

@Directive({ selector: '[appDisableForEnterpriseLoggedInAsSme]' })
export class DisableForEnterpriseLoggedInAsSmeDirective implements OnInit {
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<never>,
    private authService: AuthService,
  ) {}

  isVisible = false;

  ngOnInit(): void {
    if (this.authService.isEnterpriseUserLoggedInAsSme()) {
      this.isVisible = false;
      this.viewContainerRef.clear();
    } else if (!this.isVisible) {
      this.isVisible = true;
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
