import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuestionnaireType } from '../../scoreboard/models/questionnaire.type';

@Component({
  selector: 'app-complete-scoring-check-pop-up',
  templateUrl: './complete-scoring-check-pop-up.component.html',
  styleUrls: ['./complete-scoring-check-pop-up.component.scss'],
})
export class CompleteScoringCheckPopUpComponent {
  type!: QuestionnaireType;

  translationGroup = 'calculate_themes';

  constructor(
    public dialogRef: MatDialogRef<CompleteScoringCheckPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { translationGroup: string },
    private readonly ngZone: NgZone,
  ) {
    this.translationGroup = this.data.translationGroup;
  }

  continue(): void {
    this.ngZone.run(() => this.dialogRef.close(true));
  }

  back(): void {
    this.ngZone.run(() => this.dialogRef.close(false));
  }
}
