import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';

export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL, threshold: 1 },
    pan: { direction: Hammer.DIRECTION_HORIZONTAL, threshold: 10 },
  };
}

export const hammerConfig = {
  provide: HAMMER_GESTURE_CONFIG,
  useClass: CustomHammerConfig,
};
