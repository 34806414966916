import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';

import { LoginAction, LoginActionResponse, LogoutAction, SignupAction } from './actions';
import { AuthService } from '../auth.service';
import { ConfirmEmailAction, ConfirmEmailActionResponse } from './actions/confirm-email.action';
import { ForgotPasswordAction } from './actions/forgot-password.action';
import { ConfirmForgotPasswordAction } from './actions/confirm-forgot-password.action';
import { RefreshTokenAction, RefreshTokenActionResponse } from './actions/refresh-token.action';
import { LogoutSuccessAction } from './actions/logout-success.action';
import { RemoveEmailAction } from './actions/remove-email.action';
import { CountryInitAction } from './actions/country-init.action';
import { MatDialog } from '@angular/material/dialog';
import { FirstLoginPopupComponent } from '../login/first-login-popup/first-login-popup.component';
import { WelcomePopupComponent } from '../../onboarding/welcome-popup/welcome-popup.component';
import { ResendConfirmEmailAction } from './actions/resend-confirm.action';
import { EeveryTranslateService } from '../../shared/eevery.translate.service';
import { Role, toRole } from '../../dashboard/profile/models/role';
import { NewTokenReceivedAction } from './actions/new-token-received.action';
import { SetProfileAction } from './actions/set-profile.action';
import { SetCompanyAction } from './actions/set-company.action';
import { SetSidenavAction } from '../../shared/sidenav/xs/actions/set-sidenav.action';
import jwtDecode from 'jwt-decode';
import { PaymentRedirectionType } from './actions/payment-redirection-type';
import { SsoService } from '../sso/sso.service';
import { LoginSsoAction, LoginSsoActionResponse } from './actions/login-sso.action';
import { SsoPayloadModel } from '../sso/model/sso-payload.model';

export interface AuthStateModel {
  accessToken?: string;
  refreshToken?: string;
  country?: string;
  signUpEmail?: string;
  paymentCountry?: string;
  ssoResponsePayload?: SsoPayloadModel;
}

@State<AuthStateModel>({ name: 'auth' })
@Injectable()
export class AuthState {
  // --------------------------------------------------------------
  // State Selectors
  // --------------------------------------------------------------

  @Selector()
  static accessToken(state: AuthStateModel): string | undefined {
    return state.accessToken;
  }

  @Selector()
  static refreshToken(state: AuthStateModel): string | undefined {
    return state.refreshToken;
  }

  @Selector()
  static country(state: AuthStateModel): string | undefined {
    return state.country;
  }

  @Selector()
  static paymentCountry(state: AuthStateModel): string | undefined {
    return state.paymentCountry;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.accessToken;
  }

  @Selector()
  static Roles(state: AuthStateModel): Role[] | undefined {
    return this.tokenToRoles(state.accessToken);
  }

  @Selector()
  static ssoResponsePayload(state: AuthStateModel): SsoPayloadModel | undefined {
    return state.ssoResponsePayload;
  }

  private static tokenToRoles(token: string | undefined): Role[] | undefined {
    if (token) {
      const decodeToken = jwtDecode(token);

      // @ts-ignore
      const roles = decodeToken['cognito:groups'].map((group) => toRole(group));

      return roles;
    }

    return undefined;
  }

  @Selector()
  static signupEmail(state: AuthStateModel): string | undefined {
    return state.signUpEmail;
  }

  constructor(
    private readonly authService: AuthService,
    private readonly ssoService: SsoService,
    private readonly ngZone: NgZone,
    private readonly translate: EeveryTranslateService,
    private dialog: MatDialog,
  ) {}

  // --------------------------------------------------------------
  // Action Handlers
  // --------------------------------------------------------------
  @Action(SignupAction)
  async signup(ctx: StateContext<AuthStateModel>, action: SignupAction): Promise<void> {
    await this.authService.signup(action.payload);

    ctx.patchState({ signUpEmail: action.payload.email });
  }

  @Action(ForgotPasswordAction)
  async forgotPassword(ctx: StateContext<AuthStateModel>, action: ForgotPasswordAction): Promise<void> {
    await this.authService.forgotPassword(action.payload);
  }

  @Action(ConfirmForgotPasswordAction)
  async confirmForgotPassword(ctx: StateContext<AuthStateModel>, action: ConfirmForgotPasswordAction): Promise<void> {
    await this.authService.confirmForgotPassword(action.payload);
    this.ngZone.run(() => this.authService.navigateToPage('/auth/login'));
  }

  @Action(LoginSsoAction)
  async loginSso(ctx: StateContext<AuthStateModel>, action: LoginSsoAction): Promise<LoginSsoActionResponse> {
    if (!this.ssoService.isAuthenticated()) {
      throw new Error('User is not authenticated');
    }
    const result: LoginActionResponse = action.payload.loginResponse;

    ctx.patchState({
      accessToken: result.token.accessToken,
      refreshToken: result.token.refreshToken,
      ssoResponsePayload: this.ssoService.getSsoPayload(),
    });
    // TODO : improve this duplicate code
    await this.authService.dispatchActionAndWait(new SetProfileAction(result.profile));
    await this.authService.dispatchActionAndWait(new SetCompanyAction(result.company));
    if (result.cobranding) {
      await this.authService.dispatchActionAndWait(
        new SetSidenavAction({
          colours: result.cobranding,
          logo: result.cobranding.logo,
        }),
      );
    }

    const roles = AuthState.tokenToRoles(result.token.accessToken);

    // User is enterprise
    if (roles?.includes(Role.Enterprise) && !roles?.includes(Role.SMEViewer)) {
      const params = {
        firstLogin: result.firstLogin,
        firstUser: result.firstUser,
      };

      this.ngZone.run(() =>
        this.authService.navigateToPage('/dashboard/enterprise/home', result.firstLogin ? params : {}),
      );
    }

    return result;
  }

  @Action(LoginAction)
  async login(ctx: StateContext<AuthStateModel>, action: LoginAction): Promise<LoginActionResponse> {
    if (this.ssoService.checkSsoFromPath()) {
      this.ssoService.logout();
    }
    if (!action.payload.email || !action.payload.password) {
      throw new Error('Email or password property missing');
    }

    const result: LoginActionResponse = await this.authService.login(action.payload);

    ctx.patchState({
      accessToken: result.token.accessToken,
      refreshToken: result.token.refreshToken,
    });

    // User is SME and hasn't paid yet
    if (!result.paymentComplete) {
      ctx.setState({ signUpEmail: result.profile.email, paymentCountry: result.paymentCountry });
      this.redirectToPayment(result.paymentRedirectionType);

      return result;
    }

    await this.authService.dispatchActionAndWait(new SetProfileAction(result.profile));
    await this.authService.dispatchActionAndWait(new SetCompanyAction(result.company));
    if (result.cobranding) {
      await this.authService.dispatchActionAndWait(
        new SetSidenavAction({
          colours: result.cobranding,
          logo: result.cobranding.logo,
        }),
      );
    }
    const roles = AuthState.tokenToRoles(result.token.accessToken);

    // User is admin
    if (roles?.includes(Role.Admin)) {
      this.ngZone.run(() => this.authService.navigateToPage('/admin'));

      return result;
    }

    // User is enterprise
    if (roles?.includes(Role.Enterprise) && !roles?.includes(Role.SMEViewer)) {
      const params = {
        firstLogin: result.firstLogin,
        firstUser: result.firstUser,
      };

      this.ngZone.run(() =>
        this.authService.navigateToPage('/dashboard/enterprise/home', result.firstLogin ? params : {}),
      );

      return result;
    }

    // User is SME and has paid, therefore they can log in
    if (result.company.draft) {
      // Redirected to Company Profile (the popup always shows, not just on first login)
      this.ngZone.run(() => this.authService.navigateToPage('/company-profile'));

      const dialogRef = this.dialog.open(FirstLoginPopupComponent, {
        width: '75vw',
        autoFocus: false,
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe(() => {
        this.dialog.open(WelcomePopupComponent, { width: '60vw', autoFocus: false, disableClose: true });
      });
    } else {
      // if the code gets here and it's the first login, we know that it's an invitee
      // because "payers" get redirected to the onboarding first, not the dashboard
      this.ngZone.run(() =>
        this.authService.navigateToPage(
          `/dashboard/${result.company.type.toLowerCase()}/home`,
          result.firstLogin ? { inviteeFirstLogin: true } : {},
        ),
      );
    }

    return result;
  }

  @Action(LogoutAction)
  logout(): void {
    this.authService.logout().finally(() => {
      this.doFrontendLogout();
    });
  }

  @Action(ConfirmEmailAction)
  async confirmEmail(
    ctx: StateContext<AuthStateModel>,
    action: ConfirmEmailAction,
  ): Promise<ConfirmEmailActionResponse> {
    const result = await this.authService.confirmEmail(action.payload);

    if (result.hasError) {
      this.ngZone.run(() => this.authService.navigateToPage('/auth/signup/confirm-failed'));
    } else {
      if (result && result.email && result.userConfirmed) {
        ctx.patchState({ signUpEmail: result.email, paymentCountry: result.paymentCountry });
      }
      this.redirectToPayment(result.paymentRedirectionType);
    }

    return result;
  }

  @Action(ResendConfirmEmailAction)
  async resendConfirmEmail(ctx: StateContext<AuthStateModel>, action: ResendConfirmEmailAction): Promise<void> {
    await this.authService.resendConfirmEmail(action.payload);
  }

  @Action(RefreshTokenAction)
  async refreshAccessToken(ctx: StateContext<AuthStateModel>): Promise<RefreshTokenActionResponse | void> {
    try {
      const result = await this.authService.refreshAccessToken();

      ctx.patchState({ accessToken: result.accessToken });

      return result;
    } catch (e) {
      return this.doFrontendLogout();
    }
  }

  @Action(NewTokenReceivedAction)
  setNewAccessToken(ctx: StateContext<AuthStateModel>, action: NewTokenReceivedAction): void {
    ctx.patchState({ accessToken: action.payload.accessToken });
  }

  @Action(CountryInitAction)
  async setCountry(ctx: StateContext<AuthStateModel>, action: CountryInitAction): Promise<void> {
    ctx.patchState({ country: action.payload.country });
  }

  @Action(RemoveEmailAction)
  async removeEmailAction(ctx: StateContext<AuthStateModel>): Promise<void> {
    ctx.patchState({ signUpEmail: undefined });
  }

  @Action(LogoutSuccessAction)
  clearAuthState(ctx: StateContext<AuthStateModel>): void {
    ctx.setState({});
  }

  private doFrontendLogout(): void {
    this.authService.dispatchActionAndWait(new LogoutSuccessAction()).finally(() => {
      this.ngZone.run(() => this.authService.navigateToPage('/auth/login'));
    });
  }

  private redirectToPayment(redirectionType: PaymentRedirectionType): void {
    if (redirectionType === 'FREE') {
      this.ngZone.run(() => this.authService.navigateToPage('/auth/signup/terms-and-conditions'));
    } else if (redirectionType === 'INVOICE') {
      this.ngZone.run(() => this.authService.navigateToPage('/auth/signup/invite'));
    } else {
      this.ngZone.run(() => this.authService.navigateToPage('/auth/signup/payment'));
    }
  }
}
