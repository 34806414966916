import { Injectable } from '@angular/core';
import { UngcpModel } from './ungcp.model';

import ungcPrinciplesJson from '../../../assets/json/standards/ungc-principles.json';
import { Select, Store } from '@ngxs/store';
import { GriStandardsState } from '../gri/gri-standards-state.service';
import { Observable } from 'rxjs';
import { GriStandardModel, StandardTranslationModel } from '../gri/get-gri-disclosures.model';
import { GetGriStandardsAction } from '../gri/get-gri-standards.action';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class InfoService {
  constructor(public store: Store) {
    this.store.dispatch(new GetGriStandardsAction());
  }

  @Select(GriStandardsState.griStandards) griStandards$!: Observable<GriStandardModel[]>;

  private ungcPrinciples = ungcPrinciplesJson as UngcpModel[];

  // For now, we retrieve only the English translation.
  // We are waiting for Sophie to tell us, whether we also want to get Dutch ones depending on locale.
  public static getTranslationTitle(translations: StandardTranslationModel[]): string {
    const filteredTranslations = translations.filter((x) => x.languageCode.toUpperCase().includes('EN'));

    return (filteredTranslations.length > 0 ? filteredTranslations[0] : translations[0]).title;
  }

  public getUngcPriciples(principle: string[]): UngcpModel[] {
    const result: UngcpModel[] = [];

    this.ungcPrinciples.forEach((ungcp) => {
      if (principle.includes(ungcp.principleNumber)) {
        result.push(ungcp);
      }
    });

    return result;
  }

  public getGriStandards(code: string[]): Observable<GriStandardModel[]> {
    return this.griStandards$.pipe(
      filter((standard) => standard !== undefined),
      map((standards: GriStandardModel[]) =>
        standards
          .map((standard) => ({
            // eslint-disable-next-line id-blacklist
            number: standard.number,
            category: standard.category,
            translations: standard.translations,
            disclosures: standard.disclosures.filter((disclosure) => code.includes(disclosure.number)),
            year: standard.year,
          }))
          .filter((standard) => standard.disclosures.length > 0),
      ),
    );
  }
}
