import { AfterViewChecked, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { InfoModel } from '../info/info.model';

@Component({
  selector: 'app-question-with-info',
  templateUrl: './question-with-info.component.html',
  styleUrls: ['./question-with-info.component.scss'],
})
export class QuestionWithInfoComponent implements AfterViewChecked {
  @Input()
  questionText!: string;

  @Input()
  infoModel?: InfoModel;

  @Input()
  classList?: string[];

  @ViewChild('questionSpan')
  questionSpan?: ElementRef;

  @ViewChild('info')
  info?: ElementRef;

  ngAfterViewChecked(): void {
    if (this.questionSpan) {
      const { width } = this.questionSpan.nativeElement.getBoundingClientRect();

      if (this.info) {
        const infoWidth = this.info.nativeElement.getBoundingClientRect().width;

        this.info.nativeElement.style.marginRight = `-${infoWidth}px`;
        this.info.nativeElement.style.left = `${width}px`;
      }
    }
  }
}
