import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-esg-finish-popup',
  templateUrl: './esg-finish-popup.component.html',
  styleUrls: ['./esg-finish-popup.component.scss'],
})
export class EsgFinishPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<EsgFinishPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { first: boolean },
    private readonly ngZone: NgZone,
  ) {}

  close(): void {
    this.ngZone.run(() => this.dialogRef.close({ navigate: undefined }));
  }

  goToResults(): void {
    this.ngZone.run(() => this.dialogRef.close({ navigate: 'scoreboard' }));
  }

  goToImprovementCentre(): void {
    this.ngZone.run(() => this.dialogRef.close({ navigate: 'improvements' }));
  }
}
