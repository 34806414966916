import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { OnboardingComponent } from './onboarding.component';
import { GeneralComponent } from './general/general.component';
import { ProductsComponent } from './products/products.component';
import { GovernanceComponent } from './governance/governance.component';
import { ContactComponent } from './contact/contact.component';
import { OnboardingRouting } from './onboarding.routing';
import { MaterialDesignModule, SharedModule } from '../shared';
import { SubsidiariesComponent } from './subsidiaries/subsidiaries.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    OnboardingComponent,
    GeneralComponent,
    ProductsComponent,
    GovernanceComponent,
    ContactComponent,
    SubsidiariesComponent,
  ],
  imports: [
    OnboardingRouting,
    MaterialDesignModule,
    TranslateModule,
    CommonModule,
    SharedModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
  ],
})
export class OnboardingModule {}
