import { Component, Input } from '@angular/core';
import { ImprovementComment } from '../improvement-centre/models/improvement.model';

@Component({
  selector: 'comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent {
  @Input() comment!: ImprovementComment;
}
