import { Component, OnInit } from '@angular/core';
import { CountryModel } from '../../../../shared/questions';
import countryJsonGB from '../../../../../assets/json/country-code-gb.json';
import countryJsonNL from '../../../../../assets/json/country-code-nl.json';
import { InfoModel } from '../../../../shared/info/info.model';
import { PopUpModel } from '../../../../shared/pop-up/pop-up.model';
import { ImpactScanPageDirective } from '../abstract-impact-scan-page';
import { isCountryCodeDutchLike } from '../../../../shared/countries/country-service';

@Component({
  selector: 'app-company-context-one',
  templateUrl: './company-context-one.component.html',
  styleUrls: ['./company-context-one.component.scss'],
})
export class CompanyContextOneComponent extends ImpactScanPageDirective implements OnInit {
  countryList: CountryModel[] = [];

  sectorInfo: InfoModel = {
    description: { explanation: 'impact_scan.company_context_one.sector.description' },
    oldGriStandards: ['2-6'],
  };

  employeesInfo: InfoModel = {
    description: { explanation: 'impact_scan.company_context_one.employees.description' },
    oldGriStandards: ['2-7'],
  };

  revenueInfo: InfoModel = {
    description: { explanation: 'impact_scan.company_context_one.revenue.description' },
    oldGriStandards: ['201-1'],
  };

  assetsInfo: InfoModel = {
    description: { explanation: 'impact_scan.company_context_one.assets.description' },
    oldGriStandards: ['201-1'],
  };

  customersInfo: InfoModel = { oldGriStandards: ['2-6'] };

  popUpSectorsGb: string[] = ['64201', '64202', '64203', '64204', '64205', '64206', '64207', '64208', '64209'];

  popUpSectorsNl: string[] = ['6420'];

  bannedSectors: string[] = [];

  holdingError = false;

  ngOnInit(): void {
    this.countryInit();
  }

  private countryInit(): void {
    if (isCountryCodeDutchLike(this.country)) {
      this.countryList = countryJsonNL as CountryModel[];
      this.bannedSectors = this.popUpSectorsNl;
    } else {
      this.countryList = countryJsonGB as CountryModel[];
      this.bannedSectors = this.popUpSectorsGb;
    }
  }

  checkForSectorPopup(): void {
    if (this.impactScan?.sectorCode && this.bannedSectors.includes(this.impactScan.sectorCode)) {
      this.sectorPopup();
      this.impactScan.sectorCode = '';
      this.holdingError = true;
    } else {
      this.holdingError = false;
    }
  }

  private sectorPopup(): void {
    const data: PopUpModel = {
      title: 'pop_up.sector.title',
      message: 'pop_up.sector.description',
    };

    this.showPopUp(data);
  }

  checkForEmployeesPopup(): void {
    if (this.impactScan?.numberOfEmployees && this.impactScan.numberOfEmployees > 250) {
      this.numberOfEmployeesPopUp();
    }
  }

  private numberOfEmployeesPopUp(): void {
    const data: PopUpModel = {
      title: 'pop_up.employee_number.title',
      message: 'pop_up.employee_number.description',
    };

    this.showPopUp(data);
  }

  checkForSmeQualificationPopUp(): void {
    if (
      this.impactScan?.totalRevenue &&
      this.impactScan.totalRevenue >= 43000000 &&
      this.impactScan.totalAssets &&
      this.impactScan.totalAssets >= 50000000
    ) {
      this.smeQualificationPopUp();
    }
  }

  private smeQualificationPopUp(): void {
    const data: PopUpModel = {
      title: 'pop_up.sme_qualification.title',
      message: 'pop_up.sme_qualification.description',
    };

    this.showPopUp(data);
  }
}
