/* eslint-disable @typescript-eslint/indent,no-invalid-this */
import { Component, Input, ViewChild } from '@angular/core';
import { PopUpModel } from '../../shared/pop-up/pop-up.model';
import { FormControl, NgModel } from '@angular/forms';
import { InfoModel } from '../../shared/info/info.model';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { SearchCompanyResponse } from '../../dashboard/profile/models/search-company.model';
import { LookupCompanyAction } from '../../dashboard/profile/xs/actions/company/lookup-company.action';
import { OnboardingPageDirective } from '../onboarding.page.directive';
import { UpdateCompanyActionPayload } from '../../dashboard/profile/xs/actions/company/update-company.action';
import { isCountryCodeDutchLike } from '../../shared/countries/country-service';

export interface LookupCompany {
  country: string;
  registeredId: string;
}

@Component({
  selector: 'app-onboarding-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})
export class GeneralComponent extends OnboardingPageDirective {
  @Input()
  set companyData(value: UpdateCompanyActionPayload & { loadedFromBackend: boolean | undefined }) {
    this.company = value;
    if (value.loadedFromBackend && !this.companyInitRan) {
      this.companyInit();
    }
  }

  searchCompaniesCtrl = new FormControl();

  filteredCompanies?: SearchCompanyResponse[];

  isLoading = false;

  minLengthTerm = 1;

  selectedSearchResult: SearchCompanyResponse = { name: '', sbi: '' };

  countries: string[] = ['nl', 'gb'];

  legalEntityTypeCustom = '';

  legalEntityOptionsNl = ['BV', 'NV'];

  legalEntityOptionsGb = ['Limited company', 'Limited liability partnership'];

  legalEntityOptions: string[] = [];

  popUpSectorsGb: string[] = ['64201', '64202', '64203', '64204', '64205', '64206', '64207', '64208', '64209'];

  popUpSectorsNl: string[] = ['6420'];

  bannedSectors: string[] = [];

  showLegalEntityError = false;

  showEmployeesError = false;

  validRegisteredId = new RegExp('^.{3}\\d{5}$');

  lastUpdatedRegisteredId? = '';

  @ViewChild('numberOfEmployees') numberOfEmployees!: NgModel;

  annualError = false;

  assetsError = false;

  showSectorError = false;

  nameInfo: InfoModel = { oldGriStandards: ['2-1'] };

  idInfo: InfoModel = { oldGriStandards: ['2-1'] };

  countryInfo: InfoModel = { oldGriStandards: ['2-1'] };

  legalEntityInfo: InfoModel = { oldGriStandards: ['2-1'] };

  sectorInfo: InfoModel = { oldGriStandards: ['2-6', '207-4'] };

  turnoverInfo: InfoModel = { oldGriStandards: ['201-1'] };

  assetsInfo: InfoModel = { oldGriStandards: ['201-1'] };

  employeesInfo: InfoModel = { oldGriStandards: ['2-7'] };

  companyInitRan = false;

  validateForm(formValid: boolean): boolean {
    if (!this.company.registeredId || !this.searchCompaniesCtrl.value) {
      this.searchCompaniesCtrl.setErrors({ required: true });

      return false;
    }

    return formValid;
  }

  onSelected(): void {
    if (this.selectedSearchResult) {
      this.company.name = this.selectedSearchResult.name;
      this.company.registeredId = this.selectedSearchResult.sbi;
      this.lookupNameAndSector();
    }
  }

  displayWith(value: SearchCompanyResponse): string {
    return value?.sbi;
  }

  async companyInit(): Promise<void> {
    if (this.company.registeredId) {
      this.lastUpdatedRegisteredId = this.company.registeredId;
      this.selectedSearchResult.sbi = this.company.registeredId;
      this.searchCompaniesCtrl.setValue({
        sbi: this.company.registeredId,
        name: this.company.name,
      });
    }
    this.initRegisteredIdSearch();

    this.initLegalEntityOptions();
    if (this.company.legalEntityType && !this.legalEntityOptions.includes(this.company.legalEntityType)) {
      this.legalEntityTypeCustom = this.company.legalEntityType;
    }

    /* eslint-disable */
    this.nameInfo.description = { explanation: await this.translateService.get('onboarding.general.name.desc') };
    this.countryInfo.description = { explanation: await this.translateService.get('onboarding.general.country.desc') };
    this.idInfo.description = { explanation: await this.translateService.get('onboarding.general.id.desc') };
    this.legalEntityInfo.description = { explanation: await this.translateService.get('onboarding.general.legal_entity.desc') };
    this.sectorInfo.description = { explanation: await this.translateService.get('onboarding.general.sector.desc') };
    this.turnoverInfo.description = { explanation: await this.translateService.get('onboarding.general.turnover.desc') };
    this.assetsInfo.description = { explanation: await this.translateService.get('onboarding.general.assets.desc') };
    this.employeesInfo.description = { explanation: await this.translateService.get('onboarding.general.employees.desc') };
    /* eslint-enable */
    this.companyInitRan = true;
  }

  private initRegisteredIdSearch(): void {
    this.searchCompaniesCtrl.valueChanges
      .pipe(
        filter((res) => res !== null && res !== undefined && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        tap(() => {
          if (!this.searchCompaniesCtrl.value?.includes(this.company.registeredId)) {
            this.filteredCompanies = [];
          }
          this.company.registeredId = this.searchCompaniesCtrl.value;
        }),
        debounceTime(500),
        tap(() => {
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.companyService
            .searchCompany({
              country: this.company.country,
              query: value,
            })
            .pipe(
              finalize(() => {
                this.isLoading = false;
              }),
            ),
        ),
      )
      .subscribe((data) => {
        if (data) {
          this.filteredCompanies = data;
        }
      });
  }

  countrySelected(): void {
    this.company.legalEntityType = '';
    this.initLegalEntityOptions();
  }

  initLegalEntityOptions(): void {
    if (isCountryCodeDutchLike(this.company.country)) {
      this.bannedSectors = this.popUpSectorsNl;
      this.legalEntityOptions = this.legalEntityOptionsNl;
    } else {
      this.legalEntityOptions = this.legalEntityOptionsGb;
      this.bannedSectors = this.popUpSectorsGb;
    }
  }

  updateSectorWithoutAutosuggestion(): void {
    if (
      this.lastUpdatedRegisteredId !== this.company.registeredId &&
      this.isValidRegisteredId(this.company.registeredId)
    ) {
      this.lookupNameAndSector();
    }
  }

  isValidRegisteredId(registeredId?: string): boolean {
    return !!(registeredId && this.validRegisteredId.test(registeredId));
  }

  lookupNameAndSector(): void {
    this.lastUpdatedRegisteredId = this.company.registeredId;
    const data = this.company as LookupCompany;

    this.companyService
      .dispatchActionAndWait(new LookupCompanyAction(data))
      .then(() => {
        const companyFromBackend = this.companyService.getCompany();

        if (companyFromBackend) {
          this.company.localSectorCode = companyFromBackend.localSectorCode;
          this.company.name = `${companyFromBackend.name}`;
        }
      })
      .finally(() => {
        this.onAnswerAdded();
      });
  }

  legalEntitySelected(value: string): void {
    this.company.legalEntityType = value;
    this.legalEntityTypeCustom = '';
    this.showLegalEntityError = false;
    this.onAnswerAdded();
  }

  onCustomTypeInput(): void {
    this.company.legalEntityType = this.legalEntityTypeCustom;
    this.showLegalEntityError = false;
  }

  submit(): void {
    if (!this.company.legalEntityType || !this.company.legalEntityType.length) {
      this.showLegalEntityError = true;

      return;
    }

    if (!this.company.localSectorCode) {
      this.showSectorError = true;

      return;
    }

    if (this.company.localSectorCode && this.bannedSectors.includes(this.company.localSectorCode)) {
      this.showSectorPopUp();

      return;
    }

    if (
      this.company.annualTurnover &&
      this.company.annualTurnover >= 43000000 &&
      this.company.totalAssets &&
      this.company.totalAssets >= 50000000
    ) {
      this.smeQualificationPopUp();

      return;
    }

    if (this.company.numberOfEmployees && this.company.numberOfEmployees > 250) {
      this.numberOfEmployeesPopUp();

      return;
    }

    this.finished.emit(this.company);
  }

  private async smeQualificationPopUp(): Promise<void> {
    const data: PopUpModel = {
      title: await this.translateService.get('pop_up.sme_qualification.title'),
      message: await this.translateService.get('pop_up.sme_qualification.description'),
    };

    const dialogRef = this.showPopUp(data);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.finished.emit(this.company);
      }
    });
  }

  private async numberOfEmployeesPopUp(): Promise<void> {
    const data: PopUpModel = {
      title: await this.translateService.get('pop_up.employee_number.title'),
      message: await this.translateService.get('pop_up.employee_number.description'),
    };

    const dialogRef = this.showPopUp(data);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.finished.emit(this.company);
      }
    });
  }

  private async showSectorPopUp(): Promise<void> {
    const data: PopUpModel = {
      title: await this.translateService.get('pop_up.sector.title'),
      message: await this.translateService.get('pop_up.sector.description'),
    };

    this.showPopUp(data);
  }

  getSectionValue($event: string): void {
    this.company.localSectorCode = $event;
    this.showSectorError = false;
    this.onAnswerAdded();
  }

  validateInput(): void {
    if (this.numberOfEmployees.value < 0) {
      this.company.numberOfEmployees = 0;
    }
    if (!this.numberOfEmployees.errors?.required && this.numberOfEmployees.value > 250) {
      // this.numberOfEmployees.control.setErrors({ maxvalue: true });
    } else {
      this.numberOfEmployees.control.updateValueAndValidity();
    }
  }
}
