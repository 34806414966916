import { HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { PopUpComponent } from '../shared/pop-up/pop-up.component';
import { PopUpModel } from '../shared/pop-up/pop-up.model';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class ErrorHandler {
  constructor(public dialog: MatDialog, private authService: AuthService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public catchError(response: HttpErrorResponse): Observable<HttpEvent<any>> {
    let errorMsg;

    if (response.error instanceof ErrorEvent) {
      // Client side error
      this.unknownErrorPopup();
      errorMsg = `Error: ${response.error.message}`;
    } else {
      // Server side error
      if (response.status === 401 && response.error.error.indexOf('token refresh') > -1) {
        this.sessionExpiredPopup();
      } else if (response.status === 401) {
        this.unauthorizedErrorPopup();
      } else if (response.error && response.error.error) {
        this.errorPopup(response.error.error);
      } else {
        this.unknownErrorPopup();
      }
      errorMsg = `Error Code: ${response.status},  Message: ${response.message}`;
    }

    this.authService.stopLoading();

    return throwError(errorMsg);
  }

  private errorPopup(errorMessage: string): void {
    const popupData: PopUpModel = {
      title: 'error_pop_up.title',
      message: errorMessage,
    };

    this.showPopUp(popupData);
  }

  private unknownErrorPopup(): void {
    const popupData: PopUpModel = {
      title: 'error_pop_up.title',
      message: 'An unknown error occurred.',
    };

    this.showPopUp(popupData);
  }

  private unauthorizedErrorPopup(): void {
    const popupData: PopUpModel = {
      title: 'Unauthorised',
      message: 'You are not authorised.',
    };

    this.showPopUp(popupData);
  }

  private sessionExpiredPopup(): void {
    const popupData: PopUpModel = {
      title: 'error_pop_up.session_expired.title',
      message: 'error_pop_up.session_expired.text',
    };

    this.showPopUp(popupData);
  }

  private showPopUp(data: PopUpModel): void {
    // eslint-disable-next-line no-param-reassign
    data.roundButton = !this.authService.isUserAuthenticated();
    this.dialog.open(PopUpComponent, { width: '400px', autoFocus: false, data });
  }
}
