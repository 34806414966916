import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ImprovementCentreService } from '../improvement-centre.service';
import { ImprovementModel } from '../models/improvement.model';
import { GetLatestImprovementAction } from './get_latest_improvements.action';
import { CheckImprovementAction } from '../../dashboard/xs/actions/improvement-checked.action';
import { LogoutSuccessAction } from '../../auth/xs/actions/logout-success.action';
import { GetImprovementsAction } from './get_improvements.action';
import { UserStatus } from '../../dashboard/assessment-list/user-status.enum';
import { RefreshImprovementsAction } from './refresh-improvements.action';
import { LogoutFromSmeSuccessAction } from '../../enterprise/xs/actions/logout-from-sme-success.actions';

export interface ImprovementCentreStateModel {
  firstImprovementCheck?: boolean;
  improvements?: ImprovementModel[];
  assessmentId?: string;
  firstName?: string;
  lastName?: string;
  userStatus?: UserStatus;
}

@State<ImprovementCentreStateModel>({ name: 'improvement_centre' })
@Injectable()
export class ImprovementCentreState {
  @Selector()
  static isFirstImprovementCheck(state: ImprovementCentreStateModel): boolean {
    return state.firstImprovementCheck === undefined ? true : state.firstImprovementCheck;
  }

  @Selector()
  static getImprovements(state: ImprovementCentreStateModel): ImprovementModel[] | undefined {
    return state.improvements;
  }

  @Selector()
  static getFullName(state: ImprovementCentreStateModel): string {
    return `${state.firstName} ${state.lastName}`;
  }

  @Selector()
  static getStatus(state: ImprovementCentreStateModel): UserStatus | undefined {
    return state.userStatus;
  }

  constructor(private readonly improvementCentreService: ImprovementCentreService) {}

  @Action(GetLatestImprovementAction)
  async getLatestImprovements(ctx: StateContext<ImprovementCentreStateModel>): Promise<void> {
    const result = await this.improvementCentreService.getLatestImprovements();

    ctx.patchState({
      improvements: result.improvementSuggestions,
      assessmentId: result.id,
      firstName: result.firstName,
      lastName: result.lastName,
      userStatus: result.userStatus,
    });
  }

  @Action(GetImprovementsAction)
  async getImprovements(ctx: StateContext<ImprovementCentreStateModel>, action: GetImprovementsAction): Promise<void> {
    const result = await this.improvementCentreService.getSpecificImprovements(action.payload.id);

    ctx.patchState({
      improvements: result.improvementSuggestions,
      assessmentId: result.id,
      firstName: result.firstName,
      lastName: result.lastName,
      userStatus: result.userStatus,
    });
  }

  @Action(RefreshImprovementsAction)
  async refreshImprovements(ctx: StateContext<ImprovementCentreStateModel>): Promise<void> {
    const state = ctx.getState();

    this.improvementCentreService.dispatchAction(
      state.assessmentId ? new GetImprovementsAction({ id: state.assessmentId }) : new GetLatestImprovementAction(),
    );
  }

  @Action(CheckImprovementAction)
  async checkImprovement(
    ctx: StateContext<ImprovementCentreStateModel>,
    action: CheckImprovementAction,
  ): Promise<void> {
    ctx.patchState({ firstImprovementCheck: !action.payload.hasBeenChecked });
  }

  @Action([LogoutSuccessAction, LogoutFromSmeSuccessAction])
  clearImprovementCentre(ctx: StateContext<ImprovementCentreStateModel>): void {
    ctx.setState({});
  }
}
