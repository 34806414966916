import { Component } from '@angular/core';
import { SsoService } from '../sso.service';

@Component({ templateUrl: './sso-logout.component.html' })
export class SsoLogoutComponent {
  constructor(private ssoService: SsoService) {}

  logout(): void {
    this.ssoService.logout();
  }
}
