import { Component, OnInit } from '@angular/core';
import { GeneralSection } from '../company.models';
import { CompanyCardDirective } from '../company-card.directive';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { SearchCompanyResponse } from '../../../dashboard/profile/models/search-company.model';
import { LookupCompanyAction } from '../../../dashboard/profile/xs/actions/company/lookup-company.action';
import { LookupCompany } from '../../../onboarding';
import { CompanyService } from '../../../dashboard';
import { FormControl, NgForm } from '@angular/forms';
import { listOfSupportedCountries, sortCountriesByTranslation } from '../../countries/country-service';
import { EeveryTranslateService } from '../../eevery.translate.service';

@Component({
  selector: 'app-general-card',
  templateUrl: './general-card.component.html',
  styleUrls: ['./general-card.component.scss'],
})
export class GeneralCardComponent extends CompanyCardDirective<GeneralSection> implements OnInit {
  countries: string[] = listOfSupportedCountries;

  nameSearchCtrl = new FormControl();

  filteredCompanies?: SearchCompanyResponse[];

  isNameLoading = false;

  minLengthTerm = 1;

  selectedNameResult: SearchCompanyResponse = { name: '', sbi: '' };

  validRegisteredId = new RegExp('^.{3}\\d{5}$');

  lastUpdatedRegisteredId? = '';

  companyInitRan = false;

  showErrors = false;

  constructor(public companyService: CompanyService, public translateService: EeveryTranslateService) {
    super();
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.companyInit();
    this.countries = await sortCountriesByTranslation(
      this.countries,
      this.translateService,
      'onboarding.general.countries.',
      false,
    );
  }

  async companyInit(): Promise<void> {
    if (this.data.registeredId) {
      this.lastUpdatedRegisteredId = this.data.registeredId;
    }

    this.initNameSearch();
    this.companyInitRan = true;
  }

  private initNameSearch(): void {
    this.nameSearchCtrl.valueChanges
      .pipe(
        filter((res) => res !== null && res !== undefined && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        tap(() => {
          if (!this.nameSearchCtrl.value?.includes(this.data.name)) {
            this.filteredCompanies = [];
          }
        }),
        debounceTime(500),
        tap(() => {
          this.isNameLoading = true;
        }),
        switchMap((value) =>
          this.companyService
            .searchCompany({
              country: this.data.country,
              query: value,
            })
            .pipe(
              finalize(() => {
                this.isNameLoading = false;
              }),
            ),
        ),
      )
      .subscribe((data) => {
        if (data) {
          this.filteredCompanies = data;
        }
      });
  }

  countrySelected(): void {
    this.data.name = '';
    this.data.registeredId = '';
    this.nameSearchCtrl.reset();
    this.filteredCompanies = [];
  }

  isFormValid(form: NgForm): boolean {
    const valid = !!form.valid && !!this.data.name && !!this.data.registeredId;

    if (!valid) {
      this.showErrors = true;
    }

    return valid;
  }

  updateNameWithoutAutosuggestion(): void {
    const registeredId = this.nameSearchCtrl.value;

    if (this.lastUpdatedRegisteredId !== registeredId && this.isValidRegisteredId(registeredId)) {
      this.lookupName(registeredId);
    }
  }

  isValidRegisteredId(registeredId?: string): boolean {
    return !!(registeredId && this.validRegisteredId.test(registeredId));
  }

  onNameSelected(): void {
    if (this.selectedNameResult) {
      this.data.name = this.selectedNameResult.name;
      this.data.registeredId = this.selectedNameResult.sbi;
    }
  }

  displayWithName(value: SearchCompanyResponse): string {
    return value?.name;
  }

  lookupName(registeredId: string): void {
    this.lastUpdatedRegisteredId = registeredId;
    const data = {
      country: this.data.country,
      registeredId,
    };

    this.companyService.dispatchActionAndWait(new LookupCompanyAction(data)).then(() => {
      const companyFromBackend = this.companyService.getCompany();

      if (companyFromBackend) {
        this.data.name = `${companyFromBackend.name}`;
        this.data.registeredId = registeredId;
        this.selectedNameResult.name = this.data.name;
        this.nameSearchCtrl.setValue({
          sbi: this.data.registeredId,
          name: this.data.name,
        });
      }
    });
  }

  reset(): void {
    super.reset();
    this.showErrors = false;
  }

  lookupSectorAndSubmit(): void {
    if (!this.isValidRegisteredId(this.data.registeredId) || !this.selectedCountrySupportsNameSearch()) {
      if (!this.selectedCountrySupportsNameSearch()) {
        this.data.localSectorCode = undefined;
      }
      this.submit();

      return;
    }
    const data = this.data as LookupCompany;

    this.companyService
      .dispatchActionAndWait(new LookupCompanyAction(data))
      .then(() => {
        const companyFromBackend = this.companyService.getCompany();

        if (companyFromBackend) {
          this.data.localSectorCode = companyFromBackend.localSectorCode;
        }
      })
      .finally(() => {
        this.submit();
      });
  }

  selectedCountrySupportsNameSearch(): boolean {
    return ['nl', 'gb'].includes(this.data.country);
  }

  companySearchFieldShouldBeEnabled(): boolean {
    return this.edit && this.selectedCountrySupportsNameSearch();
  }

  companyNameAndIdFieldsShouldBeEnabled(): boolean {
    return this.edit && !this.selectedCountrySupportsNameSearch();
  }
}
