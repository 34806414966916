import { Component, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-impact-start-popup',
  templateUrl: './impact-start-popup.component.html',
  styleUrls: ['./impact-start-popup.component.scss'],
})
export class ImpactStartPopupComponent {
  constructor(public dialogRef: MatDialogRef<ImpactStartPopupComponent>, private readonly ngZone: NgZone) {}

  close(): void {
    this.ngZone.run(() => this.dialogRef.close(false));
  }

  continue(): void {
    this.ngZone.run(() => this.dialogRef.close(true));
  }
}
