import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EsgAssessmentModel } from './esg-prefill.model';

export interface DialogDataModel {
  esgAssessments: EsgAssessmentModel[];
}

export interface DialogPrefillResultModel {
  continue: boolean;
  back: boolean;
  prefillEnabled?: boolean;
  selectedAssessment?: string;
}

@Component({
  selector: 'app-esg-prefill-popup',
  templateUrl: './esg-prefill-popup.component.html',
  styleUrls: ['./esg-prefill-popup.component.scss'],
})
export class EsgPrefillPopupComponent implements OnInit {
  continueDisabled = true;

  esgAssessments: EsgAssessmentModel[] = [];

  activeButton = '';

  prefillEnabled = false;

  selectedEsgAssessment = '';

  constructor(
    public dialogRef: MatDialogRef<EsgPrefillPopupComponent>,
    private readonly ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataModel,
  ) {}

  ngOnInit(): void {
    this.esgAssessments = this.data.esgAssessments;
  }

  close(): void {
    this.ngZone.run(() => this.dialogRef.close(this.getDialogResult(false)));
  }

  back(): void {
    this.ngZone.run(() => this.dialogRef.close(this.getDialogResult(false, true)));
  }

  continue(): void {
    this.ngZone.run(() => this.dialogRef.close(this.getDialogResult(true)));
  }

  select(button: string): void {
    this.activeButton = button;
    this.continueDisabled = false;

    if (button === 'yes') {
      this.continueDisabled = true;
      this.prefillEnabled = true;
    } else {
      this.prefillEnabled = false;
    }
  }

  onSelectionChange($event: string): void {
    this.selectedEsgAssessment = $event.valueOf();
    this.continueDisabled = false;
  }

  private getDialogResult(continueValue: boolean, backValue?: boolean): DialogPrefillResultModel {
    return {
      continue: continueValue,
      back: backValue ?? false,
      prefillEnabled: this.prefillEnabled,
      selectedAssessment: this.selectedEsgAssessment,
    };
  }
}
