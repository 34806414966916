import { Injectable } from '@angular/core';
import { EeveryTranslateService } from '../eevery.translate.service';
import { SliderGroupService } from '../../dashboard/scoreboard/shared/slider-toggle/slider-group.service';
import { SdgCardModel } from '../../dashboard';
import { InfoService } from '../info/info.service';
import { ExportUtils } from './exportUtils';

@Injectable({ providedIn: 'root' })
export class SdgScoresExportService {
  constructor(private translateService: EeveryTranslateService, private slierGroupService: SliderGroupService) {}

  public async exportSdgGriCrossAnalysisToSimpleCSV(cards: SdgCardModel[], assessmentAlias?: string): Promise<void> {
    const data = [this.translateService.instant('export.scores.sdg.csv.short.headers')];

    cards.forEach((card) => {
      const sdgGoalCode = card.sdgCode;
      const sdgGoalTitle = card.title;

      card.relevantGriStandards?.forEach((griStandard) => {
        data.push(
          `${sdgGoalCode};${sdgGoalTitle};${griStandard.number};${InfoService.getTranslationTitle(
            griStandard.translations,
          )};${griStandard.category}`,
        );
      });
    });

    const filename = `${this.translateService.instant(
      'export.scores.sdg.csv.short.file_name.0',
    )}${this.slierGroupService.formatName(assessmentAlias ?? '')}${this.translateService.instant(
      'export.scores.sdg.csv.short.file_name.1',
    )}`;

    ExportUtils.downloadCSV(data.join('\n'), filename);

    return Promise.resolve();
  }

  public async exportSdgGriCrossAnalysisToDetailedCSV(cards: SdgCardModel[], assessmentAlias?: string): Promise<void> {
    const data = [this.translateService.instant('export.scores.sdg.csv.detailed.headers')];

    cards.forEach((card) => {
      const sdgGoalCode = card.sdgCode;
      const sdgGoalTitle = card.title;

      card.relevantGriStandards?.forEach((griStandard) => {
        griStandard.disclosures.forEach((disclosure) => {
          data.push(
            `${sdgGoalCode};${sdgGoalTitle};${griStandard.number};${InfoService.getTranslationTitle(
              griStandard.translations,
            )};${griStandard.category};${disclosure.number};${InfoService.getTranslationTitle(
              disclosure.translations,
            )}`,
          );
        });
      });
    });

    const filename = `${this.translateService.instant(
      'export.scores.sdg.csv.detailed.file_name.0',
    )}${this.slierGroupService.formatName(assessmentAlias ?? '')}${this.translateService.instant(
      'export.scores.sdg.csv.detailed.file_name.1',
    )}`;

    ExportUtils.downloadCSV(data.join('\n'), filename);

    return Promise.resolve();
  }
}
