import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SliderGroupService } from './slider-group.service';

export interface OptionItem {
  id?: string;
  alias?: string;
}

@Component({
  selector: 'app-slider-group',
  templateUrl: './slider-group.component.html',
  styleUrls: ['./slider-group.component.scss'],
})
export class SliderGroupComponent implements OnInit, OnChanges {
  @Input()
  options: OptionItem[] = [];

  @Input()
  sliderCount = 2;

  @Input()
  allowUnselect = true;

  @Input()
  originCode = 1;

  sliderPositions = [0, null];

  slider1Name = '';

  slider2Name = '';

  @Output() clickSwipe = new EventEmitter<{ sliderId: string; sliderPositions: (number | null)[] }>();

  currentOptionsCount = 0;

  startPosition: number | null = 0;

  constructor(protected sliderGroupService: SliderGroupService) {}

  ngOnInit(): void {
    this.setInitialSliderPositions();
    this.setSlider1Name();
    this.currentOptionsCount = this.options.length;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && changes.options.currentValue.length !== this.currentOptionsCount) {
      this.currentOptionsCount = changes.options.currentValue.length;
      // this.sliderPositions = [0, null];
      this.setInitialSliderPositions();
      // this.setSliderPositions();
      this.setSlider1Name();
      this.setSlider2Name();
    }
    if (!this.options || this.options.length === 0) {
      this.sliderPositions = [null, null];
      this.slider1Name = '';
      this.slider2Name = '';
    }
  }

  handlePanStart(sliderNumber: number): void {
    this.startPosition = this.sliderPositions[sliderNumber - 1];
  }

  handleSliderClick(sliderNumber: number): void {
    if (this.allowUnselect) {
      if (this.isSliderVisible(sliderNumber)) {
        const currentPosition = sliderNumber - 1;

        this.sliderPositions[currentPosition] = null;
        if (this.isSliderVisible(2)) {
          // eslint-disable-next-line prefer-destructuring
          this.sliderPositions[0] = this.sliderPositions[1];
          this.sliderPositions[1] = null;
          this.setSlider1Name();
        }
        this.emitClickSwipe(currentPosition);
      }
    }
  }

  handleOptionClick(positionIndex: number): void {
    const firstHiddenSliderIndex = this.sliderPositions.findIndex((pos) => pos === null);

    if (this.sliderCount === 1) {
      this.sliderPositions[0] = positionIndex;
      this.emitClickSwipe(positionIndex);
      this.setSlider1Name();
    } else if (firstHiddenSliderIndex !== -1) {
      this.sliderPositions[firstHiddenSliderIndex] = positionIndex;
      this.emitClickSwipe(positionIndex);
      this.setSlider1Name();
      this.setSlider2Name();
    }
  }

  isSliderVisible(sliderNumber: number): boolean {
    return this.sliderPositions[sliderNumber - 1] !== null;
  }

  handlePan(sliderNumber: number, event: HammerInput): void {
    const { deltaX } = event;
    const scale = 0.01;

    let newPosition = Math.round((this.startPosition ?? 0) + deltaX * scale);

    newPosition = Math.max(0, Math.min(newPosition, this.options.length - 1));

    if (
      (sliderNumber === 1 && newPosition === this.sliderPositions[1]) ||
      (sliderNumber === 2 && newPosition === this.sliderPositions[0])
    ) {
      return;
    }

    this.sliderPositions[sliderNumber - 1] = newPosition;
    this.setSlider1Name();
    this.setSlider2Name();
  }

  handlePanEnd(sliderNumber: number): void {
    const newPosition = this.sliderPositions[sliderNumber - 1];

    if (newPosition !== null) {
      this.emitClickSwipe(newPosition);
    }
  }

  getSliderStyles(sliderNumber: number): { left: string; width: string } | Record<string, unknown> {
    const currentPosition = this.sliderPositions[sliderNumber - 1];

    if (currentPosition !== null) {
      const width = 100 / this.options.length;
      const left = (currentPosition / this.options.length) * 100;

      return {
        left: `${left}%`,
        width: `${width}%`,
      };
    }

    return {};
  }

  setSlider1Name(): void {
    const slider1Position = this.sliderPositions[0];

    if (slider1Position !== null && this.options.length > 0) {
      this.slider1Name = this.sliderGroupService.formatName(this.options[slider1Position].alias ?? '');
    }
  }

  setSlider2Name(): void {
    const slider2Position = this.sliderPositions[1];

    if (slider2Position !== null) {
      this.slider2Name = this.sliderGroupService.formatName(this.options[slider2Position].alias ?? '');
    }
  }

  emitClickSwipe(positionIndex: number): void {
    this.setSliderPositions(positionIndex);
  }

  private setSliderPositions(positionIndex: number): void {
    this.sliderGroupService.setPositionIndex(
      this.sliderPositions[0] ?? undefined,
      this.options.length,
      this.originCode,
    );
    this.clickSwipe.emit({ sliderId: this.options[positionIndex].id ?? '', sliderPositions: this.sliderPositions });
  }

  private setInitialSliderPositions(): void {
    const statePosition = this.sliderGroupService.getPositionIndex();

    if (
      statePosition[0] !== undefined &&
      statePosition[1] &&
      statePosition[1] === this.options.length &&
      statePosition[2] !== undefined &&
      statePosition[2] === this.originCode
    ) {
      this.sliderPositions = [statePosition[0], null];
    } else {
      this.sliderPositions = [this.options.length - 1, null];
    }
    this.setSliderPositions(this.sliderPositions[0] ?? this.options.length - 1);
  }
}
