import { Component, Input } from '@angular/core';
import { ImpactScore } from '../models/scoring.model';

@Component({
  selector: 'app-impact',
  templateUrl: './impact.component.html',
  styleUrls: ['./impact.component.scss'],
})
export class ImpactComponent {
  @Input()
  score!: ImpactScore;
}
