import { Component, HostListener, Input, OnInit } from '@angular/core';
import { SideNavModel } from './model/sidenav.model';
import { CompanyService, ProfileService } from '../../dashboard';
import { LogoutAction } from '../../auth';
import { GoToProfileAction } from '../../dashboard/profile/xs/actions/profile/go-to-profile.action';
import { Router } from '@angular/router';
import { ColourSchemeModel } from './model/colour-scheme.model';
import { SidenavService } from './sidenav.service';
import { AuthService } from '../../auth/auth.service';
import { GetCompanyAction } from '../../dashboard/profile/xs/actions/company/get-company.action';
import { GetEnterpriseAction } from '../../dashboard/profile/xs/actions/company/get-enterprise.action';
import { SsoService } from '../../auth/sso/sso.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @Input()
  percentage: number | undefined;

  @Input()
  top: SideNavModel[] = [
    {
      matImg: 'home',
      title: 'home',
      route: '/dashboard/sme/home',
    },
  ];

  @Input()
  measure: SideNavModel[] = [
    {
      matImg: 'assignment',
      title: 'assessments',
      route: '/dashboard/sme/assessments',
    },
  ];

  @Input()
  improve: SideNavModel[] = [
    {
      matImg: 'trending_up',
      title: 'improvement_centre',
      route: '/dashboard/sme/improvements',
    },
  ];

  @Input()
  report: SideNavModel[] = [
    {
      matImg: 'insert_chart',
      title: 'results',
      route: '/dashboard/sme/scoreboard',
    },
  ];

  @Input()
  tools: SideNavModel[] = [
    {
      matImg: 'calculate',
      title: 'co2calculator',
      route: '/dashboard/sme/co2calculator',
    },
    {
      matImg: 'nest_eco_leaf',
      title: 'co2offsetting',
      route: '/dashboard/sme/co2offsetting',
    },
  ];

  @Input()
  enterprisePortfolio: SideNavModel[] = [];

  @Input()
  menu: string[] = ['profile', 'invite', 'logout'];

  profile!: {
    name: string;
    draft: boolean;
    company?: string;
    picture?: string;
  };

  @Input()
  showBackButton = false;

  @Input()
  backButtonTarget = {
    route: '',
    params: {},
  };

  @Input()
  showProgressBar = false;

  expanded = false;

  userMenuHover = false;

  type = 'sme';

  open = false;

  isMobile!: boolean;

  sidenavTitleStyle = 'navmenuTitle';

  colours: ColourSchemeModel | undefined;

  enterpriseLogoUrl: string | undefined;

  eeveryLogoUrl = '../../../assets/imgs/sidenavIMG/Eevery-Logo-Dark-BG-1000x224px.png';

  eeveryLogoUrlDark = '../../../assets/imgs/sidenavIMG/Eevery-Original-Logo-1000x224px.png';

  logoColours = {
    primary: '#5C329A',
    secondary: '#452674',
    background: '#452674',
    secondaryTranslucent: true,
  };

  isMHA = false;

  constructor(
    public readonly profileService: ProfileService,
    private readonly companyService: CompanyService,
    private readonly sidenavService: SidenavService,
    private router: Router,
    public readonly authService: AuthService,
    private readonly ssoService: SsoService,
    private location: Location,
  ) {}

  async ngOnInit(): Promise<void> {
    this.checkForCustomStyle();
    this.updateSideNavStyle();

    if (this.authService.isEnterpriseUserLoggedInAsSme()) {
      await new Promise<void>((resolve) => {
        this.companyService.dispatchAction(new GetCompanyAction());
        resolve();
      });
    } else if (this.isEnterprise()) {
      await new Promise<void>((resolve) => {
        this.companyService.dispatchAction(new GetEnterpriseAction());
        resolve();
      });
    } else if (this.isSme()) {
      await new Promise<void>((resolve) => {
        this.companyService.dispatchAction(new GetCompanyAction());
        resolve();
      });
    }

    if (!this.percentage) {
      this.percentage = 20;
    }

    const user = this.profileService.getProfile();
    const company = this.companyService.getCompany();

    let username = '';
    const companyName = company.name;

    if (this.authService.isEnterpriseSSO()) {
      username = `${this.ssoService.getUserData().firstName} ${this.ssoService.getUserData().lastName}`;
    } else {
      username = `${user.firstName} ${user.lastName}`;
    }
    const img = this.companyService.getCompany()?.logoSrc;

    this.type = `${company.type?.toLowerCase()}`;

    if (this.isEnterprise() && !this.isViewer()) {
      this.eeveryLogoUrl = '../../../assets/imgs/sidenavIMG/Eevery-Enterprise-Logo-Light-1000x240px.png';
      this.eeveryLogoUrlDark = '../../../assets/imgs/sidenavIMG/Eevery-Enterprise-Logo-Dark-1000x240px.png';
    }

    this.profile = {
      name: username,
      company: companyName,
      picture: img,
      draft: company.draft as boolean,
    };

    if (window.innerWidth > 959) {
      this.open = true;
    }

    if (this.authService.isEnterpriseUserLoggedInAsSme()) {
      this.setEnterpriseButtons();
    } else if (this.authService.isSmeViewer()) {
      this.setViewButtons();
    }

    this.isMobile = window.screen.availWidth < 959;
  }

  private setEnterpriseButtons(): void {
    this.menu = ['profile'];
  }

  private setViewButtons(): void {
    this.menu = ['profile', 'logout'];
  }

  openMenu(): void {
    this.open = true;
  }

  /**
   * this listener is here to make the sidenav look okay even if we grab the browser window
   * and resize it - this however was leading to issues on mobile, since scrolling sometimes
   * resizes the window there (toolbar showing up etc);
   * that's why I added the following fix: if the screen is altogether smaller than the minimum
   * size to see our desktop view, the check is skipped
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onResize(event: any): void {
    if (event.target.screen.availWidth > 959) {
      this.open = event.target.innerWidth > 959;
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }

  async onImgError(): Promise<void> {
    this.profile.picture = await this.companyService.onLogoError();
  }

  async onCobrandingLogoError(): Promise<void> {
    this.enterpriseLogoUrl = await this.sidenavService.onCobrandingLogoError();
  }

  goBack(): void {
    this.location.back();
  }

  logout(): void {
    if (this.authService.isEnterpriseSSO()) {
      this.ssoService.logout();
    } else {
      this.profileService.dispatchAction(new LogoutAction());
    }
  }

  navigateProfile(): void {
    this.profileService.dispatchAction(new GoToProfileAction({ type: this.type }));
  }

  public isActiveCategory(routes: SideNavModel[]): boolean {
    for (const route of routes) {
      if (route.route !== undefined && this.router.isActive(route.route, false)) {
        return true;
      }
    }

    return false;
  }

  private updateSideNavStyle(): void {
    if (this.colours) {
      return;
    }
    if (
      this.isActiveCategory(this.measure) ||
      this.router.isActive('/dashboard/sme/questionnaire', false) ||
      this.router.isActive('/dashboard/sme/impact-scan-v2', false) ||
      this.router.isActive('/dashboard/sme/impact-scan', false)
    ) {
      this.sidenavTitleStyle = 'navmenuTitle measure';
      this.logoColours = {
        primary: '#00BAFF',
        secondary: '#4440E2',
        background: '#452674',
        secondaryTranslucent: true,
      };
    } else if (this.isActiveCategory(this.improve)) {
      this.sidenavTitleStyle = 'navmenuTitle improve';
      this.logoColours = {
        primary: '#FF45E6',
        secondary: '#F73054',
        background: '#452674',
        secondaryTranslucent: false,
      };
    } else if (this.isActiveCategory(this.report)) {
      this.sidenavTitleStyle = 'navmenuTitle report';
      this.logoColours = {
        primary: '#FFCA38',
        secondary: '#F73054',
        background: '#452674',
        secondaryTranslucent: false,
      };
    } else if (this.isActiveCategory(this.enterprisePortfolio)) {
      this.logoColours = {
        primary: '#F73054',
        secondary: '#800017',
        background: '#452674',
        secondaryTranslucent: true,
      };
    } else if (this.isActiveCategory(this.tools)) {
      this.sidenavTitleStyle = 'navmenuTitle tools';
      this.logoColours = {
        primary: '#5C329A',
        secondary: '#452674',
        background: '#452674',
        secondaryTranslucent: true,
      };
    } else {
      this.sidenavTitleStyle = 'navmenuTitle home';
      this.logoColours = {
        primary: '#5C329A',
        secondary: '#452674',
        background: '#452674',
        secondaryTranslucent: true,
      };
    }
  }

  checkForCustomStyle(): void {
    const coloursFromState = this.sidenavService.getColour();

    if (coloursFromState) {
      this.colours = { ...coloursFromState };
      this.logoColours = { ...coloursFromState };
      document.documentElement.style.setProperty('--dynamic-tile-highlight', this.colours.primary);
      document.documentElement.style.setProperty('--dynamic-tile-icon-text', this.colours.text);
      document.documentElement.style.setProperty('--dynamic-tile-icon-highlight', this.colours.text);
      document.documentElement.style.setProperty('--dynamic-tile-border', this.colours.accent);
      document.documentElement.style.setProperty('--dynamic-menu-border', this.colours.accent);
      document.documentElement.style.setProperty('--dynamic-background', this.colours.background);
      document.documentElement.style.setProperty('--dynamic-bottom-text', this.colours.bottomText);
      this.sidenavTitleStyle = 'navmenuTitle custom';

      this.enterpriseLogoUrl = this.sidenavService.getLogo();
      this.isMHA = this.authService.isEnterpriseSSO();
    } else {
      document.documentElement.style.setProperty('--dynamic-tile-highlight', '#5C329A');
      document.documentElement.style.setProperty('--dynamic-tile-icon-text', 'white');
      document.documentElement.style.setProperty('--dynamic-tile-icon-highlight', 'white');
      document.documentElement.style.setProperty('--dynamic-tile-border', 'white');
      document.documentElement.style.setProperty('--dynamic-menu-border', '#4440E2');
      document.documentElement.style.setProperty('--dynamic-background', '#452674');
      document.documentElement.style.setProperty('--dynamic-bottom-text', '#FFFFFF');
    }
  }

  inviteUsers(): void {
    this.profileService.navigateToPage(`dashboard/${this.type}/profile`);
  }

  navigateToInvite(): void {
    this.profileService.navigateToPage(`dashboard/${this.type}/invite`);
  }

  isViewer(): boolean {
    return this.authService.isEnterpriseUserLoggedInAsSme();
  }

  isEnterprise(): boolean {
    return this.authService.isEnterprise();
  }

  isSme(): boolean {
    return this.authService.isSme();
  }

  getEeveryLogoUrl(): string {
    if (this.colours?.eeveryLogoDark) {
      return this.eeveryLogoUrlDark;
    }

    return this.eeveryLogoUrl;
  }

  mobileClose(): void {
    if (this.isMobile) {
      this.open = false;
    }
  }
}
