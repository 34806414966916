import { Action } from '../../shared/xs/actions';

export interface GetImproventsActionPayload {
  id: string;
}

export class GetImprovementsAction implements Action {
  static readonly type = '[Improvements] get';

  constructor(public payload: GetImproventsActionPayload) {}
}
