import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-remove-company-popup',
  templateUrl: './remove-company-popup.component.html',
  styleUrls: ['./remove-company-popup.component.scss'],
})
export class RemoveCompanyPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<RemoveCompanyPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private ngZone: NgZone,
  ) {}

  onClose(): void {
    this.ngZone.run(() => {
      this.dialogRef.close(false);
    });
  }

  onYesClick(): void {
    this.ngZone.run(() => {
      this.dialogRef.close(true);
    });
  }
}
