import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DropdownCountryComponent,
  DropdownCountryWithInputComponent,
  DropdownMultipleComponent,
  DropdownQuestionComponent,
  EmptyQuestionComponent,
  FileUploadComponent,
  MultipleChoiceQuestionComponent,
  OpenQuestionTextareaComponent,
  OpenQuestionTextNumberComponent,
  QuestionComponent,
  SelectNumberQuestionComponent,
  SliderNumberComponent,
  RelationshipListComponent,
} from './question';
import { QuestionDirective } from './question/question.directive';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared.module';
import { MaterialDesignModule } from '../design';
import { TranslateModule } from '@ngx-translate/core';
import { MatSliderModule } from '@angular/material/slider';

@NgModule({
  declarations: [
    QuestionComponent,
    OpenQuestionTextNumberComponent,
    MultipleChoiceQuestionComponent,
    SelectNumberQuestionComponent,
    SliderNumberComponent,
    RelationshipListComponent,
    DropdownQuestionComponent,
    QuestionDirective,
    FileUploadComponent,
    EmptyQuestionComponent,
    OpenQuestionTextareaComponent,
    DropdownCountryComponent,
    DropdownCountryWithInputComponent,
    DropdownMultipleComponent,
  ],
  imports: [MaterialDesignModule, CommonModule, SharedModule, FormsModule, TranslateModule, MatSliderModule],
  exports: [
    QuestionComponent,
    OpenQuestionTextNumberComponent,
    MultipleChoiceQuestionComponent,
    SelectNumberQuestionComponent,
    SliderNumberComponent,
    RelationshipListComponent,
    DropdownQuestionComponent,
    QuestionDirective,
    FileUploadComponent,
    EmptyQuestionComponent,
    OpenQuestionTextareaComponent,
    DropdownCountryComponent,
    DropdownCountryWithInputComponent,
    DropdownMultipleComponent,
  ],
})
export class QuestionsModule {}
