import { Component, OnInit } from '@angular/core';
import { Co2CalculationResultModel } from '../model/co2-calculation-result.model';
import { Co2CalculatorService } from '../co2-calculator.service';
import { ActivatedRoute, Params } from '@angular/router';
import { StartCo2CalculationAction } from '../xs/actions/start-co2-calculation.action';
import { Store } from '@ngxs/store';
import { GetCo2CalculationsListOnEmptyAction } from '../xs/actions/get-co2-calculations-list-on-empty.action';
import { GetCo2ResultsAction } from '../xs/actions/get-co2-results.action';
import html2canvas from 'html2canvas';
import JSPDF from 'jspdf';
import { MatDialog } from '@angular/material/dialog';
import { Co2CalculatorPopupComponent } from '../popups/co2-calculator-popup/co2-calculator-popup.component';
import { CheckCo2Action } from '../xs/actions/check-co2.action';
import { EeveryTranslateService } from '../../shared/eevery.translate.service';
import { ProfileService } from '../../dashboard';

@Component({
  selector: 'app-co2-calculator-component',
  templateUrl: './co2-calculator.component.html',
  styleUrls: ['./co2-calculator.component.scss'],
})
export class Co2CalculatorComponent implements OnInit {
  tabSelected = 0;

  showStartButtonInPlaceHolder = false;

  showStartButtonInCorner = false;

  co2Calculation?: Co2CalculationResultModel;

  loading = false;

  dropdownExpanded = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private co2CalculatorService: Co2CalculatorService,
    private store: Store,
    private dialog: MatDialog,
    private translationService: EeveryTranslateService,
    private profileService: ProfileService,
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.tab && params.tab >= 0 && params.tab <= 1) {
        this.updateTabSelected(+params.tab);
      } else {
        this.updateTabSelected(0);
      }
    });

    this.getCo2Results();

    if (this.co2CalculatorService.isFirstChecked() && !this.profileService.getProfile()?.suppressSmePopUp) {
      this.dialog.open(Co2CalculatorPopupComponent, { width: '60vw', autoFocus: false });

      this.co2CalculatorService.dispatchAction(new CheckCo2Action({ hasBeenChecked: true }));
    }
  }

  getCo2Results(): void {
    this.loading = true;
    this.store.dispatch(new GetCo2ResultsAction()).subscribe(
      () => {
        this.co2Calculation = this.co2CalculatorService.getResults();
        this.loading = false;
      },
      () => {
        this.loading = false;
      },
    );
  }

  startCalculation(): void {
    this.store.dispatch(new StartCo2CalculationAction());
  }

  updateTabSelected(index: number): void {
    this.tabSelected = index;
    if (index === 0) {
      this.getCo2Results();
      this.updateShowStartButtons();
    }
  }

  updateShowStartButtons(): void {
    this.showStartButtonInPlaceHolder = false;
    this.showStartButtonInCorner = false;

    this.store.dispatch(new GetCo2CalculationsListOnEmptyAction()).subscribe(() => {
      this.showStartButtonInPlaceHolder = !(this.co2CalculatorService.getCo2Calculations()?.length > 0);

      if (this.tabSelected === 0) {
        this.showStartButtonInCorner = !!this.co2Calculation;
      } else if (this.tabSelected === 1) {
        this.showStartButtonInCorner = !this.showStartButtonInPlaceHolder;
      }
    });
  }

  downloadPdf(): void {
    this.co2CalculatorService.startLoading();

    this.dropdownExpanded = true;

    setTimeout(() => this.save(), 1500);
  }

  private save(): void {
    const data = document.getElementsByClassName('pdfContent')[0];

    html2canvas(data as HTMLElement, { scale: 2 }).then((canvas) => {
      const imgWidth = 208;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new JSPDF('p', 'mm', 'a4');

      pdf.setFillColor('white');
      let position = 20;

      const pageHeight = pdf.internal.pageSize.height;
      let heightLeft = imgHeight;

      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(this.getPdfName());
    });

    this.co2CalculatorService.stopLoading();
  }

  private getPdfName(): string {
    const language = this.translationService.getLanguage();

    if (this.co2Calculation?.reportingPeriodStart) {
      const prefix = language === 'nl_NL' ? 'CO2 Berekening - ' : 'CO2 Calculation - ';
      const reportingPeriodStart = new Date(this.co2Calculation.reportingPeriodStart);
      const dateFormatOptions: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const reportingPeriodStartString = reportingPeriodStart.toLocaleDateString('nl-NL', dateFormatOptions);

      return prefix + reportingPeriodStartString;
    }

    return language === 'nl_NL' ? 'CO2 Berekening' : 'CO2 Calculation';
  }
}
