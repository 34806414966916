import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EsgPopupAssessment, EsgPopupModel, EsgPopupProgressItem } from './esg-popup.model';
import { SliderGroupService } from 'src/app/dashboard/scoreboard/shared/slider-toggle/slider-group.service';
import { Series } from '../chart/esg-over-time/esg-over-time.component';
import { SliderGroupComponent } from 'src/app/dashboard/scoreboard/shared/slider-toggle/slider-group.component';
import { GraphDataModel } from '../../graph-data.model';

@Component({
  selector: 'app-esg-popup',
  templateUrl: './esg-popup.component.html',
  styleUrls: ['./esg-popup.component.scss'],
})
export class EsgPopupComponent implements OnInit {
  @ViewChild(SliderGroupComponent) sliderGroupComponent!: SliderGroupComponent;

  category!: string;

  title!: string;

  subtitle!: string;

  message!: string;

  assessments!: EsgPopupAssessment[];

  selectedAssessments!: (number | null)[];

  year1!: string;

  year2!: string;

  popupType!: string;

  overtimeSeries?: Series[];

  overtimeFormattedSeries!: number[];

  overtimeBarNames!: string[];

  categoryIndex!: number | null;

  scoringSets: GraphDataModel[][] = [];

  maxHeight!: string;

  firstAssessment!: number;

  secondAssessment!: number;

  swapSliders = false;

  hasAtLeastOneItem = false;

  constructor(
    public dialogRef: MatDialogRef<EsgPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EsgPopupModel,
    private sliderGroupService: SliderGroupService,
  ) {}

  ngOnInit(): void {
    this.popupType = this.data.type;
    this.category = this.data.category;

    if (this.popupType === 'doughnut') {
      this.initializeDataFromDoughnutChart();
    } else if (this.popupType === 'overtime') {
      this.initializeDataFromOvertimeChart();
    } else if (this.popupType === 'kpi') {
      this.initializeDataFromKpiPanel();
    }
  }

  private initializeDataFromKpiPanel(): void {
    this.title = `scoreboard.esg.charts_popup_kpi.${this.data.category.toLowerCase()}.title`;
    this.maxHeight = '100%';
    this.message = `scoreboard.esg.charts_popup_kpi.${this.data.category.toLowerCase()}.description`;
  }

  private initializeDataFromOvertimeChart(): void {
    this.title = `scoreboard.esg.charts_popup_overtime.${this.data.category.toLowerCase()}.title`;
    this.subtitle = `scoreboard.esg.charts_popup_overtime.${this.data.category.toLowerCase()}.subtitle`;
    this.message = `scoreboard.esg.charts_popup_overtime.${this.data.category.toLowerCase()}.description`;
    this.overtimeSeries = this.data.series?.reverse();
    this.categoryIndex = this.data.categoryIndex ?? null;
    this.scoringSets = this.data.scoringSets ?? [];
    this.formatSeries();
    this.formatLabels();
  }

  private initializeDataFromDoughnutChart(): void {
    this.title = `scoreboard.esg.charts_popup.${this.data.category.toLowerCase()}.title`;
    this.subtitle = `scoreboard.esg.charts_popup.${this.data.category.toLowerCase()}.subtitle`;
    this.message = `scoreboard.esg.charts_popup.${this.data.category.toLowerCase()}.description`;
    this.assessments = this.data.assessments?.reverse() ?? [];
    this.selectedAssessments = this.data.selectedAssessments ?? [];
    this.setFirstAssessment();
    // assessments[selectedAssessments[firstAssessment]].items
    if (this.selectedAssessments[0] !== null && this.selectedAssessments[1] !== null) {
      [this.assessments[this.selectedAssessments[0]].items, this.assessments[this.selectedAssessments[1]].items] =
        this.interlaceAssessments();
    }
    // @ts-ignore
    if (!this.assessments[this.selectedAssessments[this.firstAssessment]].items?.length) {
      this.maxHeight = '100%';
    }
    this.setDoughnutSwipeYears();
  }

  interlaceAssessments(): EsgPopupProgressItem[][] {
    // @ts-ignore
    const firstItems = this.assessments[this.selectedAssessments[0]].items;
    // @ts-ignore
    const secondItems = this.assessments[this.selectedAssessments[1]].items;

    if (!(firstItems && firstItems.length) || !(secondItems && secondItems.length)) {
      return [firstItems, secondItems];
    }
    const titles = new Set([...firstItems, ...secondItems].map((item) => item.title));
    const titleArray = Array.from(titles).sort();

    return [this.selectedAssessments[0], this.selectedAssessments[1]].map((index) =>
      titleArray.map((title) => {
        // @ts-ignore
        const item = this.assessments[index].items.find((i) => i.title === title);

        return item || this.createPlaceholder(title);
      }),
    );
  }

  createPlaceholder(title: string): EsgPopupProgressItem {
    return {
      title,
      value: 0,
      maxValue: 0,
      color: '',
    };
  }

  onClose(): void {
    this.dialogRef.close();
  }

  setDoughnutSwipeYears(): void {
    if (this.selectedAssessments) {
      if (this.selectedAssessments[0] !== null && this.assessments) {
        this.year1 = this.sliderGroupService.formatName(this.assessments[this.selectedAssessments[0]].name);
      }
      if (this.selectedAssessments[1] !== null && this.assessments) {
        this.year2 = this.sliderGroupService.formatName(this.assessments[this.selectedAssessments[1]].name);
      }
      this.checkSwapSliders();
    }
  }

  formatSeries(): void {
    const formattedSeries: number[] = [];

    if (this.overtimeSeries && this.overtimeSeries.length) {
      this.overtimeSeries.forEach((series) => {
        if (this.categoryIndex !== null) {
          formattedSeries.push(series.data[this.categoryIndex]);
        }
      });
    }

    this.overtimeFormattedSeries = formattedSeries;
  }

  formatLabels(): void {
    this.overtimeBarNames = this.getOvertimeBarNames();
  }

  getOvertimeBarNames(): string[] {
    const names: string[] = [];

    if (this.scoringSets.length) {
      this.scoringSets.forEach((scoringSet) => {
        names.push(this.sliderGroupService.formatNameFullYear(scoringSet[0].alias ?? ''));
      });
    }

    return names;
  }

  getOvertimePopupKpiArrowIcon(i: number): string {
    if (i > 0) {
      if (this.overtimeFormattedSeries[i] > this.overtimeFormattedSeries[i - 1]) {
        return 'arrow_upward';
      }
      if (this.overtimeFormattedSeries[i] < this.overtimeFormattedSeries[i - 1]) {
        return 'arrow_downward';
      }

      return 'remove';
    }

    return 'remove';
  }

  getOvertimePopupKpiValues(i: number): string {
    if (i > 0) {
      if (this.overtimeFormattedSeries[i] > this.overtimeFormattedSeries[i - 1]) {
        return `${this.overtimeFormattedSeries[i] - this.overtimeFormattedSeries[i - 1]}`;
      }
      if (this.overtimeFormattedSeries[i] < this.overtimeFormattedSeries[i - 1]) {
        return `${this.overtimeFormattedSeries[i - 1] - this.overtimeFormattedSeries[i]}`;
      }

      return '0';
    }

    return '0';
  }

  setFirstAssessment(): boolean {
    if (this.selectedAssessments[0] !== null && this.assessments[this.selectedAssessments[0]].items.length) {
      this.firstAssessment = 0;
      this.secondAssessment = 1;
      this.hasAtLeastOneItem = true;
    } else if (this.selectedAssessments[1] !== null && this.assessments[this.selectedAssessments[1]].items.length) {
      this.firstAssessment = 1;
      this.secondAssessment = 0;
      this.hasAtLeastOneItem = true;
    } else {
      this.firstAssessment = 0;
      this.secondAssessment = 1;
    }

    return false;
  }

  private checkSwapSliders(): void {
    if (this.selectedAssessments[0] !== null && this.selectedAssessments[1] !== null) {
      if (this.selectedAssessments[0] > this.selectedAssessments[1]) {
        const temp = this.year1;

        this.year1 = this.year2;
        this.year2 = temp;
        this.swapSliders = true;

        const temp2 = this.firstAssessment;

        this.firstAssessment = this.secondAssessment;
        this.secondAssessment = temp2;
      }
    }
  }
}
