import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Topic } from '../models/topic.model';

@Component({
  selector: 'app-sdg',
  templateUrl: './sdg.component.html',
  styleUrls: ['./sdg.component.scss'],
})
export class SDGComponent {
  @Input()
  scores: Topic[] = [];

  @Input()
  companyName = '';

  @Output()
  placeholderButtonClick = new EventEmitter<void>();

  toggleDropdown(): void {
    this.placeholderButtonClick.emit();
  }
}
