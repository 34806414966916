import { Injectable, NgZone } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UpdateProfileAction, UpdateProfileActionResponse } from './actions/profile/update-profile.action';
import { ProfileService } from '../profile.service';
import { LogoutSuccessAction } from '../../../auth/xs/actions/logout-success.action';
import { GoToProfileAction } from './actions/profile/go-to-profile.action';
import { EeveryTranslateService } from '../../../shared/eevery.translate.service';
import { RefreshImprovementsAction } from '../../../improvement-centre/xs/refresh-improvements.action';
import { LoginAsCompanySuccessAction } from '../../../enterprise/xs/actions/login-as-company-success.action';
import { LogoutFromSmeSuccessAction } from '../../../enterprise/xs/actions/logout-from-sme-success.actions';
import { SetProfileAction } from '../../../auth/xs/actions/set-profile.action';
import { SsoService } from '../../../auth/sso/sso.service';

export interface ProfileStateModel {
  email?: string;
  firstName?: string;
  lastName?: string;
  language?: string;
}

export interface ProfileModel {
  email?: string;
  firstName?: string;
  lastName?: string;
  companyId?: string;
  language?: string;
  suppressSmePopUp?: boolean;
}

@State<ProfileStateModel>({ name: 'profile' })
@Injectable()
export class ProfileState {
  @Selector()
  static getProfile(state: ProfileStateModel): ProfileModel {
    return { ...state } as ProfileModel;
  }

  @Selector()
  static getName(state: ProfileStateModel): string {
    return `${state.firstName} ${state.lastName}`;
  }

  @Selector()
  static getLanguage(state: ProfileStateModel): string | undefined {
    return state.language;
  }

  constructor(
    private readonly ssoService: SsoService,
    private readonly profileService: ProfileService,
    private readonly translateService: EeveryTranslateService,
    private readonly ngZone: NgZone,
  ) {}

  @Action(SetProfileAction)
  setProfile(ctx: StateContext<ProfileStateModel>, action: SetProfileAction): void {
    ctx.patchState(action.profilePayload);
    this.translateService.refreshLanguage();
  }

  @Action(LoginAsCompanySuccessAction)
  setProfileAfterLoginAsSme(ctx: StateContext<ProfileStateModel>, action: LoginAsCompanySuccessAction): void {
    ctx.setState(action.profilePayload);
  }

  @Action(LogoutFromSmeSuccessAction)
  setProfileAfterLogoutFromSme(ctx: StateContext<ProfileStateModel>, action: LogoutFromSmeSuccessAction): void {
    ctx.setState(action.profilePayload);
  }

  @Action(LogoutSuccessAction)
  clearProfile(ctx: StateContext<ProfileStateModel>): void {
    ctx.setState({});
    this.translateService.init();
  }

  @Action(GoToProfileAction)
  goToProfile(ctx: StateContext<ProfileStateModel>, action: GoToProfileAction): void {
    this.ngZone.run(() => this.profileService.navigateToPage(`/dashboard/${action.payload.type}/profile`));
  }

  @Action(UpdateProfileAction)
  async updateProfile(
    ctx: StateContext<ProfileStateModel>,
    action: UpdateProfileAction,
  ): Promise<UpdateProfileActionResponse> {
    const result = await this.profileService.patchProfile(action.payload);

    ctx.patchState({ ...result });

    if (action.payload.language) {
      this.translateService.refreshLanguage();
      this.profileService.dispatchAction(new RefreshImprovementsAction());
    }

    return result as UpdateProfileActionResponse;
  }
}
