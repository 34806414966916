import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Assessment, Topic } from '../models/topic.model';
import {
  CsrdCoexistenceModel,
  CsrdMateriality,
  CsrdScore,
  Translation,
  ManagedEsrsScore,
  RelevantDisclosure,
  RelevantStandard,
} from '../models/scoring.model';
import { MatDialog } from '@angular/material/dialog';
import { ScoreboardService } from '../scoreboard.service';
import { OptionItem } from '../shared/slider-toggle/slider-group.component';
import { CsrdAxisPopupModel, CsrdPopupModel } from './popup/csrd-popup.model';
import { CsrdFramePopupComponent } from './popup/frame/csrd-frame-popup.component';
import { CsrdAxisPopupComponent } from './popup/axis/csrd-axis-popup.component';
import { PopUpModel } from '../../../shared/pop-up/pop-up.model';
import { PopUpComponent } from '../../../shared/pop-up/pop-up.component';
import { EeveryTranslateService } from '../../../shared/eevery.translate.service';
import { ExportDropdownMenuItem } from '../../../shared/export/export-dropdown/export-dropdown-menu-item';
import { CsrdScoresExportService } from '../../../shared/export/csrd-scores-export.service';

@Component({
  selector: 'app-csrd',
  templateUrl: './csrd.component.html',
  styleUrls: ['./csrd.component.scss'],
  animations: [
    trigger('changeAnimation', [
      state('hide', style({ opacity: 0 })),
      state('show', style({ opacity: 1 })),
      transition('hide => show', [animate('0.25s ease-in')]),
      transition('show => hide', [animate('0.25s ease-in')]),
    ]),
    trigger('toggleSlider', [
      state('double', style({ left: '0%' })),
      state('impact', style({ left: '33.33%' })),
      state('financial', style({ left: '66.67%' })),
      transition('* => *', [animate('0.2s ease-in-out')]),
    ]),
  ],
})
export class CSRDComponent implements AfterViewInit, OnInit {
  @Input()
  scores!: Topic[];

  @Input()
  companyName!: string;

  @Output()
  placeholderButtonClick = new EventEmitter<void>();

  csrdScores: CsrdCoexistenceModel[] = [];

  sliderPositions: number[] = [];

  sliderItems!: OptionItem[];

  relevantStandards: RelevantStandard[][] = [];

  filteredRelevantStandards: RelevantStandard[][] = [];

  showGrid = true;

  showDisclosures = false;

  selectedMaterialitiesOption: SelectedMaterialitiesOption = SelectedMaterialitiesOption.DOUBLE;

  sliderAnimation = 'double';

  animation = false;

  stopAnimation = true;

  searchValue = '';

  constructor(
    private dialog: MatDialog,
    private scoreboardService: ScoreboardService,
    private translationService: EeveryTranslateService,
    private exportService: CsrdScoresExportService,
  ) {}

  ngOnInit(): void {
    this.scoreboardService.currentTopic.subscribe((scores) => {
      if (scores) {
        this.showDisclosures = false;
        this.scores = scores;
        this.initScores();
      }
    });
  }

  private initScores(): void {
    this.sliderItems = [];
    this.csrdScores = [];

    if (this.scores) {
      this.scores
        .filter((topic) => topic.selected || topic.indeterminate)
        .reverse()
        .forEach((score) => {
          score.assessments
            .filter((assessment) => assessment.selected)
            .reverse()
            .forEach((assessment) => {
              const csrdCoexistenceModel: CsrdCoexistenceModel = {
                oldAssessment: false,
                score: [],
              };

              if (assessment.scoring?.deprecatedLatest) {
                csrdCoexistenceModel.oldAssessment = true;
                csrdCoexistenceModel.materiality = this.getOldMateriality(assessment);
                this.relevantStandards.push([]);
              } else if (assessment.scoring?.scoring) {
                csrdCoexistenceModel.score = this.getCsrdScores(assessment);
                csrdCoexistenceModel.materiality = this.getMateriality(assessment);
                this.relevantStandards.push(this.getRelevantStandards(assessment));
              }

              this.csrdScores.push(csrdCoexistenceModel);
              this.sliderItems.push({ alias: assessment.name, id: assessment.id });
            });
        });

      this.addMissingCategoriesAndSort();
      this.sliderPositions = [this.sliderItems.length - 1, 0];
    }
  }

  ngAfterViewInit(): void {
    this.stopAnimation = false;
  }

  private getOldMateriality(assessment: Assessment): CsrdMateriality {
    if (assessment.scoring.deprecatedScoring.scores.MATERIALITY === null) {
      return {
        doubleMateriality: [],
        financialMateriality: [],
        impactMateriality: [],
        matrixRankedThemes: [],
      };
    }

    return {
      doubleMateriality: assessment.scoring.deprecatedScoring.scores.MATERIALITY.doubleMateriality,
      financialMateriality: assessment.scoring.deprecatedScoring.scores.MATERIALITY.financialMateriality,
      impactMateriality: assessment.scoring.deprecatedScoring.scores.MATERIALITY.impactMateriality,
      matrixRankedThemes: [],
    };
  }

  private getMateriality(assessment: Assessment): CsrdMateriality {
    if (assessment.scoring.scoring.materiality === null) {
      return {
        doubleMateriality: [],
        financialMateriality: [],
        impactMateriality: [],
        matrixRankedThemes: [],
      };
    }

    return {
      doubleMateriality: assessment.scoring.scoring.materiality.doubleMateriality,
      financialMateriality: assessment.scoring.scoring.materiality.financialMateriality,
      impactMateriality: assessment.scoring.scoring.materiality.impactMateriality,
      matrixRankedThemes: assessment.scoring.scoring.materiality.matrixRankedThemes,
    };
  }

  private getCsrdScores(assessment: Assessment): CsrdScore[] {
    const csrds = assessment.scoring.scoring.esrs ?? [];
    const csrdScores: CsrdScore[] = [];

    this.countCsrdScores(csrds, csrdScores);

    return csrdScores;
  }

  change(value: number): void {
    if (value === this.selectedMaterialitiesOption) {
      return;
    }

    this.animation = true;

    setTimeout(() => {
      this.selectedMaterialitiesOption = value;

      this.animation = false;
    }, 250);
  }

  toggleDropdown(): void {
    this.placeholderButtonClick.emit();
  }

  private countCsrdScores(csrds: ManagedEsrsScore[], csrdScores: CsrdScore[]): void {
    const counts: { [category: string]: { standards: Set<string>; disclosures: Set<string> } } = {};

    for (const csrd of csrds) {
      if (counts[csrd.category]) {
        counts[csrd.category].standards.add(csrd.standardCode);
        counts[csrd.category].disclosures.add(csrd.disclosureCode);
      } else {
        counts[csrd.category] = {
          standards: new Set([csrd.standardCode]),
          disclosures: new Set([csrd.disclosureCode]),
        };
      }
    }

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const category in counts) {
      const csrdScore: CsrdScore = {
        standardCounts: counts[category].standards.size,
        disclosureCount: counts[category].disclosures.size,
        category: this.titleCaseWord(category),
      };

      csrdScores.push(csrdScore);
    }
  }

  titleCaseWord(word: string): string {
    if (!word) {
      return word;
    }

    return word[0].toUpperCase() + word.substring(1).toLowerCase();
  }

  private getRelevantStandards(assessment: Assessment): RelevantStandard[] {
    const csrds = assessment.scoring.scoring.esrs ?? [];
    const relevantStandards: RelevantStandard[] = [];

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const csrd of csrds) {
      const relevantStandard: RelevantStandard = {
        category: csrd.category,
        code: csrd.standardCode,
        name: this.getTranslatedName(csrd.standardTranslations),
        // averageScore: ((csrd.score / csrd.maxScore) * 100).toFixed(0),
        relevantDisclosures: [this.getRelevantDisclosure(csrd)],
      };

      const index = relevantStandards.findIndex((standard) => standard.code === csrd.standardCode);

      if (index === -1) {
        relevantStandards.push(relevantStandard);
      } else {
        relevantStandards[index].relevantDisclosures.push(this.getRelevantDisclosure(csrd));
        // relevantStandards[index].averageScore = (
        //   (Number(relevantStandards[index].averageScore) + Number(relevantStandard.averageScore)) /
        //   2
        // ).toFixed(0);
      }
    }

    this.calculateAverageScore(relevantStandards);

    return relevantStandards;
  }

  private calculateAverageScore(relevantStandards: RelevantStandard[]): void {
    relevantStandards.forEach((standard) => {
      let total = 0;

      standard.relevantDisclosures.forEach((disclosure) => {
        total += Number(disclosure.percentage);
      });

      // eslint-disable-next-line no-param-reassign
      standard.averageScore = (total / standard.relevantDisclosures.length).toFixed(0);
    });
  }

  private getRelevantDisclosure(csrd: ManagedEsrsScore): RelevantDisclosure {
    let percentage = ((csrd.score / csrd.maxScore) * 100).toFixed(0);

    if (csrd.score === 0 && csrd.maxScore === 0) {
      percentage = '100';
    }

    return {
      code: csrd.disclosureCode,
      name: this.getTranslatedName(csrd.disclosureTranslations),
      percentage,
    };
  }

  // todo: remove duplication
  toggleResults(event: { sliderId: string; sliderPositions: (number | null)[] }): void {
    this.sliderPositions = event.sliderPositions.map((position) => {
      if (position === null) {
        return 0;
      }

      return position;
    });
  }

  // private getGriStandardNameFromCode(standardCode: string): string {
  //   for (const standard of this.unfilteredCategoriesTest) {
  //     if (standard.id === standardCode) {
  //       return standard.text;
  //     }
  //   }
  //
  //   return `Name unknown for standard: ${standardCode}`;
  // }
  //
  // private getGriDisclosureNameFromCode(disclosureCode: string): string {
  //   for (const standard of this.unfilteredCategoriesTest) {
  //     for (const disclosure of standard.standards) {
  //       if (disclosure.code === disclosureCode) {
  //         return disclosure.text;
  //       }
  //     }
  //   }
  //
  //   return `Name unknown for disclosure: ${disclosureCode}`;
  // }
  private getTranslatedName(translations: Translation[]): string {
    const language = this.translationService.getLanguage().replace('_', '-').replace('UK', 'GB');

    if (!translations) {
      return 'Unknown';
    }

    return (
      translations.find((translation) => translation.languageCode === language)?.title ||
      translations[0].title ||
      'Unknown'
    );
  }

  search(): void {
    const sliderIndex = this.sliderPositions[0];

    this.filteredRelevantStandards = [...this.relevantStandards];
    let tempStandards = [...this.filteredRelevantStandards[sliderIndex]];

    if (this.searchValue === '') {
      return;
    }

    tempStandards = this.filterRelevantStandards(sliderIndex);

    if (tempStandards.length > 0) {
      this.filteredRelevantStandards[sliderIndex] = tempStandards;
    } else {
      this.filteredRelevantStandards[sliderIndex] = this.filterRelevantDisclosures(sliderIndex);
    }

    this.filteredRelevantStandards[sliderIndex] = this.filteredRelevantStandards[sliderIndex].filter(
      (relevantStandard) => relevantStandard.relevantDisclosures.length > 0,
    );

    this.showDisclosures = true;
  }

  private filterRelevantStandards(sliderIndex: number): RelevantStandard[] {
    return this.filteredRelevantStandards[sliderIndex].filter(
      (relevantStandard) =>
        relevantStandard.name.toLowerCase().includes(this.searchValue.toLowerCase()) ||
        relevantStandard.code.includes(this.searchValue),
    );
  }

  private filterRelevantDisclosures(sliderIndex: number): RelevantStandard[] {
    return this.filteredRelevantStandards[sliderIndex].map((relevantStandard) => {
      const tempStandard = { ...relevantStandard };

      tempStandard.relevantDisclosures = tempStandard.relevantDisclosures.filter(
        (disclosure) =>
          disclosure.name.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          disclosure.code.includes(this.searchValue),
      );

      return tempStandard;
    });
  }

  openFramePopup(category: string): void {
    const position = this.sliderPositions[0];
    let relevantStandards: RelevantStandard[] = [];

    if (position !== null) {
      relevantStandards = [...this.relevantStandards[position]];
    }

    const data: CsrdPopupModel = {
      category,
      relevantStandards,
    };

    this.dialog.open(CsrdFramePopupComponent, { width: '60vw', autoFocus: false, data });
  }

  private addMissingCategoriesAndSort(): void {
    const allCategories = ['General', 'Environment', 'Social', 'Governance'];

    this.csrdScores.forEach((score) => {
      const currentCategories = score.score.map((s) => s.category);
      const missingCategories = allCategories.filter((category) => !currentCategories.includes(category));

      for (const category of missingCategories) {
        const csrdScore: CsrdScore = {
          // disclosures: [],
          standardCounts: 0,
          disclosureCount: 0,
          category,
        };

        score.score.push(csrdScore);
      }
      score.score.sort((a, b) => allCategories.indexOf(a.category) - allCategories.indexOf(b.category));
    });
  }

  openXaxisPopup(): void {
    const data: CsrdAxisPopupModel = {
      title: 'scoreboard.csrd.popup.axis_x.title',
      description: 'scoreboard.csrd.popup.axis_x.description',
    };

    this.dialog.open(CsrdAxisPopupComponent, { width: '60vw', autoFocus: false, data });
  }

  openYaxisPopup(): void {
    const data: CsrdAxisPopupModel = {
      title: 'scoreboard.csrd.popup.axis_y.title',
      description: 'scoreboard.csrd.popup.axis_y.description',
    };

    this.dialog.open(CsrdAxisPopupComponent, { width: '60vw', autoFocus: false, data });
  }

  csrdInfoPopup(): void {
    const data: PopUpModel = {
      title: 'scoreboard.csrd.info_popup.title',
      message: 'scoreboard.csrd.info_popup.description',
    };

    this.dialog.open(PopUpComponent, { width: '60vw', autoFocus: false, data });
  }

  getExportMenuItems(): ExportDropdownMenuItem[] {
    return [
      {
        title: this.translationService.instant('export.scores.csrd.csv.standards_general.dropdown_menu_title'),
        id: 'csv_short',
        exportFunction: (): Promise<void> =>
          this.exportService.exportGeneralCsrdStandardsToCSV(
            this.relevantStandards[this.sliderPositions[0]],
            this.sliderItems[this.sliderPositions[0]].alias,
          ),
        icon: 'table_rows',
      },
      {
        title: this.translationService.instant(
          'export.scores.csrd.csv.standards_and_disclosures_detailed.dropdown_menu_title',
        ),
        id: 'csv_detailed',
        exportFunction: (): Promise<void> =>
          this.exportService.exportDetailedCsrdStandardsToCSV(
            this.relevantStandards[this.sliderPositions[0]],
            this.sliderItems[this.sliderPositions[0]].alias,
          ),
        icon: 'table_chart',
      },
    ];
  }

  canExportStandards(): boolean {
    return (this.relevantStandards[this.sliderPositions[0]]?.length ?? 0) > 0;
  }
}

// eslint-disable-next-line no-shadow
export enum SelectedMaterialitiesOption {
  DOUBLE,
  IMPACT,
  FINANCIAL,
}
