import { QuestionModel } from './question.model';
import { QuestionEnum } from '../question/question.enum';
import { CommentModel } from '../../comments/comment.model';
// eslint-disable-next-line no-shadow
export enum AnswerStatus {
  DRAFT = 'DRAFT',
  READY_FOR_VALIDATION = 'READY_FOR_VALIDATION',
  CHANGES_REQUESTED = 'CHANGES_REQUESTED',
  VALIDATED = 'VALIDATED',
}

// eslint-disable-next-line no-shadow
export enum NotApplicableReason {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  LEGAL_PROHIBITONS = 'LEGAL_PROHIBITONS',
  CONFIDENTIALITY_CONSTRAINTS = 'CONFIDENTIALITY_CONSTRAINTS',
  INFORMATION_UNAVAILABLE = 'INFORMATION_UNAVAILABLE',
}

// eslint-disable-next-line no-shadow
export enum Duration {
  LONG_TERM = 'LONG_TERM',
  SHORT_TERM = 'SHORT_TERM',
}

export interface AnswerModel {
  questionId: string;
  answer: AnswerContent;
  countryCode?: string;
  status: AnswerStatus;
  comment: CommentModel;
}

export interface AnswerSavedResponse {
  answerId: number;
  triggeredQuestions: QuestionModel[];
  untriggeredQuestionIds: string[];
  sectionValid: boolean;
}

export interface Upload {
  id: number;
  createdAt: string;
  filename: string;
  userId: string;
  companyId: string;
  scoringId: string;
}

export interface Concentration {
  code?: string;
  percentage?: number;
}

export interface Relationship {
  name: string;
  countryCode: string;
  duration?: Duration;
  percentage?: number;
}

export interface AnswerContent {
  type?: QuestionEnum;
  optionId?: number;
  optionIds: number[];
  text?: string;
  value?: number;
  file?: Upload;
  files: Upload[];
  countryCodes: string[];
  countryList: Concentration[];
  concentrationList: Concentration[];
  relationships: Relationship[];
  applicable: boolean;
  naReason?: NotApplicableReason;
  naExplanation?: string;
}
