import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-co2-yes-no-question',
  templateUrl: './co2-yes-no-question.component.html',
  styleUrls: ['./co2-yes-no-question.component.scss'],
})
export class Co2YesNoQuestionComponent {
  @Input()
  questionId!: number;

  @Input()
  value!: boolean;

  @Output()
  valueChange = new EventEmitter<boolean>();

  @Input()
  highLightUnansweredQuestion!: boolean;

  @Input()
  viewOnly!: boolean;

  @Input()
  question!: string;

  @Input()
  description!: string;

  @Input()
  subtext!: string;

  @Input()
  field!: string;

  @Input()
  empiricText!: string;

  @Input()
  useEmpiric!: boolean;

  @Output()
  useEmpiricChange = new EventEmitter<boolean>();

  @Output()
  answerUpdated = new EventEmitter<void>();

  valueNotSet(): boolean {
    return this.value === undefined || this.value === null;
  }

  onOptionSelected(value: boolean): void {
    this.valueChange.emit(value);
    this.answerUpdated.emit();
  }

  shouldHighlightAsUnanswered(): boolean {
    return this.highLightUnansweredQuestion && !this.useEmpiric && (this.value === undefined || this.value === null);
  }
}
