import { Injectable } from '@angular/core';
import { AbstractService } from '../../../shared/services/abstract.service';
import { BehaviorSubject } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class FilterService extends AbstractService {
  private dropDownStatusSource = new BehaviorSubject<boolean>(false);

  // eslint-disable-next-line no-invalid-this
  currentDropdownStatus = this.dropDownStatusSource.asObservable();

  toggleDropdownStatus(): void {
    this.dropDownStatusSource.next(true);
  }

  closeStatus(): void {
    this.dropDownStatusSource.next(false);
  }
}
