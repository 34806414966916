import { AuthState, LoginActionPayload, LoginActionResponse, LogoutAction, SignupActionPayload } from './xs';

import { AbstractService } from '../shared/services/abstract.service';
import { ConfirmEmailActionPayload, ConfirmEmailActionResponse } from './xs/actions/confirm-email.action';
import { ForgotPasswordActionPayload } from './xs/actions/forgot-password.action';
import { ConfirmForgotPasswordActionPayload } from './xs/actions/confirm-forgot-password.action';
import { RefreshTokenActionResponse, RefreshTokenRequest } from './xs/actions/refresh-token.action';
import { ProfileState } from '../dashboard/profile/xs/profile.state';
import { CountryInitAction } from './xs/actions/country-init.action';
import { ResendConfirmEmailActionPayload } from './xs/actions/resend-confirm.action';
import { Injectable } from '@angular/core';
import { Role } from '../dashboard/profile/models/role';
import { CompanyState } from '../dashboard/profile/xs/company.state';

@Injectable({ providedIn: 'root' })
export class AuthService extends AbstractService {
  public isEmailAvailable(email: string): Promise<{ userExist: boolean }> {
    return this.http.get<{ userExist: boolean }>(`${this.apiBaseUrl}/auth/email-check/${email}`).toPromise();
  }

  public async signup(payload: SignupActionPayload): Promise<void> {
    await this.http.post(`${this.apiBaseUrl}/auth/signup`, payload).toPromise();
  }

  public async login(payload: LoginActionPayload): Promise<LoginActionResponse> {
    const result = await this.http.post(`${this.apiBaseUrl}/auth/login`, payload).toPromise();

    return result as LoginActionResponse;
  }

  public async logout(): Promise<void> {
    await this.http.post(`${this.apiBaseUrl}/auth/logout`, {}).toPromise();
  }

  public async confirmEmail(payload: ConfirmEmailActionPayload): Promise<ConfirmEmailActionResponse> {
    const response = await this.http.post(`${this.apiBaseUrl}/auth/signup/confirm`, payload).toPromise();

    return response as ConfirmEmailActionResponse;
  }

  async resendConfirmEmail(payload: ResendConfirmEmailActionPayload): Promise<void> {
    await this.http.post(`${this.apiBaseUrl}/auth/signup/resend`, payload).toPromise();
  }

  public async forgotPassword(payload: ForgotPasswordActionPayload): Promise<void> {
    await this.http.post(`${this.apiBaseUrl}/auth/password/forgot`, payload).toPromise();
  }

  public async confirmForgotPassword(payload: ConfirmForgotPasswordActionPayload): Promise<void> {
    await this.http.post(`${this.apiBaseUrl}/auth/password/confirm`, payload).toPromise();
  }

  public async refreshAccessToken(): Promise<RefreshTokenActionResponse> {
    const response = await this.http.post(`${this.apiBaseUrl}/auth/refresh`, this.getRefreshTokenRequest()).toPromise();

    return response as RefreshTokenActionResponse;
  }

  public isUserAuthenticated(): boolean {
    return this.store.selectSnapshot(AuthState.isAuthenticated);
  }

  public getAccessToken(): string | undefined {
    return this.store.selectSnapshot(AuthState.accessToken);
  }

  public getRefreshToken(): string | undefined {
    return this.store.selectSnapshot(AuthState.refreshToken);
  }

  public getRefreshTokenRequest(): RefreshTokenRequest {
    return {
      email: this.store.selectSnapshot(ProfileState.getProfile).email,
      refreshToken: this.store.selectSnapshot(AuthState.refreshToken),
    };
  }

  public getCountry(): string | undefined {
    return this.store.selectSnapshot(AuthState.country);
  }

  public getPaymentCountry(): string | undefined {
    return this.store.selectSnapshot(AuthState.paymentCountry);
  }

  public getSignupEmail(): string | undefined {
    return this.store.selectSnapshot(AuthState.signupEmail);
  }

  public getRoles(): Role[] | undefined {
    return this.store.selectSnapshot(AuthState.Roles);
  }

  public isEnterprise(): boolean {
    return !!this.getRoles()?.includes(Role.Enterprise);
  }

  public isSme(): boolean {
    return !!this.getRoles()?.includes(Role.SME) || !!this.getRoles()?.includes(Role.SMEViewer);
  }

  public isSmeViewer(): boolean {
    return !!this.getRoles()?.includes(Role.SMEViewer);
  }

  public isEnterpriseUserLoggedInAsSme(): boolean {
    const roles = this.getRoles();

    return !!(roles?.includes(Role.Enterprise) && roles?.includes(Role.SMEViewer));
  }

  public isAdmin(): boolean {
    return !!this.getRoles()?.includes(Role.Admin);
  }

  public isEnterpriseSSO(): boolean {
    return !!this.getRoles()?.includes(Role.EnterpriseSSO);
  }

  async toPayment(email: string): Promise<string> {
    const payload: { email: string; country: string } = { email, country: this.getCountry() || 'NL' };

    const data = (await this.http.post(`${this.apiBaseUrl}/payment/sub`, payload).toPromise()) as { url: string };

    return data.url;
  }

  async initLocation(): Promise<void> {
    await fetch('https://api.ipregistry.co/?key=12xxe55uc5uzootw')
      .then((response) => response.json())
      .then((payload) => {
        this.dispatchActionAndWait(new CountryInitAction({ country: payload.location.country.code }));
      })
      .catch(console.error); // eslint-disable-line no-console
  }

  handleCanActivateFor(requiredRoles: Role[], isOnboardingPath?: boolean): boolean {
    const userRoles = this.getRoles();

    if (userRoles === undefined || !this.isUserAuthenticated()) {
      this.dispatchAction(new LogoutAction());

      return false;
    }

    for (const requiredRole of requiredRoles) {
      if (userRoles.includes(requiredRole)) {
        if (requiredRole === Role.SME || requiredRole === Role.SMEViewer) {
          const company = this.store.selectSnapshot(CompanyState.getCompany);

          if (isOnboardingPath === company.draft) {
            return true;
          }
        } else {
          return true;
        }
      }
    }

    if (userRoles.includes(Role.SME) || userRoles.includes(Role.SMEViewer)) {
      const company = this.store.selectSnapshot(CompanyState.getCompany);

      if (company?.draft) {
        this.navigateToPage('/company-profile');
      } else {
        this.navigateToPage('/dashboard/sme/home');
      }
    } else if (userRoles.includes(Role.Enterprise) && !userRoles.includes(Role.SMEViewer)) {
      this.navigateToPage('/dashboard/enterprise/home');
    } else if (userRoles.includes(Role.Admin)) {
      this.navigateToPage('/admin');
    }

    return false;
  }
}
