import { Component, NgZone, OnInit } from '@angular/core';
import { PaymentService } from '../../../../dashboard/profile/payment.service';
import { AuthService } from '../../../auth.service';

@Component({
  selector: 'app-invited',
  templateUrl: './invited.component.html',
  styleUrls: ['./invited.component.scss'],
})
export class InvitedComponent implements OnInit {
  signupEmail: string | undefined;

  constructor(
    private paymentService: PaymentService,
    private readonly ngZone: NgZone,
    private readonly authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.signupEmail = this.authService.getSignupEmail();
  }

  getLanguage(): string | undefined {
    return this.authService.getPaymentCountry();
  }

  async acceptAndContinue(): Promise<void> {
    if (this.signupEmail) {
      await this.paymentService.subscribeWithInvoice(this.signupEmail, this.getLanguage());
      this.ngZone.run(() =>
        this.authService.navigateToPage('/auth/login', { signupSuccess: true, email: this.signupEmail }),
      );
    }
  }
}
