import { Action } from '../../../shared/xs/actions';

export interface SetCo2ResultsIdPayload {
  id: string;
}

export class SetCo2ResultsIdAction implements Action {
  static readonly type = '[Co2Calculator] Set results id in state';

  constructor(public payload: SetCo2ResultsIdPayload) {}
}
