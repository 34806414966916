/* eslint-disable prettier/prettier */
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { AbstractService } from '../services/abstract.service';
import { AnswerModel, AnswerSavedResponse, QuestionCategoryModel, QuestionModel, Upload } from './models';
import { QuestionnaireState, QuestionnaireStateModel } from './questionnaire.state';
import { ProfileState } from '../../dashboard/profile/xs/profile.state';
import { CommentPayload } from '../../accountant/xs/actions/comment.action';
import { CommentModel } from '../comments/comment.model';
import { RefreshValidationActionPayload } from '../../dashboard/xs/actions/refresh-validation.action';
import { RelevantThemeModel } from '../relevant-theme/relevant-theme.model';
import {
  MaterialityType,
  SaveMaterialityDtoModel,
} from '../../dashboard/questionnaire/questionaire/materiality/save-materiality-dto.model';
import { GetQuestionnaireDto } from './models/get-questionnaire.dto';
import { Observable } from 'rxjs';
import { LockAnswersActionResponse } from '../../dashboard/xs/actions/lock-answers.action';

@Injectable({ providedIn: 'root' })
export class QuestionService extends AbstractService {
  public getQuestionnaire(): Observable<GetQuestionnaireDto> {
    return this.http.get(`${this.apiBaseUrl}/scorings/${this.getScoringId()}`) as Observable<GetQuestionnaireDto>;
  }

  public async getSectionList(category: string): Promise<QuestionCategoryModel> {
    const questionnaire = this.getScoring();
    const questionnaireType = questionnaire.type?.replace('_', '-').toLowerCase();

    // @ts-ignore
    const sections: QuestionCategoryModel = await this.http
      .get(`${this.apiBaseUrl}/${questionnaireType}/${questionnaire.id}/${category.toUpperCase()}/sections`)
      .toPromise();

    return sections;
  }

  public async getSection(
    category: string,
    section: string,
    countryCode?: string,
    accountantView?: boolean,
  ): Promise<QuestionModel[]> {
    let params = new HttpParams();

    if (countryCode) {
      params = params.append('countryCode', countryCode);
    }

    const questionnaire = this.getScoring();
    const questionnaireType = questionnaire.type?.replace('_', '-').toLowerCase();

    // @ts-ignore
    const questionSets: QuestionModel[] = await this.http
      .get(
        `${this.apiBaseUrl}/${accountantView ? 'scorings' : questionnaireType}/${
          questionnaire.id
        }/${category}/${section}/questions`,
        { params },
      )
      .toPromise();

    return questionSets;
  }

  public async getUnansweredQuestions(): Promise<QuestionModel[]> {
    const questionnaire = this.getScoring();
    const questionnaireType = questionnaire.type?.replace('_', '-').toLowerCase();

    return (await this.http
      .get(`${this.apiBaseUrl}/${questionnaireType}/${questionnaire.id}/unanswered`)
      .toPromise()) as QuestionModel[];
  }

  public async getUnansweredQuestionsPerCategory(category: string): Promise<QuestionModel[]> {
    const questionnaire = this.getScoring();
    const questionnaireType = questionnaire.type?.replace('_', '-').toLowerCase();

    return (await this.http
      .get(`${this.apiBaseUrl}/${questionnaireType}/${questionnaire.id}/${category}/unanswered`)
      .toPromise()) as QuestionModel[];
  }

  public async getPayrollSpecificQuestions(): Promise<QuestionModel[]> {
    return (await this.http
      .get(`${this.apiBaseUrl}/assessment/${this.getScoringId()}/payrollquestions`)
      .toPromise()) as QuestionModel[];
  }

  async answer(answer: AnswerModel): Promise<AnswerSavedResponse> {
    const questionnaire = this.getScoring();
    const questionnaireType = questionnaire.type?.replace('_', '-').toLowerCase();
    const result: AnswerSavedResponse = (await this.http
      .post(`${this.apiBaseUrl}/${questionnaireType}/${this.getScoringId()}/answer`, answer)
      .toPromise()) as AnswerSavedResponse;

    return result;
  }

  async uploadFiles(files: FileList): Promise<Upload[]> {
    const result: Upload[] = [];

    for (const file of Array.from(files)) {
      const form = new FormData();

      form.append('file', file);
      form.append('filename', file.name);

      try {
        // eslint-disable-next-line no-await-in-loop
        const res = (await this.http
          .post(`${this.apiBaseUrl}/upload/company/scoring/${this.getScoringId()}`, form)
          .toPromise()) as Upload;

        result.push(res);
      } catch (e) {
        return [];
      }
    }

    return result;
  }

  async getFile(id: number): Promise<Blob | undefined> {
    return this.http
      .get(`${this.apiBaseUrl}/upload/company/scoring/${this.getScoringId()}/file/${id}`,
        { responseType: 'blob' }).toPromise();
  }

  async removeFile(id: number): Promise<void> {
    await this.http.delete(`${this.apiBaseUrl}/upload/company/scoring/${this.getScoringId()}/file/${id}`).toPromise();
  }

  public getScoringId(): string | undefined {
    return this.store.selectSnapshot(QuestionnaireState.id);
  }

  public getScoring(): QuestionnaireStateModel {
    return JSON.parse(JSON.stringify(this.store.selectSnapshot(QuestionnaireState)));
  }

  public getUserName(): string {
    return this.store.selectSnapshot(ProfileState.getName);
  }

  public async comment(comment: CommentPayload): Promise<void> {
    await this.http.post(`${this.apiBaseUrl}/scorings/${this.getScoringId()}/comment`, comment).toPromise();
  }

  public async refreshComments(): Promise<CommentModel[]> {
    const result = await this.http.get(`${this.apiBaseUrl}/scorings/${this.getScoringId()}/comments`).toPromise();

    return result as CommentModel[];
  }

  public getComments(): CommentModel[] | undefined {
    return this.store.selectSnapshot(QuestionnaireState.comments);
  }

  public async saveComment(comment: CommentModel, questionId: string): Promise<void> {
    const questionnaire = this.getScoring();
    const questionnaireType = questionnaire.type?.replace('_', '-').toLowerCase();

    await this.http
      .post(`${this.apiBaseUrl}/${questionnaireType}/${questionnaire.id}/answer/${questionId}/comment`, comment)
      .toPromise();
  }

  public getUnanswered(): QuestionModel[] | undefined {
    return this.store.selectSnapshot(QuestionnaireState.unanswered);
  }

  public async refreshValidation(payload: RefreshValidationActionPayload): Promise<void> {
    const questionnaire = payload.id;
    const questionnaireType = payload.type?.replace('_', '-').toLowerCase();

    await this.http
      .post(`${this.apiBaseUrl}/${questionnaireType}/${questionnaire}/refresh-validation`, {})
      .toPromise();
  }

  public async lockAnswers(scoringId: string): Promise<LockAnswersActionResponse> {
    return await this.http
      .post(`${this.apiBaseUrl}/assessment/${scoringId}/lock`, {}).toPromise() as LockAnswersActionResponse;
  }

  public saveMateriality(materialities: RelevantThemeModel[][], type: MaterialityType): void {
    const payload: SaveMaterialityDtoModel = {
      materiality: materialities,
      materialityType: type,
    };

    this.http.put(`${this.apiBaseUrl}/assessment/${this.getScoringId()}/materiality`, payload).toPromise();
  }

  public async getDoubleMateriality(): Promise<{ doubleMateriality: RelevantThemeModel[][] }> {
    return await this.http
      .get(`${this.apiBaseUrl}/assessment/${this.getScoringId()}/double-materiality`)
      .toPromise() as { doubleMateriality: RelevantThemeModel[][] };
  }
}
