import { Injectable } from '@angular/core';
import { AbstractService } from '../shared/services/abstract.service';
import { CompanyModel } from './company.model';
import { Observable } from 'rxjs';
import { CompanyListItemModel } from './company-list/company-list-item.model';
import { EnterpriseState } from './xs/enterprise.state';
import { LoginAsCompanyActionPayload, LoginAsCompanyActionResponse } from './xs/actions/login-as-company.action';
import { LogoutFromSmeActionPayload, LogoutFromSmeActionResponse } from './xs/actions/logout-from-sme.action';
import { UpdateEnterpriseProfileActionPayload } from './xs/actions/update-enterprise-profile.action';
import { UpdateProfileActionResponse } from '../dashboard/profile/xs/actions/profile/update-profile.action';
import { EnterpriseChartModel } from './enterprise-dashboard/enterprise-chart.model';

@Injectable({ providedIn: 'root' })
export class EnterpriseService extends AbstractService {
  inviteCompany(company: CompanyModel): Observable<unknown> {
    return this.http.post(`${this.apiBaseUrl}/enterprises/companies`, company);
  }

  getCompanyListItems(): CompanyListItemModel[] {
    return this.store.selectSnapshot(EnterpriseState.companyListItems);
  }

  isFirstDashboardCheck(): boolean {
    return this.store.selectSnapshot(EnterpriseState.isFirstDashboardCheck);
  }

  isFirstPortfolioCheck(): boolean {
    return this.store.selectSnapshot(EnterpriseState.isFirstPortfolioCheck);
  }

  isFirstViewModeCheck(): boolean {
    return this.store.selectSnapshot(EnterpriseState.isFirstViewModeCheck);
  }

  async getCompanyListItemsFromBackend(): Promise<CompanyListItemModel[]> {
    const result = await this.http.get(`${this.apiBaseUrl}/enterprises/companies`).toPromise();

    return result as CompanyListItemModel[];
  }

  async loginAsSme(payload: LoginAsCompanyActionPayload): Promise<LoginAsCompanyActionResponse> {
    return (await this.http
      .post(`${this.apiBaseUrl}/enterprises/companies/view`, payload)
      .toPromise()) as LoginAsCompanyActionResponse;
  }

  async logoutFromSme(payload: LogoutFromSmeActionPayload): Promise<LogoutFromSmeActionResponse> {
    return (await this.http
      .delete(`${this.apiBaseUrl}/enterprises/companies/view`, { body: payload })
      .toPromise()) as LoginAsCompanyActionResponse;
  }

  async getDashboardData(): Promise<EnterpriseChartModel> {
    return (await this.http.get(`${this.apiBaseUrl}/enterprises/dashboard`).toPromise()) as EnterpriseChartModel;
  }

  async updateProfile(payload: UpdateEnterpriseProfileActionPayload): Promise<UpdateProfileActionResponse> {
    return (await this.http
      .put(`${this.apiBaseUrl}/enterprises/update`, payload)
      .toPromise()) as UpdateProfileActionResponse;
  }

  unlinkCompany(enterpriseClientId: number): Observable<unknown> {
    return this.http.delete(`${this.apiBaseUrl}/enterprises/companies/${enterpriseClientId}`);
  }
}
