import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetGriStandardsAction } from './get-gri-standards.action';
import { Observable } from 'rxjs';
import { config } from '../../../environments/config';
import { tap } from 'rxjs/operators';
import { GriDisclosureDTOModel, GriDisclosureModel, GriStandardModel } from './get-gri-disclosures.model';

export interface GriStandardsStateModel {
  griStandards: GriStandardModel[];
}

@State<GriStandardsStateModel>({ name: 'griDisclosures' })
@Injectable()
export class GriStandardsState {
  constructor(private http: HttpClient) {}

  protected apiBaseUrl = config.apiUrl;

  @Selector()
  static griStandards(state: GriStandardsStateModel): GriStandardModel[] {
    return state.griStandards;
  }

  @Action(GetGriStandardsAction)
  getGriDisclosures(ctx: StateContext<GriStandardsStateModel>): Observable<GriDisclosureModel[]> {
    return this.http.get<GriDisclosureDTOModel[]>(`${this.apiBaseUrl}/standards/gri/disclosures`).pipe(
      tap((result) => {
        ctx.patchState({ griStandards: this.listOfGriDisclosuresToListOfGriStandards(result) });
      }),
    );
  }

  private listOfGriDisclosuresToListOfGriStandards(griDisclosures: GriDisclosureDTOModel[]): GriStandardModel[] {
    const codeToStandard: Map<string, GriStandardModel> = new Map();

    griDisclosures.forEach((disclosureDto: GriDisclosureDTOModel) => {
      const { griStandard: standard, ...disclosure } = disclosureDto;

      if (!codeToStandard.has(standard.number)) {
        standard.disclosures = [];
        codeToStandard.set(standard.number, standard);
      }
      codeToStandard.get(standard.number)?.disclosures.push(disclosure);
    });

    return [...codeToStandard.values()];
  }
}
