import { Action } from '../../../../../shared/xs/actions';
import { SubscriptionStatus } from '../../subscription-status.enum';
import { BillingAddress } from '../../../../../auth';

export class GetCompanyAction implements Action {
  static readonly type = '[Company] Get Company';
}

export interface GetCompanyResponse {
  draft: boolean;
  country?: string;
  registeredId?: string;
  name?: string;
  legalEntityType?: string;
  numberOfEmployees?: number;
  annualTurnover?: number;
  totalAssets?: number;
  localSectorCode?: string;
  subscriptionStatus?: SubscriptionStatus;
  billingAddress?: BillingAddress;
  trialEndDate?: string;
}
