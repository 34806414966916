import { Action } from '../../../shared/xs/actions';

export interface GetScoresFromBackendActionPayload {
  id: string;
}

export class GetScoresFromBackendAction implements Action {
  static readonly type = '[Scoring] get scoring from backend passing assessment id';

  constructor(public payload: GetScoresFromBackendActionPayload) {}
}
