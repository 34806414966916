import { Action, ActionResponse } from '../../../shared/xs/actions';
import { Role } from '../../../dashboard/profile/models/role';
import { ColourSchemeModel } from '../../../shared/sidenav';
import { PaymentRedirectionType } from './payment-redirection-type';
import { LoginActionResponse } from './login.action';

export interface LoginSsoActionPayload {
  loginResponse: LoginActionResponse;
  provider: string;
}

export class LoginSsoAction implements Action {
  static readonly type = '[Auth] Login SSO';

  constructor(public payload: LoginSsoActionPayload) {}
}

export interface LoginSsoActionResponse extends ActionResponse {
  token: {
    accessToken: string;
    refreshToken: string;
  };
  profile: {
    email: string;
    firstName: string;
    lastName: string;
    language: string;
    roles: Role[];
  };
  company: {
    name: string;
    draft: boolean;
    type: string;
    country: string;
  };
  paymentCountry: string;
  cobranding: CobrandingModel;
  paymentComplete: boolean;
  paymentRedirectionType: PaymentRedirectionType;
  firstLogin: boolean;
  firstUser: boolean;
}

export interface CobrandingModel extends ColourSchemeModel {
  logo: string;
}
