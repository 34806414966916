import { Action } from '../../../shared/xs/actions';

export interface CheckPortfolioActionPayload {
  hasBeenChecked: boolean;
}

export class CheckPortfolioAction implements Action {
  static readonly type = '[Enterprise] Portfolio checked';

  constructor(public payload: CheckPortfolioActionPayload) {}
}
