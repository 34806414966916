/* eslint-disable @typescript-eslint/indent */
import {
  AfterViewInit,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Type,
  ViewChild,
} from '@angular/core';
import { AbstractQuestionDirective } from './abstract-question.directive';
import { NotApplicableReason, QuestionModel, Relationship } from '../models';
import { MultipleChoiceQuestionComponent } from './multiple-choice-question/multiple-choice-question.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { EmptyQuestionComponent } from './empty-question/empty-question.component';
import { OpenQuestionTextNumberComponent } from './open-question-text-number/open-question-text-number.component';
import { OpenQuestionTextareaComponent } from './open-question-textarea/open-question-textarea.component';
import { DropdownCountryComponent } from './dropdown-country/dropdown-country.component';
import { DropdownQuestionComponent } from './dropdown-question/dropdown-question.component';
import { SelectNumberQuestionComponent } from './select-number-question/select-number-question.component';
import { SliderNumberComponent } from './slider-number/slider-number.component';
import { RelationshipListComponent } from './relationship-list/relationship-list.component';
import { DropdownCountryWithInputComponent } from './dropdown-country-with-input/dropdown-country-with-input.component';
import { DropdownMultipleComponent } from './dropdown-multiple/dropdown-multiple.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { QuestionDirective } from './question.directive';
import { QuestionEnum } from './question.enum';
import { QuestionService } from '../question.service';
import { InfoModel } from '../../info/info.model';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  animations: [
    trigger('OpenClose', [
      state('true', style({ height: '*' })),
      state('false', style({ height: '0' })),
      transition('false <=> true', [animate(500)]),
    ]),
  ],
})
export class QuestionComponent implements OnInit, AfterViewInit {
  isOpen = true;

  commentFocus = false;

  @Input()
  question!: QuestionModel;

  @Input()
  countryCode: string | undefined;

  @Input()
  disabled = false;

  @ViewChild(QuestionDirective, { static: true })
  questionHost!: QuestionDirective;

  @Output()
  addQuestions = new EventEmitter<QuestionModel[]>();

  @Output()
  removeQuestions = new EventEmitter<string[]>();

  @Output()
  currentSectionValidity = new EventEmitter<boolean>();

  questionComponent!: ComponentRef<AbstractQuestionDirective>;

  questionInfo: InfoModel = {};

  notApplicable: {
    value: boolean;
    reason?: NotApplicableReason;
    explanation?: string;
  } = { value: false };

  constructor(private questionService: QuestionService) {}

  loadComponent(): void {
    const component: Type<AbstractQuestionDirective> = this.getComponent();
    const { viewContainerRef } = this.questionHost;

    viewContainerRef.clear();

    this.questionComponent = viewContainerRef.createComponent<AbstractQuestionDirective>(component);

    this.questionComponent.instance.question = this.question;
    this.questionComponent.instance.countryCode = this.countryCode;
    this.questionComponent.instance.disabled = this.disabled || this.notApplicable.value;

    this.questionComponent.instance.addQuestions.subscribe((questions: QuestionModel[]) => {
      this.addQuestions.emit(questions);
    });
    this.questionComponent.instance.removeQuestions.subscribe((questions: string[]) => {
      this.removeQuestions.emit(questions);
    });
    this.questionComponent.instance.currentSectionValidity.subscribe((isValid: boolean) => {
      this.currentSectionValidity.emit(isValid);
    });
  }

  ngOnInit(): void {
    if (!this.question.rootQuestion) {
      this.isOpen = false;
    }
    if (this.question.answer && this.question.answer.answer) {
      this.notApplicable.value = !this.question.answer.answer.applicable;
      this.notApplicable.reason = this.question.answer.answer.naReason;
      this.notApplicable.explanation = this.question.answer.answer.naExplanation;
    }
    if (this.question.standards) {
      // this.questionInfo.griStandards = this.question.standards.gri;
      this.questionInfo.sdgGoals = this.question.standards.sdgGoals;
      // this.questionInfo.sdgTargets = this.question.standards.sdgTargets;
      this.questionInfo.ungcPrinciples = this.question.standards.unPrinciples;
    }
    this.questionInfo.description = this.question.description;

    this.loadComponent();
  }

  // eslint-disable-next-line complexity
  isMissingAnswer(): boolean {
    switch (this.question.type) {
      case QuestionEnum.MULTIPLE_CHOICE:
        return this.hasNoOption();
      case QuestionEnum.MULTIPLE_CHOICE_WITH_INPUT:
        return this.hasNoOption() || (this.question.answer.answer.optionId === 1 && this.hasNoValue());
      case QuestionEnum.MULTIPLE_CHOICE_MULTIPLE_ANSWER:
        return this.hasNoOption();
      case QuestionEnum.DROPDOWN:
        return this.hasNoOption();
      case QuestionEnum.DROPDOWN_WITH_INPUT:
        return this.hasNoOption() || (this.question.answer.answer.optionId === 1 && this.hasNoValue());
      case QuestionEnum.DROPDOWN_MULTIPLE:
        return this.hasNoOption();
      case QuestionEnum.DROPDOWN_COUNTRY:
        return this.hasNoCountry();
      case QuestionEnum.DROPDOWN_COUNTRY_WITH_INPUT:
        return this.hasNoCountry();
      case QuestionEnum.FILE_UPLOAD_SINGLE:
        return this.hasNoFiles();
      case QuestionEnum.FILE_UPLOAD_MULTIPLE:
        return this.hasNoFiles();
      case QuestionEnum.INPUT_FILE_OR_TEXT:
        return this.hasNoFiles() && this.hasNoValue();
      case QuestionEnum.INPUT_NUMBER:
        return this.hasNoValue();
      case QuestionEnum.INPUT_TEXT:
        return this.hasNoValue();
      case QuestionEnum.INPUT_TEXTAREA:
        return this.hasNoValue();
      case QuestionEnum.MULTIPLE_CHOICE_NUMBER:
        return this.hasNoOption() && this.hasNoValue();
      case QuestionEnum.SLIDER_NUMBER:
        return this.hasNoValue();
      case QuestionEnum.RELATIONSHIP_LIST:
        return this.relationShipsInComplete();
      default:
        return true;
    }
  }

  private relationShipsInComplete(): boolean {
    if (!this.question.answer.answer.relationships) {
      return true;
    }
    if (this.question.answer.answer.relationships.length === 0) {
      return true;
    }

    return this.question.answer.answer.relationships.some((r) => this.relationShipValueMissing(r));
  }

  private relationShipValueMissing(relationShip: Relationship): boolean {
    if (!relationShip.name || relationShip.name.length === 0) {
      return true;
    }
    if (!relationShip.countryCode || relationShip.countryCode.length === 0) {
      return true;
    }
    if (!relationShip.duration) {
      return true;
    }
    if (!relationShip.percentage) {
      return true;
    }

    return false;
  }

  private hasNoValue(): boolean {
    if (this.question.answer.answer.value) {
      return false;
    }
    if (this.question.answer.answer.text) {
      return false;
    }

    return true;
  }

  private hasNoOption(): boolean {
    if (this.question.answer.answer.optionId) {
      return false;
    }
    if (!this.question.answer.answer.optionIds) {
      return true;
    }

    return this.question.answer.answer.optionIds.length === 0;
  }

  private hasNoCountry(): boolean {
    if (!this.question.answer.answer.countryCodes) {
      return true;
    }

    return this.question.answer.answer.optionIds.length === 0;
  }

  private hasNoFiles(): boolean {
    if (this.question.answer.answer.file) {
      return false;
    }
    if (!this.question.answer.answer.files) {
      return true;
    }

    return this.question.answer.answer.files.length === 0;
  }

  private getComponent(): Type<AbstractQuestionDirective> {
    switch (this.question.type) {
      case QuestionEnum.MULTIPLE_CHOICE:
      case QuestionEnum.MULTIPLE_CHOICE_WITH_INPUT:
      case QuestionEnum.MULTIPLE_CHOICE_MULTIPLE_ANSWER:
        return MultipleChoiceQuestionComponent;
      case QuestionEnum.DROPDOWN:
      case QuestionEnum.DROPDOWN_WITH_INPUT:
        return DropdownQuestionComponent;
      case QuestionEnum.DROPDOWN_MULTIPLE:
        return DropdownMultipleComponent;
      case QuestionEnum.DROPDOWN_COUNTRY:
        return DropdownCountryComponent;
      case QuestionEnum.DROPDOWN_COUNTRY_WITH_INPUT:
        return DropdownCountryWithInputComponent;
      case QuestionEnum.FILE_UPLOAD_SINGLE:
      case QuestionEnum.FILE_UPLOAD_MULTIPLE:
      case QuestionEnum.INPUT_FILE_OR_TEXT:
        return FileUploadComponent;
      case QuestionEnum.INPUT_NUMBER:
      case QuestionEnum.INPUT_TEXT:
        return OpenQuestionTextNumberComponent;
      case QuestionEnum.INPUT_TEXTAREA:
        return OpenQuestionTextareaComponent;
      case QuestionEnum.MULTIPLE_CHOICE_NUMBER:
        return SelectNumberQuestionComponent;
      case QuestionEnum.SLIDER_NUMBER:
        return SliderNumberComponent;
      case QuestionEnum.RELATIONSHIP_LIST:
        return RelationshipListComponent;
      default:
        return EmptyQuestionComponent;
    }
  }

  ngAfterViewInit(): void {
    this.isOpen = true;
  }

  saveNAAnswer(): void {
    this.questionComponent.instance.naChanged(this.notApplicable);
  }

  saveComment(): void {
    this.questionService.saveComment(this.question.answer.comment, this.question.qid);
  }
}
