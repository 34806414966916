import { Component, Input } from '@angular/core';
import { ProductModel } from '../../../dashboard/profile/models/product.model';
import { CompanyCardDirective } from '../company-card.directive';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-product-service-card',
  templateUrl: './product-service-card.component.html',
  styleUrls: ['./product-service-card.component.scss'],
})
export class ProductServiceCardComponent extends CompanyCardDirective<ProductModel[]> {
  @Input()
  headerText = 'profile.company.verify.product';

  @Input()
  addBtnText = 'profile.company.verify.add_product';

  @Input()
  noneText = 'profile.company.verify.no_product';

  editIndex = -1;

  constructor(public authService: AuthService) {
    super();
  }

  disableEdit(): void {
    this.editIndex = -1;
  }

  removeProduct(i: number): void {
    this.data.splice(i, 1);
    this.submit();
  }

  addProduct(): void {
    this.data.push({
      certificate: false,
      description: '',
      name: '',
    });

    this.editIndex = this.data.length - 1;
  }
}
