import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-background-logo',
  templateUrl: './background-logo.component.svg',
  styleUrls: ['./background-logo.component.scss'],
})
export class BackgroundLogoComponent {
  @Input()
  backgroundColour = '#452674';

  @Input()
  primaryColour = '#FFCA38';

  @Input()
  secondaryColour = '#F73054';

  @Input()
  translucent = true;
}
