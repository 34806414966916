import { Pipe, PipeTransform } from '@angular/core';
import { StandardTranslationModel } from '../gri/get-gri-disclosures.model';
import { InfoService } from './info.service';

@Pipe({ name: 'getTranslationTitle' })
export class GetTranslationTitlePipe implements PipeTransform {
  transform(translations: StandardTranslationModel[]): string {
    return InfoService.getTranslationTitle(translations);
  }
}
