import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { Co2QuestionnaireAnswersModel } from '../model/co2-questionnaire-answers.model';

@Directive()
export abstract class AbstractCo2QuestionnaireDirective {
  @Input()
  answers!: Co2QuestionnaireAnswersModel;

  @Input()
  highLightUnansweredQuestion!: boolean;

  @Input()
  viewOnly!: boolean;

  @Output()
  answersChanged = new EventEmitter<Co2QuestionnaireAnswersModel>();

  @Output()
  answerUpdated = new EventEmitter<void>();

  onInputBlur(): void {
    this.answerUpdated.emit();
  }
}
