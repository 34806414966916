import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { DashboardRouting } from './dashboard.routing';

import { MaterialDesignModule, SharedModule } from '../shared';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AssessmentListComponent } from './assessment-list/assessment-list.component';
import { RestatementPopUpComponent } from './assessment-list/restatement-pop-up/restatement-pop-up.component';
import { ChartsModule } from 'ng2-charts';
import { ScoreboardModule } from './scoreboard';
import { ImpactStartPopupComponent } from './questionnaire/impact-scan/impact-start-popup/impact-start-popup.component';
import { EsgStartPopupComponent } from './questionnaire/questionaire/esg-start-popup/esg-start-popup.component';
import { ImpactFinishPopupComponent } from './questionnaire/impact-scan/impact-finish-popup/impact-finish-popup.component';
import { EsgFinishPopupComponent } from './questionnaire/questionaire/esg-finish-popup/esg-finish-popup.component';
import { CompleteScoringCheckPopUpComponent } from './questionnaire/complete-scoring-check-pop-up/complete-scoring-check-pop-up.component';
import { WelcomePopupComponent } from './questionnaire/welcome-popup/welcome-popup.component';
import { ImpactPrefillPopupComponent } from './questionnaire/impact-scan/impact-prefill-popup/impact-prefill-popup.component';
import { ImpactScanRevampModule } from './questionnaire/impact-scan-revamp/impact-scan-revamp.module';

@NgModule({
  declarations: [
    AssessmentListComponent,
    RestatementPopUpComponent,
    ImpactStartPopupComponent,
    ImpactFinishPopupComponent,
    ImpactPrefillPopupComponent,
    EsgStartPopupComponent,
    EsgFinishPopupComponent,
    WelcomePopupComponent,
    CompleteScoringCheckPopUpComponent,
  ],
  imports: [
    DashboardRouting,
    MaterialDesignModule,
    TranslateModule,
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    ChartsModule,
    ScoreboardModule,
    FormsModule,
    ImpactScanRevampModule,
  ],
})
export class DashboardModule {}
