import { Action } from '../../xs/actions';
import { ScoringStatus } from '../../../dashboard';
import { QuestionnaireType } from '../../../dashboard/scoreboard/models/questionnaire.type';
import { UserStatus } from '../../../dashboard/assessment-list/user-status.enum';
import { AssessmentVersion } from '../../../dashboard/scoreboard/models/scoring.model';

export class UpdateQuestionnaireAction implements Action {
  static readonly type = '[Questionnaire] Update questionnaire';

  constructor(public payload: UpdateQuestionnaireActionPayload) {}
}

export interface UpdateQuestionnaireActionPayload {
  id: string;
  status: ScoringStatus;
  type: QuestionnaireType;
  version: AssessmentVersion;
  userStatus?: UserStatus;
  firstName?: string;
  lastName?: string;
  disabled: boolean;
  unfinished: boolean;
}
