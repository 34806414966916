import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-themes-welcome-popup',
  templateUrl: './welcome-popup.component.html',
  styleUrls: ['./welcome-popup.component.scss'],
})
export class WelcomePopupComponent {
  translationGroup = '';

  constructor(
    public dialogRef: MatDialogRef<WelcomePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { translationGroup: string },
    private ngZone: NgZone,
  ) {
    this.translationGroup = this.data.translationGroup;
  }

  onClose(): void {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  back(): void {
    this.ngZone.run(() => {
      this.dialogRef.close(true);
    });
  }
}
