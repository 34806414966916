import { Action } from '../../../shared/xs/actions';

export interface CheckAssessmentsActionPayload {
  hasBeenChecked: boolean;
}

export class CheckAssessmentsAction implements Action {
  static readonly type = '[Dashboard] Assessments checked';

  constructor(public payload: CheckAssessmentsActionPayload) {}
}
