import { Component, Input, ViewChild } from '@angular/core';
import { ExportDropdownMenuItem } from './export-dropdown-menu-item';
import { MatDialog } from '@angular/material/dialog';
import { PopUpComponent } from '../../pop-up/pop-up.component';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-export-dropdown',
  templateUrl: './export-dropdown.component.html',
  styleUrls: ['./export-dropdown.component.scss'],
})
export class ExportDropdownComponent {
  @Input() isDisabled = false;

  @Input() menuItems: ExportDropdownMenuItem[] = [];

  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;

  private loadingMenuItemIds: string[] = [];

  constructor(private readonly dialog: MatDialog) {}

  public onMenuItemClick(menuItem: ExportDropdownMenuItem, $event: MouseEvent): void {
    $event.stopPropagation();
    this.markMenuItemAsLoading(menuItem);

    menuItem
      .exportFunction()
      .catch(() => {
        this.triggerExportErrorPopup();
      })
      .finally(() => {
        this.markMenuItemAsNotLoading(menuItem);
        this.closeMenuIfNoItemsAreLoading();
      });
  }

  public isMenuItemLoading(menuItem: ExportDropdownMenuItem): boolean {
    return this.loadingMenuItemIds.some((loadingListItemId) => loadingListItemId === menuItem.id);
  }

  public getMenuItemLabels(): string[] {
    return this.menuItems.map((item) => item.title);
  }

  /*
   * NgIf goes haywire when iterating over anything with an anonymous function returning promises.
   * Thus, this workaround.
   */
  public getMenuItemByIndex(index: number): ExportDropdownMenuItem {
    return this.menuItems[index];
  }

  private markMenuItemAsLoading(menuItem: ExportDropdownMenuItem): void {
    this.loadingMenuItemIds.push(menuItem.id);
  }

  private markMenuItemAsNotLoading(menuItem: ExportDropdownMenuItem): void {
    this.loadingMenuItemIds = this.loadingMenuItemIds.filter((id) => id !== menuItem.id);
  }

  private closeMenuIfNoItemsAreLoading(): void {
    if (this.loadingMenuItemIds.length === 0) {
      this.menuTrigger.closeMenu();
    }
  }

  private triggerExportErrorPopup(): void {
    this.dialog.open(PopUpComponent, {
      width: '400px',
      autoFocus: false,
      data: {
        title: 'error_pop_up.title',
        message: 'export.failure-text',
      },
    });
  }
}
