import { Action } from '../../../shared/xs/actions';

export interface ResendConfirmEmailActionPayload {
  email: string;
}

export class ResendConfirmEmailAction implements Action {
  static readonly type = '[Auth] Resend confirmation email';

  constructor(public payload: ResendConfirmEmailActionPayload) {}
}
