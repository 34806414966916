import { Component, OnInit } from '@angular/core';
import { ImpactScanPageDirective } from '../abstract-impact-scan-page';
import { InfoModel } from '../../../../shared/info/info.model';
import { isCountryCodeDutchLike } from '../../../../shared/countries/country-service';

@Component({
  selector: 'app-governance',
  templateUrl: './governance.component.html',
  styleUrls: ['./governance.component.scss'],
})
export class GovernanceComponent extends ImpactScanPageDirective implements OnInit {
  legalEntityTypes = ['Limited Company', 'Limited Liability Partnership', 'Partnership', 'Foundation'];

  controlTypes = ['PRIVATELY', 'PRIVATE_EQUITY', 'NON_SME'];

  legalEntityInfo: InfoModel = {
    description: { explanation: 'impact_scan.governance.legal_entity.info' },
    oldGriStandards: ['2-1'],
  };

  executiveDirectorsInfo: InfoModel = {
    description: { explanation: 'impact_scan.governance.executive_directors.info' },
    oldGriStandards: ['2-9'],
  };

  nonExecutiveDirectorsInfo: InfoModel = {
    description: { explanation: 'impact_scan.governance.non_executive_directors.info' },
    oldGriStandards: ['2-9'],
  };

  multipleShareholdersInfo: InfoModel = {
    description: { explanation: 'impact_scan.governance.multiple_shareholders.info' },
    oldGriStandards: ['2-9'],
  };

  controlTypeInfo: InfoModel = {
    description: { explanation: 'impact_scan.governance.control_type.info' },
    oldGriStandards: ['2-9'],
  };

  ngOnInit(): void {
    if (isCountryCodeDutchLike(this.country)) {
      this.legalEntityTypes = ['BV', 'NV', 'Maatschap', 'Stichting'];
    }
    // eslint-disable-next-line no-console
    console.log(this.controlTypeInfo);
  }
}
