import { Directive, HostListener } from '@angular/core';
import { MatInput } from '@angular/material/input';

@Directive({ selector: '[matInput][appTrimOnPaste]' })
export class TrimOnPasteDirective {
  @HostListener('paste', ['$event'])
  trimInput(ev: ClipboardEvent): void {
    ev.preventDefault();
    this.element.value = ev.clipboardData?.getData('text/plain')?.trimEnd().trimStart() || this.element.value;
    this.element.ngControl.control?.setValue(this.element.value);
  }

  constructor(private element: MatInput) {}
}
