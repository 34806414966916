import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProfileService } from '../../../dashboard';
import { UsersService } from '../../users/users.service';
import { SupportRequestModel } from '../help.model';

@Component({
  selector: 'app-help-popup',
  templateUrl: './help-popup.component.html',
  styleUrls: ['./help-popup.component.scss'],
})
export class HelpPopupComponent implements OnInit {
  contactEmail?: string;

  help: SupportRequestModel = {
    text: '',
    title: '',
    contactAtUserPrimaryEmail: false,
    alternativeEmail: '',
    phone: '',
  };

  showEmail = false;

  showPhone = false;

  constructor(
    private dialogRef: MatDialogRef<HelpPopupComponent>,
    private userService: UsersService,
    private profileService: ProfileService,
  ) {}

  ngOnInit(): void {
    this.contactEmail = this.profileService.getProfile().email;
  }

  close(): void {
    this.dialogRef.close();
  }

  async send(): Promise<void> {
    await this.userService.sendHelpRequest(this.help);
    this.close();
  }

  selectRegistered(): void {
    this.clearHelp();
    this.help.contactAtUserPrimaryEmail = true;
  }

  selectOther(): void {
    this.clearHelp();
    this.showEmail = true;
  }

  selectPhoneNr(): void {
    this.clearHelp();
    this.showPhone = true;
  }

  private clearHelp(): void {
    this.help.contactAtUserPrimaryEmail = false;
    this.help.alternativeEmail = '';
    this.help.phone = '';
    this.showEmail = false;
    this.showPhone = false;
  }
}
