import { Action } from '../../../shared/xs/actions';
import { QuestionnaireType } from '../../scoreboard/models/questionnaire.type';
import { ScoringStatus } from '../../scoreboard';
import { UserStatus } from '../../assessment-list/user-status.enum';
import { AssessmentVersion } from '../../scoreboard/models/scoring.model';

export class ViewQuestionnaireAction implements Action {
  static readonly type = '[Dashboard] view scoring';

  constructor(public payload: ViewQuestionnaireActionPayload) {}
}

export interface ViewQuestionnaireActionPayload {
  id: string;
  status: ScoringStatus;
  type: QuestionnaireType;
  version: AssessmentVersion;
  userStatus: UserStatus;
  firstName: string;
  lastName: string;
  disabled: boolean;
  viewOnly: boolean;
  unfinished: boolean;
}
