import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Co2CalculatorService } from '../../../co2-calculator/co2-calculator.service';
import { Co2CalculationStatus } from '../../../co2-calculator/model/co2-calculation-list-item.model';
import { Co2OffsettingService } from '../../co2-offsetting.service';

@Component({
  selector: 'app-co2-offsetting-goal-popup',
  templateUrl: './co2-offsetting-goal-popup.component.html',
  styleUrls: ['./co2-offsetting-goal-popup.component.scss'],
})
export class Co2OffsettingGoalPopupComponent {
  co2OffsettingGoal = '';

  submitDisabled = true;

  constructor(
    public dialogRef: MatDialogRef<Co2OffsettingGoalPopupComponent>,
    private ngZone: NgZone,
    private co2CalculatorService: Co2CalculatorService,
    private co2offsettingService: Co2OffsettingService,
    private cdRef: ChangeDetectorRef,
  ) {
    const calculations = this.co2CalculatorService
      .getCo2Calculations()
      .filter((calculation) => calculation.status === Co2CalculationStatus.COMPLETED);

    this.co2OffsettingGoal = this.co2offsettingService.formatRadix(
      (calculations[0]?.totalEmission ?? 0).toFixed(2).toString(),
    );
    if (this.co2OffsettingGoal.length > 0 || this.co2OffsettingGoal === '0') {
      this.submitDisabled = false;
    }
  }

  submit(): void {
    this.ngZone.run(() => {
      this.dialogRef.close({
        co2OffsettingGoal: this.co2offsettingService.revertRadix(this.co2OffsettingGoal),
        continue: true,
      });
    });
  }

  close(): void {
    this.ngZone.run(() => {
      this.dialogRef.close({
        co2OffsettingGoal: this.co2offsettingService.revertRadix(this.co2OffsettingGoal),
        continue: false,
      });
    });
  }

  validateInput($event: KeyboardEvent): void {
    const allowedKeys = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '.',
      ',',
      'Backspace',
      'Tab',
      'ArrowLeft',
      'ArrowRight',
      'Delete',
      'Enter',
    ];

    if (!allowedKeys.includes($event.key)) {
      $event.preventDefault();
    } else if (
      ($event.key === '.' || $event.key === ',') &&
      (($event.target as HTMLInputElement).value.includes('.') ||
        ($event.target as HTMLInputElement).value.includes(','))
    ) {
      // Prevent additional dots if one already exists in the value
      $event.preventDefault();
    } else if ($event.key === 'Enter') {
      this.submit();
    }
  }
}
