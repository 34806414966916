import { Component } from '@angular/core';
import { Subsidiary } from '../impact-scan-revamp.model';
import { InfoModel } from '../../../../shared/info/info.model';
import { PopUpComponent } from '../../../../shared/pop-up/pop-up.component';
import { ImpactScanPageDirective } from '../abstract-impact-scan-page';

@Component({
  selector: 'app-company-context-two',
  templateUrl: './company-context-two.component.html',
  styleUrls: ['./company-context-two.component.scss'],
})
export class CompanyContextTwoComponent extends ImpactScanPageDirective {
  subsidiariesInfo: InfoModel = {
    description: { explanation: 'impact_scan.company_context_two.subsidiaries.info' },
    oldGriStandards: ['2-1', '2-2'],
  };

  productsInfo: InfoModel = {
    description: { explanation: 'impact_scan.company_context_two.products.info' },
    oldGriStandards: ['2-6'],
  };

  errorIndex?: number;

  addSubsidiary(): void {
    if (this.impactScan?.subsidiaries.length >= 5) {
      return;
    }

    const sub: Subsidiary = {
      includedInFinancial: false,
      includedInSustainability: false,
      country: '',
      name: '',
      sectorCode: '',
    };

    this.impactScan?.subsidiaries?.push(sub);
    this.onAnswerChanged();
  }

  removeSubsidiary(index: number): void {
    this.impactScan?.subsidiaries.splice(index, 1);
    this.onAnswerChanged();
  }

  employeesChanged(index: number): void {
    if (this.impactScan?.numberOfEmployees && this.getTotalSubsidiaryEmployees() > this.impactScan.numberOfEmployees) {
      this.dialog.open(PopUpComponent, {
        width: '400px',
        autoFocus: false,
        data: {
          title: 'pop_up.employee_number_total.title',
          message: 'pop_up.employee_number_total.description',
        },
      });

      this.errorIndex = index;
      this.impactScan.subsidiaries[index].numberOfEmployees = undefined;
    } else {
      this.errorIndex = undefined;
      this.onAnswerChanged();
    }
  }

  private getTotalSubsidiaryEmployees(): number {
    let employees = 0;

    this.impactScan.subsidiaries.forEach((sub) => {
      employees += this.addEmployeeCondition(sub);
    });

    return employees;
  }

  private addEmployeeCondition(sub: Subsidiary): number {
    return sub.numberOfEmployees && (sub.includedInFinancial || sub.includedInSustainability)
      ? sub.numberOfEmployees
      : 0;
  }
}
