import { Component, Input, OnInit } from '@angular/core';
import { InfoModel } from '../../shared/info/info.model';
import { OnboardingPageDirective } from '../onboarding.page.directive';

@Component({
  selector: 'app-onboarding-governance',
  templateUrl: './governance.component.html',
  styleUrls: ['./governance.component.scss'],
})
export class GovernanceComponent extends OnboardingPageDirective implements OnInit {
  @Input() locale = 'gb';

  options = [1, 2, 3, 4, 5];

  executivesError = false;

  controlTypeError = false;

  nonExecutivesError = false;

  multipleShareholdersError = false;

  buttons = true;

  otherValue?: number;

  executivesInfo: InfoModel = { oldGriStandards: ['2-9'] };

  nonExecutiveInfo: InfoModel = { oldGriStandards: ['2-9'] };

  controlTypeInfo: InfoModel = { oldGriStandards: ['2-9'] };

  shareholdersInfo: InfoModel = { oldGriStandards: ['2-9'] };

  async ngOnInit(): Promise<void> {
    if (this.company.executiveDirectors > 5) {
      this.otherValue = this.company.executiveDirectors;
    }

    /* eslint-disable */
    this.executivesInfo.description = { explanation: await this.translateService.get('onboarding.governance.executives.desc') };
    this.nonExecutiveInfo.description = { explanation: await this.translateService.get('onboarding.governance.non_executive.desc') };
    this.shareholdersInfo.description = { explanation: await this.translateService.get('onboarding.governance.multiple_shareholders.desc') };
    this.controlTypeInfo.description = { explanation: await this.translateService.get('onboarding.governance.control_type.desc') };
    /* eslint-enable */
  }

  async submit(): Promise<void> {
    let hasError = false;

    if (!this.company.executiveDirectors || this.company.executiveDirectors === 0) {
      this.executivesError = true;
      hasError = true;
    }

    if (this.company.hasMultipleShareholders === null) {
      this.multipleShareholdersError = true;
      hasError = true;
    }

    if (this.company.executiveDirectors > 1 && this.company.hasNonExecutiveDirectors === null) {
      this.nonExecutivesError = true;
      hasError = true;
    }

    if (this.company.hasMultipleShareholders && (!this.company.controlType || this.company.controlType === '')) {
      this.controlTypeError = true;
      hasError = true;
    }

    if (hasError) {
      this.showRequiredFieldsPopUp();

      return;
    }

    this.finished.emit(this.company);
  }

  buttonSelect(directors: number): void {
    this.company.executiveDirectors = directors;
    this.buttons = true;
    this.otherValue = undefined;
    this.executivesError = false;
    if (directors < 2) {
      this.company.hasNonExecutiveDirectors = null;
      this.nonExecutivesError = false;
    }
    this.onAnswerAdded();
  }

  directorInput(): void {
    if (this.otherValue) {
      this.company.executiveDirectors = this.otherValue;
    }
    if (this.company.executiveDirectors < 2) {
      this.company.hasNonExecutiveDirectors = null;
      this.nonExecutivesError = false;
    }
    this.executivesError = false;
    this.buttons = false;
  }

  setNonExecutiveDirectors(value: boolean): void {
    this.company.hasNonExecutiveDirectors = value;
    this.nonExecutivesError = false;
    this.onAnswerAdded();
  }

  setMultipleShareholders(value: boolean): void {
    this.company.hasMultipleShareholders = value;
    this.multipleShareholdersError = false;
    if (value === false) {
      this.company.controlType = null;
      this.controlTypeError = false;
    }
    this.onAnswerAdded();
  }

  setControlType(value: string): void {
    this.company.controlType = value;
    this.controlTypeError = false;
    this.onAnswerAdded();
  }
}
