import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SignupActionPayload } from '../../../xs';
import { PrivacyModel } from '../privacy-pop-up/privacy.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PrivacyPopUpComponent } from '../privacy-pop-up/privacy-pop-up.component';
import { AuthService } from '../../../auth.service';
import { EeveryTranslateService } from '../../../../shared/eevery.translate.service';
import { NonActiveSignupValues } from '../create-account.component';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements OnInit {
  @Output()
  create = new EventEmitter<SignupActionPayload & NonActiveSignupValues>();

  @Output()
  back = new EventEmitter<SignupActionPayload & NonActiveSignupValues>();

  @Input()
  user: SignupActionPayload & NonActiveSignupValues = {
    dataUsageApproved: false,
    confirmEmailValue: '',
    confirmPasswordValue: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    billingAddress: {
      city: '',
      name: '',
      houseNumber: '',
      street: '',
      postalCode: '',
      country: '',
    },
  };

  countries: string[] = ['NL', 'GB'];

  @Input()
  supportEmail = '';

  policyError = false;

  popUpData = {
    PSName: '',
    PSTitle: '',
  };

  constructor(
    public dialog: MatDialog,
    private readonly authService: AuthService,
    private readonly translateService: EeveryTranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.refreshLanguage();
  }

  refreshLanguage(): void {
    this.authService.initLocation().finally(() => {
      this.translateService.refreshLanguage();
      this.initLanguage();
    });
  }

  private show(data: PrivacyModel, showAcceptButton?: boolean): MatDialogRef<PrivacyPopUpComponent> {
    return this.dialog.open(PrivacyPopUpComponent, {
      width: '90vw',
      autoFocus: false,
      data: { agreement: data, canAccept: showAcceptButton },
    });
  }

  policyChecked(): void {
    this.policyError = !this.user.dataUsageApproved;
  }

  showPolicy(): void {
    const data: PrivacyModel = {
      title: this.popUpData.PSTitle,
      filename: this.popUpData.PSName,
    };

    this.show(data, true)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.user.dataUsageApproved = result;
        }
      });
  }

  createAccount(formValid: boolean): void {
    if (!this.user.dataUsageApproved) {
      this.policyError = true;

      return;
    }

    if (!formValid) {
      return;
    }
    this.create.emit(this.user);
  }

  async initLanguage(): Promise<void> {
    const language = this.getLanguage();
    /* eslint-disable @typescript-eslint/indent */

    switch (language) {
      case 'NL':
        this.popUpData.PSName = 'PS_NL.html';
        this.popUpData.PSTitle = 'Privacyverklaring';
        break;
      case 'UK':
      default:
        this.popUpData.PSName = 'PS_UK.html';
        this.popUpData.PSTitle = 'Privacy Policy';
    }
    /* eslint-enable @typescript-eslint/indent */
  }

  getLanguage(): string | undefined {
    return this.authService.getCountry();
  }

  goBack(): void {
    this.back.emit(this.user);
  }
}
