/* eslint-disable @typescript-eslint/indent */
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../auth.service';
import { ResendConfirmEmailAction } from '../../xs/actions/resend-confirm.action';

@Component({
  selector: 'app-confirm-failed',
  templateUrl: './confirm-failed.component.html',
  styleUrls: ['./confirm-failed.component.scss'],
})
export class ConfirmFailedComponent implements OnInit {
  signupEmail: string | undefined;

  emailSent = false;

  constructor(private readonly authService: AuthService, public dialog: MatDialog) {}

  async ngOnInit(): Promise<void> {
    this.signupEmail = this.authService.getSignupEmail();
    if (!this.signupEmail) {
      this.authService.navigateToPage('auth/login');
    }
  }

  async resendEmail(): Promise<void> {
    if (this.signupEmail && !this.emailSent) {
      await this.authService.dispatchActionAndWait(new ResendConfirmEmailAction({ email: this.signupEmail }));
      this.emailSent = true;
    }
  }
}
