// eslint-disable-next-line no-shadow
export enum ScoringStatus {
  DRAFT = 'DRAFT',
  ANSWERS_LOCKED = 'ANSWERS_LOCKED',
  READY_FOR_VALIDATION = 'READY_FOR_VALIDATION',
  SUBMITTED_FOR_VALIDATION = 'SUBMITTED_FOR_VALIDATION',
  VALIDATION_IN_PROGRESS = 'VALIDATION_IN_PROGRESS',
  CHANGES_REQUESTED = 'CHANGES_REQUESTED',
  VALIDATED = 'VALIDATED',
  OUTDATED = 'OUTDATED',
  DISABLED = 'DISABLED',
  DISABLED_DRAFT = 'DISABLED_DRAFT',
}
