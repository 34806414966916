import { Component, OnInit } from '@angular/core';
import { AbstractQuestionDirective } from '../abstract-question.directive';
import { Concentration } from '../../models/answer.model';

@Component({
  selector: 'app-dropdown-multiple',
  templateUrl: './dropdown-multiple.component.html',
  styleUrls: ['./dropdown-multiple.component.scss'],
})
export class DropdownMultipleComponent extends AbstractQuestionDirective implements OnInit {
  concentrations: Concentration[] = [];

  ngOnInit(): void {
    super.ngOnInit();
    if (!this.answerModel.answer.concentrationList) {
      this.answerModel.answer.concentrationList = [];
    }
    while (this.answerModel.answer.concentrationList.length < 5) {
      this.answerModel.answer.concentrationList.push({});
    }
    this.concentrations = [...this.answerModel.answer.concentrationList];
  }

  onChange(): void {
    this.answerModel.answer.concentrationList = [...this.concentrations];
    for (let i = 0; i < this.answerModel.answer.concentrationList.length; i++) {
      if (!this.answerModel.answer.concentrationList[i].code) {
        this.answerModel.answer.concentrationList.splice(i, 1);
        i -= 1;
      }
    }
    this.answerChanged();
  }
}
