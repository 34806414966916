import { Component, Input } from '@angular/core';
import { ESGScore, ManagedEsgScore, RatioScore } from '../../models/scoring.model';

@Component({
  selector: 'app-eesg',
  templateUrl: './eesg.component.html',
  styleUrls: ['./eesg.component.scss'],
})
export class EesgComponent {
  @Input()
  score: ESGScore | undefined;

  @Input()
  managedScore!: ManagedEsgScore[] | undefined;

  @Input()
  indicators!: RatioScore;
}
