import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sidenav-icons',
  templateUrl: './sidenav-icons.component.svg',
  styleUrls: ['./sidenav-icons.component.scss'],
})
export class SidenavIconsComponent {
  @Input()
  icon!: string;

  @Input()
  color!: string;

  @Input()
  disabled = false;

  getOpacity(): number {
    if (this.disabled === undefined || !this.disabled) {
      return 1;
    }

    return 0.4;
  }
}
