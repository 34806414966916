import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../auth.service';
import { ConfirmForgotPasswordAction } from '../../xs/actions/confirm-forgot-password.action';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-reset-password-check',
  templateUrl: './reset-password-check.component.html',
  styleUrls: ['./reset-password-check.component.scss'],
})
export class ResetPasswordCheckComponent {
  showPasswordRequirements = false;

  showPassword = false;

  showPasswordConfirm = false;

  newPassword = '';

  confirmNewPassword = '';

  serverError = false;

  @ViewChild('password') password!: NgModel;

  @ViewChild('confirmPassword') confirmPassword!: NgModel;

  // source: https://stackoverflow.com/questions/58767980/aws-cognito-password-regex-specific-to-aws-cognito
  // with space removed, since we do not want to count that as a special character
  acceptedSymbols = '\\^$*.[\\]{}()?"!@#%&/\\\\,><\':;|_~`=+\\-';

  passwordRegex = '';

  showPasswordCheck = false;

  constructor(private activatedRoute: ActivatedRoute, private readonly authService: AuthService) {
    this.passwordRegex =
      '^(?=.*[A-Z])' +
      '(?=.*[a-z])' +
      '(?=.*[0-9])' +
      `(?=.*[${this.acceptedSymbols}])` +
      `[A-Za-z0-9${this.acceptedSymbols}]{8,256}$`;
  }

  togglePasswordRequirements(): void {
    this.showPasswordRequirements = !this.showPasswordRequirements;
  }

  async confirmCode(): Promise<void> {
    this.activatedRoute.queryParams.subscribe(async (params: Params) => {
      try {
        await this.authService.dispatchActionAndWait(
          new ConfirmForgotPasswordAction({
            email: decodeURIComponent(params.email),
            password: this.password.value,
            confirmationCode: params.code,
          }),
        );
      } catch (e) {
        this.serverError = true;
      }
    });
  }

  validatePassword(): void {
    if (!this.confirmPassword.errors?.required && this.password.value !== this.confirmPassword.value) {
      this.confirmPassword.control.setErrors({ confirmError: true });
    } else {
      this.confirmPassword.control.updateValueAndValidity();
    }
  }

  back(): void {
    this.authService.navigateToPage('login');
  }
}
