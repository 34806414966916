import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImpactScanModel } from '../../impact-scan-revamp/impact-scan-revamp.model';

export interface DialogDataModel {
  impactScans: ImpactScanModel[];
}

export interface DialogPrefillResultModel {
  continue: boolean;
  back: boolean;
  prefillEnabled?: boolean;
  selectedImpactScan?: string;
}

@Component({
  selector: 'app-impact-prefill-popup',
  templateUrl: './impact-prefill-popup.component.html',
  styleUrls: ['./impact-prefill-popup.component.scss'],
})
export class ImpactPrefillPopupComponent implements OnInit {
  continueDisabled = true;

  impactScans: ImpactScanModel[] = [];

  activeButton = '';

  prefillEnabled = false;

  selectedImpactScan = '';

  constructor(
    public dialogRef: MatDialogRef<ImpactPrefillPopupComponent>,
    private readonly ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataModel,
  ) {}

  ngOnInit(): void {
    this.impactScans = this.data.impactScans;
  }

  close(): void {
    this.ngZone.run(() => this.dialogRef.close(this.getDialogResult(false)));
  }

  back(): void {
    this.ngZone.run(() => this.dialogRef.close(this.getDialogResult(false, true)));
  }

  continue(): void {
    this.ngZone.run(() => this.dialogRef.close(this.getDialogResult(true)));
  }

  select(button: string): void {
    this.activeButton = button;
    this.continueDisabled = false;

    if (button === 'yes') {
      this.continueDisabled = true;
      this.prefillEnabled = true;
    } else {
      this.prefillEnabled = false;
    }
  }

  onSelectionChange($event: string): void {
    this.selectedImpactScan = $event.valueOf();
    this.continueDisabled = false;
  }

  private getDialogResult(continueValue: boolean, backValue?: boolean): DialogPrefillResultModel {
    return {
      continue: continueValue,
      back: backValue ?? false,
      prefillEnabled: this.prefillEnabled,
      selectedImpactScan: this.selectedImpactScan,
    };
  }
}
