import { Action } from '../../../shared/xs/actions';
import { NavigationTargetEnum } from '../navigation-target.enum';

export interface SetNavigationTargetActionPayload {
  navigationTarget: NavigationTargetEnum;
}

export class SetNavigationTargetAction implements Action {
  static readonly type = '[Dashboard] set navigation target';

  constructor(public payload: SetNavigationTargetActionPayload) {}
}
