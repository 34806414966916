import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Component } from '@angular/core';

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.scss'],
})
export class SsoLoginComponent {
  constructor(public oidcSecurityService: OidcSecurityService) {}

  login(): void {
    this.oidcSecurityService.authorize();
  }
}
