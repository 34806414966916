import { Action } from '../../../shared/xs/actions';
import { ScoringStatus } from '../../scoreboard';

export class GoToImpactScanRevampAction implements Action {
  static readonly type = '[Impact] Go to revamped impact scan';

  constructor(public payload: GoToImpactScanRevampActionPayload) {}
}

export interface GoToImpactScanRevampActionPayload {
  id: number;
  status: ScoringStatus;
}
