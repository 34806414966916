import { Component, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-impact-finish-popup',
  templateUrl: './impact-finish-popup.component.html',
  styleUrls: ['./impact-finish-popup.component.scss'],
})
export class ImpactFinishPopupComponent {
  constructor(public dialogRef: MatDialogRef<ImpactFinishPopupComponent>, private readonly ngZone: NgZone) {}

  close(): void {
    this.ngZone.run(() => this.dialogRef.close({ startEsg: false }));
  }

  startEsg(): void {
    this.ngZone.run(() => this.dialogRef.close({ startEsg: true }));
  }
}
