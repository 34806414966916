import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Co2CalculationListItemModel } from '../../model/co2-calculation-list-item.model';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SetCo2QuestionnaireIdAction } from '../../xs/actions/set-co2-questionnaire-id.action';
import { SetCo2ResultsIdAction } from '../../xs/actions/set-co2-results-id.action';
import { EeveryTranslateService } from '../../../shared/eevery.translate.service';
import { Co2CalculatorService } from '../../co2-calculator.service';

@Component({
  selector: 'app-co2-calculation-list-item',
  templateUrl: './co2-calculation-list-item.component.html',
  styleUrls: ['./co2-calculation-list-item.component.scss'],
})
export class Co2CalculationListItemComponent implements OnInit {
  @Input()
  calculation!: Co2CalculationListItemModel;

  @Output()
  resultsButtonClicked = new EventEmitter<void>();

  language = 'nl-NL';

  constructor(
    private store: Store,
    private router: Router,
    private translateService: EeveryTranslateService,
    public readonly co2CalculatorService: Co2CalculatorService,
  ) {}

  ngOnInit(): void {
    this.language = this.translateService.getLanguage() || 'nl-NL';
  }

  navigateToQuestionnaire(): void {
    this.store.dispatch(new SetCo2QuestionnaireIdAction({ id: this.calculation.id })).subscribe(() => {
      this.router.navigate(['dashboard/sme/co2calculator/questionnaire']);
    });
  }

  showResults(id: string): void {
    this.store.dispatch(new SetCo2ResultsIdAction({ id })).subscribe(() => {
      this.resultsButtonClicked.emit();
    });
  }
}
