import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AbstractQuestionDirective } from '../abstract-question.directive';
import { QuestionEnum } from '../question.enum';

@Component({
  selector: 'app-open-question',
  templateUrl: './open-question-text-number.component.html',
  styleUrls: ['./open-question-text-number.component.scss'],
})
export class OpenQuestionTextNumberComponent extends AbstractQuestionDirective implements OnInit {
  value: number | string | undefined;

  type = 'text';

  @ViewChild('openQuestionForm') form!: NgForm;

  validate(): void {
    // this.clearErrors();
    //
    // if (!this.validateCallBack(this.value)) {
    //   this.form.controls.openQuestionInput.setErrors(['invalid']);
    // }
  }

  clearErrors(): void {
    // this.form.controls.openQuestionInput.setErrors({ invalid: null });
    // this.form.controls.openQuestionInput.updateValueAndValidity();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (
      this.question.type === QuestionEnum.INPUT_NUMBER ||
      this.question.type === QuestionEnum.DROPDOWN_COUNTRY_WITH_INPUT ||
      this.question.type === QuestionEnum.MULTIPLE_CHOICE_NUMBER
    ) {
      this.type = 'number';
      this.value = this.answerModel.answer.value;
    } else {
      this.value = this.answerModel.answer.text;
    }
  }

  onBlur(): void {
    if (this.type === 'number') {
      // @ts-ignore
      this.answerModel.answer.value = this.value;
    } else {
      // @ts-ignore
      this.answerModel.answer.text = this.value;
    }
    this.answerChanged();
  }
}
