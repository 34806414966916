import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { RefreshTokenAction } from '../auth/xs/actions/refresh-token.action';
import { config } from '../../environments/config';
import { ErrorHandler } from './error.handler';
import { InterceptorService } from './interceptor.service';
import { LogoutAction } from '../auth';

/* eslint-disable  @typescript-eslint/no-explicit-any */
@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
  private static UNPROTECTED_APIS = [
    `${config.apiUrl}/auth/signup`,
    `${config.apiUrl}/auth/login`,
    `${config.apiUrl}/auth/signup/confirm`,
    `${config.apiUrl}/auth/password/forgot`,
    `${config.apiUrl}/auth/password/confirm`,
    `${config.apiUrl}/auth/refresh`,
  ];

  private isRefreshing = false;

  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private interceptorService: InterceptorService, private errorHandler: ErrorHandler) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const interceptedRequest = this.addToken(request, this.interceptorService.getAccessToken());

    return next.handle(interceptedRequest).pipe(
      catchError((error) => {
        if (error.status === 401) {
          if (error instanceof HttpErrorResponse) {
            if (error.error?.error === 'force_logout') {
              this.interceptorService.dispatchAction(new LogoutAction());
            }
            if (interceptedRequest.url === `${config.apiUrl}/auth/logout`) {
              return throwError(error);
            }
            if (!ErrorInterceptor.UNPROTECTED_APIS.includes(interceptedRequest.url)) {
              return this.handle401Error(interceptedRequest, next);
            }
          }
          if (interceptedRequest.url === `${config.apiUrl}/auth/login`) {
            return throwError(error);
          }
        }

        return this.errorHandler.catchError(error);
      }),
    );
  }

  private addToken(request: HttpRequest<any>, token: string | undefined): HttpRequest<any> {
    if (token && !ErrorInterceptor.UNPROTECTED_APIS.includes(request.url)) {
      return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
    }

    return request;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next('');
      try {
        this.interceptorService.dispatchActionAndWait(new RefreshTokenAction()).then(() => {
          const token = this.interceptorService.getAccessToken();

          this.refreshTokenSubject.next(token || '');
          this.isRefreshing = false;

          return next.handle(this.addToken(request, token));
        });
      } catch (e) {
        this.isRefreshing = false;
      }
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== ''),
      take(1),
      switchMap((jwt) => next.handle(this.addToken(request, jwt))),
    );
  }
}
