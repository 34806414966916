import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EsgAssessmentModel } from '../esg-prefill.model';

@Component({
  selector: 'app-esg-prefill-selector-dropdown',
  templateUrl: './esg-prefill-selector-dropdown.component.html',
  styleUrls: ['./esg-prefill-selector-dropdown.component.scss'],
  animations: [
    trigger('dropdownAnimation', [
      state('open', style({ opacity: 1 })),
      state('closed', style({ opacity: 0 })),
      transition('open <=> closed', [animate('0.15s')]),
    ]),
  ],
})
export class EsgPrefillSelectorDropdownComponent implements OnInit {
  @Input() esgAssessments: EsgAssessmentModel[] = [];

  @Input() title = '';

  @Input() openTitle = '';

  @Input() closedTitle = '';

  @Input() selectionLimit = 1;

  @Output() selectionChange = new EventEmitter<string>();

  isOpen = false;

  @Input()
  disabled = false;

  ignoreClickOutside = false;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.esgAssessments.forEach((esgAssessment) => {
      // eslint-disable-next-line no-param-reassign
      esgAssessment.selected = false;
    });
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (this.ignoreClickOutside) {
      this.ignoreClickOutside = false;
    } else if (!this.elementRef.nativeElement.contains(event.target) && this.isOpen) {
      this.isOpen = false;
      this.updateTitle();
    }
  }

  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
    this.updateTitle();
  }

  private updateTitle(): void {
    this.title = this.isOpen ? this.openTitle : this.closedTitle;
  }

  toggleImpactScan(id: string, event: MatCheckboxChange): void {
    if (!event.checked) {
      // eslint-disable-next-line no-param-reassign
      event.source.checked = true;
    }
    this.esgAssessments.forEach((esgAssessment) => {
      if (esgAssessment.id === id) {
        // eslint-disable-next-line no-param-reassign
        esgAssessment.selected = true;
        setTimeout(() => {
          this.closedTitle = `ESG Assessment - ${esgAssessment.reportingPeriodDate}`;
          this.isOpen = false;
          this.updateTitle();
        }, 160);
      } else {
        // eslint-disable-next-line no-param-reassign
        esgAssessment.selected = false;
      }
    });

    this.selectionChange.emit(id.toString());
  }
}
