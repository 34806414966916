import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Assessment, Topic } from '../../models/topic.model';
import { FilterService } from '../filter.service';

@Component({
  selector: 'app-multi-selector-dropdown',
  templateUrl: './multi-selector-dropdown.component.html',
  styleUrls: ['./multi-selector-dropdown.component.scss'],
  animations: [
    trigger('dropdownAnimation', [
      state('open', style({ opacity: 1 })),
      state('closed', style({ opacity: 0 })),
      transition('open <=> closed', [animate('0.15s')]),
    ]),
  ],
})
export class MultiSelectorDropdownComponent implements OnInit {
  @Input() topics: Topic[] = [];

  @Input() title = '';

  @Input() openTitle = '';

  @Input() closedTitle = '';

  @Input() selectionLimit = 5;

  @Output() selectionChange = new EventEmitter<Topic[]>();

  isOpen = false;

  isLimit = false;

  @Input()
  disabled = false;

  ignoreClickOutside = false;

  constructor(private elementRef: ElementRef, private filterService: FilterService) {}

  ngOnInit(): void {
    this.detectIncomingActions();
  }

  private detectIncomingActions(): void {
    this.filterService.currentDropdownStatus.subscribe((status) => {
      if (status) {
        this.openDropdown();
      }
    });
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (this.ignoreClickOutside) {
      this.ignoreClickOutside = false;
    } else if (!this.elementRef.nativeElement.contains(event.target) && this.isOpen) {
      this.isOpen = false;
      this.updateTitle();
    }
  }

  openDropdown(): void {
    this.isOpen = true;
    this.updateTitle();
    this.getSelectedAssessmentCount();
    this.ignoreClickOutside = true;
  }

  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
    this.updateTitle();
    this.getSelectedAssessmentCount();
  }

  private updateTitle(): void {
    this.title = this.isOpen ? this.openTitle : this.closedTitle;
  }

  toggleTopic(clickedTopic: Topic, event: MatCheckboxChange): void {
    const isChecked = event.checked;

    this.topics.forEach((topic) => {
      if (topic === clickedTopic) {
        // eslint-disable-next-line no-param-reassign
        topic.selected = isChecked;
        topic.assessments.forEach((assessment) => {
          // eslint-disable-next-line no-param-reassign
          assessment.selected = isChecked;
          if (this.selectionLimit < this.getSelectedAssessmentCount()) {
            // eslint-disable-next-line no-param-reassign
            assessment.selected = false;
          }
        });
      }
    });

    this.updateTopicStates();
    this.selectionChange.emit(this.topics);
  }

  toggleAssessment(clickedTopic: Topic, clickedAssessment: Assessment, event: MatCheckboxChange): void {
    const isChecked = event.checked;

    this.topics.forEach((topic) => {
      if (topic === clickedTopic) {
        topic.assessments.forEach((assessment) => {
          if (assessment === clickedAssessment) {
            // eslint-disable-next-line no-param-reassign
            assessment.selected = isChecked;

            if (this.selectionLimit < this.getSelectedAssessmentCount()) {
              // eslint-disable-next-line no-param-reassign
              event.source.checked = false;
              // eslint-disable-next-line no-param-reassign
              assessment.selected = false;
            }
          }
        });
      }
    });

    this.updateTopicStates();
    this.selectionChange.emit(this.topics);
  }

  updateTopicStates(): void {
    this.topics.forEach((topic) => {
      const allSelected = topic.assessments.every((a) => a.selected);
      const someSelected = topic.assessments.some((a) => a.selected);

      // eslint-disable-next-line no-param-reassign
      topic.selected = allSelected;
      // eslint-disable-next-line no-param-reassign
      topic.indeterminate = !allSelected && someSelected;
    });
  }

  getSelectedAssessmentCount(): number {
    let count = 0;

    this.topics.forEach((topic) => {
      count += topic.assessments.filter((a) => a.selected).length;
    });

    this.isLimit = count >= this.selectionLimit;

    return count;
  }

  checkLimit(selected: boolean, indeterminate: boolean): boolean {
    return this.isLimit && !selected && !indeterminate;
  }
}
