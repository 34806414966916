import { Component, OnInit } from '@angular/core';
import { RelevantThemeModel } from '../../../../../shared/relevant-theme/relevant-theme.model';
import { QuestionService } from '../../../../../shared/questions';

@Component({
  selector: 'app-double-materiality',
  templateUrl: './double-materiality.component.html',
  styleUrls: ['./double-materiality.component.scss'],
})
export class DoubleMaterialityComponent implements OnInit {
  disclosureList: RelevantThemeModel[][] = [];

  constructor(private readonly questionService: QuestionService) {}

  async ngOnInit(): Promise<void> {
    this.disclosureList = (await this.questionService.getDoubleMateriality()).doubleMateriality;
  }
}
