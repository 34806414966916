import { AbstractService } from '../services/abstract.service';
import { UserModel } from '../../dashboard/profile/models/user.model';
import { InviteUserModel } from '../../dashboard/profile/models/invite-user.model';
import { Observable } from 'rxjs';
import { SupportRequestModel } from '../help/help.model';
import { Injectable } from '@angular/core';
import { UsersState } from './xs/users.state';

@Injectable({ providedIn: 'root' })
export class UsersService extends AbstractService {
  // eslint-disable-next-line @typescript-eslint/ban-types
  inviteUsers(users: InviteUserModel[], companyType: string): Observable<Object> {
    /* eslint-disable @typescript-eslint/indent */
    switch (companyType) {
      default:
      case 'sme':
        return this.inviteUsersSme(users);
      case 'enterprise':
        return this.inviteUsersEnterprise(users);
    }
    /* eslint-enable @typescript-eslint/indent */
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  inviteUsersSme(users: InviteUserModel[]): Observable<Object> {
    return this.http.post(`${this.apiBaseUrl}/companies/invite-more`, users);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  inviteUsersEnterprise(users: InviteUserModel[]): Observable<Object> {
    return this.http.post(`${this.apiBaseUrl}/enterprises/invite-more`, users);
  }

  async deleteUser(userId: string): Promise<void> {
    await this.http.delete(`${this.apiBaseUrl}/users/${userId}/delete`).toPromise();
  }

  async getOtherUsersOf(companyType: string): Promise<UserModel[]> {
    /* eslint-disable @typescript-eslint/indent */
    switch (companyType) {
      default:
      case 'sme':
        return this.getOtherUsersOfCurrentCompany();
      case 'enterprise':
        return this.getOtherUsersOfCurrentEnterprise();
    }
    /* eslint-enable @typescript-eslint/indent */
  }

  async getOtherUsersOfCurrentCompany(): Promise<UserModel[]> {
    const result = await this.http.get(`${this.apiBaseUrl}/users/current-company/other-users`).toPromise();

    return result as UserModel[];
  }

  async getAllUsersOfCurrentCompany(): Promise<UserModel[]> {
    const result = await this.http.get(`${this.apiBaseUrl}/users/current-company/all-users`).toPromise();

    return result as UserModel[];
  }

  async getOtherUsersOfCurrentEnterprise(): Promise<UserModel[]> {
    const result = await this.http.get(`${this.apiBaseUrl}/users/current-enterprise/other-users`).toPromise();

    return result as UserModel[];
  }

  async sendHelpRequest(payload: SupportRequestModel): Promise<void> {
    await this.http.post(`${this.apiBaseUrl}/support/help`, payload).toPromise();
  }

  getUsersToInvite(): InviteUserModel[] {
    return this.store.selectSnapshot(UsersState.inviteUsers);
  }
}
