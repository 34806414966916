/* eslint-disable */
import {
  asNativeElements,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ControlValueAccessor, FormBuilder, NgControl, FormGroup, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DecimalPipe } from '@angular/common';
import { Store } from '@ngxs/store';
import { getDigitsInfo } from './currency-input-utils';
import { FocusMonitor } from '@angular/cdk/a11y';
import '@angular/common/locales/global/nl';

@Component({
  selector: 'app-currency-input',
  templateUrl: './currency-input.component.html',
  providers: [{ provide: MatFormFieldControl, useExisting: CurrencyInputComponent }, DecimalPipe],
  styleUrls: ['./currency-input.component.scss'],
})
export class CurrencyInputComponent
implements MatFormFieldControl<number | string>, ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  label!: string;

  private static nextId = 0;

  private readonly DOT = '.';

  private readonly COMMA = ',';

  private thousandSeparator = this.COMMA;

  private readonly euro = '€';

  private readonly pound = '£';

  currentCurrency = this.pound;

  private locale = 'nl-NL';

  private allowedCharacters: RegExp = new RegExp('');

  private localSubscription!: Subscription;

  private focusSubscription!: Subscription;

  private formValueSubscription!: Subscription;

  @HostBinding()
  public readonly id = `caple-currency-input-${CurrencyInputComponent.nextId++}`;

  @HostBinding('attr.aria-describedby')
  public describedBy = '';

  public conrolType = 'caple-currency-input';

  public readonly stateChanges: Observable<void> = new Subject();
  readonly autofilled!: boolean;
  readonly controlType!: string;
  readonly userAriaDescribedBy!: string;
  readonly placeholder!: string;

  @ViewChild('input', { static: false })
  public amountField!: ElementRef;

  public form: FormGroup;

  @Input()
  public country!: string | null;

  private _value: number | null = null;

  @Output()
  valueOutputEvent = new EventEmitter<number | null>();

  private _required = false;

  private _disabled = false;

  private _focused = false;

  private _errorState!: boolean | null;

  @Output()
  errorOutputEvent = new EventEmitter<boolean>();

  @Input()
  public set value(value: number | null) {
    if (typeof value !== 'number') {
      value = this.parseNumber(value);
    }

    this._value = value;
    this.form.patchValue({ amount: value }, { emitEvent: true });
    this.updateNumberView();
  }

  public get value(): number | null {
    return this.parseNumber(this._value);
  }

  @Input()
  public set required(req) {
    this._required = coerceBooleanProperty(req);
  }

  public get required() {
    return this._required;
  }

  private showError = false;

  @Input()
  public set disabled(req) {
    this._disabled = coerceBooleanProperty(req);
    if (this._disabled) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
  }

  public get disabled() {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }

    return this._disabled;
  }

  @Input()
  public set focused(val) {
    if (this._focused && val === false) {
      this._onTouched();
    }

    this._focused = val;

    this.updateNumberView();
  }

  public get focused() {
    return this._focused;
  }

  private _onChange = (val: any) => {};

  private _onTouched = () => {};

  constructor(formBuilder: FormBuilder,
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef,
    private store: Store,
    private decimalPipe: DecimalPipe,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.focusSubscription = focusMonitor.monitor(elementRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this._value = this.parseNumber(this.form.value.amount);
    });

    this.form = formBuilder.group({ amount: '' });
    if (this.required) {
      this.form.setValidators(Validators.required);
    }

    this.formValueSubscription = this.form.valueChanges.subscribe((formValues) => {
      this._value = this.parseNumber(formValues.amount);
      this.valueOutputEvent.emit(this._value);
      this._onChange(this._value);
      this.sendError();
      this.initializeInternationalization();
    });
  }

  private sendError(): void {
    const input = this.form.get('amount')

    if (input?.errors?.required) {
      this.errorOutputEvent.emit(true);
    } else {
      this.errorOutputEvent.emit(false)
    }
  }

  ngOnInit(): void {
    this.initializeInternationalization();
  }

  public initializeInternationalization(): void {
    switch (this.country?.toUpperCase()) {
      case 'GB':
        this.thousandSeparator = this.COMMA;
        this.allowedCharacters = new RegExp(`^[0-9-${this.DOT}]$`);
        this.currentCurrency = this.pound;
        this.locale = 'en-GB';
        break;
      default:
        this.thousandSeparator = this.DOT;
        this.allowedCharacters = new RegExp(`^[0-9-${this.COMMA}]$`);
        this.currentCurrency = this.euro;
        this.locale = 'nl-NL';
    }
  }

  public get empty() {
    const { amount } = this.form.value;

    return amount === null || amount === undefined || amount === '';
  }

  @HostBinding('class.floating')
  public get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  public onKeyPress(event: KeyboardEvent) {
    if (!this.allowedCharacters.test(event.key)) {
      event.preventDefault();
    }
  }

  public get errorState(): boolean {
    const currentErrorState = this.ngControl && this.ngControl.touched && this.ngControl.errors != null;

    if (currentErrorState != this._errorState) {
      setTimeout(() => console.log());
      this._errorState = currentErrorState;
    }

    // @ts-ignore
    return currentErrorState;
  }

  public onContainerClick(event: MouseEvent): void {
    this.elementRef.nativeElement.querySelector('input').focus();
  }

  public setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  private parseNumber(input: string | number | null): number | null {
    if (input === null || input === undefined) {
      return null;
    }

    if (typeof input === 'number') {
      return input;
    }

    if (this.thousandSeparator === this.DOT) {
      input = input.replace(',', '.');
    }

    const result = Number.parseFloat(Number.parseFloat(input).toFixed(2));

    if (isNaN(result)) {
      return 0;
    }

    return result;
  }

  ngOnDestroy(): void {
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);

    if (this.localSubscription) {
      this.localSubscription.unsubscribe();
    }

    if (this.focusSubscription) {
      this.focusSubscription.unsubscribe();
    }

    if (this.focusSubscription) {
      this.formValueSubscription.unsubscribe();
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  private updateNumberView(): void {
    setTimeout(() => {
      const { nativeElement } = this.amountField;

      if (this.focused) {
        if (!this.value || (this.value && this.value < 0.01)) {
          nativeElement.value = '';
          return;
        }
        const oldValue = nativeElement.value;
        const carrotPosition = nativeElement.selectionStart;

        nativeElement.value = this.value?.toFixed(2);
        if (this.thousandSeparator === this.DOT) {
          nativeElement.value = nativeElement.value.replace('.', ',');
        }

        const relevantValuePart = oldValue.toString().substring(0, carrotPosition);
        const amountOfThousandSeparators = relevantValuePart.split(this.thousandSeparator).length - 1;
        const newCaretPosition = carrotPosition - amountOfThousandSeparators;

        nativeElement.setSelectionRange(newCaretPosition, newCaretPosition);
      } else {
        const value = this.parseNumber(this.value);
        const digitInfo = getDigitsInfo(value);

        nativeElement.value = this.decimalPipe.transform(value, digitInfo, this.locale);
      }
    });
  }

}
