/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { InfoModel } from '../../shared/info/info.model';
import { Upload } from '../../shared/questions';
import { saveAs } from 'file-saver';
import { OnboardingPageDirective } from '../onboarding.page.directive';
import { ProductModel } from '../../dashboard/profile/models/product.model';
import { ServiceModel } from '../../dashboard/profile/models/service.model';

export interface Employees {
  numberOfEmployees: number;
}

@Component({
  selector: 'app-onboarding-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent extends OnboardingPageDirective implements OnInit {
  showError = false;

  productsInfo: InfoModel = { oldGriStandards: ['2-6', '3-1'] };

  servicesInfo: InfoModel = { oldGriStandards: ['2-6', '3-1'] };

  async ngOnInit(): Promise<void> {
    if (!this.company.products) {
      this.company.products = [];
    }
    if (!this.company.services) {
      this.company.services = [];
    }

    /* eslint-disable */
    this.productsInfo.description = { explanation: await this.translateService.get('onboarding.products.product.desc') };
    this.servicesInfo.description = { explanation: await this.translateService.get('onboarding.products.service.desc') };
    /* eslint-enable */
  }

  addProduct(): void {
    this.company.products.push({
      certificate: null,
      description: '',
      name: '',
      certifications: [],
    });
    this.onAnswerAdded();
  }

  addService(): void {
    this.company.services.push({
      certificate: null,
      description: '',
      name: '',
      certifications: [],
    });
    this.onAnswerAdded();
  }

  removeProduct(index: number): void {
    this.company.products.splice(index, 1);
    this.onAnswerAdded();
  }

  removeService(index: number): void {
    this.company.services.splice(index, 1);
    this.onAnswerAdded();
  }

  validateForm(formFieldsValid: boolean | null): boolean {
    const productsValid = this.validateCertificates(this.company.products);
    const servicesValid = this.validateCertificates(this.company.services);

    return !!formFieldsValid && productsValid && servicesValid;
  }

  validateCertificates(products: ProductModel[] | ServiceModel[]): boolean {
    let allValid = true;

    for (const product of products) {
      if (product.certificate === null) {
        product.certificateError = true;
        allValid = false;
      }
    }

    return allValid;
  }

  setProductHasCertificate(index: number, value: boolean): void {
    this.company.products[index].certificate = value;
    this.company.products[index].certificateError = false;
    this.onAnswerAdded();
  }

  setServiceHasCertificate(index: number, value: boolean): void {
    this.company.services[index].certificate = value;
    this.company.services[index].certificateError = false;
    this.onAnswerAdded();
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  async setProductCertificates(event: any, index: number): Promise<void> {
    const result = await this.questionService.uploadFiles(event.target.files);

    this.company.products[index].certifications = this.company.products[index].certifications?.concat(result);
    this.onAnswerAdded();
  }

  async setServiceCertificates(event: any, index: number): Promise<void> {
    const result = await this.questionService.uploadFiles(event.target.files);

    this.company.services[index].certifications = this.company.services[index].certifications?.concat(result);
    this.onAnswerAdded();
  }
  /* eslint-enable */

  downloadCert(file: Upload): void {
    this.questionService.getFile(file.id).then((blob) => {
      if (blob) {
        saveAs(blob, file.filename);
      }
    });
  }

  deleteProductCert(product: number, file: number): void {
    this.company.products[product].certifications?.splice(file, 1);
    this.onAnswerAdded();
  }

  deleteServiceCert(service: number, file: number): void {
    this.company.services[service].certifications?.splice(file, 1);
    this.onAnswerAdded();
  }

  productsTrack(index: number): number {
    return index;
  }
}
