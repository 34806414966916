import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  signupEmail: string | undefined;

  url = '';

  bothPoliciesAccepted = false;

  constructor(private readonly authService: AuthService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.signupEmail = this.authService.getSignupEmail();
  }

  async goToStripe(): Promise<void> {
    if (this.signupEmail) {
      this.authService.startLoading();
      this.authService
        .toPayment(this.signupEmail)
        .then((result) => {
          if (result) {
            window.open(result, '_blank');
          }
        })
        .finally(() => this.authService.stopLoading());
    }
  }
}
