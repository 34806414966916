import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { config } from '../../../environments/config';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthState, LoginActionResponse } from '../xs';
import { CheckDashboardAction } from '../../dashboard';
import { CheckAssessmentsAction } from '../../dashboard/xs/actions/assessments-checked.action';
import { CheckResultsAction } from '../../dashboard/xs/actions/results-checked.action';
import { CheckImprovementAction } from '../../dashboard/xs/actions/improvement-checked.action';
import { CheckEnterpriseDashboardAction } from '../../enterprise/xs/actions/dashboard-checked.action';
import { CheckPortfolioAction } from '../../enterprise/xs/actions/portfolio-checked.action';
import { CheckViewModeAction } from '../../enterprise/xs/actions/view-mode-checked.action';
import { CheckCo2Action } from '../../co2-calculator/xs/actions/check-co2.action';
import { LoginSsoAction, LoginSsoActionPayload } from '../xs/actions/login-sso.action';
import { SsoModel } from './model/sso.model';
import { SsoUserModel } from './model/sso-user.model';
import { AuthService } from '../auth.service';
import { LogoutSuccessAction } from '../xs/actions/logout-success.action';
import { SsoPayloadModel } from './model/sso-payload.model';

@Injectable({ providedIn: 'root' })
export class SsoService {
  protected apiBaseUrl = config.apiUrl;

  private provider = '';

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  validateSsoBackend(): Observable<LoginActionResponse> {
    return this.http.get<LoginActionResponse>(`${this.apiBaseUrl}/auth/${this.getProviderFromPath()}/login-sso`, {
      headers: {
        accessToken: `${this.oidcSecurityService.getAccessToken()}`,
        provider: this.getProviderFromPath() ?? '',
      },
    });
  }

  getProviderFromPath(): string | null {
    const urlPath = window.location.pathname.split('/');
    // Assuming the format is app.com/{provider}/auth/login

    if (urlPath.length > 1) {
      const provider = urlPath[1];
      // Check if the provider is one of your allowed providers
      const allowedProviders = ['mha', 'provider_2'];

      if (allowedProviders.includes(provider)) {
        this.provider = provider;

        return provider;
      }
    }

    return null;
  }

  getProvider(): string {
    return this.provider;
  }

  checkSsoFromPath(): boolean {
    return this.getProviderFromPath() !== null;
  }

  isSsoUser(): boolean {
    return this.provider !== '';
  }

  isAuthenticated(): boolean {
    this.oidcSecurityService.checkAuth().subscribe((auth) => auth.isAuthenticated);

    return this.oidcSecurityService.isAuthenticated();
  }

  async activate(loginResponse: LoginActionResponse): Promise<void> {
    try {
      const ssoModel: SsoModel = {
        loginResponse,
        provider: this.getProviderFromPath(),
      };

      await this.authService.dispatchActionAndWait(new LoginSsoAction(ssoModel as LoginSsoActionPayload));

      this.authService.dispatchAction(new CheckDashboardAction({ hasBeenChecked: false }));
      this.authService.dispatchAction(new CheckAssessmentsAction({ hasBeenChecked: false }));
      this.authService.dispatchAction(new CheckResultsAction({ hasBeenChecked: false }));
      this.authService.dispatchAction(new CheckImprovementAction({ hasBeenChecked: false }));
      this.authService.dispatchAction(new CheckEnterpriseDashboardAction({ hasBeenChecked: false }));
      this.authService.dispatchAction(new CheckPortfolioAction({ hasBeenChecked: false }));
      this.authService.dispatchAction(new CheckViewModeAction({ hasBeenChecked: false }));
      this.authService.dispatchAction(new CheckCo2Action({ hasBeenChecked: false }));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  logout(): void {
    this.oidcSecurityService.logoff();
    this.oidcSecurityService.checkAuth().subscribe((auth) => {
      if (!auth.isAuthenticated) {
        this.logoutSession();
      }
    });
  }

  logoutSession(): void {
    this.authService.logout().finally(() => {
      this.authService.navigateToPage(`/${this.provider}/auth/login`);
      this.oidcSecurityService.logoffLocal();
      this.logoutLocalSession();
    });
  }

  logoutLocalSession(): void {
    this.authService.dispatchActionAndWait(new LogoutSuccessAction());
  }

  getSsoPayload(): SsoPayloadModel {
    return this.oidcSecurityService.getPayloadFromIdToken();
  }

  getUserData(): SsoUserModel {
    const payloadFromIdToken = this.authService.store.selectSnapshot(AuthState.ssoResponsePayload);

    let firstName: string;
    let lastName: string;

    const name = (payloadFromIdToken?.name ?? '') as string;

    // Check if the name is in the "LastName, FirstName" format
    if (name.includes(',')) {
      [lastName, firstName] = name.split(',').map((str) => str.trim());
    } else {
      // Assume the name is in "FirstName LastName" format
      const [first, ...last] = name.split(' ');

      firstName = first;
      lastName = last.join(' ');
    }

    return {
      enterpriseName: '',
      firstName,
      lastName,
      email: payloadFromIdToken?.preferred_username,
      language: '',
    };
  }
}
