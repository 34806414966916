import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pop-up-header',
  templateUrl: './pop-up-header.component.html',
  styleUrls: ['./pop-up-header.component.scss'],
})
export class PopUpHeaderComponent {
  @Input()
  title?: string;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  close = new EventEmitter<void>();

  onClose(): void {
    this.close.emit();
  }
}
