import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Directive()
export abstract class CompanyCardDirective<T> implements OnInit {
  @Input()
  prefilledData!: T;

  data!: T;

  @Output()
  submitted = new EventEmitter<T>();

  edit = false;

  ngOnInit(): void {
    this.data = JSON.parse(JSON.stringify(this.prefilledData));
  }

  submit(): void {
    this.submitted.emit(this.data);
    this.disableEdit();
    this.prefilledData = JSON.parse(JSON.stringify(this.data));
  }

  reset(): void {
    this.data = JSON.parse(JSON.stringify(this.prefilledData));
    this.disableEdit();
  }

  disableEdit(): void {
    this.edit = false;
  }
}
