import { Component } from '@angular/core';
import { GovernanceSection } from '../company.models';
import { CompanyCardDirective } from '../company-card.directive';
import { ProfileService } from '../../../dashboard';

@Component({
  selector: 'app-governance-card',
  templateUrl: './governance-card.component.html',
  styleUrls: ['./governance-card.component.scss'],
})
export class GovernanceCardComponent extends CompanyCardDirective<GovernanceSection> {
  constructor(public profileService: ProfileService) {
    super();
  }
}
