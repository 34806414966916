import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Topic } from '../models/topic.model';
import { OptionItem } from '../shared/slider-toggle/slider-group.component';
import { ScoreboardService } from '../scoreboard.service';

@Component({
  selector: 'app-eu-taxonomy',
  templateUrl: './eu-taxonomy.component.html',
  styleUrls: ['./eu-taxonomy.component.scss'],
})
export class EUTaxonomyComponent implements OnInit {
  @Input()
  scores: Topic[] = [];

  @Input()
  companyName = '';

  @Output()
  placeholderButtonClick = new EventEmitter<void>();

  compliantList!: boolean[];

  sliderItems!: OptionItem[];

  sliderPositions: (number | null)[] = [0, null];

  constructor(private scoreboardService: ScoreboardService) {}

  ngOnInit(): void {
    this.scoreboardService.currentTopic.subscribe((scores) => {
      if (scores) {
        this.scores = scores;
        this.initScores();
      }
    });
  }

  initScores(): void {
    this.sliderItems = [];
    this.compliantList = [];

    if (this.scores) {
      this.scores
        .filter((topic) => topic.selected || topic.indeterminate)
        .reverse()
        .forEach((score) => {
          score.assessments
            .filter((assessment) => assessment.selected)
            .reverse()
            .forEach((assessment) => {
              let compliant = false;

              if (assessment.scoring?.deprecatedLatest) {
                compliant = assessment.scoring.deprecatedScoring.scores.EU_TAXONOMY?.compliant ?? false;
              } else if (assessment.scoring?.scoring) {
                compliant = assessment.scoring.scoring.euTax.compliant ?? false;
              }

              this.compliantList.push(compliant);
              this.sliderItems.push({ alias: assessment.name, id: assessment.id });
            });
        });
      this.sliderPositions = [this.sliderItems.length - 1, null];
    }
  }

  toggleResults(event: { sliderId: string; sliderPositions: (number | null)[] }): void {
    this.sliderPositions = event.sliderPositions;
  }

  toggleDropdown(): void {
    this.placeholderButtonClick.emit();
  }
}
