import { Action } from '../../../shared/xs/actions';
import { QuestionnaireType } from '../../scoreboard/models/questionnaire.type';

export class RefreshValidationAction implements Action {
  static readonly type = '[Dashboard] Refresh questionnaire validation';

  constructor(public payload: RefreshValidationActionPayload) {}
}

export interface RefreshValidationActionPayload {
  id: string;
  type: QuestionnaireType;
}
