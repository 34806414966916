import { Component, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-welcome-popup',
  templateUrl: './welcome-popup.component.html',
  styleUrls: ['./welcome-popup.component.scss'],
})
export class WelcomePopupComponent {
  constructor(public dialogRef: MatDialogRef<WelcomePopupComponent>, private readonly ngZone: NgZone) {}

  close(): void {
    this.ngZone.run(() => this.dialogRef.close());
  }
}
