import { Component } from '@angular/core';
import { ImpactScanPageDirective } from '../abstract-impact-scan-page';
import { InfoModel } from '../../../../shared/info/info.model';
import { Supplier } from '../impact-scan-revamp.model';

@Component({
  selector: 'app-stakeholders',
  templateUrl: './stakeholders.component.html',
  styleUrls: ['./stakeholders.component.scss'],
})
export class StakeholdersComponent extends ImpactScanPageDirective {
  customersInfo: InfoModel = {
    description: { explanation: 'impact_scan.stakeholders.suppliers.info' },
    oldGriStandards: ['2-6'],
  };

  stakeholderInfo: InfoModel = {
    description: { explanation: 'impact_scan.stakeholders.stakeholders.info' },
    oldGriStandards: ['2-29'],
  };

  hasOtherStakeholderInfo: InfoModel = { oldGriStandards: ['2-29'] };

  otherStakeholderInfo: InfoModel = {
    description: { explanation: 'impact_scan.stakeholders.other_stakeholders.info' },
    oldGriStandards: ['2-29'],
  };

  stakeholderNames = [
    'CUSTOMERS',
    'SUPPLIERS_OR_SUBCONTRACTORS',
    'EMPLOYEES',
    'SHAREHOLDERS',
    'WORKERS',
    'BUSINESS_PARTNERS',
  ];

  otherStakeholderNames = [
    'CIVIL_SOCIETY_ORGINASATIONS',
    'CONSUMERS',
    'NGOS',
    'TRADE_UNIONS',
    'GOVERNMENTS',
    'LOCAL_COMMUNITIES',
  ];

  addStakeholder(): void {
    if (this.impactScan?.suppliers.length >= 5) {
      return;
    }

    const stakeholderRevamp: Supplier = {
      countryCode: '',
      name: '',
    };

    this.impactScan?.suppliers?.push(stakeholderRevamp);
    this.onAnswerChanged();
  }

  removeStakeholder(index: number): void {
    this.impactScan?.suppliers.splice(index, 1);
    this.onAnswerChanged();
  }

  stakeholderClicked(value: string): void {
    const index = this.impactScan.stakeholders.findIndex((stakeholder) => stakeholder === value);

    if (index >= 0) {
      this.impactScan.stakeholders.splice(index, 1);
    } else {
      this.impactScan.stakeholders.push(value);
    }
    this.onAnswerChanged();
  }

  otherStakeholderClicked(value: string): void {
    const index = this.impactScan.otherStakeholders.findIndex((stakeholder) => stakeholder === value);

    if (index >= 0) {
      this.impactScan.otherStakeholders.splice(index, 1);
    } else {
      this.impactScan.otherStakeholders.push(value);
    }
    this.onAnswerChanged();
  }

  setHasOtherStakeholders(value: boolean): void {
    this.impactScan.hasOtherStakeholders = value;
    this.onAnswerChanged();
  }
}
