import { Component } from '@angular/core';
import { format, isOperator, Operator, rpn, yard } from './calculator-helper';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
})
export class CalculatorComponent {
  showCalculator = false;

  tokens: string[] = [];

  showResult = false;

  toggle(): void {
    this.showCalculator = !this.showCalculator;
  }

  insertChar(character: string): void {
    const { lastToken } = this;
    const doubleMin = lastToken === '-' && isOperator(this.beforeLastToken);

    if (lastToken === undefined || (isOperator(lastToken) && !doubleMin)) {
      if (character === '.') {
        // eslint-disable-next-line no-param-reassign
        character = `0${character}`;
      }

      this.tokens.push(character);
    } else if (this.showResult) {
      this.tokens = [character];
    } else {
      this.tokens[this.tokens.length - 1] = lastToken + character;
    }

    this.showResult = false;
  }

  togglePlusMinus(): void {
    if (this.showResult) {
      this.tokens = [this.input];
      this.showResult = false;
    }

    const { lastToken } = this;

    if (isOperator(lastToken)) {
      return;
    }

    if (lastToken.startsWith('-')) {
      this.tokens[this.tokens.length - 1] = this.tokens[this.tokens.length - 1].slice(1, lastToken.length);
    } else {
      this.tokens[this.tokens.length - 1] = `-${this.tokens[this.tokens.length - 1]}`;
    }
  }

  get lastToken(): string {
    return this.tokens[this.tokens.length - 1];
  }

  get beforeLastToken(): string {
    return this.tokens[this.tokens.length - 2];
  }

  get input(): string {
    if (this.showResult) {
      try {
        return format(rpn(yard(this.tokens)).toString());
      } catch (e) {
        return 'Err';
      }
    }

    return format(
      this.tokens
        .slice()
        .reverse()
        .find((t) => !isOperator(t)) || '0',
    );
  }

  get formattedTokens(): string {
    return this.tokens.map(format).join(' ').replace(/\*/g, 'x') || '0';
  }

  reset(): void {
    this.tokens = [];
    this.showResult = false;
  }

  clearInput(): void {
    if (this.showResult) {
      return;
    }
    if (!isOperator(this.lastToken)) {
      this.tokens.pop();
    }
  }

  evaluate(): void {
    // repeat last action
    if (this.showResult && this.tokens.length >= 2) {
      this.tokens = this.tokens.concat(this.tokens.slice(-2));
    }

    this.showResult = true;
  }

  execOperator(operator: Operator): void {
    // ANS support
    if (this.showResult) {
      this.tokens = [rpn(yard(this.tokens)).toString()];
    }

    if (!this.lastToken && operator !== '(') {
      this.tokens.push('0');
    }

    if (isOperator(this.lastToken)) {
      this.tokens[this.tokens.length - 1] = operator;
    } else {
      this.tokens.push(operator);
    }

    this.showResult = false;
  }

  // KEYBOARD SUPPORT
  onKeyDown(event: KeyboardEvent): void {
    if (!this.showCalculator) {
      return;
    }

    const key = event.key.toLowerCase();

    event.preventDefault();

    if (key === 'c') {
      this.reset();
    } else if (key === 'backspace') {
      this.clearInput();
    } else if (key === ',' || key === '.') {
      this.insertChar('.');
      // eslint-disable-next-line no-restricted-globals
    } else if (!isNaN(parseInt(key, 10))) {
      this.insertChar(key);
    } else if (key === 'enter') {
      this.evaluate();
    } else if (isOperator(key)) {
      this.execOperator(key);
    }
  }
}
