import { Injectable } from '@angular/core';
import { AbstractService } from '../shared/services/abstract.service';
import { Observable } from 'rxjs';
import { StartCalculationResponseModel } from './model/start-calculation-response.model';
import { Co2CalculationListItemModel } from './model/co2-calculation-list-item.model';
import { Co2CalculatorState } from './xs/co2-calculator.state';
import { Co2QuestionnaireAnswersModel } from './model/co2-questionnaire-answers.model';
import { Co2QuestionnaireModel } from './model/co2-questionnaire.model';
import { Co2CalculationResultModel } from './model/co2-calculation-result.model';

@Injectable({ providedIn: 'root' })
export class Co2CalculatorService extends AbstractService {
  startCalculation(reportingPeriodStart: Date): Observable<StartCalculationResponseModel> {
    const body = { reportingPeriodStart };

    return this.http.post<StartCalculationResponseModel>(`${this.apiBaseUrl}/co2calculator`, body);
  }

  getCo2Calculations(): Co2CalculationListItemModel[] {
    return this.store.selectSnapshot(Co2CalculatorState.co2Calculations);
  }

  getCurrentQuestionnaireId(): string | undefined {
    return this.store.selectSnapshot(Co2CalculatorState.co2QuestionnaireId);
  }

  isFirstChecked(): boolean {
    return this.store.selectSnapshot(Co2CalculatorState.isFirstCalculationCheck);
  }

  getQuestionnaireFromBackend(id: string): Observable<Co2QuestionnaireModel> {
    return this.http.get<Co2QuestionnaireModel>(`${this.apiBaseUrl}/co2calculator/questionnaire/${id}`);
  }

  getCo2CalculationsFromBackend(): Observable<Co2CalculationListItemModel[]> {
    return this.http.get<Co2CalculationListItemModel[]>(`${this.apiBaseUrl}/co2calculator`);
  }

  updateAnswersOnBackend(id: string, answers: Co2QuestionnaireAnswersModel): Observable<unknown> {
    return this.http.put(`${this.apiBaseUrl}/co2calculator`, { id, answers });
  }

  finishCalculation(calculationId: string): Observable<unknown> {
    return this.http.post(`${this.apiBaseUrl}/co2calculator/finish`, { calculationId });
  }

  getResults(): Co2CalculationResultModel | undefined {
    return this.store.selectSnapshot(Co2CalculatorState.co2Results);
  }

  getLatestResultsFromBackend(): Observable<Co2CalculationResultModel> {
    return this.http.get<Co2CalculationResultModel>(`${this.apiBaseUrl}/co2calculator/results`);
  }

  getResultsFromBackend(calculationId: string): Observable<Co2CalculationResultModel> {
    return this.http.get<Co2CalculationResultModel>(`${this.apiBaseUrl}/co2calculator/results/${calculationId}`);
  }
}
