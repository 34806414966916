import { Action } from '../../../shared/xs/actions';

// eslint-disable-next-line no-shadow
export enum AssessmentSource {
  ASSESSMENT_LIST = 'ASSESSMENT_LIST',
  DASHBOARD_WIDGET = 'DASHBOARD_WIDGET',
  IMPACT_SCAN = 'IMPACT_SCAN',
}

export interface StartScoringPayload {
  impactScanId?: number;
  source: AssessmentSource;
}

export class StartScoringAction implements Action {
  static readonly type = '[Dashboard] Start scoring';

  constructor(public payload: StartScoringPayload) {}
}

export interface StartScoringResponse {
  id: string;
}
