import { Action } from '../../../../../shared/xs/actions';

export interface GoToProfileActionPayload {
  type: string;
}

export class GoToProfileAction implements Action {
  static readonly type = '[Profile] Go To Profile';

  constructor(public payload: GoToProfileActionPayload) {}
}
