import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import countryJsonGB from './../../../assets/json/country-code-gb.json';
import countryJsonNL from './../../../assets/json/country-code-nl.json';
import { CountryModel } from '../questions';
import { DashboardService } from '../../dashboard';

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
})
export class CountrySelectComponent implements OnInit {
  @Input()
  countryCode = '';

  @Input()
  language!: string;

  @Input()
  disabled = false;

  @Input()
  fieldIndex?: number;

  @Output()
  answerChangedEmitter = new EventEmitter<string>();

  countryList: CountryModel[] = [];

  constructor(public service: DashboardService) {}

  ngOnInit(): void {
    if (this.language === 'nl_NL') {
      this.countryList = countryJsonNL as CountryModel[];
    } else {
      this.countryList = countryJsonGB as CountryModel[];
    }
  }

  onSelectionChange(): void {
    this.answerChangedEmitter.emit(this.countryCode);
  }
}
