import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PopUpModel } from './pop-up.model';

@Component({
  selector: 'app-pop-up-onboarding',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss'],
})
export class PopUpComponent {
  title = '';

  message = '';

  messageParams = {};

  list: string[] | undefined;

  okColour = 'accent';

  okText = 'common.ok';

  cancelText: string | undefined;

  showCloseButton: boolean;

  roundButton?: boolean;

  layoutAlign = 'end bottom';

  constructor(
    public dialogRef: MatDialogRef<PopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PopUpModel,
    private ngZone: NgZone,
  ) {
    this.title = data.title;
    this.layoutAlign = data.layoutAlign || 'end bottom';
    this.message = data.message;
    this.messageParams = data.messageParams || {};
    this.cancelText = data.cancelText;
    this.list = data.list;
    this.showCloseButton = !!data.showCloseButton;
    this.roundButton = !!data.roundButton;

    if (data.okText) {
      this.okText = data.okText;
    }

    if (data.okColour) {
      this.okColour = data.okColour;
    }
  }

  onClose(): void {
    this.ngZone.run(() => {
      this.dialogRef.close(false);
    });
  }

  onYesClick(): void {
    this.ngZone.run(() => {
      this.dialogRef.close(true);
    });
  }
}
