import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CsrdAxisPopupModel } from '../csrd-popup.model';

@Component({
  selector: 'app-axis-frame-popup',
  templateUrl: './csrd-axis-popup.component.html',
  styleUrls: ['./csrd-axis-popup.component.scss'],
})
export class CsrdAxisPopupComponent implements OnInit {
  title!: string;

  description!: string;

  maxHeight = '300px';

  constructor(
    public dialogRef: MatDialogRef<CsrdAxisPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CsrdAxisPopupModel,
  ) {}

  ngOnInit(): void {
    this.title = this.data.title;
    this.description = this.data.description;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
