import { AbstractService } from '../shared/services/abstract.service';
import { AuthState } from '../auth';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class InterceptorService extends AbstractService {
  public getAccessToken(): string | undefined {
    return this.store.selectSnapshot(AuthState.accessToken);
  }
}
