import { Action, ActionResponse } from '../../../../../shared/xs/actions';

export interface UpdateProfileActionPayload {
  email?: string;
  firstName?: string;
  lastName?: string;
  language?: string;
}

export class UpdateProfileAction implements Action {
  static readonly type = '[Profile] Update Profile';

  constructor(public payload: UpdateProfileActionPayload) {}
}

export interface UpdateProfileActionResponse extends ActionResponse {
  email: string;
  firstName: string;
  lastName: string;
  language: string;
}
