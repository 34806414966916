/* eslint-disable no-param-reassign */
import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PrivacyModel } from './privacy.model';

@Component({
  selector: 'app-privacy-pop-up',
  templateUrl: './privacy-pop-up.component.html',
  styleUrls: ['./privacy-pop-up.component.scss'],
})
export class PrivacyPopUpComponent implements AfterViewInit {
  loading = true;

  constructor(
    public dialogRef: MatDialogRef<PrivacyPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { agreement: PrivacyModel; canAccept?: boolean },
  ) {}

  ngAfterViewInit(): void {
    this.loading = false;
  }

  close(result: boolean): void {
    this.dialogRef.close(result);
  }
}
