import { Injectable, NgZone } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UpdateQuestionnaireAction } from './models/update-questionnaire.action';
import { ScoringStatus } from '../../dashboard';
import { CommentModel } from '../comments/comment.model';
import { CommentAction } from '../../accountant/xs/actions/comment.action';
import { GetCommentsAction } from '../../accountant/xs/actions/get-comments.action';
import { QuestionService } from './question.service';
import { QuestionnaireType } from '../../dashboard/scoreboard/models/questionnaire.type';
import { QuestionModel } from './models';
import { CheckUnansweredAction } from '../../accountant/xs/actions/check-unanswered.action';
import { UserStatus } from '../../dashboard/assessment-list/user-status.enum';
import { LogoutSuccessAction } from '../../auth/xs/actions/logout-success.action';
import { LogoutFromSmeSuccessAction } from '../../enterprise/xs/actions/logout-from-sme-success.actions';
import { RefreshValidationAction } from '../../dashboard/xs/actions/refresh-validation.action';
import { AssessmentVersion } from '../../dashboard/scoreboard/models/scoring.model';
import { LockAnswersAction } from '../../dashboard/xs/actions/lock-answers.action';
import { RelevantThemeModel } from '../relevant-theme/relevant-theme.model';
import { GetQuestionnaireAction } from './action/get-questionnaire.action';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GetQuestionnaireDto } from './models/get-questionnaire.dto';
import { WelcomePopupComponent } from '../../dashboard/questionnaire/welcome-popup/welcome-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { EsgAssessmentLockAnswersAction } from '../../dashboard/xs/actions/esg-assessment-lock-answers.action';
import { EsgAssessmentService } from '../../dashboard/esg-assessment/esg-assessment.service';
import { EsgAssessmentUpdateMaterialitiesAction } from '../../dashboard/xs/actions/esg-assessment-update-materialities.action';
import { MaterialityType } from '../../dashboard/questionnaire/questionaire/materiality/save-materiality-dto.model';

export interface QuestionnaireStateModel {
  id?: string;
  status?: ScoringStatus;
  type?: QuestionnaireType;
  version?: AssessmentVersion;
  comments?: CommentModel[];
  unansweredQuestions?: QuestionModel[];
  userStatus?: UserStatus;
  firstName?: string;
  lastName?: string;
  disabled?: boolean;
  viewOnly?: boolean;
  unfinished?: boolean;
  impactMateriality?: RelevantThemeModel[][];
  impactMaterialityChanged?: boolean;
  financialMateriality?: RelevantThemeModel[][];
  financialMaterialityChanged?: boolean;
  prioritisedThemes?: RelevantThemeModel[][];
  isPrefilling?: boolean;
}

@State<QuestionnaireStateModel>({ name: 'questionnaire' })
@Injectable()
export class QuestionnaireState {
  @Selector()
  static id(state: QuestionnaireStateModel): string | undefined {
    return state.id;
  }

  @Selector()
  static comments(state: QuestionnaireStateModel): CommentModel[] | undefined {
    return state.comments;
  }

  @Selector()
  static unanswered(state: QuestionnaireStateModel): QuestionModel[] | undefined {
    return state.unansweredQuestions;
  }

  @Selector()
  static status(state: QuestionnaireStateModel): ScoringStatus | undefined {
    return state.status;
  }

  @Selector()
  static state(state: QuestionnaireStateModel): QuestionnaireStateModel {
    return state;
  }

  constructor(
    private readonly questionService: QuestionService,
    private readonly esgAssessmentService: EsgAssessmentService,
    private readonly ngZone: NgZone,
    private readonly dialog: MatDialog,
  ) {}

  @Action(GetQuestionnaireAction)
  get(ctx: StateContext<QuestionnaireStateModel>): Observable<GetQuestionnaireDto> {
    return this.questionService.getQuestionnaire().pipe(
      tap<GetQuestionnaireDto>((dto) => {
        const stateModel: QuestionnaireStateModel = { ...dto };

        stateModel.version = dto.assessmentVersion;
        ctx.patchState(stateModel);
      }),
    );
  }

  @Action(UpdateQuestionnaireAction)
  update(ctx: StateContext<QuestionnaireStateModel>, action: UpdateQuestionnaireAction): void {
    ctx.patchState(action.payload);
  }

  @Action(CommentAction)
  async comment(ctx: StateContext<QuestionnaireStateModel>, action: CommentAction): Promise<void> {
    await this.questionService.comment(action.payload);
  }

  @Action(GetCommentsAction)
  async getComments(ctx: StateContext<QuestionnaireStateModel>): Promise<void> {
    const comments: CommentModel[] = await this.questionService.refreshComments();

    ctx.patchState({ comments });
  }

  @Action(CheckUnansweredAction)
  async checkForUnansweredQuestions(
    ctx: StateContext<QuestionnaireStateModel>,
    action: CheckUnansweredAction,
  ): Promise<void> {
    let unansweredQuestions: QuestionModel[] = [];

    if (action.payload) {
      unansweredQuestions = await this.questionService.getUnansweredQuestionsPerCategory(action.payload.category);
    } else {
      unansweredQuestions = await this.questionService.getUnansweredQuestions();
    }

    ctx.patchState({ unansweredQuestions });
  }

  @Action(LockAnswersAction)
  async lockAnswers(ctx: StateContext<QuestionnaireStateModel>, action: LockAnswersAction): Promise<void> {
    this.questionService.startLoading();

    const response = await this.questionService.lockAnswers(action.payload.id);

    ctx.patchState({
      impactMateriality: response.impactMateriality,
      financialMateriality: response.financialMateriality,
      status: ScoringStatus.ANSWERS_LOCKED,
    });
    this.questionService.stopLoading();
    this.ngZone.run(() => this.questionService.navigateToPage('/dashboard/sme/questionnaire/materiality'));

    this.dialog
      .open(WelcomePopupComponent, {
        width: '40vw',
        autoFocus: false,
        data: { translationGroup: 'questionnaire.materiality.welcome_popup' },
      })
      .afterClosed()
      .subscribe((value) => {
        if (value) {
          this.ngZone.run(() => this.questionService.navigateToPage('/dashboard/sme/questionnaire'));
        }
      });
  }

  @Action(EsgAssessmentLockAnswersAction)
  async lockAnswersForCMSManagedAssessment(ctx: StateContext<QuestionnaireStateModel>): Promise<void> {
    this.questionService.startLoading();

    this.esgAssessmentService.lockAnswers().subscribe((response) => {
      ctx.patchState({
        impactMateriality: response.impactMateriality,
        financialMateriality: response.financialMateriality,
        status: ScoringStatus.ANSWERS_LOCKED,
      });
      this.questionService.stopLoading();
      this.ngZone.run(() => this.questionService.navigateToPage('/dashboard/sme/esg-assessment/materiality'));

      this.dialog
        .open(WelcomePopupComponent, {
          width: '40vw',
          autoFocus: false,
          data: { translationGroup: 'questionnaire.materiality.welcome_popup' },
        })
        .afterClosed()
        .subscribe((value) => {
          if (value) {
            this.ngZone.run(() => this.questionService.navigateToPage('/dashboard/sme/esg-assessment'));
          }
        });
    });
  }

  @Action(EsgAssessmentUpdateMaterialitiesAction)
  async patchMaterialities(
    ctx: StateContext<QuestionnaireStateModel>,
    action: EsgAssessmentUpdateMaterialitiesAction,
  ): Promise<void> {
    if (action.payload.type === MaterialityType.IMPACT) {
      ctx.patchState({
        impactMateriality: action.payload.materiality,
        impactMaterialityChanged: true,
      });
    } else {
      ctx.patchState({
        financialMateriality: action.payload.materiality,
        financialMaterialityChanged: true,
      });
    }
  }

  @Action(RefreshValidationAction)
  async refreshValidation(ctx: StateContext<QuestionnaireStateModel>, action: RefreshValidationAction): Promise<void> {
    await this.questionService.refreshValidation(action.payload);
  }

  @Action([LogoutSuccessAction, LogoutFromSmeSuccessAction])
  clearQuestionnaire(ctx: StateContext<QuestionnaireStateModel>): void {
    ctx.setState({});
  }
}
