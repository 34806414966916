import { Action, ActionResponse } from '../../../shared/xs/actions';
import { PaymentRedirectionType } from './payment-redirection-type';

export interface ConfirmEmailActionPayload {
  email: string;
  confirmationCode: string;
}

export class ConfirmEmailAction implements Action {
  static readonly type = '[Auth] Confirm Email';

  constructor(public payload: ConfirmEmailActionPayload) {}
}

export interface ConfirmEmailActionResponse extends ActionResponse {
  email: string;
  userConfirmed: boolean;
  paymentRedirectionType: PaymentRedirectionType;
  hasError: boolean;
  errorMsg: string;
  paymentCountry: string;
}
