import { Injectable, NgZone } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GetCompanyAction } from './actions/company/get-company.action';
import { CompanyService } from '../company.service';
import { LookupCompanyAction } from './actions/company/lookup-company.action';
import { UpdateCompanyAction, UpdateCompanyActionResponse } from './actions/company/update-company.action';
import { UploadLogoAction } from './actions/company/upload-logo.action';
import { RefreshLogoAction } from './actions/company/refresh-logo.action';
import { LogoutSuccessAction } from '../../../auth/xs/actions/logout-success.action';
import { SubsidiaryModel } from '../models/subsidiary.model';
import { ProductModel } from '../models/product.model';
import { ServiceModel } from '../models/service.model';
import { MatDialog } from '@angular/material/dialog';
import { CompletePopupComponent } from '../../../shared/onboarding-complete/complete-popup.component';
import { CheckDashboardAction } from '../../xs';
import { IntroductionPopUpComponent } from '../../../shared/introduction-pop-up/introduction-pop-up.component';
import { StartImpactScanAction } from '../../questionnaire/impact-scan-revamp/xs/start-impact.action';
import { SubscriptionStatus } from './subscription-status.enum';
import { LoginAsCompanySuccessAction } from '../../../enterprise/xs/actions/login-as-company-success.action';
import { LogoutFromSmeSuccessAction } from '../../../enterprise/xs/actions/logout-from-sme-success.actions';
import { SetCompanyAction } from '../../../auth/xs/actions/set-company.action';
import { BillingAddress } from '../../../auth';
import {
  SubmitCompanyProfileAction,
  SubmitCompanyProfileActionResponse,
} from '../../../company-profile/xs/submit-company-profile.action';
import { GetEnterpriseAction } from './actions/company/get-enterprise.action';

export interface CompanyStateModel {
  draft?: boolean;
  dataImmutable?: boolean;

  name?: string;
  clientName?: string;
  country?: string;
  registeredId?: string;
  legalEntityType?: string;
  annualTurnover?: number;
  totalAssets?: number;
  localSectorCode?: string;
  numberOfEmployees?: number;
  subsidiaries?: SubsidiaryModel[];
  products?: ProductModel[];
  service?: ServiceModel[];
  executiveDirectors?: number;
  hasNonExecutiveDirectors?: boolean;
  hasMultipleShareholders?: boolean;
  controlType?: string;
  contactEmail?: string;
  billingAddress?: BillingAddress;

  redirect?: boolean;

  type?: string;
  logoSrc?: string;

  subscriptionStatus?: SubscriptionStatus;

  trialEndDate?: string;
}

@State<CompanyStateModel>({ name: 'company' })
@Injectable()
export class CompanyState {
  @Selector()
  static getCompany(state: CompanyStateModel): CompanyStateModel {
    return state;
  }

  @Selector()
  static getSubscriptionStatus(state: CompanyStateModel): SubscriptionStatus {
    const status = state.subscriptionStatus;

    if (status === undefined) {
      return SubscriptionStatus.ENDED;
    }

    return status;
  }

  constructor(
    private readonly companyService: CompanyService,
    public dialog: MatDialog,
    private readonly ngZone: NgZone,
  ) {}

  @Action(LoginAsCompanySuccessAction)
  setSmeCompanyForEnterprise(ctx: StateContext<CompanyStateModel>, action: LoginAsCompanySuccessAction): void {
    ctx.setState(action.companyPayload);
  }

  @Action(LogoutFromSmeSuccessAction)
  unsetSmeCompanyForEnterprise(ctx: StateContext<CompanyStateModel>, action: LogoutFromSmeSuccessAction): void {
    ctx.setState(action.companyPayload);
  }

  @Action(SetCompanyAction)
  setCompany(ctx: StateContext<CompanyStateModel>, action: SetCompanyAction): void {
    ctx.patchState(action.companyPayload);
  }

  @Action(LogoutSuccessAction)
  clearCompany(ctx: StateContext<CompanyStateModel>): void {
    ctx.setState({});
  }

  @Action(GetCompanyAction)
  async getCompany(ctx: StateContext<CompanyStateModel>): Promise<void> {
    const result = await this.companyService.refreshCompany();

    ctx.patchState({ ...ctx.getState(), ...result });
  }

  @Action(GetEnterpriseAction)
  async getEnterprise(ctx: StateContext<CompanyStateModel>): Promise<void> {
    const result = await this.companyService.refreshEnterprise();

    ctx.patchState({ ...ctx.getState(), ...result });
  }

  @Action(LookupCompanyAction)
  async lookupCompany(ctx: StateContext<CompanyStateModel>, action: LookupCompanyAction): Promise<void> {
    const result = (await this.companyService.lookupCompany(action.payload)) as CompanyStateModel;

    ctx.patchState(result);
  }

  @Action(UpdateCompanyAction)
  async updateCompany(
    ctx: StateContext<CompanyStateModel>,
    action: UpdateCompanyAction,
  ): Promise<UpdateCompanyActionResponse> {
    let result;

    if (action.payload.draft) {
      this.companyService.startLoading();
      result = await this.companyService.patchCompany(action.payload);
      ctx.patchState({ ...result });
      this.companyService.stopLoading();
    } else {
      this.companyService.startLoading();
      result = await this.companyService.saveCompany(action.payload);
      ctx.patchState({ ...result });
      this.companyService.stopLoading();
      if (action.payload.redirect) {
        this.ngZone.run(() => this.companyService.navigateToPage('/dashboard/sme/home', { noShowPopUp: true }));
        this.dialog
          .open(CompletePopupComponent, { width: '60vw', autoFocus: false, disableClose: true })
          .afterClosed()
          .subscribe((data: { startImpact: boolean }) => {
            if (data.startImpact) {
              this.companyService.dispatchAction(new CheckDashboardAction({ hasBeenChecked: false }));
              this.companyService.dispatchAction(new StartImpactScanAction());
            } else {
              this.openWelcomeToDashboardPopUp();
              this.companyService.dispatchAction(new CheckDashboardAction({ hasBeenChecked: true }));
            }
          });
      }
    }

    return result as UpdateCompanyActionResponse;
  }

  @Action(SubmitCompanyProfileAction)
  async submitCompanyProfile(
    ctx: StateContext<CompanyStateModel>,
    action: SubmitCompanyProfileAction,
  ): Promise<SubmitCompanyProfileActionResponse> {
    this.companyService.startLoading();
    const result = await this.companyService.submitCompanyProfile(action.payload);

    ctx.patchState({ ...result });
    this.companyService.stopLoading();
    if (action.payload.redirect) {
      this.ngZone.run(() => this.companyService.navigateToPage('/dashboard/sme/home', { noShowPopUp: true }));
      this.dialog
        .open(CompletePopupComponent, { width: '60vw', autoFocus: false, disableClose: true })
        .afterClosed()
        .subscribe((data: { startImpact: boolean }) => {
          if (data.startImpact) {
            this.companyService.dispatchAction(new CheckDashboardAction({ hasBeenChecked: false }));
            this.companyService.dispatchAction(new StartImpactScanAction());
          } else {
            this.openWelcomeToDashboardPopUp();
            this.companyService.dispatchAction(new CheckDashboardAction({ hasBeenChecked: true }));
          }
        });
    }

    return result as SubmitCompanyProfileActionResponse;
  }

  @Action(UploadLogoAction)
  async uploadLogo(ctx: StateContext<CompanyStateModel>, action: UploadLogoAction): Promise<void> {
    await this.companyService.uploadLogo(action.payload.file);
    await this.companyService.dispatchActionAndWait(new RefreshLogoAction());
  }

  @Action(RefreshLogoAction)
  async refreshLogo(ctx: StateContext<CompanyStateModel>): Promise<void> {
    let url = 'assets/imgs/profile.png';

    const logo = await this.companyService.getLogo();

    url = logo.src || url;

    ctx.patchState({ ...ctx.getState(), ...{ logoSrc: url } });
  }

  private openWelcomeToDashboardPopUp(): void {
    this.dialog.open(IntroductionPopUpComponent, {
      width: '60vw',
      autoFocus: false,
      data: {
        title: 'home.pop_up.title',
        message: 'home.pop_up.description',
        list: ['home.pop_up.item_one', 'home.pop_up.item_two', 'home.pop_up.item_three', 'home.pop_up.item_four'],
      },
    });
  }
}
