import { Action } from '../../../../../shared/xs/actions';

export interface UploadLogoActionPayload {
  file: File;
}

export class UploadLogoAction implements Action {
  static readonly type = '[Dashboard] Upload Logo';

  constructor(public payload: UploadLogoActionPayload) {}
}
