import Chart from 'chart.js';
/* eslint-disable */
export const roundEdgesPlugin = {
  afterUpdate(chart) {
    let a = chart.config.data.datasets.length - 1;

    for (const i in chart.config.data.datasets) {
      for (let j = chart.config.data.datasets[i].data.length - 1; j >= 0; --j) {
        if (Number(j) == chart.config.data.datasets[i].data.length - 1) {
          continue;
        }
        const arc = chart.getDatasetMeta(i).data[j];

        arc.round = {
          x: (chart.chartArea.left + chart.chartArea.right) / 2,
          y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
          radius: chart.innerRadius + chart.radiusLength / 2 + a * chart.radiusLength,
          thickness: chart.radiusLength / 2 - 1,
          backgroundColor: arc._model.backgroundColor,
        };
      }
      a--;
    }
  },

  afterDraw(chart) {
    const { ctx } = chart.chart;

    for (const i in chart.config.data.datasets) {
      for (let j = chart.config.data.datasets[i].data.length - 1; j >= 0; --j) {
        if (Number(j) == chart.config.data.datasets[i].data.length - 1) {
          continue;
        }
        const arc = chart.getDatasetMeta(i).data[j];
        const startAngle = Math.PI / 2 - arc._view.startAngle;
        const endAngle = Math.PI / 2 - arc._view.endAngle;

        ctx.save();
        ctx.translate(arc.round.x, arc.round.y);
        ctx.fillStyle = arc.round.backgroundColor;
        ctx.beginPath();
        ctx.arc(
          arc.round.radius * Math.sin(startAngle),
          arc.round.radius * Math.cos(startAngle),
          arc.round.thickness,
          0,
          2 * Math.PI,
        );
        ctx.arc(
          arc.round.radius * Math.sin(endAngle),
          arc.round.radius * Math.cos(endAngle),
          arc.round.thickness,
          0,
          2 * Math.PI,
        );
        ctx.closePath();
        ctx.fill();
        ctx.restore();
      }
    }
  },
};

export const textInMiddlePlugin = {
  afterUpdate: function (chart) {
    if (chart.config.options.elements.center) {
      var helpers = Chart.helpers;
      var centerConfig = chart.config.options.elements.center;
      var globalConfig = Chart.defaults.global;
      var ctx = chart.chart.ctx;

      var fontStyle = helpers.getValueOrDefault(centerConfig.fontStyle, globalConfig.defaultFontStyle);
      var fontFamily = helpers.getValueOrDefault(centerConfig.fontFamily, globalConfig.defaultFontFamily);

      if (centerConfig.fontSize)
        var fontSize = centerConfig.fontSize;
      // figure out the best font size, if one is not specified
      else {
        ctx.save();
        var fontSize = helpers.getValueOrDefault(centerConfig.minFontSize, 1);
        var maxFontSize = helpers.getValueOrDefault(centerConfig.maxFontSize, 256);
        var maxText = helpers.getValueOrDefault(centerConfig.maxText, centerConfig.text);

        do {
          ctx.font = helpers.fontString(fontSize, fontStyle, fontFamily);
          var textWidth = ctx.measureText(maxText).width;

          // check if it fits, is within configured limits and that we are not simply toggling back and forth
          if (textWidth < (chart.innerRadius * 2) - 20 && fontSize < maxFontSize)
            fontSize += 1;
          else {
            // reverse last step
            fontSize -= 1;
            break;
          }
        } while (true)
        ctx.restore();
      }

      // save properties
      chart.center = {
        font: helpers.fontString(fontSize, fontStyle, fontFamily),
        fillStyle: helpers.getValueOrDefault(centerConfig.fontColor, globalConfig.defaultFontColor)
      };
    }
  },
  afterDraw: function (chart) {
    if (chart.center) {
      var centerConfig = chart.config.options.elements.center;
      var ctx = chart.chart.ctx;

      ctx.save();
      ctx.font = chart.center.font;
      ctx.fillStyle = chart.center.fillStyle;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      ctx.fillText(centerConfig.text, centerX, centerY);
      ctx.restore();
    }
  },
};

export const textArrayInMiddlePlugin = {
  afterUpdate: function (chart) {
    if (chart.config.options.elements.center) {
      var helpers = Chart.helpers;
      var globalConfig = Chart.defaults.global;
      var ctx = chart.chart.ctx;

      chart.center = chart.config.options.elements.center.map(centerConfig => {
        var fontStyle = helpers.getValueOrDefault(centerConfig.fontStyle, globalConfig.defaultFontStyle);
        var fontFamily = helpers.getValueOrDefault(centerConfig.fontFamily, globalConfig.defaultFontFamily);

        var fontSize = centerConfig.fontSize;
        if (!fontSize) {
          ctx.save();
          fontSize = helpers.getValueOrDefault(centerConfig.minFontSize, 1);
          var maxFontSize = helpers.getValueOrDefault(centerConfig.maxFontSize, 256);
          var maxText = helpers.getValueOrDefault(centerConfig.maxText, centerConfig.text);

          do {
            ctx.font = helpers.fontString(fontSize, fontStyle, fontFamily);
            var textWidth = ctx.measureText(maxText).width;

            if (textWidth < (chart.innerRadius * 2) - 20 && fontSize < maxFontSize)
              fontSize += 1;
            else {
              fontSize -= 1;
              break;
            }
          } while (true)
          ctx.restore();
        }

        return {
          font: helpers.fontString(fontSize, fontStyle, fontFamily),
          fillStyle: helpers.getValueOrDefault(centerConfig.fontColor, globalConfig.defaultFontColor)
        };
      });
    }
  },
  afterDraw: function (chart) {
    if (chart.center) {
      var ctx = chart.ctx;
      var width = chart.width;
      var height = chart.height;
      var isSingle = chart.center.length === 1;

      ctx.save();
      // ctx.textAlign = 'center';
      // ctx.textBaseline = 'middle';
      // var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      // var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

      chart.center.forEach((center, index) => {
        ctx.font = center.font;
        ctx.fillStyle = center.fillStyle;
        const text = chart.config.options.elements.center[index].text;
        let centerX = 0;
        let centerY = 0;
        if (!isSingle) {
          centerX = Math.round((width - ctx.measureText(text).width) / 2 + 2);
          centerY = height / 2 - 5;
        } else {
          centerX = Math.round((width - ctx.measureText(text).width) / 2 + 1);
          centerY = height / 2;
        }
        ctx.fillText(text, centerX, centerY + index * 20);
      });

      ctx.restore();
    }
  },
};
/* eslint-enable */
