import { Component, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-first-login-popup',
  templateUrl: './first-login-popup.component.html',
  styleUrls: ['./first-login-popup.component.scss'],
})
export class FirstLoginPopupComponent {
  constructor(public dialogRef: MatDialogRef<FirstLoginPopupComponent>, private readonly ngZone: NgZone) {}

  close(): void {
    this.ngZone.run(() => this.dialogRef.close());
  }
}
