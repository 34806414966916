import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { CompanyService } from '../../dashboard';

@Directive({ selector: '[appDisableForFreeTrial]' })
export class HideForFreeTrialDirective implements OnInit {
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<never>,
    private companyService: CompanyService,
  ) {}

  isVisible = false;

  ngOnInit(): void {
    if (this.companyService.isFreeTrialUser()) {
      this.isVisible = false;
      this.viewContainerRef.clear();
    } else if (!this.isVisible) {
      this.isVisible = true;
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
