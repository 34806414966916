import { Component, OnInit } from '@angular/core';
import { StartImpactScanAction } from '../questionnaire/impact-scan-revamp/xs/start-impact.action';
import { DashboardService } from '../dashboard.service';
import { ImpactListModel } from './impact-list.model';
import { MatDialog } from '@angular/material/dialog';
import { GetAssessmentListAction } from '../xs/actions/get-assessment-list.action';
import { CheckAssessmentsAction } from '../xs/actions/assessments-checked.action';
import { ActivatedRoute, Params } from '@angular/router';
import { IntroductionPopUpComponent } from '../../shared/introduction-pop-up/introduction-pop-up.component';
import { ProfileService } from '../profile';
import { SetNavigationTargetAction } from '../xs/actions/set-navigation-target.action';
import { NavigationTargetEnum } from '../xs/navigation-target.enum';

@Component({
  selector: 'app-assessment-list',
  templateUrl: './assessment-list.component.html',
  styleUrls: ['./assessment-list.component.scss'],
})
export class AssessmentListComponent implements OnInit {
  oldImpactScans: ImpactListModel[] = [];

  revampedImpactScans: ImpactListModel[] = [];

  showPlaceholder = true;

  shouldShowPopUp = true;

  loading = true;

  constructor(
    public profileService: ProfileService,
    private dashboardService: DashboardService,
    private dialog: MatDialog,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParams.subscribe(async (params: Params) => {
      if (params.noShowPopUp || this.profileService.getProfile().suppressSmePopUp) {
        this.shouldShowPopUp = false;
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.dashboardService.dispatchActionAndWait(new GetAssessmentListAction());
    this.oldImpactScans = this.dashboardService.getOldAssessmentList();
    this.revampedImpactScans = this.dashboardService.getRevampedAssessmentList();
    this.showPlaceholder = this.oldImpactScans.length === 0 && this.revampedImpactScans.length === 0;
    this.loading = false;

    if (this.shouldShowPopUp && this.dashboardService.isFirstAssessmentsCheck()) {
      this.openDialog();
      this.dashboardService.dispatchAction(new CheckAssessmentsAction({ hasBeenChecked: true }));
    }

    this.dashboardService.dispatchAction(
      new SetNavigationTargetAction({ navigationTarget: NavigationTargetEnum.LIST }),
    );
  }

  openDialog(): void {
    this.dialog.open(IntroductionPopUpComponent, {
      width: '60vw',
      autoFocus: false,
      data: {
        title: 'dashboard.assessment_list.pop_up.title',
        message: 'dashboard.assessment_list.pop_up.description',
        list: [
          'dashboard.assessment_list.pop_up.item_one',
          'dashboard.assessment_list.pop_up.item_two',
          'dashboard.assessment_list.pop_up.item_three',
        ],
      },
    });
  }

  startImpactScan(): void {
    this.dashboardService.dispatchAction(new StartImpactScanAction());
  }
}
