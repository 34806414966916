import { Action } from '../../../shared/xs/actions';
import { UpdateProfileActionPayload } from '../../../dashboard/profile/xs/actions/profile/update-profile.action';

export interface UpdateEnterpriseProfileActionPayload extends UpdateProfileActionPayload {
  enterpriseName: string;
}

export class UpdateEnterpriseProfileAction implements Action {
  static readonly type = '[Profile] Update Enterprise Profile';

  constructor(public payload: UpdateEnterpriseProfileActionPayload) {}
}
