import { Injectable } from '@angular/core';
import { RelevantStandard } from '../../dashboard/scoreboard/models/scoring.model';
import { ExportUtils } from './exportUtils';
import { EeveryTranslateService } from '../eevery.translate.service';
import { SliderGroupService } from '../../dashboard/scoreboard/shared/slider-toggle/slider-group.service';

@Injectable({ providedIn: 'root' })
export class GriScoresExportService {
  constructor(private translateService: EeveryTranslateService, private slierGroupService: SliderGroupService) {}

  public async exportGeneralGriStandardsToCSV(
    relevantStandards: RelevantStandard[],
    assessmentAlias?: string,
  ): Promise<void> {
    const headers = this.translateService.instant('export.scores.gri.csv.standards_general.headers');
    const data = relevantStandards.map(
      (standard) =>
        `${standard.code};${standard.name};${this.capitalizeFirstLetter(standard.category)};${standard.averageScore}%`,
    );

    const filename = `${this.translateService.instant(
      'export.scores.gri.csv.standards_general.file_name.0',
    )}${this.slierGroupService.formatName(assessmentAlias ?? '')}${this.translateService.instant(
      'export.scores.gri.csv.standards_general.file_name.1',
    )}`;

    ExportUtils.downloadCSV([headers, ...data].join('\n'), filename);
  }

  public async exportDetailedGriStandardsToCSV(
    relevantStandards: RelevantStandard[],
    assessmentAlias?: string,
  ): Promise<void> {
    const headers = this.translateService.instant('export.scores.gri.csv.standards_and_disclosures_detailed.headers');
    const data = relevantStandards.map((standard) =>
      [
        [
          standard.code,
          standard.name,
          this.capitalizeFirstLetter(standard.category),
          '',
          '',
          `${standard.averageScore}%`,
          '',
        ],
        ...standard.relevantDisclosures.map((disclosure) => [
          standard.code,
          standard.name,
          this.capitalizeFirstLetter(standard.category),
          disclosure.code,
          disclosure.name,
          '',
          `${disclosure.percentage}%`,
        ]),
      ]
        .map((row) =>
          row
            .map((x) => x ?? '')
            .map(String)
            .map((str) => str.replace(/\n/g, ' ').replace(/;/g, '.'))
            .join(';'),
        )
        .join('\n'),
    );

    const filename = `${this.translateService.instant(
      'export.scores.gri.csv.standards_and_disclosures_detailed.file_name.0',
    )}${this.slierGroupService.formatName(assessmentAlias ?? '')}${this.translateService.instant(
      'export.scores.gri.csv.standards_and_disclosures_detailed.file_name.1',
    )}`;

    ExportUtils.downloadCSV([headers, ...data].join('\n'), filename);
  }

  private capitalizeFirstLetter(str: string): string {
    return !str ? str : str.charAt(0).toUpperCase() + str.slice(1);
  }
}
