import { Action } from '../../../shared/xs/actions';

export interface SetCompanyPayload {
  name: string;
  draft?: boolean;
  type?: string;
  country?: string;
}

export class SetCompanyAction implements Action {
  static readonly type = '[Company] Set company';

  constructor(public companyPayload: SetCompanyPayload) {}
}
