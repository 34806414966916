import { Action } from '../../../shared/xs/actions';

export interface CheckEnterpriseDashboardActionPayload {
  hasBeenChecked: boolean;
}

export class CheckEnterpriseDashboardAction implements Action {
  static readonly type = '[Enterprise] Dashboard checked';

  constructor(public payload: CheckEnterpriseDashboardActionPayload) {}
}
