import { Component } from '@angular/core';
import { Co2CalculatorService } from '../../co2-calculator.service';

@Component({
  selector: 'app-co2-calculation-list-header',
  templateUrl: './co2-calculation-list-header.component.html',
  styleUrls: ['./co2-calculation-list-header.component.scss'],
})
export class Co2CalculationListHeaderComponent {
  constructor(public readonly co2CalculatorService: Co2CalculatorService) {}
}
