import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ManagedSdgTarget } from '../../models/scoring.model';
import { SdgPopupModel } from './sdg-popup.model';
import { SdgTargetModel } from '../../../../shared/info/sdg-target.model';
import sdgTargetsJson from 'src/assets/json/standards/sdg-targets.json';
import { EeveryTranslateService } from '../../../../shared/eevery.translate.service';

@Component({
  selector: 'sdg-esg-popup',
  templateUrl: './sdg-popup.component.html',
  styleUrls: ['./sdg-popup.component.scss'],
})
export class SdgPopupComponent implements OnInit {
  sdgGoalCode!: string;

  sdgGoalTitle!: string;

  sdgTargetCodes!: ManagedSdgTarget[];

  allSdgTargets? = sdgTargetsJson as SdgTargetModel[];

  filteredTargets?: SdgTargetModel[];

  maxHeight = '300px';

  constructor(
    public dialogRef: MatDialogRef<SdgPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SdgPopupModel,
    private translationService: EeveryTranslateService,
  ) {}

  ngOnInit(): void {
    this.sdgGoalCode = this.data.sdgGoalCode;
    this.sdgGoalTitle = this.data.sdgGoalTitle;
    this.sdgTargetCodes = this.data.sdgTargets;
    this.filterTargets();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private filterTargets(): void {
    if (!this.allSdgTargets) {
      this.filteredTargets = [];

      return;
    }
    this.filteredTargets = this.allSdgTargets?.filter((target) =>
      this.sdgTargetCodes.find((code) => code.code === target.id),
    );
  }

  getTargetTextTranslation(target: ManagedSdgTarget): string {
    const language = this.translationService.getLanguage().replace('_', '-').replace('GB', 'UK');
    const text = target.translations.find((translation) => translation.languageCode === language);

    if (text) {
      return text.description;
    }
    if (target.translations[0]) {
      return target.translations[0].description;
    }

    return 'Unknown';
  }
}
