import { Injectable } from '@angular/core';
import { UnansweredQuestionModel } from '../shared/unanswered-popup-simple/unanswered-question.model';
import { Co2QuestionnaireAnswersModel } from './model/co2-questionnaire-answers.model';

@Injectable({ providedIn: 'root' })
export class Co2CalculatorValidationService {
  validateUnansweredQuestions(answers: Co2QuestionnaireAnswersModel): Set<UnansweredQuestionModel> {
    const result: Set<UnansweredQuestionModel> = new Set();

    this.validateGeneral(answers, result);
    this.validateEnergy(answers, result);
    this.validateMobility(answers, result);
    this.validateBoard(answers, result);
    this.validateMaterial(answers, result);

    return result;
  }

  validateCategory(answers: Co2QuestionnaireAnswersModel, category: string): boolean {
    const result: Set<UnansweredQuestionModel> = new Set();

    // eslint-disable-next-line default-case
    switch (category) {
      case 'general':
        this.validateGeneral(answers, result);
        break;
      case 'energy':
        this.validateEnergy(answers, result);
        break;
      case 'mobility':
        this.validateMobility(answers, result);
        break;
      case 'board':
        this.validateBoard(answers, result);
        break;
      case 'material':
        this.validateMaterial(answers, result);
        break;
    }

    return result.size === 0;
  }

  private validateGeneral(answers: Co2QuestionnaireAnswersModel, result: Set<UnansweredQuestionModel>): void {
    this.validateGeneralField(answers.name, 1, result);
    this.validateGeneralField(answers.country, 2, result);
    this.validateGeneralField(answers.numberOfEmployees, 3, result);
    this.validateGeneralField(answers.annualTurnover, 4, result);
    this.validateGeneralField(answers.heatedArea, 5, result);
  }

  private validateGeneralField(value: unknown, id: number, result: Set<UnansweredQuestionModel>): void {
    const categoryName = 'co2calculator.questionnaire.categories.general';

    if (!this.isValid(value, true)) {
      result.add(new UnansweredQuestionModel(id.toString(), categoryName, 1));
    }
  }

  private validateEnergy(answers: Co2QuestionnaireAnswersModel, result: Set<UnansweredQuestionModel>): void {
    this.validateEnergyField(answers.powerConsumptionKwh, 1, !answers.estimateEnergy, result);
    this.validateEnergyField(answers.greenElectricity, 2, !answers.estimateEnergy, result);
    this.validateEnergyField(answers.heatPump, 3, !answers.estimateEnergy, result);
    this.validateEnergyField(answers.oilLiters, 4, !answers.estimateEnergy, result);
    this.validateEnergyField(answers.naturalGasM3, 5, !answers.estimateEnergy, result);
    this.validateEnergyField(answers.biogasM3, 6, !answers.estimateEnergy, result);
    this.validateEnergyField(answers.woodKwh, 7, !answers.estimateEnergy, result);
    this.validateEnergyField(answers.districtHeatKwh, 8, !answers.estimateEnergy, result);
  }

  private validateMobility(answers: Co2QuestionnaireAnswersModel, result: Set<UnansweredQuestionModel>): void {
    this.validateMobilityField(answers.yearlyPublicTransportationKm, 1, !answers.estimateCommutingMobility, result);
    this.validateMobilityField(answers.yearlyCarKm, 2, !answers.estimateCommutingMobility, result);

    this.validateMobilityField(
      answers.businessYearlyCarKm,
      3,
      !answers.estimateBusinessTripMobility && !answers.advancedBusinessTripInputs,
      result,
    );

    this.validateMobilityField(
      answers.carFuelGasolineLiters,
      4,
      !answers.estimateBusinessTripMobility && answers.advancedBusinessTripInputs,
      result,
    );
    this.validateMobilityField(
      answers.carFuelDieselLiters,
      5,
      !answers.estimateBusinessTripMobility && answers.advancedBusinessTripInputs,
      result,
    );
    this.validateMobilityField(
      answers.carFuelNaturalGasKg,
      6,
      !answers.estimateBusinessTripMobility && answers.advancedBusinessTripInputs,
      result,
    );
    this.validateMobilityField(
      answers.carFuelBiogasKg,
      7,
      !answers.estimateBusinessTripMobility && answers.advancedBusinessTripInputs,
      result,
    );
    this.validateMobilityField(
      answers.carFuelElectricKwh,
      8,
      !answers.estimateBusinessTripMobility && answers.advancedBusinessTripInputs,
      result,
    );
    this.validateMobilityField(answers.yearlyPublicTransportKm, 9, !answers.estimateBusinessTripMobility, result);
    this.validateMobilityField(answers.hotelNightAmount, 10, !answers.estimateBusinessTripMobility, result);

    this.validateMobilityField(answers.shortFlightsAmount, 11, true, result);
    this.validateMobilityField(answers.mediumFlightsAmount, 12, true, result);
    this.validateMobilityField(answers.longFlightsAmount, 13, true, result);
    // this.validateMobilityField(answers.businessOrFirstFlightsPerCent, 14, true, result);
    this.validatePercentageField(
      answers.businessOrFirstFlightsPerCent,
      14,
      'co2calculator.questionnaire.categories.mobility',
      3,
      result,
    );
  }

  private validateMobilityField(
    value: unknown,
    id: number,
    required: boolean,
    result: Set<UnansweredQuestionModel>,
  ): void {
    const categoryName = 'co2calculator.questionnaire.categories.mobility';

    this.validateField(categoryName, 3, value, id, required, result);
  }

  private validateEnergyField(
    value: unknown,
    id: number,
    required: boolean,
    result: Set<UnansweredQuestionModel>,
  ): void {
    const categoryName = 'co2calculator.questionnaire.categories.energy';

    this.validateField(categoryName, 2, value, id, required, result);
  }

  private validateBoard(answers: Co2QuestionnaireAnswersModel, result: Set<UnansweredQuestionModel>): void {
    this.validateBoardField(answers.warmMealMeatAmount, 1, !answers.estimateFoodAndBeverages, result);
    this.validateBoardField(answers.warmMealVegaAmount, 2, !answers.estimateFoodAndBeverages, result);
    this.validateBoardField(answers.snacks, 3, !answers.estimateFoodAndBeverages, result);
    this.validateBoardField(answers.sodaLiters, 4, !answers.estimateFoodAndBeverages, result);
    this.validateBoardField(answers.coffeePortions, 5, !answers.estimateFoodAndBeverages, result);
    this.validateBoardField(answers.teaPortions, 6, !answers.estimateFoodAndBeverages, result);
  }

  private validateBoardField(
    value: unknown,
    id: number,
    required: boolean,
    result: Set<UnansweredQuestionModel>,
  ): void {
    const categoryName = 'co2calculator.questionnaire.categories.board';

    this.validateField(categoryName, 4, value, id, required, result);
  }

  private validateMaterial(answers: Co2QuestionnaireAnswersModel, result: Set<UnansweredQuestionModel>): void {
    this.validateMaterialField(answers.paperKg, 1, !answers.estimateSupplies, result);
    this.validateMaterialField(answers.paperRecyclingPaperPerCent, 2, !answers.estimateSupplies, result);
    this.validateMaterialField(answers.printedMattersKg, 3, !answers.estimateSupplies, result);
    this.validateMaterialField(answers.electricDevicesAmount, 4, !answers.estimateSupplies, result);
    this.validateMaterialField(
      answers.tonersAmount,
      5,
      !answers.estimateSupplies && answers.advancedSuppliesInputs,
      result,
    );
    this.validateMaterialField(answers.waterM3, 6, !answers.estimateSupplies && answers.advancedSuppliesInputs, result);
    this.validateMaterialField(answers.garbageKg, 7, !answers.estimateSupplies, result);
    this.validateMaterialField(
      answers.recyclingKg,
      8,
      !answers.estimateSupplies && answers.advancedGarbageInputs,
      result,
    );
    this.validateMaterialField(
      answers.electronicsKg,
      9,
      !answers.estimateSupplies && answers.advancedGarbageInputs,
      result,
    );
  }

  private validateMaterialField(
    value: unknown,
    id: number,
    required: boolean,
    result: Set<UnansweredQuestionModel>,
  ): void {
    const categoryName = 'co2calculator.questionnaire.categories.material';

    this.validateField(categoryName, 5, value, id, required, result);
  }

  private validateField(
    categoryName: string,
    categoryOder: number,
    value: unknown,
    id: number,
    required: boolean,
    result: Set<UnansweredQuestionModel>,
  ): void {
    if (!this.isValid(value, required)) {
      result.add(new UnansweredQuestionModel(id.toString(), categoryName, categoryOder));
    }
  }

  private isValid(value: unknown, isRequired: boolean): boolean {
    if (!isRequired) {
      return true;
    }

    return this.isAnswered(value);
  }

  private isAnswered(value: unknown): boolean {
    return value !== undefined && value !== null;
  }

  private validatePercentageField(
    percentage: number,
    id: number,
    categoryName: string,
    categoryOder: number,
    result: Set<UnansweredQuestionModel>,
  ): void {
    if (!(percentage !== null && percentage >= 0 && percentage <= 100)) {
      result.add(new UnansweredQuestionModel(id.toString(), categoryName, categoryOder));
    }
  }
}
