import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EeveryTranslateService } from '../eevery.translate.service';
import { GriEntityModel, RelevantThemeModel } from './relevant-theme.model';
import { MatDialog } from '@angular/material/dialog';
import { PopUpComponent } from '../pop-up/pop-up.component';

@Component({
  selector: 'app-relevant-theme',
  templateUrl: './relevant-theme.component.html',
  styleUrls: ['./relevant-theme.component.scss'],
})
export class RelevantThemeComponent implements OnInit {
  @Input()
  themeAndSubthemes!: RelevantThemeModel[];

  @Input()
  dragAndDrop = false;

  @Input()
  removable = false;

  @Input()
  showAddedLabel = false;

  @Input()
  showNewLabel = false;

  @Input()
  openByDefault!: boolean;

  @Input()
  disableClick = false;

  @Output()
  opened = new EventEmitter<void>();

  @Output()
  closed = new EventEmitter<void>();

  @Output()
  remove = new EventEmitter<GriEntityModel>();

  theme = '';

  subthemes: string[] = [];

  open = false;

  constructor(private translateService: EeveryTranslateService, private dialog: MatDialog) {}

  ngOnInit(): void {
    const language = this.translateService.getLanguage();

    this.loadLanguage(language);

    this.subthemes.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

    this.open = this.openByDefault;

    this.translateService.onLanguageChange().subscribe((event) => {
      this.loadLanguage(event.lang);
    });
  }

  loadLanguage(language: string): void {
    if (this.themeAndSubthemes[0]) {
      if (language === 'nl_NL') {
        this.theme = this.themeAndSubthemes[0].standard.nameNL;
        this.subthemes = this.themeAndSubthemes.map((themeAndSubtheme) => themeAndSubtheme.disclosure.nameNL);
      } else {
        this.theme = this.themeAndSubthemes[0].standard.nameUK;
        this.subthemes = this.themeAndSubthemes.map((themeAndSubtheme) => themeAndSubtheme.disclosure.nameUK);
      }
    }
  }

  removeStandard(): void {
    this.remove.next(this.themeAndSubthemes[0].standard);
  }

  showTagPopup(tag: string): void {
    const data = {
      title: `impact_scan.themes.popup.tag.${tag.toLowerCase()}.title`,
      message: `impact_scan.themes.popup.tag.${tag.toLowerCase()}.body`,
      layoutAlign: 'end',
    };

    const popup = this.dialog.open(PopUpComponent, {
      width: '60vw',
      autoFocus: false,
      data,
    });

    popup.afterClosed().subscribe(() => {
      if (document.activeElement) {
        (document.activeElement as HTMLElement).blur();
      }
    });
  }
}
