import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../auth.service';
import { ConfirmEmailAction } from '../../xs/actions/confirm-email.action';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
})
export class ConfirmEmailComponent {
  constructor(private activatedRoute: ActivatedRoute, private readonly authService: AuthService) {
    this.confirmCode();
  }

  async confirmCode(): Promise<void> {
    this.activatedRoute.queryParams.subscribe(async (params: Params) => {
      this.authService.dispatchAction(
        new ConfirmEmailAction({
          email: decodeURIComponent(params.email),
          confirmationCode: params.code,
        }),
      );
    });
  }
}
