import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CategoryModel, CommentModel } from './comment.model';
import { CommentPayload } from '../../accountant/xs/actions/comment.action';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent {
  amountOfComments = 0;

  categoryTitle = '';

  questionTitle = '';

  private _comments: CommentModel[] = [];

  @Input() set comments(value: CommentModel[]) {
    if (!value) {
      return;
    }
    if (this._comments.length + 1 === value.length) {
      this.addCommentToCorrectPlace(value[value.length - 1]);
      this._comments = value;
    } else {
      this._comments = value;
      this.sections = [];
      for (const comment of this._comments) {
        this.addCommentToCorrectPlace(comment);
      }
    }
  }

  get comments(): CommentModel[] {
    return this._comments || [];
  }

  @Input()
  question: { id: string; displayId: string } | undefined;

  @Output()
  closeClicked = new EventEmitter<void>();

  @Output()
  commented = new EventEmitter<CommentPayload>();

  sections: CategoryModel[] = [];

  addCommentToCorrectPlace(comment: CommentModel): void {
    const commentCategory = comment.category || 'Scoring';
    const existingCategorySection = this.sections.filter((section) => section.category === commentCategory);

    if (existingCategorySection.length === 0) {
      if (!comment.question) {
        this.sections.push({
          category: commentCategory,
          questions: [],
          comments: [comment],
        });
      } else {
        this.sections.push({
          category: commentCategory,
          questions: [
            {
              question: comment.question,
              comments: [comment],
            },
          ],
          comments: [],
        });
      }
    } else if (comment.question) {
      const existingQuestionSection = existingCategorySection[0].questions.filter(
        (question) => question.question === comment.question,
      );

      if (existingQuestionSection.length > 0) {
        existingQuestionSection[0].comments.push(comment);
      } else {
        existingCategorySection[0].questions.push({
          question: comment.question,
          comments: [comment],
        });
      }
    } else {
      existingCategorySection[0].comments.push(comment);
    }
  }

  comment(text: string, questionId?: string): void {
    if (!text || text.length < 1) {
      return;
    }
    this.commented.emit({
      text,
      questionId,
    });
  }

  closeComments(): void {
    this.closeClicked.emit();
  }
}
