import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FiletypeEnum } from './filetype.enum';

@Component({
  selector: 'app-preview-popup',
  templateUrl: './preview-popup.component.html',
  styleUrls: ['./preview-popup.component.scss'],
})
export class PreviewPopupComponent implements OnInit, AfterViewInit {
  public readonly FiletypeEnum = FiletypeEnum;

  title!: string;

  src!: string;

  constructor(
    public dialogRef: MatDialogRef<PreviewPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; fileType: FiletypeEnum; file: Blob },
  ) {}

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).pdfWorkerSrc = './assets/js/pdf.worker.min.js?v=2.6.347';
  }

  ngAfterViewInit(): void {
    this.title = this.data.title;

    if (this.data.fileType === FiletypeEnum.IMG) {
      const canvas = document.getElementById('canvas') as HTMLCanvasElement;
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
      const img = new Image();

      img.onload = (): void => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        canvas.toDataURL(this.data.fileType);
      };

      img.src = URL.createObjectURL(this.data.file);
    } else {
      this.src = URL.createObjectURL(this.data.file);
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
