import { Action } from '../../../shared/xs/actions';

export interface SetSelectedStateAssessmentActionPayload {
  assessmentId: string;
}

export class SetSelectedStateAssessmentAction implements Action {
  static readonly type = '[Scoreboard] set current assessment on state before open scoreboard';

  constructor(public payload: SetSelectedStateAssessmentActionPayload) {}
}
