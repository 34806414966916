import { Component, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-c02-start-calculation-pop-up',
  templateUrl: './c02-start-calculation-pop-up.component.html',
  styleUrls: ['./c02-start-calculation-pop-up.component.scss'],
})
export class C02StartCalculationPopUpComponent {
  reportingPeriodStart: Date = new Date();

  constructor(public dialogRef: MatDialogRef<C02StartCalculationPopUpComponent>, private ngZone: NgZone) {
    const date = new Date();

    date.setFullYear(date.getFullYear(), 0, 1);
    this.reportingPeriodStart = date;
  }

  submit(): void {
    this.ngZone.run(() => {
      this.dialogRef.close({ reportingPeriodStart: this.reportingPeriodStart, continue: true });
    });
  }

  close(): void {
    this.ngZone.run(() => {
      this.dialogRef.close({ reportingPeriodStart: undefined, continue: false });
    });
  }
}
