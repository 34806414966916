import { Action } from '../../../shared/xs/actions';

export interface ForgotPasswordActionPayload {
  email: string;
  language?: string;
}

export class ForgotPasswordAction implements Action {
  static readonly type = '[Auth] Forgot password';

  constructor(public payload: ForgotPasswordActionPayload) {}
}
