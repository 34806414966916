import { Action } from '../../../../shared/xs/actions';
import { GriEntityModel } from '../../../../shared/relevant-theme/relevant-theme.model';

export interface AddThemeActionPayload {
  id: number;
  themes: GriEntityModel[];
}

export class AddThemeAction implements Action {
  static readonly type = '[Impact Scan Revamp] Add theme';

  constructor(public payload: AddThemeActionPayload) {}
}
