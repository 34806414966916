import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SidenavService } from '../sidenav.service';

@Component({
  selector: 'app-navtile',
  templateUrl: './navtile.component.html',
  styleUrls: ['./navtile.component.scss'],
})
export class NavtileComponent {
  private static ASSESSMENT_TITLES = [
    'impact_scan',
    'themes',
    'questionnaire',
    'economic',
    'environmental',
    'social',
    'governance',
    'materiality',
  ];

  @Input()
  img: string | undefined;

  @Input()
  matIcon: string | undefined;

  @Input()
  title = '';

  @Input()
  route: string | undefined;

  @Input()
  exact = false;

  @Input()
  finished = false;

  @Output()
  tilePressed: EventEmitter<void> = new EventEmitter<void>();

  iconColour = 'white';

  isCustomColoured = false;

  constructor(public router: Router, private readonly sidenavService: SidenavService) {
    const customColours = this.sidenavService.getColour();

    if (customColours) {
      this.iconColour = customColours.text;
      this.isCustomColoured = true;
    }
  }

  getClass(): string {
    if (this.isCustomColoured) {
      return 'custom';
    }
    if (NavtileComponent.ASSESSMENT_TITLES.includes(this.title)) {
      return 'assessments';
    }

    return this.title;
  }

  refreshColour(isActive: boolean): void {
    const className = this.getClass();

    if (!this.isCustomColoured) {
      this.iconColour = isActive ? this.getIconColour(className) : 'white';
    }
  }

  /* eslint-disable @typescript-eslint/indent,prettier/prettier,no-trailing-spaces,no-shadow */
  getIconColour(className: string): string {
    switch (className) {
      case 'assessments':
        return '#00BAFF';
      case 'improvement_centre':
        return '#FF45E6';
      case 'results':
        return '#FFCA38';
      case 'companies':
        return '#F73054';
      default:
        return 'white';
    }
  }
  /* eslint-enable @typescript-eslint/indent,prettier/prettier,no-trailing-spaces,no-shadow */
}
