import { Action } from '../../../shared/xs/actions';
import { RelevantThemeModel } from '../../../shared/relevant-theme/relevant-theme.model';
import { MaterialityType } from '../../questionnaire/questionaire/materiality/save-materiality-dto.model';

export class EsgAssessmentUpdateMaterialitiesAction implements Action {
  static readonly type = '[Dashboard] refresh materialities state for the esg assessment';

  constructor(public payload: EsgAssessmentUpdateMaterialitiesActionPayload) {}
}

export interface EsgAssessmentUpdateMaterialitiesActionPayload {
  materiality: RelevantThemeModel[][];
  type: MaterialityType;
}
