import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ImpactScanModel } from '../../../impact-scan-revamp/impact-scan-revamp.model';

@Component({
  selector: 'app-prefill-selector-dropdown',
  templateUrl: './prefill-selector-dropdown.component.html',
  styleUrls: ['./prefill-selector-dropdown.component.scss'],
  animations: [
    trigger('dropdownAnimation', [
      state('open', style({ opacity: 1 })),
      state('closed', style({ opacity: 0 })),
      transition('open <=> closed', [animate('0.15s')]),
    ]),
  ],
})
export class PrefillSelectorDropdownComponent implements OnInit {
  @Input() impactScans: ImpactScanModel[] = [];

  @Input() title = '';

  @Input() openTitle = '';

  @Input() closedTitle = '';

  @Input() selectionLimit = 1;

  @Output() selectionChange = new EventEmitter<string>();

  isOpen = false;

  @Input()
  disabled = false;

  ignoreClickOutside = false;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.impactScans.forEach((impactScan) => {
      // eslint-disable-next-line no-param-reassign
      impactScan.selected = false;
    });
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (this.ignoreClickOutside) {
      this.ignoreClickOutside = false;
    } else if (!this.elementRef.nativeElement.contains(event.target) && this.isOpen) {
      this.isOpen = false;
      this.updateTitle();
    }
  }

  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
    this.updateTitle();
  }

  private updateTitle(): void {
    this.title = this.isOpen ? this.openTitle : this.closedTitle;
  }

  toggleImpactScan(id: number, event: MatCheckboxChange): void {
    if (!event.checked) {
      // eslint-disable-next-line no-param-reassign
      event.source.checked = true;
    }
    this.impactScans.forEach((impactScan) => {
      if (impactScan.id === id) {
        // eslint-disable-next-line no-param-reassign
        impactScan.selected = true;
        setTimeout(() => {
          this.closedTitle = `Impact Scan - ${impactScan.completionDate}`;
          this.isOpen = false;
          this.updateTitle();
        }, 160);
      } else {
        // eslint-disable-next-line no-param-reassign
        impactScan.selected = false;
      }
    });

    this.selectionChange.emit(id.toString());
  }
}
