import { Action } from '../../../shared/xs/actions';
import { RelevantThemeModel } from '../../../shared/relevant-theme/relevant-theme.model';

export class EsgAssessmentLockAnswersAction implements Action {
  static readonly type = '[Dashboard] Lock answers for the last version of the esg assessment';
}

export interface EsgAssessmentLockAnswersActionResponse {
  impactMateriality: RelevantThemeModel[][];
  financialMateriality: RelevantThemeModel[][];
}
