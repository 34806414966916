import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CountryModel, QuestionModel } from '../questions';
import { EeveryTranslateService } from '../eevery.translate.service';
import countryJsonGB from '../../../assets/json/country-code-gb.json';
import countryJsonNL from '../../../assets/json/country-code-nl.json';

@Component({
  selector: 'app-unanswered-pop-up',
  templateUrl: './unanswered-pop-up.component.html',
  styleUrls: ['./unanswered-pop-up.component.scss'],
})
export class UnansweredPopUpComponent implements OnInit {
  list: QuestionModel[] | undefined;

  canProceed: boolean | undefined;

  countryList: CountryModel[] = countryJsonGB as CountryModel[];

  constructor(
    public dialogRef: MatDialogRef<UnansweredPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { list: QuestionModel[]; canProceed: boolean },
    private ngZone: NgZone,
    private translateService: EeveryTranslateService,
  ) {
    this.canProceed = data.canProceed;
    const questionsToSort = [...data.list];

    this.list = questionsToSort.sort((a, b) => {
      if (a.section === b.section) {
        if (a.countryCode === b.countryCode) {
          return a.displayId < b.displayId ? -1 : 1;
        }

        return a.countryCode > b.countryCode ? -1 : 1;
      }

      return a.section < b.section ? -1 : 1;
    });
  }

  ngOnInit(): void {
    if (this.translateService.getLanguage() === 'nl_NL') {
      this.countryList = countryJsonNL as CountryModel[];
    }
  }

  onNoClick(): void {
    this.ngZone.run(() => {
      this.dialogRef.close(false);
    });
  }

  onYesClick(): void {
    this.ngZone.run(() => {
      this.dialogRef.close(true);
    });
  }

  getCountryByCode(countryCode: string | undefined): string {
    if (!countryCode) {
      return '';
    }
    for (const country of this.countryList) {
      if (country.countryCode === countryCode) {
        return country.countryName;
      }
    }

    return '';
  }
}
