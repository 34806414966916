import { Action } from '../../../shared/xs/actions';

export interface SubmitForValidationPayload {
  validatorEmail: string;
}

export class SubmitForValidationAction implements Action {
  static readonly type = '[Scoring] Submit for validation';

  constructor(public payload: SubmitForValidationPayload) {}
}
