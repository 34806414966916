import { Directive, Input, OnChanges, OnInit } from '@angular/core';
import { ESGScore, ManagedEsgScore } from '../../models/scoring.model';
import { FormattedGraphData, GraphDataModel } from '../../graph-data.model';

@Directive()
export abstract class EesgDirective implements OnInit, OnChanges {
  @Input()
  score!: ESGScore;

  @Input()
  managedScore!: ManagedEsgScore[] | undefined;

  doughnutCharts!: FormattedGraphData[];

  blue = '#4440E2';

  beige = '#E6E9EB';

  /* eslint-disable no-invalid-this */
  doughnutChartColors = [
    {
      backgroundColor: [this.blue, this.beige],
      borderColor: this.beige,
      borderWidth: 2,
      pointHoverRadius: 5,
      hoverBorderWidth: 0,
      hoverBackgroundColor: [this.blue, this.beige],
      hoverBorderColor: [this.blue, this.beige],
    },
  ];

  ngOnInit(): void {
    this.scoresInit();
  }

  ngOnChanges(): void {
    this.scoresInit();
  }

  scoresInit(): void {
    const scoringGraphs: GraphDataModel[] = [];

    if (this.score) {
      const keys = ['ECONOMIC', 'ENVIRONMENTAL', 'SOCIAL', 'GOVERNANCE'];

      for (const key of keys) {
        // @ts-ignore
        const value = this.score[key];

        scoringGraphs.push({
          graphName: this.titleCaseWord(key),
          score: value.unweightedScore,
          scoreMax: value.maxScore,
          percentage: Math.round((value.unweightedScore / value.maxScore) * 100),
        });
      }
    } else if (this.managedScore) {
      for (const score of this.managedScore) {
        scoringGraphs.push({
          graphName: this.titleCaseWord(score.category),
          score: score.score,
          scoreMax: score.maxScore,
          percentage: Math.round((score.score / score.maxScore) * 100),
        });
      }
    } else {
      return;
    }
    this.doughnutCharts = this.formatDataForChart(scoringGraphs);
  }

  titleCaseWord(word: string): string {
    if (!word) {
      return word;
    }

    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  formatDataForChart(scoringDataSet: GraphDataModel[]): FormattedGraphData[] {
    const formattedCharts = [];

    for (const scoringData of scoringDataSet) {
      const chartData = [[scoringData.score, scoringData.scoreMax - scoringData.score]];

      const chartOptions = {
        aspectRatio: 1.5,
        tooltips: { enabled: false },
        elements: {
          center: {
            // the longest text that could appear in the center
            maxText: '35/35',
            text: `${scoringData.percentage.toFixed()}%`,
            fontColor: '#263A46',
            fontFamily: 'oxygen, serif',
            fontStyle: 'normal',
            fontSize: 18,
            minFontSize: 18,
            maxFontSize: 256,
          },
        },
        cutoutPercentage: 80,
        animation: { animateRotate: true },
      };

      formattedCharts.push({
        chartData,
        chartOptions,
        chartName: scoringData.graphName,
      });
    }

    return formattedCharts;
  }
}
