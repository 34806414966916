import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FrontpageComponent } from './login';
import {
  ConfirmEmailComponent,
  CreateAccountComponent,
  ResetPasswordCheckComponent,
  ResetPasswordComponent,
} from './signup';
import { PaymentComponent } from './signup/create-account/payment/payment.component';
import { ConfirmFailedComponent } from './signup/confirm-failed/confirm-failed.component';
import { FreeComponent } from './signup/create-account/free/free.component';
import { InvitedComponent } from './signup/create-account/invited/invited.component';
import { SsoLogoutComponent } from './sso/sso-logout/sso-logout.component';
import { SsoCallbackComponent } from './sso/sso-callback/sso-callback.component';
import { LoggedInGuard } from './logged-in.guard';
import { SsoFrontpageComponent } from './sso/sso-login/sso-frontpage';

const authRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    canActivate: [LoggedInGuard],
    component: FrontpageComponent,
  },
  {
    path: 'signup',
    component: CreateAccountComponent,
  },
  {
    path: 'signup/confirm',
    component: ConfirmEmailComponent,
  },
  {
    path: 'signup/confirm-failed',
    component: ConfirmFailedComponent,
  },
  {
    path: 'signup/payment',
    component: PaymentComponent,
  },
  {
    path: 'signup/terms-and-conditions',
    component: FreeComponent,
  },
  {
    path: 'signup/invite',
    component: InvitedComponent,
  },
  {
    path: 'password/forgot',
    component: ResetPasswordComponent,
  },
  {
    path: 'password/confirm',
    component: ResetPasswordCheckComponent,
  },
  {
    path: ':provider/login',
    canActivate: [LoggedInGuard],
    component: SsoFrontpageComponent,
  },
  {
    path: ':provider/callback',
    component: SsoCallbackComponent,
  },
  {
    path: ':provider/logout',
    component: SsoLogoutComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule],
})
export class AuthRouting {}
