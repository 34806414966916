import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-gridtiles',
  templateUrl: './gridtiles.component.html',
  styleUrls: ['./gridtiles.component.scss'],
})
export class GridtilesComponent {
  @Input()
  header = 'temp';

  @Input()
  text = 'temp';
}
