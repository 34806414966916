import { Injectable } from '@angular/core';
import { ImpactScanRevampModel } from './impact-scan-revamp.model';
import { UnansweredQuestionModel } from '../../../shared/unanswered-popup-simple/unanswered-question.model';

@Injectable({ providedIn: 'root' })
export class ImpactScanRevampValidatorService {
  validateImpactScan(answers: ImpactScanRevampModel): Set<UnansweredQuestionModel> {
    const result = new Set<UnansweredQuestionModel>();

    this.validateCompanyContextOne(answers).forEach((unanswered) => result.add(unanswered));
    this.validateCompanyContextTwo(answers).forEach((unanswered) => result.add(unanswered));
    this.validateGovernance(answers).forEach((unanswered) => result.add(unanswered));
    this.validateStakeholders(answers).forEach((unanswered) => result.add(unanswered));

    return result;
  }

  isValid(category: string, answers: ImpactScanRevampModel): boolean {
    switch (category) {
      case 'company_context_one':
        return this.validateCompanyContextOne(answers).size === 0;
      case 'company_context_two':
        return this.validateCompanyContextTwo(answers).size === 0;
      case 'governance':
        return this.validateGovernance(answers).size === 0;
      case 'stakeholders':
        return this.validateStakeholders(answers).size === 0;
      default:
        return false;
    }
  }

  private validateCompanyContextOne(answers: ImpactScanRevampModel): Set<UnansweredQuestionModel> {
    const result = new Set<UnansweredQuestionModel>();
    const category = 'impact_scan.categories.company_context_one';
    const categoryOrder = 1;

    if (!answers.sectorCode || answers.sectorCode.trim() === '') {
      result.add({ id: '1', category, categoryOrder });
    }

    if (this.isNullOrUndefined(answers.numberOfEmployees)) {
      result.add({ id: '2', category, categoryOrder });
    }

    if (this.isNullOrUndefined(answers.totalRevenue)) {
      result.add({ id: '3', category, categoryOrder });
    }

    if (this.isNullOrUndefined(answers.totalAssets)) {
      result.add({ id: '4', category, categoryOrder });
    }

    let customersValid = true;

    answers.customers.forEach((customer) => {
      let isValid = true;

      isValid = isValid && customer.name?.trim() !== '';
      isValid = isValid && customer.countryCode?.trim() !== '';

      customersValid = customersValid && isValid;
    });
    if (!customersValid) {
      result.add({ id: '5', category, categoryOrder });
    }

    return result;
  }

  private validateCompanyContextTwo(answers: ImpactScanRevampModel): Set<UnansweredQuestionModel> {
    const result = new Set<UnansweredQuestionModel>();
    const category = 'impact_scan.categories.company_context_two';
    const categoryOrder = 2;

    let subsidiariesValid = true;

    if (answers.subsidiaries) {
      answers.subsidiaries.forEach((subsidiary) => {
        let isValid = true;

        isValid = isValid && subsidiary.name?.trim() !== '';
        isValid = isValid && subsidiary.country?.trim() !== '';
        isValid = isValid && subsidiary.sectorCode?.trim() !== '';
        isValid = isValid && !this.isNullOrUndefined(subsidiary.numberOfEmployees);
        isValid = isValid && !this.isNullOrUndefined(subsidiary.percentOfOwnership);
        isValid = isValid && this.isValidPercentage(subsidiary.percentOfOwnership);

        subsidiariesValid = subsidiariesValid && isValid;
      });
    }
    if (!subsidiariesValid) {
      result.add({ id: '6', category, categoryOrder });
    }

    if (!answers.products || answers.products.trim() === '') {
      result.add({ id: '7', category, categoryOrder });
    }

    return result;
  }

  private validateGovernance(answers: ImpactScanRevampModel): Set<UnansweredQuestionModel> {
    const result = new Set<UnansweredQuestionModel>();
    const category = 'impact_scan.categories.governance';
    const categoryOrder = 3;

    if (!answers.legalEntityType) {
      result.add({ id: '8', category, categoryOrder });
    }

    if (!answers.executiveDirectors) {
      result.add({ id: '9', category, categoryOrder });
    } else if (answers.executiveDirectors > 1 && this.isNullOrUndefined(answers.hasNonExecutiveDirectors)) {
      result.add({ id: '9.1', category, categoryOrder });
    }

    if (this.isNullOrUndefined(answers.hasMultipleShareholders)) {
      result.add({ id: '10', category, categoryOrder });
    } else if (answers.hasMultipleShareholders && !answers.controlType) {
      result.add({ id: '10.1', category, categoryOrder });
    }

    return result;
  }

  private validateStakeholders(answers: ImpactScanRevampModel): Set<UnansweredQuestionModel> {
    const result = new Set<UnansweredQuestionModel>();
    const category = 'impact_scan.categories.stakeholders';
    const categoryOrder = 4;

    let suppliersValid = true;

    if (answers.suppliers) {
      answers.suppliers.forEach((supplier) => {
        let isValid = true;

        isValid = isValid && supplier.name?.trim() !== '';
        isValid = isValid && supplier.countryCode?.trim() !== '';
        isValid = isValid && !this.isNullOrUndefined(supplier.percentage);
        isValid = isValid && this.isValidPercentage(supplier.percentage);

        suppliersValid = suppliersValid && isValid;
      });
      if (!suppliersValid) {
        result.add({ id: '11', category, categoryOrder });
      }
    }

    if (!answers.stakeholders) {
      result.add({ id: '12', category, categoryOrder });
    }

    if (this.isNullOrUndefined(answers.hasOtherStakeholders)) {
      result.add({ id: '13', category, categoryOrder });
    } else if (answers.hasOtherStakeholders && answers.otherStakeholders.length < 1) {
      result.add({ id: '13.1', category, categoryOrder });
    }

    return result;
  }

  private isNullOrUndefined(value: unknown): boolean {
    return value === undefined || value === null;
  }

  private isValidPercentage(percentage: number | undefined): boolean {
    if (percentage) {
      return percentage >= 0 && percentage <= 100;
    }

    return false;
  }
}
