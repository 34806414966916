/* eslint-disable @typescript-eslint/no-explicit-any */
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AssessmentListComponent } from './assessment-list/assessment-list.component';

const dashboardRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'assessments',
    component: AssessmentListComponent,
  },
  {
    path: 'home',
    loadChildren: (): Promise<any> => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'questionnaire',
    loadChildren: (): Promise<any> =>
      import('./questionnaire/questionaire/questionnaire.module').then((m) => m.QuestionnaireModule),
  },
  {
    path: 'esg-assessment',
    loadChildren: (): Promise<any> =>
      import('./esg-assessment/esg-assessment.module').then((m) => m.EsgAssessmentModule),
  },
  {
    path: 'impact-scan',
    loadChildren: (): Promise<any> =>
      import('./questionnaire/impact-scan/impact-scan.module').then((m) => m.ImpactScanModule),
  },
  {
    path: 'impact-scan-v2',
    loadChildren: (): Promise<any> =>
      import('./questionnaire/impact-scan-revamp/impact-scan-revamp.module').then((m) => m.ImpactScanRevampModule),
  },
  {
    path: 'scoreboard',
    loadChildren: (): Promise<any> => import('./scoreboard/scoreboard.module').then((m) => m.ScoreboardModule),
  },
  {
    path: 'profile',
    loadChildren: (): Promise<any> => import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'invite',
    loadChildren: (): Promise<any> => import('./../invite-users/invite.module').then((m) => m.InviteModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(dashboardRoutes)],
  exports: [RouterModule],
})
export class DashboardRouting {}
