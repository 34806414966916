import { Component, OnInit } from '@angular/core';
import { AbstractQuestionDirective } from '../abstract-question.directive';

@Component({
  selector: 'app-slider-question',
  templateUrl: './slider-number.component.html',
  styleUrls: ['./slider-number.component.scss'],
})
export class SliderNumberComponent extends AbstractQuestionDirective implements OnInit {
  value: number | undefined;

  ngOnInit(): void {
    super.ngOnInit();
    this.value = this.answerModel.answer.value;
  }

  onBlur(): void {
    if (this.disabled) {
      return;
    }
    this.answerModel.answer.value = this.value;
    this.answerChanged();
  }
}
