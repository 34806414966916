import { GetCompanyResponse } from './get-company.action';
import { Action } from '../../../../../shared/xs/actions';

export interface LookupCompanyActionPayload {
  country: string;
  registeredId: string;
}

export class LookupCompanyAction implements Action {
  static readonly type = '[Company] Lookup Company';

  constructor(public payload: LookupCompanyActionPayload) {}
}

export interface LookupCompanyActionPayloadResponse extends GetCompanyResponse {
  dataImmutable: boolean;
  logoSrc: string;
}
