import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-company-removed-popup',
  templateUrl: './company-removed-popup.component.html',
  styleUrls: ['./company-removed-popup.component.scss'],
})
export class CompanyRemovedPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<CompanyRemovedPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private ngZone: NgZone,
  ) {}

  onClose(): void {
    this.ngZone.run(() => {
      this.dialogRef.close(false);
    });
  }

  onYesClick(): void {
    this.ngZone.run(() => {
      this.dialogRef.close(true);
    });
  }
}
