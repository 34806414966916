import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { LoginAction, LoginActionPayload } from '../xs';
import { NgForm } from '@angular/forms';
import { CheckDashboardAction } from '../../dashboard';
import { MatDialog } from '@angular/material/dialog';
import { PopUpComponent } from '../../shared/pop-up/pop-up.component';
import { CheckResultsAction } from '../../dashboard/xs/actions/results-checked.action';
import { CheckImprovementAction } from '../../dashboard/xs/actions/improvement-checked.action';
import { CheckAssessmentsAction } from '../../dashboard/xs/actions/assessments-checked.action';
import { CheckEnterpriseDashboardAction } from '../../enterprise/xs/actions/dashboard-checked.action';
import { CheckPortfolioAction } from '../../enterprise/xs/actions/portfolio-checked.action';
import { CheckViewModeAction } from '../../enterprise/xs/actions/view-mode-checked.action';
import { CheckCo2Action } from '../../co2-calculator/xs/actions/check-co2.action';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public user: { email: string; password: string } = {
    email: '',
    password: '',
  };

  showPassword = false;

  @ViewChild('form') form!: NgForm;

  constructor(
    private dialog: MatDialog,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.signupSuccess) {
        this.showSignUpSuccess();
      }
      if (params.email) {
        this.user.email = params.email;
      }
    });
  }

  showSignUpSuccess(): void {
    this.dialog.open(PopUpComponent, {
      width: '400px',
      autoFocus: false,
      data: {
        title: 'login.pop_up.header',
        message: 'login.pop_up.text',
        roundButton: true,
      },
    });
  }

  setFormValid(): void {
    this.form.controls.username.setErrors({ invalid: null });
    this.form.controls.password.setErrors({ invalid: null });
    this.form.controls.username.updateValueAndValidity();
    this.form.controls.password.updateValueAndValidity();
  }

  async login(): Promise<void> {
    try {
      await this.authService.dispatchActionAndWait(new LoginAction(this.user as LoginActionPayload));

      this.authService.dispatchAction(new CheckDashboardAction({ hasBeenChecked: false }));
      this.authService.dispatchAction(new CheckAssessmentsAction({ hasBeenChecked: false }));
      this.authService.dispatchAction(new CheckResultsAction({ hasBeenChecked: false }));
      this.authService.dispatchAction(new CheckImprovementAction({ hasBeenChecked: false }));
      this.authService.dispatchAction(new CheckEnterpriseDashboardAction({ hasBeenChecked: false }));
      this.authService.dispatchAction(new CheckPortfolioAction({ hasBeenChecked: false }));
      this.authService.dispatchAction(new CheckViewModeAction({ hasBeenChecked: false }));
      this.authService.dispatchAction(new CheckCo2Action({ hasBeenChecked: false }));
    } catch (e) {
      this.form.controls.username.setErrors(['invalid']);
      this.form.controls.password.setErrors(['invalid']);
    }
  }

  forgotPassword(): void {
    this.router.navigate(['auth/password/forgot']);
  }
}
