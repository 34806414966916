import { Action } from '../../../shared/xs/actions';

export interface CheckViewModeActionPayload {
  hasBeenChecked: boolean;
}

export class CheckViewModeAction implements Action {
  static readonly type = '[Enterprise] View-mode checked';

  constructor(public payload: CheckViewModeActionPayload) {}
}
