import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ImpactScanRevampModel } from './impact-scan-revamp.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopUpModel } from '../../../shared/pop-up/pop-up.model';
import { PopUpComponent } from '../../../shared/pop-up/pop-up.component';
import { NgModel } from '@angular/forms';

@Directive()
export abstract class ImpactScanPageDirective {
  @Input()
  impactScan!: ImpactScanRevampModel;

  @Input()
  country = 'gb';

  @Input()
  language!: string;

  @Input()
  disabled?: boolean;

  @Input()
  highLightUnansweredQuestion?: boolean;

  @Output()
  saveAnswer = new EventEmitter<ImpactScanRevampModel>();

  constructor(protected dialog: MatDialog) {}

  onAnswerChanged(): void {
    this.saveAnswer.emit(this.impactScan);
  }

  onAnswerWithPercentageChanged(control: NgModel): void {
    control.control.markAsTouched();
    this.saveAnswer.emit(this.impactScan);
  }

  showPopUp(data: PopUpModel): MatDialogRef<PopUpComponent, unknown> {
    return this.dialog.open(PopUpComponent, { width: '60vw', autoFocus: false, data });
  }

  checkNumberBooleanFieldError(value: number | boolean | undefined | null): boolean {
    return value === undefined || value === null;
  }
}
