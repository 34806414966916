import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// @ts-ignore
import { roundEdgesPlugin, textArrayInMiddlePlugin } from '../../../../../assets/js/chart-plugin';
import { EsgPopupComponent } from '../popup/esg-popup.component';
import { EsgPopupModel } from '../popup/esg-popup.model';
import { FormattedGraphData, GraphDataModel } from '../../graph-data.model';
import { Assessment, Topic } from '../../models/topic.model';
import { RatioScore } from '../../models/scoring.model';
import { ScoreboardService } from '../../scoreboard.service';
import { BaseChartDirective, MultiDataSet } from 'ng2-charts';
import { EeveryTranslateService } from '../../../../shared/eevery.translate.service';
import { EsgOverTimeComponent } from './esg-over-time/esg-over-time.component';
import { OptionItem } from '../../shared/slider-toggle/slider-group.component';

@Component({
  selector: 'app-esg-chart',
  templateUrl: './esg-chart.component.html',
  styleUrls: ['./esg-chart.component.scss'],
})
export class EsgChartComponent implements OnInit {
  @Input()
  scores: Topic[] = [];

  @Input()
  indicators!: RatioScore;

  @Output()
  placeholderButtonClick = new EventEmitter<void>();

  @ViewChild(BaseChartDirective)
  chart?: BaseChartDirective;

  @ViewChild(EsgOverTimeComponent) esgOverTimeComponent!: EsgOverTimeComponent;

  doughnutCharts!: FormattedGraphData[];

  blue = '#4440E2';

  turquoise = '#00BAFF';

  beige = '#E6E9EB';

  animationInProgress = false;

  /* eslint-disable no-invalid-this */
  esgColorsDouble = [
    {
      backgroundColor: [this.blue, this.beige],
      borderColor: this.beige,
      borderWidth: 2,
      pointHoverRadius: 5,
      hoverBorderWidth: 0,
      hoverBackgroundColor: [this.blue, this.beige],
      hoverBorderColor: [this.blue, this.beige],
    },
    {
      backgroundColor: [this.turquoise, this.beige],
      borderColor: this.beige,
      borderWidth: 2,
      pointHoverRadius: 5,
      hoverBorderWidth: 0,
      hoverBackgroundColor: [this.turquoise, this.beige],
      hoverBorderColor: [this.turquoise, this.beige],
    },
  ];

  scoringGraphs: GraphDataModel[] = [];

  scoringSets: GraphDataModel[][] = [];

  sliderItems: OptionItem[] = [];

  readonly roundEdgesPlugin = roundEdgesPlugin;

  readonly textArrayInMiddlePlugin = textArrayInMiddlePlugin;

  hasMultipleAssessmentsSelected = false;

  currentFormattedCharts?: FormattedGraphData[];

  placeholderChart = false;

  categories = ['Economic', 'Environmental', 'Social', 'Governance'];

  sliderPositions: (number | null)[] = [0, null];

  constructor(
    private dialog: MatDialog,
    private scoreboardService: ScoreboardService,
    private translateService: EeveryTranslateService,
  ) {}

  ngOnInit(): void {
    // this.scoresInit();

    this.scoreboardService.currentTopic.subscribe((scores) => {
      if (scores) {
        this.scores = scores;
        // this.updateScores();
        this.initScores();
      }
    });
  }

  initScores(): void {
    this.scoringSets = [];
    this.sliderItems = [];

    this.scores
      .filter((topic) => topic.selected || topic.indeterminate)
      .reverse()
      .forEach((score) => {
        score.assessments
          .filter((assessment) => assessment.selected)
          .reverse()
          .forEach((assessment) => {
            this.scoringGraphs = [];

            if (assessment.scoring?.deprecatedLatest) {
              this.createDeprecatedScoringGraphData(assessment);
            } else if (assessment.scoring?.scoring) {
              this.createScoringGraphData(assessment);
            }

            this.scoringSets.push(this.scoringGraphs);
            this.sliderItems.push({ alias: this.scoringGraphs[0].alias, id: this.scoringGraphs[0].id });
          });
      });

    this.sliderPositions = [this.sliderItems.length - 1, null];

    this.hasMultipleAssessmentsSelected = false;
    this.doughnutCharts = this.formatDataForChart(this.scoringSets);

    this.placeholderChart = this.doughnutCharts.length === 0;
  }

  private createDeprecatedScoringGraphData(assessment: Assessment): void {
    const categories = ['ECONOMIC', 'ENVIRONMENTAL', 'SOCIAL', 'GOVERNANCE'];

    categories.forEach((category) => {
      // @ts-ignore
      const score = assessment.scoring.deprecatedScoring.scores.ESG.scores[category];

      this.scoringGraphs.push({
        graphName: this.titleCaseWord(category),
        score: score.unweightedScore,
        scoreMax: score.maxScore,
        percentage: Math.round((score.unweightedScore / score.maxScore) * 100),
        id: assessment.id,
        alias: assessment.name,
      });
    });
  }

  private createScoringGraphData(assessment: Assessment): void {
    for (const esgScore of assessment.scoring.scoring.esg) {
      this.scoringGraphs.push({
        graphName: this.titleCaseWord(esgScore.category),
        score: esgScore.score,
        scoreMax: esgScore.maxScore,
        percentage: Math.round((esgScore.score / esgScore.maxScore) * 100),
        id: assessment.id,
        alias: assessment.name,
      });
    }
  }

  formatDataForChart(scoringDataSet: GraphDataModel[][]): FormattedGraphData[] {
    const formattedCharts: FormattedGraphData[] = [];

    const firstChart = scoringDataSet[scoringDataSet.length - 1];

    if (!firstChart) {
      return this.getPlaceholderDonutCharts();
    }

    firstChart.forEach((scoringData) => {
      const chartData: MultiDataSet = [[scoringData.score, scoringData.scoreMax - scoringData.score]];
      const chartOptions = this.getChartOptions(scoringData, 80);

      formattedCharts.push({
        chartData,
        chartOptions,
        chartName: scoringData.graphName,
        id: scoringData.id,
        alias: scoringData.alias,
      });
    });

    this.currentFormattedCharts = formattedCharts;

    return formattedCharts;
  }

  updateChartData(toggleId: string, sliderPositions: (number | null)[]): void {
    const formattedCharts: FormattedGraphData[] = [];
    const { scoringSets } = this;
    const slider1Selected = sliderPositions[0] !== null;
    const slider2Selected = sliderPositions[1] !== null;

    const scoringSetIndex = slider1Selected ? sliderPositions[0] : sliderPositions[1];

    if ((!slider1Selected && !slider2Selected) || scoringSetIndex === null) {
      formattedCharts.push(...this.getPlaceholderDonutCharts());
      this.placeholderChart = true;
    } else {
      const firstChart = scoringSets[scoringSetIndex];

      if (this.doughnutCharts.length === 0) {
        firstChart.forEach((scoringData) => {
          const chartData = [[scoringData.score, scoringData.scoreMax - scoringData.score]];
          const chartOptions = this.getChartOptions(scoringData, 80);

          formattedCharts.push({
            chartData,
            chartOptions,
            chartName: scoringData.graphName,
            id: scoringData.id,
            alias: scoringData.alias,
          });
        });
        this.currentFormattedCharts = formattedCharts;
      }

      scoringSets[scoringSetIndex].forEach((scoringData, index) => {
        this.placeholderChart = false;
        const formattedChart = this.doughnutCharts.find((chart) => chart.chartName === scoringData.graphName);

        if (formattedChart) {
          // @ts-ignore
          formattedChart?.chartData = [[scoringData.score, scoringData.scoreMax - scoringData.score]];
          // @ts-ignore
          formattedChart?.chartOptions = this.getChartOptions(scoringData, 80);
          this.hasMultipleAssessmentsSelected = false;

          if (slider1Selected && slider2Selected) {
            this.hasMultipleAssessmentsSelected = true;
            const scoringData2 = scoringSets[sliderPositions[1] ?? 0][index];

            let newScore = null;
            let newScoreMax = null;

            newScore = scoringData2.score;
            newScoreMax = scoringData2.scoreMax;
            if (newScore !== null && newScoreMax !== null) {
              formattedChart?.chartData.push([newScore, newScoreMax - newScore]);
              // @ts-ignore
              formattedChart?.chartOptions = this.getChartOptions(scoringData, 60);
              formattedChart?.chartOptions.elements.center.push({
                maxText: '35/35',
                text: `${scoringData2.percentage.toFixed()}%`,
                fontColor: this.turquoise,
                fontFamily: 'oxygen, serif',
                fontStyle: 'normal',
                fontSize: 18,
                minFontSize: 18,
                maxFontSize: 256,
              });
            }
          }
          formattedCharts.push(formattedChart);
        }
      });
    }
    this.doughnutCharts = formattedCharts;

    // if (this.chart) {
    //   this.chart.update();
    // }
  }

  // eslint-disable-next-line
  private getChartOptions(scoringData: GraphDataModel, cutoutPercentage: number) {
    return {
      aspectRatio: 1.6,
      tooltips: { enabled: false },
      elements: {
        center: [
          {
            // the longest text that could appear in the center
            maxText: '35/35',
            text: `${scoringData.percentage.toFixed()}%`,
            fontColor: this.blue,
            fontFamily: 'oxygen, serif',
            fontStyle: 'normal',
            fontSize: 18,
            minFontSize: 18,
            maxFontSize: 256,
          },
        ],
      },
      cutoutPercentage,
      animation: {
        animateRotate: true,
        duration: 1000,
        easing: 'easeOutQuart',
      },
    };
  }

  toggleChartSet(event: { sliderId: string; sliderPositions: (number | null)[] }): void {
    this.sliderPositions = event.sliderPositions;
    this.updateChartData(event.sliderId, event.sliderPositions);
  }

  titleCaseWord(word: string): string {
    if (!word) {
      return word;
    }

    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  private getPlaceholderDonutCharts(): FormattedGraphData[] {
    const formattedCharts: FormattedGraphData[] = [];

    for (let i = 0; i < 4; i++) {
      const chartData: MultiDataSet = [[0, 1]];
      const chartOptions = this.getChartOptions({ graphName: '', score: 0, scoreMax: 100, percentage: 0 }, 80);

      formattedCharts.push({
        chartData,
        chartOptions,
        chartName: this.categories[i],
        id: '',
        alias: '',
      });
    }

    return formattedCharts;
  }

  toggleDropdown(): void {
    this.placeholderButtonClick.emit();
  }

  showChartsPopup(chartName: string): void {
    const language = this.translateService.getLanguage();

    if (this.sliderPositions[0] !== null || this.sliderPositions[1] !== null) {
      const data: EsgPopupModel = {
        category: chartName,
        type: 'doughnut',
        assessments: this.scoreboardService.buildEsgPopupAssessments(chartName.toLowerCase(), language),
        selectedAssessments: this.sliderPositions,
      };

      this.dialog.open(EsgPopupComponent, { width: '60vw', autoFocus: false, data });
    }
  }

  showOvertimeChartPopup(quarterPosition: number): void {
    const category = this.categories[quarterPosition];
    const data: EsgPopupModel = {
      category,
      type: 'overtime',
      series: this.esgOverTimeComponent.getAbsolutSeries(),
      categoryIndex: quarterPosition,
      scoringSets: this.scoringSets,
    };

    this.dialog.open(EsgPopupComponent, { width: '60vw', autoFocus: false, data });
  }
}
