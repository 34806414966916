import { Action } from '../../../../shared/xs/actions';

export interface CalculateThemesPayload {
  id: number;
}

export class CalculateThemesAction implements Action {
  static readonly type = '[Impact Scan Revamp] Calculate themes';

  constructor(public payload: CalculateThemesPayload) {}
}
