/* eslint-disable @typescript-eslint/indent */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../auth.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PrivacyModel } from '../privacy-pop-up/privacy.model';
import { PrivacyPopUpComponent } from '../privacy-pop-up/privacy-pop-up.component';
import { EeveryTranslateService } from '../../../../shared/eevery.translate.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {
  role = 'SME';

  popUpData = {
    TACName: '',
    TACTitle: '',

    SAName: '',
    SATitle: '',
  };

  subscriptionAgreementApproved = false;

  termsOfServiceApproved = false;

  @Input()
  showPrice = true;

  @Input()
  canButtonBecomeActive = false;

  @Output()
  policiesChecked = new EventEmitter<boolean>();

  @Output()
  buttonClicked = new EventEmitter<void>();

  constructor(
    private readonly authService: AuthService,
    private readonly translateService: EeveryTranslateService,
    public dialog: MatDialog,
  ) {}

  async ngOnInit(): Promise<void> {
    this.refreshLanguage();
  }

  refreshLanguage(): void {
    this.authService.initLocation().finally(() => {
      this.translateService.refreshLanguage();
      this.checkForLanguage();
    });
  }

  checkForLanguage(): void {
    const language = this.getPaymentLanguage();

    switch (language) {
      case 'NETHERLANDS':
        this.popUpData.TACName = 'TAC_NL.html';
        this.popUpData.SAName = 'SA_NL.html';

        this.popUpData.TACTitle = 'Algemene voorwaarden';
        this.popUpData.SATitle = 'Abbonementsovereenkomst';
        break;
      case 'UNITED_KINGDOM':
      default:
        this.popUpData.TACName = 'TAC_UK.html';
        this.popUpData.SAName = 'SA_UK.html';

        this.popUpData.TACTitle = 'Terms and Conditions';
        this.popUpData.SATitle = 'Subscription Agreement';
    }
  }

  showSubscriptionAgreement(): void {
    const data: PrivacyModel = {
      title: this.popUpData.SATitle,
      filename: this.popUpData.SAName,
    };

    this.show(data, true)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.subscriptionAgreementApproved = result;
          this.checkboxChanged();
        }
      });
  }

  showTAC(): void {
    const data: PrivacyModel = {
      title: this.popUpData.TACTitle,
      filename: this.popUpData.TACName,
    };

    this.show(data, true)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.termsOfServiceApproved = result;
          this.checkboxChanged();
        }
      });
  }

  private show(data: PrivacyModel, showAcceptButton?: boolean): MatDialogRef<PrivacyPopUpComponent> {
    return this.dialog.open(PrivacyPopUpComponent, {
      width: '90vw',
      autoFocus: false,
      data: { agreement: data, canAccept: showAcceptButton },
    });
  }

  getPaymentLanguage(): string | undefined {
    return this.authService.getPaymentCountry();
  }

  checkboxChanged(): void {
    this.policiesChecked.next(this.termsOfServiceApproved && this.subscriptionAgreementApproved);
  }

  public onButtonClick(): void {
    if (this.canButtonBecomeActive && this.termsOfServiceApproved && this.subscriptionAgreementApproved) {
      this.buttonClicked.next();
    }
  }
}
