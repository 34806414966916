import { iso31661 } from 'iso-3166';
import { EeveryTranslateService } from '../eevery.translate.service';

const dutchLikeCountryCodes = ['NL', 'BE'];

export const listOfSupportedCountries: string[] = iso31661
  .map((iso3166Entry) => iso3166Entry.alpha2.toLowerCase())
  .filter((countryCode) => countryCode.toLowerCase() !== 'cg');

export async function sortCountriesByTranslation(
  input: string[],
  translateService: EeveryTranslateService,
  translationPrefix: string,
  toUpperCase: boolean,
): Promise<string[]> {
  const translationPromises = input.map(async (item) => {
    const translation = await translateService.get(translationPrefix + item);

    return { item, translation };
  });

  const translatedArray = await Promise.all(translationPromises);

  translatedArray.sort((a, b) => {
    if (a.item.toLowerCase() === 'nl') {
      return -1;
    }

    if (['nl', 'gb'].includes(b.item.toLowerCase())) {
      return 1;
    }

    if (a.item.toLowerCase() === 'gb') {
      return -1;
    }

    return a.translation.localeCompare(b.translation);
  });

  return translatedArray.map((entry) => (toUpperCase ? entry.item.toUpperCase() : entry.item.toLowerCase()));
}

export function isCountryCodeDutchLike(countryCode: string | undefined | null): boolean {
  return dutchLikeCountryCodes.includes(countryCode?.toUpperCase() ?? '');
}
