import { Component } from '@angular/core';
import { EesgDirective } from '../eesg.directive';
import { MatDialog } from '@angular/material/dialog';
// @ts-ignore
import { roundEdgesPlugin, textInMiddlePlugin } from 'src/assets/js/chart-plugin';
import { EesgPopupModel } from '../eesg-popup/eesg-popup.model';
import { EesgPopupComponent } from '../eesg-popup/eesg-popup.component';

@Component({
  selector: 'app-eesg-charts',
  templateUrl: './eesg-charts.component.html',
  styleUrls: ['./eesg-charts.component.scss'],
})
export class EesgChartsComponent extends EesgDirective {
  readonly roundEdgesPlugin = roundEdgesPlugin;

  readonly textInMiddlePlugin = textInMiddlePlugin;

  constructor(private dialog: MatDialog) {
    super();
  }

  showChartsPopup(chartName: string): void {
    let category;

    // eslint-disable-next-line default-case
    switch (chartName) {
      case 'Economic':
        category = this.score.ECONOMIC;
        break;
      case 'Environmental':
        category = this.score.ENVIRONMENTAL;
        break;
      case 'Social':
        category = this.score.SOCIAL;
        break;
      case 'Governance':
        category = this.score.GOVERNANCE;
        break;
    }
    const percentage =
      category !== undefined ? Math.round((category.unweightedScore / category.maxScore) * 100) : undefined;

    const data: EesgPopupModel = {
      category: `${chartName.toLowerCase()}`,
      percentage,
    };

    this.dialog.open(EesgPopupComponent, { width: '60vw', autoFocus: false, data });
  }
}
