import { Action } from '../../../../../shared/xs/actions';

export interface SetSliderPositionsActionPayload {
  positionIndex?: number | undefined;
  optionsLength?: number;
  origin?: number;
}

export class SetSliderPositionsAction implements Action {
  static readonly type = '[sliderPosition] set current positions for slide';

  constructor(public payload: SetSliderPositionsActionPayload) {}
}
