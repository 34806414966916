import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { SsoLoginComponent } from './sso-login/sso-login.component';
import { SsoCallbackComponent } from './sso-callback/sso-callback.component';
import { SsoLogoutComponent } from './sso-logout/sso-logout.component';
import { SsoService } from './sso.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MaterialDesignModule, SharedModule } from '../../shared';
import { TranslateModule } from '@ngx-translate/core';
import { SsoFrontpageComponent } from './sso-login/sso-frontpage';
import { SsoLeftWebsiteComponent } from './sso-login/sso-left-website/sso-left-website.component';

@NgModule({
  declarations: [
    SsoLoginComponent,
    SsoCallbackComponent,
    SsoLogoutComponent,
    SsoFrontpageComponent,
    SsoLeftWebsiteComponent,
  ],
  imports: [
    AuthModule.forRoot({
      config: {
        redirectUrl: `${window.location.origin}/mha/auth/callback`,
        postLogoutRedirectUri: `${window.location.origin}/mha/auth/login`,
        // MHA real tenant
        authority: 'https://login.microsoftonline.com/3fc789ab-a3b5-4afa-8dd1-063ef8005bff/v2.0',
        clientId: '1c2887ea-4bbb-4026-8025-86c1824667b5',
        scope: 'openid profile 1c2887ea-4bbb-4026-8025-86c1824667b5/.default',
        // local testing
        // authority: 'https://login.microsoftonline.com/69552971-c311-467c-8c1f-e896dbc31796/v2.0',
        // clientId: 'ffe1d181-7314-4af0-a78c-289ec3cf40b7',
        // scope: 'openid profile ffe1d181-7314-4af0-a78c-289ec3cf40b7/User.Read',
        logLevel: LogLevel.Debug,
        responseType: 'code',
        useRefreshToken: true,
        maxIdTokenIatOffsetAllowedInSeconds: 600,
        autoUserInfo: false,
        // login, consent
        customParamsAuthRequest: { prompt: 'select_account' },
      },
    }),
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MaterialDesignModule,
    SharedModule,
    TranslateModule,
    AuthModule,
  ],
  providers: [SsoService],
})
export class AuthConfigModule {}
