import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AbstractQuestionDirective } from '../abstract-question.directive';

@Component({
  selector: 'app-open-question-textarea',
  templateUrl: './open-question-textarea.component.html',
  styleUrls: ['./open-question-textarea.component.scss'],
})
export class OpenQuestionTextareaComponent extends AbstractQuestionDirective {
  value!: number | string;

  @ViewChild('openQuestionForm') form!: NgForm;

  validate(): void {
    // this.clearErrors();
    //
    // if (!this.validateCallBack(this.value)) {
    //   this.form.controls.openQuestionInput.setErrors(['invalid']);
    // }
  }

  clearErrors(): void {
    // this.form.controls.openQuestionInput.setErrors({ invalid: null });
    // this.form.controls.openQuestionInput.updateValueAndValidity();
  }
}
