import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RelevantThemeModel } from '../../../../../shared/relevant-theme/relevant-theme.model';
import { ChangedThemePopupModel, FormattedChangedTheme } from './changed-theme-popup.model';
import { EeveryTranslateService } from '../../../../../shared/eevery.translate.service';

@Component({
  selector: 'app-changed-theme-popup',
  templateUrl: './changed-theme-popup.component.html',
  styleUrls: ['./changed-theme-popup.component.scss'],
})
export class ChangedThemePopupComponent implements OnInit {
  changedThemes: FormattedChangedTheme[] = [];

  maxHeight = '600px';

  language = 'nl_NL';

  constructor(
    public dialogRef: MatDialogRef<ChangedThemePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChangedThemePopupModel,
    private translateService: EeveryTranslateService,
  ) {}

  ngOnInit(): void {
    this.language = this.translateService.getLanguage();
    this.changedThemes = this.formatThemes(this.data.changedThemes);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private formatThemes(themes: RelevantThemeModel[]): FormattedChangedTheme[] {
    const changedThemes: FormattedChangedTheme[] = [];

    themes.forEach((theme) => {
      const changedTheme = changedThemes.find((ct) => ct.id === theme.standard.id);

      if (changedTheme) {
        changedTheme.subThemes.push(theme.disclosure);
      } else {
        changedThemes.push({
          id: theme.standard.id,
          nameUK: theme.standard.nameUK,
          nameNL: theme.standard.nameNL,
          subThemes: [theme.disclosure],
        });
      }
    });

    this.sortThemes(changedThemes);

    this.sortSubThemes(changedThemes);

    return changedThemes;
  }

  private sortSubThemes(changedThemes: FormattedChangedTheme[]): void {
    changedThemes.forEach((changedTheme) => {
      changedTheme.subThemes.sort((a, b) => {
        if (this.language === 'nl_NL') {
          return a.nameNL.localeCompare(b.nameNL);
        }

        return a.nameUK.localeCompare(b.nameUK);
      });
    });
  }

  private sortThemes(changedThemes: FormattedChangedTheme[]): void {
    changedThemes.sort((a, b) => {
      if (this.language === 'nl_NL') {
        return a.nameNL.localeCompare(b.nameNL);
      }

      return a.nameUK.localeCompare(b.nameUK);
    });
  }
}
