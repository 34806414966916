import { AbstractService } from '../../shared/services/abstract.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PaymentService extends AbstractService {
  async cancelSubscription(): Promise<void> {
    await this.http.delete(`${this.apiBaseUrl}/payment/`).toPromise();
  }

  async subscribeForFree(email: string, country?: string): Promise<void> {
    const payload: { email: string; country: string } = { email, country: country || 'NL' };

    await this.http.post(`${this.apiBaseUrl}/payment/free`, payload).toPromise();
  }

  async subscribeWithInvoice(email: string, country?: string): Promise<void> {
    const payload: { email: string; country: string } = { email, country: country || 'NL' };

    await this.http.post(`${this.apiBaseUrl}/payment/invoice`, payload).toPromise();
  }

  async reactivateSubscription(): Promise<void> {
    await this.http.post(`${this.apiBaseUrl}/payment/reactivate`, {}).toPromise();
  }
}
