import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Role } from '../dashboard/profile/models/role';

@Injectable({ providedIn: 'root' })
export class EnterpriseGuard implements CanActivate {
  constructor(private readonly authService: AuthService) {}

  canActivate(): boolean {
    return this.authService.handleCanActivateFor(Array.of(Role.Enterprise));
  }
}
