import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { GraphDataModel } from '../../../graph-data.model';
import { EsgPopupModel } from '../../popup/esg-popup.model';
import { EsgPopupComponent } from '../../popup/esg-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-results-over-time',
  templateUrl: './result-over-time.component.html',
  styleUrls: ['./result-over-time.component.scss'],
})
export class ResultOverTimeComponent implements OnChanges, OnInit {
  @Input()
  scoringSets: GraphDataModel[][] = [];

  kpis: number[] = [];

  categories = ['Economic', 'Environmental', 'Social', 'Governance'];

  constructor(private dialog: MatDialog) {}

  getKpiIcon(kpi: number): string {
    if (kpi > 0) {
      return 'arrow_upward';
    }
    if (kpi < 0) {
      return 'arrow_downward';
    }

    return 'remove';
  }

  ngOnChanges(): void {
    this.formatKpi();
  }

  ngOnInit(): void {
    this.formatKpi();
  }

  private formatKpi(): void {
    this.kpis = new Array(this.categories.length).fill(0);
    const chronologicalSet = [...this.scoringSets];

    if (chronologicalSet.length > 0) {
      chronologicalSet.forEach((scoringSet, index) => {
        scoringSet.forEach((scoring, scoringIndex) => {
          if (index !== 0) {
            this.kpis[scoringIndex] += scoring.percentage - chronologicalSet[index - 1][scoringIndex].percentage;
          }
        });
      });
      this.kpis = this.kpis.map((kpi: number) => Number.parseFloat((kpi / chronologicalSet.length).toFixed(0)));
    }
  }

  openExplanationPopup(categoryIndex: number): void {
    const category = this.categories[categoryIndex];
    const data: EsgPopupModel = {
      category,
      type: 'kpi',
      categoryIndex,
    };

    this.dialog.open(EsgPopupComponent, { width: '60vw', autoFocus: false, data });
  }
}
