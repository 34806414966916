import { Component, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-co2-calculation-start-popup',
  templateUrl: './co2-calculation-start-popup.component.html',
  styleUrls: ['./co2-calculation-start-popup.component.scss'],
})
export class Co2CalculationStartPopupComponent {
  constructor(public dialogRef: MatDialogRef<Co2CalculationStartPopupComponent>, private ngZone: NgZone) {}

  close(): void {
    this.ngZone.run(() => {
      this.dialogRef.close(false);
    });
  }

  start(): void {
    this.ngZone.run(() => {
      this.dialogRef.close(true);
    });
  }
}
