import { Component, OnInit } from '@angular/core';
import { InfoModel } from '../../shared/info/info.model';
import { OnboardingPageDirective } from '../onboarding.page.directive';

@Component({
  selector: 'app-onboarding-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent extends OnboardingPageDirective implements OnInit {
  userEmail = '';

  emailError = false;

  contactInfo: InfoModel = { oldGriStandards: ['2-3'] };

  contactEmailInfo: InfoModel = { oldGriStandards: ['2-3'] };

  async ngOnInit(): Promise<void> {
    this.userEmail = `${this.profileService.getProfile().email}`;

    if (this.company.contactEmail === null || this.company.contactEmail === '') {
      this.company.contactEmail = this.userEmail;
    }

    /* eslint-disable */
    this.contactInfo.description = { explanation: await this.translateService.get('onboarding.contact.question') };
    this.contactEmailInfo.description = { explanation: await this.translateService.get('onboarding.contact.email.question') };
    /* eslint-enable */
  }

  setSameContactPerson(value: boolean): void {
    this.company.contactEmail = value ? this.userEmail : '';
    this.onAnswerAdded();
  }

  submit(): void {
    if (!(this.company.contactEmail?.includes('@') && this.company.contactEmail?.includes('.'))) {
      this.emailError = true;

      return;
    }

    this.finished.emit(this.company);
  }

  inputEmail(): void {
    this.emailError = false;
  }
}
