import { Component } from '@angular/core';
import { GridtilesModel } from '../frontpage/gridtiles/gridtiles.model';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-frontpage',
  templateUrl: './frontpage.component.html',
  styleUrls: ['./frontpage.component.scss'],
})
export class FrontpageComponent {
  gridTiles: GridtilesModel[] = [
    {
      tilename: 'What',
      tiletext:
        'An assessment tool that helps you effectively measure, report and rate all sustainability aspects of ' +
        'your organisation.',
    },
    {
      tilename: 'Why',
      tiletext:
        'In order to nowadays grow your business you need to monitor, enhance and demonstrate your environmental ' +
        'and sustainability progress.',
    },
    {
      tilename: 'How',
      tiletext:
        'The application is a comprehensive but SME friendly ESG assessment tool which is calibrated to globally ' +
        'accepted standards and reporting practices.',
    },
  ];

  constructor(private readonly service: AuthService) {}

  createAccountSME(): void {
    this.service.navigateToPage('auth/signup');
  }
}
