import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-placeholder',
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.scss'],
})
export class PlaceholderComponent {
  @Input()
  title?: string;

  @Input()
  subtitle?: string;

  @Input()
  button?: string;

  @Input()
  button2?: string;

  @Input()
  showButton = true;

  @Input()
  showButton2 = false;

  @Input()
  showLogo = true;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output()
  onButtonPressed = new EventEmitter<void>();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output()
  onButton2Pressed = new EventEmitter<void>();
}
