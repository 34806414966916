import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { environment } from '../environments/environment';
import { AuthModule, AuthState } from './auth';
import { DashboardModule, DashboardState, ScoreboardState } from './dashboard';
import { ProfileState } from './dashboard/profile/xs/profile.state';
import { CompanyState } from './dashboard/profile/xs/company.state';
import { InterceptorModule } from './interceptor/interceptor.module';
import { ImprovementCentreState } from './improvement-centre/xs/improvement-centre.state';
import { QuestionnaireState } from './shared/questions';
import { EnterpriseState } from './enterprise/xs/enterprise.state';
import { SidenavState } from './shared/sidenav/xs/sidenav.state';
import { Co2CalculatorState } from './co2-calculator/xs/co2-calculator.state';
import { Co2CalculatorModule } from './co2-calculator/co2-calculator.module';
import { EnterpriseModule } from './enterprise/enterprise.module';
import { ImpactScanRevampState } from './dashboard/questionnaire/impact-scan-revamp/impact-scan-revamp.state';
import { CompanyProfileState } from './company-profile/xs/company-profile.state';
import { UsersState } from './shared/users/xs/users.state';
import { AuthConfigModule } from './auth/sso/auth-config.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Co2OffsettingState } from './co2-offsetting/xs/co2-offsetting.state';
import { ServiceWorkerModule } from '@angular/service-worker';
import { hammerConfig } from 'src/app/config/hammer.config';
import { SliderGroupState } from './dashboard/scoreboard/shared/slider-toggle/xs/slider-group.state';
import { CommentModule } from './comment/comment.module';
import { GriStandardsState } from './shared/gri/gri-standards-state.service';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${Date.now()}`);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    NgxsModule.forRoot(
      [
        AuthState,
        CompanyState,
        ImprovementCentreState,
        ProfileState,
        QuestionnaireState,
        DashboardState,
        ScoreboardState,
        EnterpriseState,
        SidenavState,
        Co2CalculatorState,
        Co2OffsettingState,
        ImpactScanRevampState,
        CompanyProfileState,
        UsersState,
        SliderGroupState,
        GriStandardsState,
      ],
      { developmentMode: !environment.production },
    ),
    NgxsStoragePluginModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    BrowserModule,
    AuthModule,
    InterceptorModule,
    DashboardModule,
    Co2CalculatorModule,
    EnterpriseModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: false,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      // registrationStrategy: 'registerWhenStable:30001',
    }),
    AuthConfigModule,
    FormsModule,
    ReactiveFormsModule,
    HammerModule,
    CommentModule,
  ],
  providers: [hammerConfig],
  bootstrap: [AppComponent],
})
export class AppModule {}
