import { Component, Input } from '@angular/core';
import { InfoModel } from './info.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InfoPopUpComponent } from './info-pop-up/info-pop-up.component';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent {
  @Input()
  info?: InfoModel;

  dialogRef?: MatDialogRef<InfoPopUpComponent>;

  constructor(private dialog: MatDialog) {}

  hasContent(): boolean {
    if (!this.info) {
      return false;
    }
    if (
      this.info.description &&
      (this.info.description.source?.trim() ||
        this.info.description.simpleText?.trim() ||
        this.info.description.example?.trim() ||
        this.info.description.whoToAsk?.trim() ||
        this.info.description.calculation?.trim() ||
        this.info.description.explanation?.trim() ||
        this.info.description.whyIsItImportant?.trim())
    ) {
      return true;
    }
    if (this.info.oldGriStandards && this.info.oldGriStandards.length > 0) {
      return true;
    }
    if (this.info.sdgGoals && this.info.sdgGoals.length > 0) {
      return true;
    }
    if (this.info.sdgTargets && this.info.sdgTargets.length > 0) {
      return true;
    }
    if (this.info.ungcPrinciples && this.info.ungcPrinciples.length > 0) {
      return true;
    }

    return !!(this.info.esrsStandard && this.info.esrsStandard.length > 0);
  }

  showInfo(): void {
    const data = {
      width: '80vw',
      autoFocus: false,
      data: this.info,
    };

    this.dialogRef = this.dialog.open(InfoPopUpComponent, data);
  }
}
