// eslint-disable-next-line max-classes-per-file
import { Action } from '../../../shared/xs/actions';

export interface CheckUnansweredActionPayload {
  category: string;
}

export class CheckUnansweredAction implements Action {
  static readonly type = '[Questionnaire] CheckUnansweredAction';

  constructor(public payload?: CheckUnansweredActionPayload) {}
}
