import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SignupActionPayload } from '../../../xs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgModel } from '@angular/forms';
import { NonActiveSignupValues } from '../create-account.component';
import { AuthService } from '../../../auth.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({ height: '71px' })),
      state('close', style({ height: '0px' })),
      transition('* => *', [animate('0.5s')]),
    ]),
  ],
})
export class SignUpComponent implements OnInit {
  @Output()
  nextPage = new EventEmitter<SignupActionPayload & NonActiveSignupValues>();

  @Input()
  user: SignupActionPayload & NonActiveSignupValues = {
    dataUsageApproved: false,
    confirmEmailValue: '',
    confirmPasswordValue: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    billingAddress: {
      city: '',
      name: '',
      houseNumber: '',
      street: '',
      postalCode: '',
      country: '',
    },
  };

  @Input()
  supportEmail = '';

  showPassword = false;

  showPasswordConfirm = false;

  // source: https://stackoverflow.com/questions/58767980/aws-cognito-password-regex-specific-to-aws-cognito
  // with space removed, since we do not want to count that as a special character
  acceptedSymbols = '\\^$*.[\\]{}()?"!@#%&/\\\\,><\':;|_~`=+\\-';

  passwordRegex = '';

  @ViewChild('email') email!: NgModel;

  @ViewChild('confirmEmail') confirmEmail!: NgModel;

  @ViewChild('password') password!: NgModel;

  @ViewChild('confirmPassword') confirmPassword!: NgModel;

  @ViewChild('signupForm') signupForm!: NgModel;

  showPasswordCheck = false;

  emailExists = true;

  constructor(private readonly activatedRoute: ActivatedRoute, private readonly authService: AuthService) {
    this.passwordRegex =
      '^(?=.*[A-Z])' +
      '(?=.*[a-z])' +
      '(?=.*[0-9])' +
      `(?=.*[${this.acceptedSymbols}])` +
      `[A-Za-z0-9${this.acceptedSymbols}]{8,256}$`;
  }

  async ngOnInit(): Promise<void> {
    this.initPresetEmailField();
  }

  initPresetEmailField(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.email) {
        this.user.email = params.email;
      }
    });
  }

  validateEmail(): void {
    if (!this.confirmEmail.errors?.required && this.email.value !== this.confirmEmail.value) {
      this.confirmEmail.control.setErrors({ confirmError: true });
    } else {
      this.confirmEmail.control.updateValueAndValidity();
    }
  }

  validatePassword(): void {
    if (!this.confirmPassword.errors?.required && this.password.value !== this.confirmPassword.value) {
      this.confirmPassword.control.setErrors({ confirmError: true });
    } else {
      this.confirmPassword.control.updateValueAndValidity();
    }
  }

  next(): void {
    this.nextPage.emit(this.user);
  }

  async submitForm(): Promise<void> {
    if (this.signupForm.valid) {
      const emailValue: string = this.email.value as string;

      const result = await this.authService.isEmailAvailable(emailValue);

      this.emailExists = result.userExist;

      if (!this.emailExists) {
        this.next();
      } else {
        this.email.control.setErrors({ existError: true });
      }
    }
  }
}
