import { Component, Input } from '@angular/core';
import { Co2CalculationResultModel } from '../model/co2-calculation-result.model';
import { CompanyService } from '../../dashboard';
import { PopUpModel } from '../../shared/pop-up/pop-up.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopUpComponent } from '../../shared/pop-up/pop-up.component';

@Component({
  selector: 'app-co2-calculator-results',
  templateUrl: './co2-calculator-results.component.html',
  styleUrls: ['./co2-calculator-results.component.scss'],
})
export class Co2CalculatorResultsComponent {
  @Input()
  co2calculation?: Co2CalculationResultModel;

  @Input()
  showStartButtonInPlaceHolder?: boolean;

  @Input()
  dropdownExpanded = false;

  constructor(private companyService: CompanyService, private dialog: MatDialog) {}

  getCompanyName(): string {
    return this.companyService.getCompany()?.name || '';
  }

  showEnergyPopup(): void {
    this.showPopUp({
      title: 'pop_up.co2_results.energy.title',
      message: 'pop_up.co2_results.energy.message',
    });
  }

  showMobilityPopup(): void {
    this.showPopUp({
      title: 'pop_up.co2_results.mobility.title',
      message: 'pop_up.co2_results.mobility.message',
    });
  }

  showBoardPopup(): void {
    this.showPopUp({
      title: 'pop_up.co2_results.board.title',
      message: 'pop_up.co2_results.board.message',
    });
  }

  showMaterialPopup(): void {
    this.showPopUp({
      title: 'pop_up.co2_results.material.title',
      message: 'pop_up.co2_results.material.message',
    });
  }

  showPerTurnoverPopup(): void {
    this.showPopUp({
      title: 'pop_up.co2_results.turnover.title',
      message: 'pop_up.co2_results.turnover.message',
    });
  }

  showPerFtePopup(): void {
    this.showPopUp({
      title: 'pop_up.co2_results.fte.title',
      message: 'pop_up.co2_results.fte.message',
    });
  }

  showPopUp(data: PopUpModel): MatDialogRef<PopUpComponent, unknown> {
    return this.dialog.open(PopUpComponent, { width: '60vw', autoFocus: false, data });
  }
}
