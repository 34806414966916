import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Co2CalculationResultModel } from '../../model/co2-calculation-result.model';
import { Co2CalculatorService } from '../../co2-calculator.service';
import { StartCo2CalculationAction } from '../../xs/actions/start-co2-calculation.action';
import { EeveryTranslateService } from '../../../shared/eevery.translate.service';

@Component({
  selector: 'app-co2-kpi',
  templateUrl: './co2-kpi.component.html',
  styleUrls: ['./co2-kpi.component.scss'],
})
export class Co2KpiComponent implements OnInit {
  @Input()
  co2calculation?: Co2CalculationResultModel;

  @Input()
  showStartButtonInPlaceHolder?: boolean;

  @Output()
  turnoverClicked = new EventEmitter<void>();

  @Output()
  fteClicked = new EventEmitter<void>();

  language = 'nl-NL';

  constructor(private co2CalculatorService: Co2CalculatorService, private translateService: EeveryTranslateService) {}

  ngOnInit(): void {
    this.language = this.translateService.getLanguage() || 'nl-NL';
  }

  startCalculation(): void {
    this.co2CalculatorService.dispatchAction(new StartCo2CalculationAction());
  }

  showPerTurnoverPopup(): void {
    this.turnoverClicked.emit();
  }

  showPerFtePopup(): void {
    this.fteClicked.emit();
  }
}
