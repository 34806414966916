import { Injectable } from '@angular/core';
import { AbstractService } from '../services/abstract.service';
import { ColourSchemeModel } from './model/colour-scheme.model';
import { SidenavState } from './xs/sidenav.state';
import { RefreshCobrandingLogoAction } from './xs/actions/refresh-cobranding-logo.action';

@Injectable({ providedIn: 'root' })
export class SidenavService extends AbstractService {
  getColour(): ColourSchemeModel | undefined {
    return this.store.selectSnapshot(SidenavState.colours);
  }

  getLogo(): string | undefined {
    return this.store.selectSnapshot(SidenavState.logo);
  }

  public async refreshLogo(): Promise<{ src: string | null }> {
    return (await this.http.get(`${this.apiBaseUrl}/upload/cobranding/logo`).toPromise()) as {
      src: string;
    };
  }

  public async onCobrandingLogoError(): Promise<string | undefined> {
    await this.dispatchActionAndWait(new RefreshCobrandingLogoAction());

    return this.getLogo();
  }
}
