import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { PersonalComponent } from './personal/personal.component';
import { CompanyComponent } from './company/company.component';
import { SecurityComponent } from './security/security.component';
import { MaterialDesignModule, SharedModule } from '../../shared';
import { TranslateModule } from '@ngx-translate/core';
import { ProfileRouting } from './profile.routing';
import { SubscriptionComponent } from './subscription/subscription.component';
import { CancelCheckPopupComponent } from './subscription/cancel-check/cancel-check-popup.component';

@NgModule({
  declarations: [
    ProfileComponent,
    PersonalComponent,
    CompanyComponent,
    SecurityComponent,
    SubscriptionComponent,
    CancelCheckPopupComponent,
  ],
  imports: [ProfileRouting, MaterialDesignModule, TranslateModule, CommonModule, SharedModule],
  exports: [PersonalComponent, CompanyComponent],
})
export class ProfileModule {}
