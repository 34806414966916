import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UsersModel } from './users.model';
import { UpdateUsersAction } from '../../xs/actions/update-users.action';
import { InviteUserModel } from '../../../dashboard/profile/models/invite-user.model';

@State<UsersModel>({
  name: 'userManagement',
  defaults: { usersToInvite: [{ email: '', firstName: '', lastName: '' }] },
})
export class UsersState {
  @Selector()
  static inviteUsers(state: UsersModel): InviteUserModel[] {
    return state.usersToInvite.map((user: InviteUserModel) => ({ ...user }));
  }

  @Action(UpdateUsersAction)
  updateUsersAction(ctx: StateContext<UsersModel>, action: UpdateUsersAction): void {
    const state = ctx.getState();
    const updatedUsersToInvite = action.payload.map((user: InviteUserModel) => ({ ...user }));

    ctx.setState({ ...state, usersToInvite: updatedUsersToInvite });
  }
}
