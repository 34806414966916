import { Action, ActionResponse } from '../../../../../shared/xs/actions';
import { SubsidiaryModel } from '../../../models/subsidiary.model';
import { ProductModel } from '../../../models/product.model';
import { ServiceModel } from '../../../models/service.model';
import { BillingAddress } from '../../../../../auth';
import { UserToInviteModel } from '../../../../../company-profile/invite/user-to-invite.model';

export interface UpdateCompanyActionPayload {
  draft: boolean;
  name: string;
  country: string;
  registeredId?: string;
  legalEntityType?: string;
  annualTurnover: number;
  totalAssets: number;
  localSectorCode?: string;
  numberOfEmployees: number;
  subsidiaries: SubsidiaryModel[];
  products: ProductModel[];
  services: ServiceModel[];
  executiveDirectors: number;
  hasNonExecutiveDirectors: boolean | null;
  hasMultipleShareholders: boolean | null;
  controlType: string | null;
  contactEmail: string;
  redirect?: boolean;
  billingAddress?: BillingAddress;
}

export interface UpdateCompanyActionPayloadV2 {
  draft: boolean;
  name: string;
  country: string;
  registeredId: string;
  contactEmail: string;
  localSectorCode?: string;
  redirect?: boolean;
  usersToInvite?: UserToInviteModel[];
  loadedFromBackend?: boolean;
}

export class UpdateCompanyAction implements Action {
  static readonly type = '[Dashboard] Update Company';

  constructor(public payload: UpdateCompanyActionPayload | UpdateCompanyActionPayloadV2) {}
}

export interface UpdateCompanyActionResponse extends ActionResponse {
  country: string;
  registeredId: string;
  name: string;
  legalEntityType: string;
  numberOfEmployees: number;
  annualTurnover: number;
  totalAssets: number;
  localSectorCode: string;
  billingAddress?: BillingAddress;
}
