/* eslint-disable @typescript-eslint/indent */
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  companyName = '';

  pageTitle = 'title';

  constructor(public authService: AuthService) {}

  ngOnInit(): void {
    if (this.authService.isEnterpriseUserLoggedInAsSme()) {
      this.pageTitle = 'company_title';
    }
  }
}
