import { Action } from '../../../shared/xs/actions';

export interface CheckResultsActionPayload {
  hasBeenChecked: boolean;
}

export class CheckResultsAction implements Action {
  static readonly type = '[Results] Results checked';

  constructor(public payload: CheckResultsActionPayload) {}
}
