import { Component, OnInit } from '@angular/core';
import { AbstractQuestionnaireDirective } from '../abstract-questionnaire.directive';
import { QuestionnaireType } from '../../scoreboard/models/questionnaire.type';
import { SideNavModel } from '../../../shared/sidenav';
import { AssessmentVersion } from '../../scoreboard/models/scoring.model';
import { ScoringStatus } from '../../scoreboard';

@Component({
  selector: 'app-questionaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
})
export class QuestionnaireComponent extends AbstractQuestionnaireDirective implements OnInit {
  type: QuestionnaireType = QuestionnaireType.ASSESSMENT;

  navMenu: SideNavModel[] = [];

  navMenuOld: SideNavModel[] = [
    {
      img: 'economic',
      title: 'economic',
      route: '../economic',
    },
    {
      img: 'environment',
      title: 'environmental',
      route: '../environmental',
    },
    {
      img: 'social',
      title: 'social',
      route: '../social',
    },
    {
      img: 'governance',
      title: 'governance',
      route: '../governance',
    },
  ];

  navMenuMateriality: SideNavModel[] = [
    {
      img: 'economic',
      title: 'economic',
      route: '../economic',
    },
    {
      img: 'environment',
      title: 'environmental',
      route: '../environmental',
    },
    {
      img: 'social',
      title: 'social',
      route: '../social',
    },
    {
      img: 'governance',
      title: 'governance',
      route: '../governance',
    },
    {
      matImg: 'format_list_numbered',
      title: 'materiality',
      route: '../materiality',
    },
  ];

  navMenuMaterialityDisabled: SideNavModel[] = [
    {
      img: 'economic',
      title: 'economic',
      route: '../economic',
    },
    {
      img: 'environment',
      title: 'environmental',
      route: '../environmental',
    },
    {
      img: 'social',
      title: 'social',
      route: '../social',
    },
    {
      img: 'governance',
      title: 'governance',
      route: '../governance',
    },
    {
      matImg: 'format_list_numbered',
      title: 'materiality',
      route: '',
    },
  ];

  updateSidenav(): void {
    this.navMenu = [];
    if (this.version === AssessmentVersion.OLD) {
      this.navMenu.push(...this.navMenuOld);
    } else if (this.status === ScoringStatus.DRAFT) {
      this.navMenu.push(...this.navMenuMaterialityDisabled);
    } else {
      this.navMenu.push(...this.navMenuMateriality);
    }
  }
}
