import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { CategoryListItemModel } from './category-list-item.model';
import { StatusInfoModel } from './status-info.model';
import countryJsonGB from '../../../assets/json/country-code-gb.json';
import countryJsonNL from '../../../assets/json/country-code-nl.json';
import { CountryModel } from '../questions';
import { EeveryTranslateService } from '../eevery.translate.service';

@Component({
  selector: 'app-questionnaire-header',
  templateUrl: './questionnaire-header.component.html',
  styleUrls: ['./questionnaire-header.component.scss'],
})
export class QuestionnaireHeaderComponent implements OnInit, OnChanges {
  @Input()
  categoryListItems!: CategoryListItemModel[];

  @Input()
  currentCategory!: number;

  @Input()
  categoriesClickable = true;

  @Input()
  shouldShowCheckmarks!: boolean;

  @Output()
  categoryClicked = new EventEmitter<number>();

  @Input()
  smallTitle?: string;

  @Input()
  bigTitle?: string;

  @Input()
  whiteButtonText?: string;

  @Output()
  whiteButtonClicked = new EventEmitter<void>();

  @Input()
  yellowButtonText?: string;

  @Output()
  yellowButtonClicked = new EventEmitter<void>();

  @Input()
  redButtonText?: string;

  @Output()
  redButtonClicked = new EventEmitter<void>();

  @Input()
  statusInfo?: StatusInfoModel;

  @ViewChild('categoryListContainer', { read: ElementRef })
  categoryContainer?: ElementRef;

  countryList: CountryModel[] = countryJsonGB as CountryModel[];

  constructor(private translateService: EeveryTranslateService) {}

  ngOnInit(): void {
    if (this.translateService.getLanguage() === 'nl_NL') {
      this.countryList = countryJsonNL as CountryModel[];
    }
    this.scrollToView(this.currentCategory);
  }

  ngOnChanges(): void {
    this.scrollToView(this.currentCategory);
  }

  getClassForCategory(index: number): string {
    if (this.currentCategory === index) {
      return 'selected';
    }
    if (this.shouldShowCheckmarks && this.categoryListItems[index].isValid()) {
      return 'checkmark';
    }

    return 'grey';
  }

  getCountryByCode(countryCode: string | undefined): string {
    if (!countryCode) {
      return '';
    }
    for (const country of this.countryList) {
      if (country.countryCode === countryCode) {
        return country.countryName;
      }
    }

    return '';
  }

  clickCategory(index: number): void {
    if (this.categoriesClickable) {
      this.categoryClicked.emit(index);
    }
  }

  clickWhiteButton(): void {
    this.whiteButtonClicked.emit();
  }

  clickYellowButton(): void {
    this.yellowButtonClicked.emit();
  }

  clickRedButton(): void {
    this.redButtonClicked.emit();
  }

  scrollToView(index: number): void {
    if (index === 0) {
      if (this.categoryContainer) {
        this.categoryContainer.nativeElement.scrollTo({ left: 0, behavior: 'smooth' });
      }
    } else {
      document.getElementById(`category-list-item-${index}`)?.scrollIntoView(true);
    }
  }
}
