import { Directive, Input, forwardRef, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl, Validators } from '@angular/forms';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MAX_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MaxValidator),
  multi: true,
};

@Directive({
  selector: '[max][formControlName],[max][formControl],[max][ngModel]',
  providers: [MAX_VALIDATOR],
})
export class MaxValidator implements Validator, OnInit, OnChanges {
  @Input()
  max!: number;

  private validator!: ValidatorFn;

  private onChange!: () => void;

  ngOnInit(): void {
    this.validator = max(this.max);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in changes) {
      if (key === 'max') {
        this.validator = max(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validate(c: AbstractControl): { [key: string]: any } | null {
    return this.validator(c);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}

/* eslint-disable */
export const max = (max: number): ValidatorFn => (control: AbstractControl): { [key: string]: any } | null => {
  if (!isPresent(max)) {
    return null;
  }
  if (isPresent(Validators.required(control))) {
    return null;
  }

  const v: number = +control.value;

  return v <= +max ? null : { actualValue: v, requiredValue: +max, max: true };
};

export function isPresent(obj: any): boolean {
  return obj !== undefined && obj !== null;
}
/* eslint-enable */
