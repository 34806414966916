import { Component, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-gri-info-popup',
  templateUrl: './gri-info-popup.component.html',
  styleUrls: ['./gri-info-popup.component.scss'],
})
export class GriInfoPopupComponent {
  constructor(public dialogRef: MatDialogRef<GriInfoPopupComponent>, private ngZone: NgZone) {}

  onClose(): void {
    this.ngZone.run(() => {
      this.dialogRef.close(false);
    });
  }
}
