import { Component } from '@angular/core';
import { AuthService } from '../../auth.service';
import { ForgotPasswordAction } from '../../xs/actions/forgot-password.action';
import { isCountryCodeDutchLike } from '../../../shared/countries/country-service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  email = '';

  serverError = false;

  emailSent = false;

  constructor(private readonly authService: AuthService) {}

  async resetPassword(): Promise<void> {
    this.emailSent = false;
    this.serverError = false;
    try {
      const language = isCountryCodeDutchLike(this.authService.getCountry()) ? 'NL' : 'GB';

      await this.authService.dispatchActionAndWait(new ForgotPasswordAction({ email: this.email, language }));
      this.emailSent = true;
    } catch (e) {
      this.serverError = true;
    }
  }

  back(): void {
    this.authService.navigateToPage('login');
  }

  createAccountSME(): void {
    this.authService.navigateToPage('auth/signup');
  }
}
