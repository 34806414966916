export class ExportUtils {
  static downloadCSV(data: string, filename: string): void {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');

    downloadLink.href = url;
    downloadLink.download = `${filename}.csv`;
    downloadLink.click();
    URL.revokeObjectURL(url);
  }
}
