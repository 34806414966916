import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-co2-numerical-question',
  templateUrl: './co2-numerical-question.component.html',
  styleUrls: ['./co2-numerical-question.component.scss'],
})
export class Co2NumericalQuestionComponent implements OnInit {
  emptyValue?: number | null = null;

  @Input()
  highLightUnansweredQuestion!: boolean;

  @Input()
  questionId!: number;

  @Input()
  value!: number;

  @Output()
  valueChange = new EventEmitter<number>();

  @Input()
  viewOnly!: boolean;

  @Input()
  question!: string;

  @Input()
  description!: string;

  @Input()
  subtext!: string;

  @Input()
  field!: string;

  @Input()
  useEmpiric!: boolean;

  @Input()
  unit!: string;

  @Input()
  max = 100000000000;

  @Input()
  min = 0;

  @Input()
  minError = 'errors.min';

  @Input()
  maxError = 'errors.max';

  @Output()
  answerUpdated = new EventEmitter<void>();

  ngOnInit(): void {
    this.setPercentageRules();
  }

  private setPercentageRules(): void {
    if (this.unit && this.unit === '%') {
      this.max = 100;
      this.maxError = 'errors.max_percentage';
      this.minError = 'errors.min_percentage';
    }
  }

  getPlaceholder(): string {
    return !this.useEmpiric ? this.field : 'co2calculator.questionnaire.estimated_value_placeholder';
  }

  onInputChanged(control: NgModel): void {
    this.touchField(control);
    this.answerUpdated.emit();
  }

  shouldHighlightAsUnanswered(): boolean {
    return this.highLightUnansweredQuestion && !this.useEmpiric && (this.value === undefined || this.value === null);
  }

  private touchField(control: NgModel): void {
    control.control.markAsTouched();
  }
}
