import { Action } from '../../../shared/xs/actions';

export interface CheckImprovementActionPayload {
  hasBeenChecked: boolean;
}

export class CheckImprovementAction implements Action {
  static readonly type = '[Improvement Centre] Improvement checked';

  constructor(public payload: CheckImprovementActionPayload) {}
}
