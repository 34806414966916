import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GriStandardCategoryModel } from '../models';
import standardsJSON from '../../../../assets/json/standards/gri-standards.json';
import {
  OldGriScore,
  GriScore,
  GriCoexistenceModel,
  RelevantStandard,
  ManagedGriScore,
  RelevantDisclosure,
  Translation,
} from '../models/scoring.model';
import { Assessment, Topic } from '../models/topic.model';
import { OptionItem } from '../shared/slider-toggle/slider-group.component';
import { ScoreboardService } from '../scoreboard.service';
import { GriPopupModel } from './popup/gri-popup/gri-popup.model';
import { GriPopupComponent } from './popup/gri-popup/gri-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { EeveryTranslateService } from '../../../shared/eevery.translate.service';
import { ExportDropdownMenuItem } from '../../../shared/export/export-dropdown/export-dropdown-menu-item';
import { GriScoresExportService } from '../../../shared/export/gri-scores-export.service';

@Component({
  selector: 'app-gri',
  templateUrl: './gri.component.html',
  styleUrls: ['./gri.component.scss'],
})
export class GRIComponent implements OnInit {
  @Input()
  scores!: Topic[];

  @Input()
  companyName!: string;

  @Output()
  placeholderButtonClick = new EventEmitter<void>();

  sliderItems!: OptionItem[];

  sliderPositions: number[];

  sliderIndex = 0;

  griScores: GriCoexistenceModel[] = [];

  relevantStandards: RelevantStandard[][] = [];

  filteredRelevantStandards: RelevantStandard[][] = [];

  showGrid = true;

  showDisclosures = false;

  filteredCategories!: GriStandardCategoryModel[];

  applicableCategories: GriStandardCategoryModel[] = [];

  unfilteredCategories: GriStandardCategoryModel[] = standardsJSON as GriStandardCategoryModel[];

  searchValue = '';

  constructor(
    private scoreboardService: ScoreboardService,
    private dialog: MatDialog,
    private translationService: EeveryTranslateService,
    private readonly exportService: GriScoresExportService,
  ) {
    this.sliderPositions = [0, 0];
  }

  ngOnInit(): void {
    this.scoreboardService.currentTopic.subscribe((scores) => {
      if (scores) {
        this.showDisclosures = false;
        this.scores = scores;
        this.initScores();
      }

      this.filteredCategories = [...this.applicableCategories];
    });
  }

  private initScores(): void {
    this.sliderItems = [];
    this.griScores = [];
    this.applicableCategories = [];
    this.relevantStandards = [];
    this.filteredRelevantStandards = [];

    if (this.scores) {
      this.scores
        .filter((topic) => topic.selected || topic.indeterminate)
        .reverse()
        .forEach((score) => {
          score.assessments
            .filter((assessment) => assessment.selected)
            .reverse()
            .forEach((assessment) => {
              const griCoexistenceModel: GriCoexistenceModel = {
                oldAssessment: false,
                oldScore: [],
                score: [],
              };

              if (assessment.scoring?.deprecatedLatest) {
                griCoexistenceModel.oldAssessment = true;
                griCoexistenceModel.oldScore = this.getOldGriScores(assessment);
                this.relevantStandards.push([]);
              } else if (assessment.scoring?.scoring) {
                griCoexistenceModel.score = this.getGriScores(assessment);
                this.relevantStandards.push(this.getRelevantStandards(assessment));
              }

              this.griScores.push(griCoexistenceModel);
              this.sliderItems.push({ alias: assessment.name, id: assessment.id });
            });
        });

      this.addMissingCategoriesAndSort();
      this.sliderPositions = [this.sliderItems.length - 1, 0];
    }
  }

  private getOldGriScores(assessment: Assessment): OldGriScore[] {
    const griAssessment = assessment.scoring.deprecatedScoring.scores.GRI;
    const oldGriScores: OldGriScore[] = [];

    if (griAssessment) {
      oldGriScores.push({
        name: 'General',
        score: griAssessment.counts.GENERAL,
      });
      oldGriScores.push({
        name: 'Economic',
        score: griAssessment.counts.ECONOMIC,
      });
      oldGriScores.push({
        name: 'Environmental',
        score: griAssessment.counts.ENVIRONMENTAL,
      });
      oldGriScores.push({
        name: 'Social',
        score: griAssessment.counts.SOCIAL,
      });

      for (const category of this.unfilteredCategories) {
        for (const standard of category.standards) {
          standard.value = this.getTextFromValue(griAssessment.scores[standard.code]);
        }
      }

      this.unfilteredCategories.forEach((category) => {
        const tempCat = { ...category };

        tempCat.standards = category.standards.filter((standard) =>
          this.isApplicable(griAssessment.scores[standard.code]),
        );

        this.applicableCategories.push(tempCat);
      });
    }

    return oldGriScores;
  }

  searchOld(): void {
    this.filteredCategories = [];

    this.applicableCategories.forEach((category) => {
      const tempCat = { ...category };

      tempCat.standards = category.standards.filter(
        (standard) =>
          standard.text.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          standard.id.includes(this.searchValue),
      );

      this.filteredCategories.push(tempCat);
    });
  }

  search(): void {
    const sliderIndex = this.sliderPositions[0];

    this.filteredRelevantStandards = [...this.relevantStandards];
    let tempStandards = [...this.filteredRelevantStandards[sliderIndex]];

    if (this.searchValue === '') {
      return;
    }

    tempStandards = this.filterRelevantStandards(sliderIndex);

    if (tempStandards.length > 0) {
      this.filteredRelevantStandards[sliderIndex] = tempStandards;
    } else {
      this.filteredRelevantStandards[sliderIndex] = this.filterRelevantDisclosures(sliderIndex);
    }

    this.filteredRelevantStandards[sliderIndex] = this.filteredRelevantStandards[sliderIndex].filter(
      (relevantStandard) => relevantStandard.relevantDisclosures.length > 0,
    );

    this.showDisclosures = true;
  }

  private filterRelevantStandards(sliderIndex: number): RelevantStandard[] {
    return this.filteredRelevantStandards[sliderIndex].filter(
      (relevantStandard) =>
        relevantStandard.name.toLowerCase().includes(this.searchValue.toLowerCase()) ||
        relevantStandard.code.includes(this.searchValue),
    );
  }

  private filterRelevantDisclosures(sliderIndex: number): RelevantStandard[] {
    return this.filteredRelevantStandards[sliderIndex].map((relevantStandard) => {
      const tempStandard = { ...relevantStandard };

      tempStandard.relevantDisclosures = tempStandard.relevantDisclosures.filter(
        (disclosure) =>
          disclosure.name.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          disclosure.code.includes(this.searchValue),
      );

      return tempStandard;
    });
  }

  getTextFromValue(value: string): string {
    if (value === 'APPLICABLE') {
      return 'Yes';
    }

    return 'No';
  }

  isApplicable(value: string): boolean {
    return value === 'APPLICABLE';
  }

  titleCaseWord(word: string): string {
    if (!word) {
      return word;
    }

    return word[0].toUpperCase() + word.substring(1).toLowerCase();
  }

  getGriScores(assessment: Assessment): GriScore[] {
    const gris = assessment.scoring.scoring.gri;
    const griScores: GriScore[] = [];

    this.countGriScores(gris, griScores);

    return griScores;
  }

  private countGriScores(gris: ManagedGriScore[], griScores: GriScore[]): void {
    const counts: { [category: string]: { standards: Set<string>; disclosures: Set<string> } } = {};

    for (const gri of gris) {
      if (counts[gri.category]) {
        counts[gri.category].standards.add(gri.standardCode);
        counts[gri.category].disclosures.add(gri.disclosureCode);
      } else {
        counts[gri.category] = {
          standards: new Set([gri.standardCode]),
          disclosures: new Set([gri.disclosureCode]),
        };
      }
    }

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const category in counts) {
      const griScore: GriScore = {
        standardCounts: counts[category].standards.size,
        disclosureCount: counts[category].disclosures.size,
        category: this.titleCaseWord(category),
      };

      griScores.push(griScore);
    }
  }

  private getRelevantStandards(assessment: Assessment): RelevantStandard[] {
    const gris = assessment.scoring.scoring.gri;
    const relevantStandards: RelevantStandard[] = [];

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const gri of gris) {
      const relevantStandard: RelevantStandard = {
        category: gri.category,
        code: gri.standardCode,
        name: this.getTranslatedName(gri.standardTranslations),
        relevantDisclosures: [this.getRelevantDisclosure(gri)],
      };

      const index = relevantStandards.findIndex((standard) => standard.code === gri.standardCode);

      if (index === -1) {
        relevantStandards.push(relevantStandard);
      } else {
        relevantStandards[index].relevantDisclosures.push(this.getRelevantDisclosure(gri));
      }
    }

    this.calculateAverageScore(relevantStandards);

    return relevantStandards;
  }

  private getRelevantDisclosure(gri: ManagedGriScore): RelevantDisclosure {
    let percentage = ((gri.score / gri.maxScore) * 100).toFixed(0);

    if (gri.score === 0 && gri.maxScore === 0) {
      percentage = '100';
    }

    return {
      code: gri.disclosureCode,
      name: this.getTranslatedName(gri.disclosureTranslations),
      percentage,
    };
  }

  toggleResults(event: { sliderId: string; sliderPositions: (number | null)[] }): void {
    this.sliderPositions = event.sliderPositions.map((position) => {
      if (position === null) {
        return 0;
      }

      return position;
    });
  }

  private addMissingCategoriesAndSort(): void {
    const allCategories = ['General', 'Economic', 'Environmental', 'Social'];

    this.griScores.forEach((score) => {
      const currentCategories = score.score.map((s) => s.category);
      const missingCategories = allCategories.filter((category) => !currentCategories.includes(category));

      for (const category of missingCategories) {
        const griScore: GriScore = {
          // disclosures: [],
          standardCounts: 0,
          disclosureCount: 0,
          category,
        };

        score.score.push(griScore);
      }
      score.score.sort((a, b) => allCategories.indexOf(a.category) - allCategories.indexOf(b.category));
    });
  }

  toggleDropdown(): void {
    this.placeholderButtonClick.emit();
  }

  openFramePopup(category: string): void {
    const position = this.sliderPositions[0];
    let relevantStandards: RelevantStandard[] = [];

    if (position !== null && this.relevantStandards && this.relevantStandards[position]) {
      relevantStandards = [...this.relevantStandards[position]];
    }

    const data: GriPopupModel = {
      category,
      relevantStandards,
    };

    this.dialog.open(GriPopupComponent, { width: '60vw', autoFocus: false, data });
  }

  private getTranslatedName(translations: Translation[]): string {
    const language = this.translationService.getLanguage().replace('_', '-').replace('UK', 'GB');

    if (!translations) {
      return 'Unknown';
    }

    return (
      translations.find((translation) => translation.languageCode === language)?.title ||
      translations[0].title ||
      'Unknown'
    );
  }

  private calculateAverageScore(relevantStandards: RelevantStandard[]): void {
    relevantStandards.forEach((standard) => {
      let total = 0;

      standard.relevantDisclosures.forEach((disclosure) => {
        total += Number(disclosure.percentage);
      });

      // eslint-disable-next-line no-param-reassign
      standard.averageScore = (total / standard.relevantDisclosures.length).toFixed(0);
    });
  }

  getExportMenuItems(): ExportDropdownMenuItem[] {
    return [
      {
        title: this.translationService.instant('export.scores.gri.csv.standards_general.dropdown_menu_title'),
        id: 'csv_short',
        exportFunction: (): Promise<void> =>
          this.exportService.exportGeneralGriStandardsToCSV(
            this.relevantStandards[this.sliderPositions[0]],
            this.sliderItems[this.sliderPositions[0]].alias,
          ),
        icon: 'table_rows',
      },
      {
        title: this.translationService.instant(
          'export.scores.gri.csv.standards_and_disclosures_detailed.dropdown_menu_title',
        ),
        id: 'csv_detailed',
        exportFunction: (): Promise<void> =>
          this.exportService.exportDetailedGriStandardsToCSV(
            this.relevantStandards[this.sliderPositions[0]],
            this.sliderItems[this.sliderPositions[0]].alias,
          ),
        icon: 'table_chart',
      },
    ];
  }

  canExportStandards(): boolean {
    return (this.relevantStandards[this.sliderPositions[0]]?.length ?? 0) > 0;
  }
}
