import { Action } from '../../../../shared/xs/actions';
import { GriEntityModel } from '../../../../shared/relevant-theme/relevant-theme.model';

export interface RemoveThemeActionPayload {
  id: number;
  theme: GriEntityModel;
}

export class RemoveThemeAction implements Action {
  static readonly type = '[Impact Scan Revamp] Remove theme';

  constructor(public payload: RemoveThemeActionPayload) {}
}
