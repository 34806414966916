import { Component, Input } from '@angular/core';
import { ImpactListModel } from '../../dashboard/assessment-list/impact-list.model';
import { DashboardService } from '../../dashboard';

@Component({
  selector: 'app-impact-scan-group',
  templateUrl: './impact-scan-group.component.html',
  styleUrls: ['./impact-scan-group.component.scss'],
})
export class ImpactScanGroupComponent {
  @Input()
  impactScanOldList: ImpactListModel[] = [];

  @Input()
  impactScanRevampList: ImpactListModel[] = [];

  @Input()
  impactScanManagedList: ImpactListModel[] = [];

  @Input()
  expandedOnStart = false;

  constructor(public readonly dashboardService: DashboardService) {}
}
