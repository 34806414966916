import { UserStatus } from '../../dashboard/assessment-list/user-status.enum';

// eslint-disable-next-line no-shadow
export enum Co2CalculationStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export interface Co2CalculationListItemModel {
  id: string;
  status: Co2CalculationStatus;
  firstName: string;
  lastName: string;
  userStatus: UserStatus;
  canContinue: boolean;
  updatedAt: Date;
  totalEmission?: number;
}
