import { Component, OnInit } from '@angular/core';
import { SideNavModel } from '../../shared/sidenav';
import { Co2QuestionnaireModel } from '../model/co2-questionnaire.model';
import { Router } from '@angular/router';
import { Co2CalculatorService } from '../co2-calculator.service';
import { Store } from '@ngxs/store';
import { FinishCo2CalculationAction } from '../xs/actions/finish-co2-calculation.action';
import { MatDialog } from '@angular/material/dialog';
import { UnansweredQuestionModel } from '../../shared/unanswered-popup-simple/unanswered-question.model';
import { Co2CalculatorValidationService } from '../co2-calculator-validation.service';
import { Co2CalculationEndPopupComponent } from '../popups/co2-calculation-end-popup/co2-calculation-end-popup.component';
import { StatusInfoModel } from '../../shared/questionnaire-header/status-info.model';
import { UserStatus } from '../../dashboard/assessment-list/user-status.enum';
import { CategoryListItemModel } from '../../shared/questionnaire-header/category-list-item.model';
import { SubmitQuestionnairePopupComponent } from '../../shared/submit-questionnaire-popup/submit-questionnaire-popup.component';
import { UnansweredPopupSimpleComponent } from '../../shared/unanswered-popup-simple/unanswered-popup-simple.component';

@Component({
  selector: 'app-co2-calculator-questionnaire',
  templateUrl: './co2-calculator-questionnaire.component.html',
  styleUrls: ['./co2-calculator-questionnaire.component.scss'],
})
export class Co2CalculatorQuestionnaireComponent implements OnInit {
  backButtonTarget = {
    route: 'dashboard/sme/co2calculator',
    params: { tab: 1 },
  };

  navMenu: SideNavModel[] = [
    {
      matImg: 'calculate',
      title: 'co2calculation',
      route: '/dashboard/sme/co2calculator',
    },
  ];

  co2Questionnaire?: Co2QuestionnaireModel;

  currentCategory = 0;

  highLightUnansweredQuestion = false;

  categories = ['general', 'energy', 'mobility', 'board', 'material'];

  headerCategories!: CategoryListItemModel[];

  statusInfo?: StatusInfoModel;

  constructor(
    private validationService: Co2CalculatorValidationService,
    private co2CalculatorService: Co2CalculatorService,
    private dialog: MatDialog,
    private router: Router,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.updateQuestionnaireFromBackend();
  }

  updateQuestionnaireFromBackend(): void {
    const currentQuestionId = this.co2CalculatorService.getCurrentQuestionnaireId();

    if (currentQuestionId) {
      this.co2CalculatorService.getQuestionnaireFromBackend(currentQuestionId).subscribe(
        (response) => {
          this.co2Questionnaire = response;
          if (this.co2Questionnaire.viewOnly) {
            this.statusInfo = {
              name: `${this.co2Questionnaire.firstName} ${this.co2Questionnaire.lastName}`,
              status: `co2calculator.questionnaire.status.${this.co2Questionnaire.status.toLowerCase()}`,
              userDeleted: this.co2Questionnaire.userStatus === UserStatus.INACTIVE,
            };
          }
          this.loadCategoriesForHeader();
        },
        () => {
          this.backToList();
        },
      );
    } else {
      this.backToList();
    }
  }

  loadCategoriesForHeader(): void {
    const assembledList: CategoryListItemModel[] = [];

    for (const [i, category] of this.categories.entries()) {
      assembledList.push({
        categoryName: `co2calculator.questionnaire.categories.${category.toLowerCase()}`,
        isValid: (): boolean => this.validateCategory(i),
      });
    }
    this.headerCategories = assembledList;
  }
  /* eslint-enable no-invalid-this */

  validateCategory(index: number): boolean {
    if (!this.co2Questionnaire) {
      return false;
    }
    if (this.co2Questionnaire.status === 'COMPLETED') {
      return false;
    }

    return this.validationService.validateCategory(this.co2Questionnaire.answers, this.categories[index]);
  }

  scrollToTop(): void {
    const component = document.getElementById('page_container');

    if (component) {
      component.scrollTop = 0;
    }
  }

  getWhiteButtonText(): string | undefined {
    if (this.currentCategory > 0) {
      return 'co2calculator.questionnaire.button.previous';
    }

    return undefined;
  }

  getYellowButtonText(): string | undefined {
    if (this.currentCategory < 4) {
      return 'co2calculator.questionnaire.button.next';
    }

    return undefined;
  }

  getRedButtonText(): string | undefined {
    if (this.currentCategory === 4) {
      if (this.co2Questionnaire?.viewOnly) {
        return 'co2calculator.questionnaire.button.back';
      }

      return 'co2calculator.questionnaire.button.finish';
    }

    return undefined;
  }

  jumpToCategory(index: number): void {
    this.updateQuestionnaireFromBackend();
    this.currentCategory = index;
    this.scrollToTop();
  }

  jumpToPrevious(): void {
    if (this.currentCategory > 0) {
      this.jumpToCategory(this.currentCategory - 1);
    }
  }

  jumpToNext(): void {
    if (this.currentCategory < 4) {
      this.jumpToCategory(this.currentCategory + 1);
    }
  }

  redButtonClicked(): void {
    if (this.co2Questionnaire?.viewOnly) {
      this.backToList();
    } else {
      this.finishQuestionnaire();
    }
  }

  finishQuestionnaire(): void {
    if (this.currentCategory === 4 && this.co2Questionnaire?.answers) {
      const unansweredQuestions: Set<UnansweredQuestionModel> = this.validationService.validateUnansweredQuestions(
        this.co2Questionnaire.answers,
      );

      if (unansweredQuestions.size === 0) {
        this.dialog
          .open(SubmitQuestionnairePopupComponent, {
            width: '40vw',
            autoFocus: false,
            data: {
              title: 'pop_up.complete_co2_calculation.title',
              text: 'pop_up.complete_co2_calculation.body',
              submitButton: 'pop_up.complete_co2_calculation.finish',
              cancelButton: 'pop_up.complete_co2_calculation.back',
            },
          })
          .afterClosed()
          .subscribe((confirmed) => {
            if (confirmed && this.co2Questionnaire) {
              this.store.dispatch(new FinishCo2CalculationAction({ id: this.co2Questionnaire?.id })).subscribe(() => {
                this.router
                  .navigate(['dashboard/sme/co2calculator/'])
                  .then(() => this.dialog.open(Co2CalculationEndPopupComponent, { width: '60vw', autoFocus: false }));
              });
            }
          });
      } else {
        this.highLightUnansweredQuestion = true;
        const popUpData = { questions: unansweredQuestions };

        this.dialog.open(UnansweredPopupSimpleComponent, { width: '60vw', autoFocus: false, data: popUpData });
      }
    }
  }

  backToList(): void {
    this.router.navigate(['dashboard/sme/co2calculator/'], { queryParams: { tab: 1 } });
  }

  updateAnswersOnBackend(): void {
    if (this.co2Questionnaire) {
      this.co2CalculatorService
        .updateAnswersOnBackend(this.co2Questionnaire.id, this.co2Questionnaire.answers)
        .subscribe();
    }
  }
}
