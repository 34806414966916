import { Action } from '../../../shared/xs/actions';
import { Role } from '../../../dashboard/profile/models/role';

export interface LogoutFromSmeActionPayload {
  refreshToken: string;
}

export class LogoutFromSmeAction implements Action {
  static readonly type = '[Enterprise] Logout from (sme) company';

  constructor(public payload: LogoutFromSmeActionPayload) {}
}

export interface LogoutFromSmeActionResponse {
  accessToken: string;
  profile: {
    email: string;
    firstName: string;
    lastName: string;
    language: string;
    roles: Role[];
  };
  company: {
    name: string;
    draft: boolean;
    type: string;
    country: string;
  };
}
