import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaterialityTheme, RelevantStandard } from '../../../models/scoring.model';
import { CsrdDataPointPopupModel } from '../csrd-popup.model';

@Component({
  selector: 'app-csrd-datapoint-popup',
  templateUrl: './csrd-datapoint-popup.component.html',
  styleUrls: ['./csrd-datapoint-popup.component.scss'],
})
export class CsrdDataPointPopupComponent implements OnInit {
  selectedDatapoint!: number[];

  matrixRankedThemes!: MaterialityTheme[];

  maxHeight = '300px';

  standardCode = '';

  themeName = '';

  language = '';

  category = '';

  explanation = '';

  constructor(
    public dialogRef: MatDialogRef<CsrdDataPointPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CsrdDataPointPopupModel,
  ) {}

  ngOnInit(): void {
    this.selectedDatapoint = this.data.datapointValue;
    this.matrixRankedThemes = this.data.matrixRankedThemes;
    this.language = this.data.language;
    this.findThemeFromDatapoint();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private filterRelevantStandardsByCategory(
    relevantStandards: RelevantStandard[],
    category: string,
  ): RelevantStandard[] {
    return relevantStandards.filter((standard) => standard.category.toLowerCase() === category.toLowerCase());
  }

  private findThemeFromDatapoint(): void {
    this.matrixRankedThemes.forEach((theme) => {
      if (theme.financialIndex === this.selectedDatapoint[0] && theme.impactIndex === this.selectedDatapoint[1]) {
        this.standardCode = theme.standardCode;
        this.category = theme.category;
        if (this.language === 'nl-NL') {
          this.themeName = theme.standardNameNL;
          this.explanation = theme.explanationNL;
        } else {
          this.themeName = theme.standardNameUK;
          this.explanation = theme.explanationUK;
        }
      }
    });
  }
}
