import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ColourSchemeModel } from '../model/colour-scheme.model';
import { SetSidenavAction } from './actions/set-sidenav.action';
import { LogoutSuccessAction } from '../../../auth/xs/actions/logout-success.action';
import { SidenavService } from '../sidenav.service';
import { RefreshCobrandingLogoAction } from './actions/refresh-cobranding-logo.action';

export interface SidenavStateModel {
  colours?: ColourSchemeModel;
  logo?: string;
}

@State<SidenavStateModel>({ name: 'sidenav' })
@Injectable()
export class SidenavState {
  @Selector()
  static colours(state: SidenavStateModel): ColourSchemeModel | undefined {
    return state.colours;
  }

  @Selector()
  static logo(state: SidenavStateModel): string | undefined {
    return state.logo;
  }

  constructor(private sidenavService: SidenavService) {}

  @Action(SetSidenavAction)
  setProfile(ctx: StateContext<SidenavStateModel>, action: SetSidenavAction): void {
    ctx.patchState(action.payload);
  }

  @Action(RefreshCobrandingLogoAction)
  async refreshLogo(ctx: StateContext<SidenavStateModel>): Promise<void> {
    const logo = await this.sidenavService.refreshLogo();

    ctx.patchState({ logo: logo.src || undefined });
  }

  @Action(LogoutSuccessAction)
  clearState(ctx: StateContext<SidenavStateModel>): void {
    ctx.setState({});
  }
}
