import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InfoModel, InfoStandardModel, SdgTarget } from '../info.model';
import { InfoService } from '../info.service';
import { UngcpModel } from '../ungcp.model';
import { SdgGoalModel } from '../sdgGoalModel';
import { filter, take } from 'rxjs/operators';
import { GriStandardModel } from '../../gri/get-gri-disclosures.model';

@Component({
  selector: 'app-info-pop-up',
  templateUrl: './info-pop-up.component.html',
  styleUrls: ['./info-pop-up.component.scss'],
})
export class InfoPopUpComponent implements OnInit {
  protected readonly InfoService = InfoService;

  info: InfoModel = {};

  ungcPrinciples?: UngcpModel[];

  griStandards: GriStandardModel[] = [];

  esrsStandards?: InfoStandardModel[];

  sdgGoals?: SdgGoalModel[];

  sdgTargets?: SdgTarget[];

  constructor(
    public dialogRef: MatDialogRef<InfoPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) private data: InfoModel,
    protected infoService: InfoService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.info = this.data;

    if (this.info.sdgTargets) {
      this.sdgTargets = this.info.sdgTargets;

      this.sdgGoals = this.getSdgGoals();
    }

    if (this.info.ungcPrinciples) {
      this.ungcPrinciples = this.infoService.getUngcPriciples(this.info.ungcPrinciples);
    }

    let griDisclosureCodes: string[] = [];

    if (this.info.oldGriStandards) {
      griDisclosureCodes.push(...this.info.oldGriStandards);
    }

    if (this.info.griStandards) {
      griDisclosureCodes.push(
        ...(this.info.griStandards.map((x) => x.number).filter((x) => x !== undefined) as string[]),
      );
    }
    griDisclosureCodes = [...new Set(griDisclosureCodes)];

    if (griDisclosureCodes.length > 0) {
      this.infoService
        .getGriStandards(griDisclosureCodes)
        .pipe(
          filter((griStandards: GriStandardModel[]) => griStandards !== undefined),
          take(1),
        )
        .subscribe((standards: GriStandardModel[]) => {
          this.griStandards = standards;
        });
    }

    if (this.info.esrsStandard) {
      this.esrsStandards = this.info.esrsStandard;
    }
  }

  private getSdgGoals(): SdgGoalModel[] {
    if (!this.sdgTargets) {
      return [];
    }

    return this.sdgTargets
      .map((sdgTarget) => ({
        goal: sdgTarget.sdgGoal,
        description: sdgTarget.goalTranslations[0].description ?? '',
        name: sdgTarget.goalTranslations[0].title ?? '',
      }))
      .filter((goal, index, self) => index === self.findIndex((t) => t.goal === goal.goal));
  }

  hasStandards(): boolean {
    // @ts-ignore
    return (
      (this.esrsStandards && this.esrsStandards.length > 0) ||
      (this.griStandards && this.griStandards.length > 0) ||
      (this.sdgGoals && this.sdgGoals.length > 0) ||
      (this.sdgTargets && this.sdgTargets.length > 0) ||
      (this.ungcPrinciples && this.ungcPrinciples.length > 0)
    );
  }

  close(): void {
    this.dialogRef.close();
  }
}
