import { Injectable } from '@angular/core';
import { CompanyService } from '../../dashboard';
import { EeveryTranslateService } from '../eevery.translate.service';
import { ImpactScanRevampService } from '../../dashboard/questionnaire/impact-scan-revamp/impact-scan-revamp.service';
import {
  Customer,
  ImpactScanRevampDetailsModel,
  ImpactScanRevampModel,
} from '../../dashboard/questionnaire/impact-scan-revamp/impact-scan-revamp.model';
import sectorGbJson from '../../../assets/json/sectors-gb.json';
import sectorNlJson from '../../../assets/json/sectors-nl.json';
import { SectorModel } from '../dropdown-sectors/section-models/sectorModel';
import { ExportUtils } from './exportUtils';

@Injectable({ providedIn: 'root' })
export class ImpactScanExportService {
  constructor(
    private readonly impactScanService: ImpactScanRevampService,
    private readonly companyService: CompanyService,
    private readonly translateService: EeveryTranslateService,
  ) {}

  async exportImpactScanToCSV(impactScanId: number): Promise<void> {
    const impactScan: ImpactScanRevampDetailsModel = await this.impactScanService.getImpactScan(impactScanId);
    const { questionnaire } = impactScan;
    const data = [this.translateService.instant('export.impact_scan.csv.headers')].concat(
      this.questionnaireToArray(questionnaire).map((row) =>
        row
          .map((x) => x ?? '')
          .map(String)
          .map((str) => str.replace(/\n/g, ' ').replace(/;/g, '.'))
          .join(';'),
      ),
    );

    const companyName = this.companyService.getCompany().name;
    const filename = `${companyName} ${this.translate('export.impact_scan.csv.file_name.0')}`;

    ExportUtils.downloadCSV(data.join('\n'), filename);
  }

  private questionnaireToArray(questionnaire: ImpactScanRevampModel): (string | number | undefined)[][] {
    return [
      [
        this.translate('impact_scan.categories.company_context_one'),
        1,
        this.translate('impact_scan.company_context_one.sector.question'),
        this.translate('impact_scan.company_context_one.sector.subtext'),
        this.getSICCode(questionnaire.sectorCode),
      ],
      [
        this.translate('impact_scan.categories.company_context_one'),
        2,
        this.translate('impact_scan.company_context_one.employees.question'),
        this.translate('impact_scan.company_context_one.employees.subtext'),
        questionnaire.numberOfEmployees,
      ],
      [
        this.translate('impact_scan.categories.company_context_one'),
        3,
        this.translate('impact_scan.company_context_one.revenue.question'),
        this.translate('impact_scan.company_context_one.revenue.subtext'),
        questionnaire.totalRevenue == null ? '' : `${this.currencyPrefix()} ${questionnaire.totalRevenue ?? ''}`,
      ],
      [
        this.translate('impact_scan.categories.company_context_one'),
        4,
        this.translate('impact_scan.company_context_one.assets.question'),
        this.translate('impact_scan.company_context_one.assets.subtext'),
        questionnaire.totalAssets == null ? '' : `${this.currencyPrefix()} ${questionnaire.totalAssets ?? ''}`,
      ],
      [
        this.translate('impact_scan.categories.company_context_one'),
        5,
        this.translate('impact_scan.company_context_one.customers.question'),
        '',
        this.translate('export.impact_scan.csv.question_only_answer_text'),
      ],
      ...[0, 1, 2].map((index) => [
        this.translate('impact_scan.categories.company_context_one'),
        `5.${index + 1}`,
        `${this.translate('export.impact_scan.csv.question_texts.customer_no')} ${index + 1}`,
        '',
        this.customerToString(questionnaire.customers[index]),
      ]),
      [
        this.translate('impact_scan.categories.company_context_two'),
        6,
        this.translate('impact_scan.company_context_two.subsidiaries.question'),
        '',
        this.translate('export.impact_scan.csv.question_only_answer_text'),
      ],
      ...questionnaire.subsidiaries
        .map((subsidiary, index) => [
          [
            this.translate('impact_scan.categories.company_context_two'),
            `6.${index + 1}`,
            `${this.translate('export.impact_scan.csv.question_texts.subsidiary_no')}${index + 1}`,
            '',
            this.translate('export.impact_scan.csv.question_only_answer_text'),
          ],
          [
            this.translate('impact_scan.categories.company_context_two'),
            `6.${index + 1}.1`,
            `${this.translate('export.impact_scan.csv.question_texts.subsidiary_no')}${index + 1} ${this.translate(
              'export.impact_scan.csv.question_texts.name',
            )}`,
            '',
            subsidiary.name,
          ],
          [
            this.translate('impact_scan.categories.company_context_two'),
            `6.${index + 1}.2`,
            `${this.translate('export.impact_scan.csv.question_texts.subsidiary_no')}${index + 1} ${this.translate(
              'export.impact_scan.csv.question_texts.country',
            )}`,
            '',
            this.countryCodeToCountryName(subsidiary.country),
          ],
          [
            this.translate('impact_scan.categories.company_context_two'),
            `6.${index + 1}.3`,
            `${this.translate('export.impact_scan.csv.question_texts.subsidiary_no')}${index + 1} ${this.translate(
              'export.impact_scan.csv.question_texts.sector',
            )}`,
            '',
            this.getSICCode(subsidiary.sectorCode),
          ],
          [
            this.translate('impact_scan.categories.company_context_two'),
            `6.${index + 1}.4`,
            `${this.translate('export.impact_scan.csv.question_texts.subsidiary_no')}${index + 1} ${this.translate(
              'export.impact_scan.csv.question_texts.number_of_employees',
            )}`,
            '',
            subsidiary.numberOfEmployees,
          ],
          [
            this.translate('impact_scan.categories.company_context_two'),
            `6.${index + 1}.5`,
            `${this.translate('export.impact_scan.csv.question_texts.subsidiary_no')}${index + 1} ${this.translate(
              'export.impact_scan.csv.question_texts.ownership_percentage',
            )}`,
            '',
            subsidiary.percentOfOwnership != null ? `${subsidiary.percentOfOwnership}%` : '',
          ],
          [
            this.translate('impact_scan.categories.company_context_two'),
            `6.${index + 1}.6`,
            `${this.translate('export.impact_scan.csv.question_texts.subsidiary_no')}${index + 1} ${this.translate(
              'export.impact_scan.csv.question_texts.included_in_sustainability',
            )}`,
            '',
            this.optionalBooleanToString(subsidiary.includedInSustainability),
          ],
          [
            this.translate('impact_scan.categories.company_context_two'),
            `6.${index + 1}.7`,
            `${this.translate('export.impact_scan.csv.question_texts.subsidiary_no')}${index + 1} ${this.translate(
              'export.impact_scan.csv.question_texts.included_in_financial',
            )}`,
            '',
            this.optionalBooleanToString(subsidiary.includedInFinancial),
          ],
        ])
        .flat(),
      [
        this.translate('impact_scan.categories.company_context_two'),
        7,
        this.translate('impact_scan.company_context_two.products.question'),
        this.translate('impact_scan.company_context_two.products.subtext'),
        questionnaire.products,
      ],
      [
        this.translate('impact_scan.categories.governance'),
        8,
        this.translate('impact_scan.governance.legal_entity.question'),
        '',
        questionnaire.legalEntityType,
      ],
      [
        this.translate('impact_scan.categories.governance'),
        9,
        this.translate('impact_scan.governance.executive_directors.question'),
        this.translate('impact_scan.governance.executive_directors.subtext'),
        questionnaire.executiveDirectors === 5 ? '5+' : questionnaire.executiveDirectors,
      ],
      questionnaire.executiveDirectors && questionnaire.executiveDirectors > 1
        ? [
            this.translate('impact_scan.categories.governance'),
            '9.1',
            this.translate('impact_scan.governance.non_executive_directors.question'),
            this.translate('impact_scan.governance.non_executive_directors.subtext'),
            this.optionalBooleanToString(questionnaire.hasNonExecutiveDirectors),
          ]
        : undefined,
      [
        this.translate('impact_scan.categories.governance'),
        10,
        this.translate('impact_scan.governance.multiple_shareholders.question'),
        this.translate('impact_scan.governance.multiple_shareholders.subtext'),
        this.optionalBooleanToString(questionnaire.hasMultipleShareholders),
      ],
      questionnaire.hasMultipleShareholders
        ? [
            this.translate('impact_scan.categories.governance'),
            '10.1',
            this.translate('impact_scan.governance.control_type.question'),
            '',
            (questionnaire.controlType?.length ?? 0) > 1
              ? this.translate(`onboarding.governance.control_type.${questionnaire.controlType.toLowerCase()}`)
              : '',
          ]
        : undefined,
      [
        this.translate('impact_scan.categories.stakeholders'),
        11,
        this.translate('impact_scan.stakeholders.suppliers.question'),
        '',
        this.translate('export.impact_scan.csv.question_only_answer_text'),
      ],
      ...questionnaire.suppliers
        .map((supplier, index) => [
          [
            this.translate('impact_scan.categories.stakeholders'),
            `11.${index + 1}`,
            `${this.translate('export.impact_scan.csv.question_texts.supplier_subcontractor_no')}${index + 1}`,
            '',
            this.translate('export.impact_scan.csv.question_only_answer_text'),
          ],
          [
            this.translate('impact_scan.categories.stakeholders'),
            `11.${index + 1}.1`,
            `${this.translate('export.impact_scan.csv.question_texts.supplier_subcontractor_no')}${
              index + 1
            } ${this.translate('export.impact_scan.csv.question_texts.name')}`,
            '',
            supplier.name,
          ],
          [
            this.translate('impact_scan.categories.stakeholders'),
            `11.${index + 1}.2`,
            `${this.translate('export.impact_scan.csv.question_texts.supplier_subcontractor_no')}${
              index + 1
            } ${this.translate('export.impact_scan.csv.question_texts.country')}`,
            '',
            this.countryCodeToCountryName(supplier.countryCode),
          ],
          [
            this.translate('impact_scan.categories.stakeholders'),
            `11.${index + 1}.3`,
            `${this.translate('export.impact_scan.csv.question_texts.supplier_subcontractor_no')}${
              index + 1
            } ${this.translate('export.impact_scan.csv.question_texts.procurement_percentage')}`,
            '',
            supplier.percentage != null ? `${supplier.percentage}%` : '',
          ],
        ])
        .flat(),
      [
        this.translate('impact_scan.categories.stakeholders'),
        12,
        this.translate('impact_scan.stakeholders.stakeholders.question'),
        '',
        questionnaire.stakeholders
          ?.map((stakeholder) => this.translate(`impact_scan.stakeholders.stakeholders.${stakeholder.toLowerCase()}`))
          ?.join(', '),
      ],
      [
        this.translate('impact_scan.categories.stakeholders'),
        13,
        this.translate('impact_scan.stakeholders.other.question'),
        '',
        this.optionalBooleanToString(questionnaire.hasOtherStakeholders),
      ],
      questionnaire.hasOtherStakeholders
        ? [
            this.translate('impact_scan.categories.stakeholders'),
            '13.1',
            this.translate('impact_scan.stakeholders.other_stakeholders.question'),
            '',
            questionnaire.otherStakeholders
              ?.map((stakeholder) =>
                this.translate(`impact_scan.stakeholders.other_stakeholders.${stakeholder.toLowerCase()}`),
              )
              ?.join(', '),
          ]
        : undefined,
    ].filter((row): row is (string | number | undefined)[] => row !== undefined);
  }

  private translate(key: string): string {
    return this.translateService.instant(key);
  }

  private currencyPrefix(): string {
    return this.isCountryEnglish() ? '£' : '€';
  }

  isCountryEnglish(): boolean {
    return this.companyService.getCompany().country?.toUpperCase()?.includes('GB') ?? true;
  }

  private getSICCode(code: string): string {
    if (code == null) {
      return '';
    }

    const isCountryEnglish = this.isCountryEnglish();
    const primarySectors: SectorModel[] = isCountryEnglish ? sectorGbJson : sectorNlJson;
    const backupSectors: SectorModel[] = isCountryEnglish ? sectorNlJson : sectorGbJson;

    let foundSector = primarySectors.find((sector) => sector.localSectorCode === code);

    if (foundSector === undefined) {
      foundSector = backupSectors.find((sector) => sector.localSectorCode === code);
      if (foundSector === undefined) {
        return '';
      }
    }

    return `${foundSector.localSectorCode} - ${foundSector.description}`;
  }

  customerToString(customer?: Customer): string {
    if (customer === undefined) {
      return '';
    }

    let returnValue = customer.name ?? '';

    if (customer.countryCode) {
      returnValue += ` (${this.countryCodeToCountryName(customer.countryCode)})`;
    }

    return returnValue;
  }

  // TODO: move to a service (and move country codes out from 'onboarding.general')
  private countryCodeToCountryName(countryCode?: string): string {
    if (countryCode === undefined || countryCode === '') {
      return '';
    }

    return this.translate(`onboarding.general.countries.${countryCode.toLowerCase()}`) ?? '';
  }

  private optionalBooleanToString(value?: boolean): string {
    if (value === undefined || value === null) {
      return '';
    }

    return this.translate(value ? 'common.yes' : 'common.no');
  }
}
