import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalculationInfoModel } from '../calculation-info.model';

@Component({
  selector: 'app-calculation-info-pop-up',
  templateUrl: './calculation-info-pop-up.component.html',
  styleUrls: ['./calculation-info-pop-up.component.scss'],
})
export class CalculationInfoPopUpComponent implements OnInit {
  info: CalculationInfoModel = {};

  constructor(
    public dialogRef: MatDialogRef<CalculationInfoPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) private data: CalculationInfoModel,
  ) {}

  ngOnInit(): void {
    this.info = this.data;
  }

  close(): void {
    this.dialogRef.close();
  }
}
