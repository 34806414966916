// eslint-disable-next-line no-shadow
export enum Role {
  Admin = 'ADMIN',
  SME = 'SME',
  User = 'USER',
  Enterprise = 'ENTERPRISE',
  EnterpriseSSO = 'ENTERPRISE_SSO',
  SMEViewer = 'SME_VIEWER',
}

export function toRole(roleName: string): Role {
  if (roleName === 'admin') {
    return Role.Admin;
  }
  if (roleName === 'sme') {
    return Role.SME;
  }
  if (roleName === 'sme_viewer') {
    return Role.SMEViewer;
  }
  if (roleName === 'user') {
    return Role.User;
  }
  if (roleName === 'enterprise') {
    return Role.Enterprise;
  }
  if (roleName === 'enterprise_sso') {
    return Role.EnterpriseSSO;
  }

  throw new Error('Unrecognised role');
}
