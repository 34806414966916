import { Component } from '@angular/core';
import { AbstractCo2QuestionnaireDirective } from '../abstract-co2-questionnaire.directive';

@Component({
  selector: 'app-co2-questionnaire-energy',
  templateUrl: './co2-questionnaire-energy.component.html',
  styleUrls: ['./co2-questionnaire-energy.component.scss'],
})
export class Co2QuestionnaireEnergyComponent extends AbstractCo2QuestionnaireDirective {
  greenElectricityAnswered(value: boolean): void {
    this.answers.greenElectricity = value;
    this.onInputBlur();
  }

  heatPumpAnswered(value: boolean): void {
    this.answers.heatPump = value;
    this.onInputBlur();
  }
}
