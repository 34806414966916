import { Action } from '../../../shared/xs/actions';

export interface Co2OffsettingPopupShownActionPayload {
  hasBeenChecked: boolean;
}

export class CheckCo2OffsettingFirstTimeAction implements Action {
  static readonly type = '[Co2Offsetting] first popup shown action';

  constructor(public payload: Co2OffsettingPopupShownActionPayload) {}
}
