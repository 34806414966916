import { Component, OnInit } from '@angular/core';
import { StatusInfoModel } from '../../../../shared/questionnaire-header/status-info.model';
import { ImpactScanRevampDetailsModel } from '../impact-scan-revamp.model';
import { ImpactScanRevampService } from '../impact-scan-revamp.service';
import { EeveryTranslateService } from '../../../../shared/eevery.translate.service';
import { UserStatus } from '../../../assessment-list/user-status.enum';
import { GriEntityModel, RelevantThemeModel } from '../../../../shared/relevant-theme/relevant-theme.model';
import { SubmitImpactScanAction } from '../xs/submit-impact-scan.action';
import { SubmitQuestionnairePopupComponent } from '../../../../shared/submit-questionnaire-popup/submit-questionnaire-popup.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DashboardService } from '../../../dashboard.service';
import { AddThemePopupComponent } from './add-theme/add-theme-popup.component';
import { RemoveThemeAction } from '../xs/remove-theme.action';
import { PopUpComponent } from '../../../../shared/pop-up/pop-up.component';
import { AddThemeAction } from '../xs/add-theme.action';
import { ChangedThemePopupComponent } from './changed-theme-popup/changed-theme-popup.component';

@Component({
  selector: 'app-themes',
  templateUrl: './themes.component.html',
  styleUrls: ['./themes.component.scss'],
})
export class ThemesComponent implements OnInit {
  impactScanDetails!: ImpactScanRevampDetailsModel;

  themesThatAreOpened: Set<string> = new Set<string>();

  themes: RelevantThemeModel[][] = [];

  addedThemes: RelevantThemeModel[][] = [];

  unfilteredThemes: RelevantThemeModel[][] = [];

  unfilteredAddedThemes: RelevantThemeModel[][] = [];

  changedThemes: RelevantThemeModel[] = [];

  newThemes: RelevantThemeModel[] = [];

  previousRelevantThemes: RelevantThemeModel[] = [];

  filter = '';

  navMenu = [
    {
      matImg: 'business',
      title: 'impact_scan',
      route: '/dashboard/sme/impact-scan-v2/',
      exact: true,
    },
    {
      matImg: 'format_list_numbered',
      title: 'themes',
      route: '/dashboard/sme/impact-scan-v2/themes',
    },
  ];

  statusInfo?: StatusInfoModel;

  categoriesForHeader = [
    {
      categoryName: 'impact_scan.themes.category_title',
      isValid: (): boolean => false,
    },
  ];

  navigationTarget = { route: 'dashboard/sme', params: {} };

  constructor(
    private impactScanService: ImpactScanRevampService,
    private translateService: EeveryTranslateService,
    private dashboardService: DashboardService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.loadImpactScan();

    if (this.impactScanDetails.status === 'DRAFT') {
      this.impactScanService.navigateToPage('/dashboard/sme/impact-scan-v2');
    } else {
      this.initPage();
      this.translateService.onLanguageChange().subscribe((event) => {
        this.themes = this.sortThemes(this.unfilteredThemes, event.lang, true);
        this.addedThemes = this.sortThemes(this.unfilteredAddedThemes, event.lang, false);
      });
    }

    this.navigationTarget.route += `/${this.dashboardService.getNavigationTarget().toLowerCase()}`;
  }

  private loadImpactScan(): void {
    const impactScanFromState = this.impactScanService.getImpactScanFromState();

    if (!impactScanFromState) {
      this.impactScanService.navigateToPage('/dashboard/sme');
    } else {
      this.impactScanDetails = impactScanFromState;
    }
  }

  private initPage(): void {
    if (this.impactScanDetails.viewOnly) {
      this.statusInfo = {
        name: `${this.impactScanDetails.firstName} ${this.impactScanDetails.lastName}`,
        status: `dashboard.status.${this.impactScanDetails.status.toLowerCase()}`,
        userDeleted: this.impactScanDetails.userStatus === UserStatus.INACTIVE,
      };
    }
    this.loadAndSortThemes();
  }

  private loadAndSortThemes(): void {
    if (this.impactScanDetails.isPrefilled) {
      if (this.impactScanDetails.previousRelevantThemes && this.impactScanDetails.previousRelevantThemes.length > 0) {
        this.previousRelevantThemes = this.impactScanDetails.previousRelevantThemes;
      }
      this.newThemes = this.impactScanDetails.newThemes;
      this.changedThemes = this.impactScanDetails.changedThemes;
    }
    this.unfilteredThemes = JSON.parse(JSON.stringify(this.impactScanDetails.relevantThemes));
    this.themes = this.sortThemes(this.unfilteredThemes, this.translateService.getLanguage(), true);

    this.unfilteredAddedThemes = JSON.parse(JSON.stringify(this.impactScanDetails.userAddedTopics));
    this.addedThemes = this.sortThemes(this.unfilteredAddedThemes, this.translateService.getLanguage(), false);
  }

  sortThemes(relevantThemes: RelevantThemeModel[][], language: string, moveNewThemes: boolean): RelevantThemeModel[][] {
    const themes: RelevantThemeModel[][] = JSON.parse(JSON.stringify(relevantThemes));

    if (language === 'nl_NL') {
      themes.sort((firstList, secondList) =>
        firstList[0].standard.nameNL.toLowerCase().localeCompare(secondList[0].standard.nameNL.toLowerCase()),
      );
    } else {
      themes.sort((firstList, secondList) =>
        firstList[0].standard.nameUK.toLowerCase().localeCompare(secondList[0].standard.nameUK.toLowerCase()),
      );
    }

    if (moveNewThemes) {
      return this.moveNewThemesToTop(themes);
    }

    return themes;
  }

  private moveNewThemesToTop(themes: RelevantThemeModel[][]): RelevantThemeModel[][] {
    let newThemes: RelevantThemeModel[][] = [];
    let oldThemes: RelevantThemeModel[][] = [];

    themes.forEach((themeGroup) => {
      if (this.checkNewThemeLabel(themeGroup)) {
        newThemes.push(themeGroup);
      } else {
        oldThemes.push(themeGroup);
      }
    });

    const language = this.translateService.getLanguage();

    newThemes = this.sortThemes(newThemes, language, false);
    oldThemes = this.sortThemes(oldThemes, language, false);

    return newThemes.concat(oldThemes);
  }

  checkNewThemeLabel(theme: RelevantThemeModel[]): boolean {
    const foundTheme = this.newThemes.find((newTheme) => newTheme.standard.id === theme[0].standard.id);

    return foundTheme !== undefined;
  }

  filterThemes(): void {
    const language = this.translateService.getLanguage();

    const themeGroups: RelevantThemeModel[][] = [];

    this.unfilteredThemes.forEach((themeGroup) => {
      const groupAfterFilter = themeGroup.filter((theme) => this.themeMatchesFilter(theme, language));

      if (groupAfterFilter && groupAfterFilter.length > 0) {
        themeGroups.push(groupAfterFilter);
      }
    });

    this.themes = this.sortThemes(themeGroups, language, true);

    const addedThemeGroups: RelevantThemeModel[][] = [];

    this.unfilteredAddedThemes.forEach((themeGroup) => {
      const groupAfterFilter = themeGroup.filter((theme) => this.themeMatchesFilter(theme, language));

      if (groupAfterFilter && groupAfterFilter.length > 0) {
        addedThemeGroups.push(groupAfterFilter);
      }
    });

    this.addedThemes = this.sortThemes(addedThemeGroups, language, false);
  }

  private themeMatchesFilter(theme: RelevantThemeModel, language: string): boolean {
    if (language === 'nl_NL') {
      return this.includesFilter(theme.standard.nameNL) || this.includesFilter(theme.disclosure.nameNL);
    }

    return this.includesFilter(theme.standard.nameUK) || this.includesFilter(theme.disclosure.nameUK);
  }

  private includesFilter(data: string): boolean {
    return data.toLowerCase().includes(this.filter.toLowerCase());
  }

  back(): void {
    this.impactScanService.navigateToPage('/dashboard/sme/impact-scan-v2');
  }

  submit(): void {
    if (!this.impactScanDetails) {
      return;
    }

    this.dialog
      .open(SubmitQuestionnairePopupComponent, {
        width: '40vw',
        autoFocus: false,
        data: {
          title: 'pop_up.complete_impact_scan.title',
          text: 'pop_up.complete_impact_scan.body',
          submitButton: 'pop_up.complete_impact_scan.finish',
          cancelButton: 'pop_up.complete_impact_scan.back',
        },
      })
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm && this.impactScanDetails) {
          this.impactScanService.dispatchAction(new SubmitImpactScanAction({ id: this.impactScanDetails.id }));
        }
      });
  }

  getRedButtonText(): string {
    if (!this.impactScanDetails.viewOnly) {
      return 'impact_scan.themes.submit';
    }

    return 'impact_scan.themes.return_to_list';
  }

  handleRedButtonClicked(): void {
    if (!this.impactScanDetails) {
      return;
    }

    if (this.impactScanDetails.viewOnly) {
      this.impactScanService.navigateToPage('/dashboard/sme/assessments');

      return;
    }

    this.submit();
  }

  handleOpenClose(theme: RelevantThemeModel[], isOpen: boolean): void {
    const standardsName = this.getStandardsName(theme);

    if (!standardsName) {
      return;
    }

    if (isOpen) {
      this.themesThatAreOpened.add(standardsName);
    } else {
      this.themesThatAreOpened.delete(standardsName);
    }
  }

  getStandardsName(theme: RelevantThemeModel[]): string | undefined {
    if (theme.length === 0) {
      return undefined;
    }

    return theme[0].standard.nameUK;
  }

  addButtonClicked(): void {
    this.impactScanService.getAddableThemes(this.impactScanDetails.id).subscribe((themesFromBackend) => {
      const idsToRemove = this.impactScanDetails.userAddedTopics.flat().map((topic) => topic.standard.id);
      const filteredThemes = themesFromBackend.filter((theme) => !idsToRemove.includes(theme.id));

      this.dialog
        .open(AddThemePopupComponent, {
          width: '60vw',
          autoFocus: false,
          data: {
            selectableThemes: filteredThemes,
            maxThemes: 2 - this.impactScanDetails.userAddedTopics.length,
            changedThemes: this.changedThemes,
          },
        })
        .afterClosed()
        .subscribe((possibleThemes: GriEntityModel[]) => {
          this.saveAddedThemes(possibleThemes);
        });
    });
  }

  private saveAddedThemes(possibleThemes: GriEntityModel[]): void {
    if (possibleThemes !== undefined) {
      this.impactScanService
        .dispatchActionAndObserve(
          new AddThemeAction({
            id: this.impactScanDetails.id,
            themes: possibleThemes,
          }),
        )
        .subscribe(() => {
          this.loadImpactScan();
          this.initPage();
        });
    }
  }

  async removeTheme(theme: GriEntityModel): Promise<void> {
    const themeName = this.translateService.getLanguage() === 'nl_NL' ? theme.nameNL : theme.nameUK;

    this.confirmationPopup(themeName)
      .afterClosed()
      .subscribe(async (confirmed) => {
        if (confirmed) {
          await this.impactScanService.dispatchActionAndWait(
            new RemoveThemeAction({
              id: this.impactScanDetails.id,
              theme,
            }),
          );

          this.loadImpactScan();
          this.loadAndSortThemes();
        }
      });
  }

  private confirmationPopup(themeName: string): MatDialogRef<PopUpComponent> {
    const data = {
      title: 'impact_scan.themes.delete_popup.title',
      message: 'impact_scan.themes.delete_popup.body',
      messageParams: { themeName },
      okText: 'impact_scan.themes.delete_popup.delete',
      okColour: 'warn',
      cancelText: 'impact_scan.themes.delete_popup.back',
    };

    return this.dialog.open(PopUpComponent, {
      width: '600px',
      autoFocus: false,
      data,
    });
  }

  showChangedThemesPopup(): void {
    this.dialog.open(ChangedThemePopupComponent, {
      width: '60vw',
      autoFocus: false,
      data: { changedThemes: this.changedThemes },
    });
  }
}
