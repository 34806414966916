export type Operator = '-' | '+' | '*' | '/' | '(' | ')';

export function isOperator(token: string): token is Operator {
  return token === '-' || token === '+' || token === '*' || token === '/' || token === '(' || token === ')';
}

export function format(input: string): string {
  if (isOperator(input)) {
    return input;
  }

  return input.replace('.', ',');
}

export function yard(infix: string[]): string[] {
  const ops = { '+': 1, '-': 1, '*': 2, '/': 2 };
  const peek = (arr: string[]): string => arr[arr.length - 1];
  const stack: string[] = [];

  return infix
    .reduce((output: string[], token) => {
      if (!isOperator(token)) {
        output.push(token);
      } else {
        if (token in ops) {
          // @ts-ignore
          while (peek(stack) && ops[token] <= ops[peek(stack)]) {
            const lastValue = stack.pop();

            if (lastValue) {
              output.push(lastValue);
            }
          }

          stack.push(token);
        }

        if (token === '(') {
          stack.push(token);
        }

        if (token === ')') {
          while (stack.length > 0 && peek(stack) !== '(') {
            const lastValue = stack.pop();

            if (lastValue) {
              output.push(lastValue);
            }
          }

          stack.pop();
        }
      }

      return output;
    }, [])
    .concat(stack.reverse());
}

export function rpn(tokens: string[]): number {
  const stack: number[] = [];

  tokens.forEach((token) => {
    if (!isOperator(token)) {
      stack.push(parseFloat(token));
    } else if (stack.length < 2) {
      throw new Error('Syntax error.');
    } else {
      const val2 = stack.pop();
      const val1 = stack.pop();

      if (val1 !== undefined && val2 !== undefined) {
        /* eslint-disable @typescript-eslint/indent */
        // eslint-disable-next-line default-case
        switch (token) {
          case '+':
            stack.push(val1 + val2);
            break;
          case '*':
            stack.push(val1 * val2);
            break;
          case '/':
            if (val2 === 0) {
              throw new Error('Division by zero.');
            }

            stack.push(val1 / val2);
            break;
          case '-':
            stack.push(val1 - val2);
            break;
        }
        /* eslint-enable @typescript-eslint/indent */
      }
    }
  });

  return stack.pop() || 0;
}
