import { Action } from '../../../xs/actions';
import { ColourSchemeModel } from '../../model/colour-scheme.model';

export interface SetSidenavActionPayload {
  colours: ColourSchemeModel;
  logo?: string;
}

export class SetSidenavAction implements Action {
  static readonly type = '[Sidenav] Set colours';

  constructor(public payload: SetSidenavActionPayload) {}
}
