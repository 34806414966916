/* eslint-disable @typescript-eslint/indent,prettier/prettier,no-trailing-spaces,no-shadow */
import { Component, Input } from '@angular/core';

export enum PasswordStatus {
  Weak = 'weak',
  Fair = 'fair',
  Good = 'good',
  Great = 'great',
}

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent {
  status!: PasswordStatus;

  currentPassword = '';

  show = false;

  @Input()
  public set password(value: string) {
    this.currentPassword = value;

    let counter = 0;

    if (this.checkUpperLetter()) {
      counter += 1;
    }
    if (this.checkNumbers()) {
      counter += 1;
    }
    if (this.checkSpecials()) {
      counter += 1;
    }
    if (value.length >= 8) {
      counter += 1;
    }

    switch (counter) {
      default:
      case 0:
      case 1:
        this.status = PasswordStatus.Weak;

        return;
      case 2:
        this.status = PasswordStatus.Fair;

        return;
      case 3:
        this.status = PasswordStatus.Good;

        return;
      case 4:
        this.status = PasswordStatus.Great;
    }
  }

  checkUpperLetter(): boolean {
    const characters = /[A-Z]/;

    return characters.test(this.currentPassword);
  }

  checkNumbers(): boolean {
    const characters = /[0-9]/;

    return characters.test(this.currentPassword);
  }

  checkSpecials(): boolean {
    const characters = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

    return characters.test(this.currentPassword);
  }
}
