import { Action } from '../../../shared/xs/actions';

export interface NewTokenReceivedPayload {
  accessToken: string;
}

export class NewTokenReceivedAction implements Action {
  static readonly type = '[Auth] New token received';

  constructor(public payload: NewTokenReceivedPayload) {}
}
