import { Action } from '../../../shared/xs/actions';
import { Role } from '../../../dashboard/profile/models/role';

export interface LoginAsCompanyActionPayload {
  clientId: number;
  refreshToken: string;
}

export class LoginAsCompanyAction implements Action {
  static readonly type = '[Enterprise] Login as (sme) company';

  constructor(public payload: LoginAsCompanyActionPayload) {}
}

export interface LoginAsCompanyActionResponse {
  accessToken: string;
  profile: {
    email: string;
    firstName: string;
    lastName: string;
    language: string;
    roles: Role[];
  };
  company: {
    name: string;
    draft: boolean;
    type: string;
    country: string;
  };
}
