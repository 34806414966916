import { Action } from '../../../shared/xs/actions';

export interface SetProfilePayload {
  email: string;
  firstName: string;
  lastName: string;
  language: string;
}

export class SetProfileAction implements Action {
  static readonly type = '[Profile] Set profile';

  constructor(public profilePayload: SetProfilePayload) {}
}
